import BackgroundComponent from "./components/background/Background.vue";
import LogarComponent from "./components/logar/Logar.vue";
import RegisterComponent from "./components/register/Register.vue";

import ComponentChoiceLanguage from "@/shared/components/choiceLanguage/ChoiceLanguage.vue";

import LoginService from "@/core/services/LoginService";

export default {
    data() {
        return {
            modalLanguage: false
        }
    },
    computed: {
        logged() {
            return this.$store.state.generalModule;
        }
    },
    methods: {
        registerNewUser() {
            this.$store.commit("createIdentifier");
            let data = this.$store.state.generalModule.dataNewUser;
            LoginService.methods.postNewUser(data);
        }
    },
    components: {
        BackgroundComponent,
        LogarComponent,
        RegisterComponent,
        ComponentChoiceLanguage
    },
    mounted() {
        const language = localStorage.getItem('language');
        if(!language) this.modalLanguage = true;
        // this.logar();
    }
}