import Vue from 'vue'
import Vuex from 'vuex'

import mapModule from "./modules/map"
import personModule from "./modules/personModule/person"
import monstersModule from "./modules/monstersModule/monsters"
import bossesModule from "./modules/bosses"
import generalModule from "./modules/generalModule/general"
import itemsModule from "./modules/itemsModule/items"
import spellModule from "./modules/spellModule/spell"
import propertiesModule from "./modules/propertiesModule/properties"
import craftModule from "./modules/craftModule/craft.js"
import skillModule from "./modules/skillModule/skills"
import classWorkModule from "./modules/classModule/classWork"
import randomEventsModule from "./modules/randomEventsModule/randomEvents"
import contractModule from './modules/contract/contract'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        personModule,
        monstersModule,
        bossesModule,
        mapModule,
        generalModule,
        itemsModule,
        spellModule,
        propertiesModule,
        craftModule,
        skillModule,
        classWorkModule,
        randomEventsModule,
        contractModule
    }
})