const mutations = {
    changeABI(state, abi) {
        state.contractABI = abi;
    },
    changeAddressContract(state, address) {
        state.addressContract = address;
    },
    changeNameContract(state, name) {
        state.nameContract = name;
    },
    changeSupply(state, supply) {
        state.supply = supply;
    },
    changeWalletAddress(state, address) {
        state.address = address;
    },
    changeIsConnected(state, boolean) {
        state.isConnected = boolean;
    },
    setWhiteList(state, array) {
        state.whiteList = array;
    },
    setNFTsURI(state, arrayUri) {
        state.nfts.uri = arrayUri;
    },
    setNFTsNewURI(state, arrayUri) {
        state.nfts.uri = arrayUri;
    },
    emptyNFTMintedList(state) {
        state.nfts.minted = [];
    },
    setNFTMintedList(state, item) {
        state.nfts.minted.push(item);
    },
    addNFTMintedList(state, array) {
        state.nfts.minted = array;
    },
    changeNFTViewList(state, boolean) {
        state.importNFTsView = boolean;
    },
    changeTypeContract(state, number) {
        // 1 = Box, 2 = Packs, 3 = Avatar e Skins
        state.typeContract = number;
    }
}

export default mutations;