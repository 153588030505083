import Phaser from "phaser";

import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;

export function updatePlayer(vm) {
    var keyboard = {};

    keyboard.a = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
    keyboard.d = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);

    if(vm.key) {
        vm.key.x = vm.player.x;
        vm.key.y = vm.player.y - 70;
    }
    if (!vm.player.body.embedded) {
        if(vm.key) vm.key.setAlpha(0);
        vm.interage = "none";
    } else {
        if(vm.key) vm.key.setAlpha(1);
    }
    if(vm.lightPlayer) {
        vm.lightPlayer.x = vm.player.x;
        vm.lightPlayer.y = vm.player.y;
    }
    if(vm.input.keyboard.checkDown(keyboard.a) ||
    vm.input.keyboard.checkDown(keyboard.d)) {
        if(vm.player.body.onFloor()) {
            vm.methods.isWalk = true;
            vm.player.play('walk', true);
        }
    }
    if(!vm.player.body.onFloor()) {
        if(vm.player.body.velocity.y < 0) vm.player.play('up', true);
        else vm.player.play('down', true);
        vm.sounds.walk.stop();
        vm.methods.isWalk = false;
    } else if (!vm.methods.isWalk) {
        vm.player.play('turn', true);
    }
}

export function updateActions(vm) {
    updatePlayer(vm);
    if(!vm.player.dead) {
        if(vm.player.y > vm.mapSize.y || statusPerson.state.mainPerson.infos[0].li[0].value <= 0) {
            vm.player.play('dead', true);
            vm.player.dead = true;
            vm.player.body.enable = false;
            var timeOut = setTimeout(() => {
                statusPerson.actions.deadPlayer(Store);
                clearTimeout(timeOut);
            }, 1200)
        }
        if(vm.enemyArray.length) {
            vm.enemyArray.forEach(enemy => {
                if(!enemy.body.enable) return;
                if(enemy.y > vm.mapSize.y - 100) {
                    enemy.body.enable = false;
                    enemy.alpha = 0;
                }
                var distance = vm.player.x - enemy.x > 0 ? vm.player.x - enemy.x : (vm.player.x - enemy.x) * -1;
                var distanceY = vm.player.y - enemy.y > 0 ? vm.player.y - enemy.y : (vm.player.y - enemy.y) * -1;
                var speed = Phaser.Math.Between(enemy.status.speed / 3, enemy.status.speed);
                if(distance < enemy.status.visionCamp + vm.player.body.sourceWidth && distanceY < enemy.status.visionCamp + vm.player.body.sourceHeight * 1.5) {
                    enemy.isAttack = true;
                    enemy.body.velocity.x = 0;
                    enemy.play(`${enemy.class}Attack`, true);
                    if (vm.player.x < enemy.x) {
                        if(enemy.status.collisions) enemy.body.offset.x = enemy.status.collisions.offset.flipxFalse;
                        if(enemy.status.flip) enemy.flipX = true;
                        else enemy.flipX = false;
                    } else if (vm.player.x > enemy.x) {
                        if(enemy.status.collisions) enemy.body.offset.x = enemy.status.collisions.offset.flipxTrue;
                        if(enemy.status.flip) enemy.flipX = false;
                        else enemy.flipX = true;
                    }
                    return;
                } else if (enemy.hp < enemy.status.maxhp || distance < (enemy.status.visionCamp + vm.player.body.sourceWidth) * 5 && distanceY < enemy.status.visionCamp + vm.player.body.sourceHeight * 1.5) {
                    if(enemy.body) {
                        if(enemy.fly) {vm.physics.moveToObject(enemy, vm.player, speed);}
                        else if (enemy.status.move === false) {
                            enemy.isAttack = false;
                            enemy.body.velocity.x = 0;
                            enemy.play(`${enemy.class}Idle`, true);
                            return;
                        }
                        else if(enemy.body.onFloor()) {vm.physics.moveTo(enemy, vm.player.x, enemy.y, speed);}
                        if (enemy.body.velocity.x <= 0) {
                            if(enemy.status.collisions) enemy.body.offset.x = enemy.status.collisions.offset.flipxFalse;
                            if(enemy.status.flip) enemy.flipX = true;
                            else enemy.flipX = false;
                        } else if (enemy.body.velocity.x > 0) {
                            if(enemy.status.collisions) enemy.body.offset.x = enemy.status.collisions.offset.flipxTrue;
                            if(enemy.status.flip) enemy.flipX = false;
                            else enemy.flipX = true;
                        }
                        enemy.isAttack = false;
                        enemy.play(`${enemy.class}Walk`, true);
                    }
                    return;
                }
                else {
                    if(enemy.body) {
                        enemy.isAttack = false;
                        enemy.body.velocity.x = 0;
                        enemy.play(`${enemy.class}Idle`, true);
                        return;
                    }
                }
            })
        }
    } else {
        if(vm.enemyArray.length) {
            vm.enemyArray.forEach(enemy => {
                enemy.body.enable = false;
                enemy.play(`${enemy.class}Idle`, true);
            })
        }
    }
}

export function updateActionsCamp(vm) {
    updatePlayer(vm);
    if(!vm.player.dead) {
        if(statusPerson.state.mainPerson.infos[0].li[0].value <= 0) {
            vm.player.play('dead', true);
            vm.player.dead = true;
            vm.player.body.enable = false;
            var timeOut = setTimeout(() => {
                statusPerson.actions.deadPlayer(Store);
                clearTimeout(timeOut);
            }, 1200)
        }
        if(vm.wave.monsterNumber == 0 && !vm.startWave) {
            vm.wave.currentWave++;
            vm.methods.nextWave(vm);
            vm.methods.showCount();
            vm.methods.navegateWave(vm, vm.typeCamp);
            return;
        }
        if(vm.enemyArray.length) {
            vm.enemyArray.forEach(enemy => {
                var distance = vm.player.x - enemy.x > 0 ? vm.player.x - enemy.x : (vm.player.x - enemy.x) * -1;
                if(distance < enemy.status.visionCamp) {
                    enemy.isAttack = true;
                    enemy.body.velocity.x = 0;
                    enemy.play(`${enemy.class}Attack`, true);
                    return;
                }
                var speed = Phaser.Math.Between(enemy.status.speed / 3, enemy.status.speed);
                if(enemy.body.onFloor()) vm.physics.moveTo(enemy, vm.player.x, 690, speed);
                enemy.isAttack = false;
                enemy.play(`${enemy.class}Walk`, true);
                enemy.hpBar.move(enemy.x, enemy.y - 50);
                if (enemy.body.velocity.x < 0) {
                    if(enemy.status.collisions) enemy.body.offset.x = enemy.status.collisions.offset.flipxFalse;
                    if(enemy.status.flip) enemy.flipX = true;
                    else enemy.flipX = false;
                } else if (enemy.body.velocity.x > 0) {
                    if(enemy.status.collisions) enemy.body.offset.x = enemy.status.collisions.offset.flipxTrue;
                    if(enemy.status.flip) enemy.flipX = false;
                    else enemy.flipX = true;
                }
            })
        }
    } else {
        if(vm.enemyArray.length) {
            for(var z = 0; z < vm.enemyArray.length; z++) {
                vm.enemyArray[z].body.enable = false;
                vm.enemyArray[z].play(`${vm.enemyArray[z].class}Idle`, true);
            }
        }
    }
}

function trapSpawn(vm, trap, positions, time) {
    positions.forEach(i => {
        vm.methods.spawnTrap(vm, trap, trap, i.x, i.y, time);
    })
}

export function updateBoss(vm, boss) {
    var bossMob = vm.boss.getChildren()[0], timeOut, intervalVar;
    updatePlayer(vm);
    switch(boss) {
        case "anubis":
            if(!vm.player.dead) {
                if(vm.player.y > vm.mapSize.y || statusPerson.state.mainPerson.infos[0].li[0].value <= 0) {
                    vm.player.play('dead', true);
                    vm.player.dead = true;
                    vm.player.body.enable = false;
                    timeOut = setTimeout(() => {
                        statusPerson.actions.deadPlayer(Store);
                        clearTimeout(timeOut);
                    }, 1200)
                }
                if(bossMob.body.enable) {
                    bossMob.body.velocity.x = 0;
                    if (vm.player.x < bossMob.x) {
                        if(bossMob.status.collisions) bossMob.body.offset.x = bossMob.status.collisions.offset.flipxFalse;
                        if(bossMob.status.flip) bossMob.flipX = true;
                        else bossMob.flipX = false;
                    } else if (vm.player.x > bossMob.x) {
                        if(bossMob.status.collisions) bossMob.body.offset.x = bossMob.status.collisions.offset.flipxTrue;
                        if(bossMob.status.flip) bossMob.flipX = false;
                        else bossMob.flipX = true;
                    }
                    switch(vm.randomAttack) {
                        case -1:
                            return;
                        case 0:
                            vm.randomAttack = Phaser.Math.Between(1, 3);
                            return;
                        case 1:
                            bossMob.body.setAllowGravity(true);
                            bossMob.play("anubisAttack2", true);
                            trapSpawn(
                                vm, 
                                "rock", 
                                [{x:Phaser.Math.Between(350, vm.mapSize.x), y:0}, {x:Phaser.Math.Between(150, vm.mapSize.x), y:0}, {x:Phaser.Math.Between(150, vm.mapSize.x), y:0}, {x:Phaser.Math.Between(150, vm.mapSize.x), y:0}, {x:Phaser.Math.Between(150, vm.mapSize.x), y:0}], 
                                3000
                            );
                            timeOut = setTimeout(() => {
                                vm.randomAttack = 0;
                                clearTimeout(timeOut);
                            }, 3000);
                            vm.randomAttack = -1;
                            vm.sounds.rockSky.play({volume: 1});
                            break;
                        case 2:
                            bossMob.body.setAllowGravity(false);
                            bossMob.x = Phaser.Math.Between(500, vm.mapSize.x - 350);
                            intervalVar = setInterval(() => {
                                if(bossMob.body.enable) {
                                    bossMob.play("anubisAttack4", true);
                                    vm.sounds.anubisBall.play({volume: 0.5});
                                    vm.methods.distanceAttackEnemy(vm, bossMob, "guided", "anubisBall", null, 250, {x: 8, y: 8}, 0, 4, 3500);
                                } else {
                                    vm.randomAttack = 0;
                                    clearInterval(intervalVar);
                                    clearTimeout(timeOut);
                                }
                            }, 1000)
                            timeOut = setTimeout(() => {
                                vm.randomAttack = 0;
                                clearInterval(intervalVar);
                                clearTimeout(timeOut);
                            }, 15000)
                            vm.randomAttack = -1;
                            break;
                        case 3:
                            bossMob.play("anubisSneer", true);
                            timeOut = setTimeout(() => {
                                vm.randomAttack = 0;
                                clearTimeout(timeOut);
                            }, 2000);
                            vm.randomAttack = -1;
                            vm.sounds.shout.play({volume: 1});
                            break;
                    }
                }
            } else {
                bossMob.body.enable = false;
                bossMob.play(`${bossMob.description}Idle`, true);
            }
            break;
    }
}