export default {
    state: {
        hpBossEnabled: false,
        bossStatus: {
            name: "",
            hp: 0,
            maxHp: 0
        },
        bosses: [
            {
                name: "Anúbis, O primeiro filho da morte",
                identification: "anubis",
                icon: "desert/bosses/pharaonic-mummy/Anubis.png",
                img: "desert/bosses/pharaonic-mummy/Anubis_idle.png",
                lvl: {
                    initial: 5,
                    end: 80
                },
                status: {
                    hp: 10000,
                    maxhp: 10000,
                    mp: 1,
                    energia: 20,
                    atk: 150,
                    def: 100,
                    agility: 30,
                    atkArea: {
                        x: 230,
                        y: 400
                    },
                    collisions: {
                        size: {
                            x: 30,
                            y: 55
                        },
                        offset: {
                            flipxFalse: 40,
                            flipxTrue: 12,
                            y: 17
                        },
                        atk: {
                            flipxFalse: 210,
                            flipxTrue: -70,
                            y: 65
                        }
                    }
                },
                loots: {
                    exp: 25000,
                    gold: [0,0],
                    items: [
                        {
                            name: "pó de múmia",
                            porcent: 70,
                            qtd: [10, 30]
                        },
                        {
                            name: "future grenade",
                            porcent: 25,
                            qtd: [3, 15]
                        }
                    ]
                }
            }
        ]
    },
    mutations: {
        changeHpBossEnabled(state, boolean) {
            state.hpBossEnabled = boolean;
        },
        changeBoss(state, boss) {
            state.bossStatus.name = boss.name;
            state.bossStatus.hp = boss.status.hp;
            state.bossStatus.maxHp = boss.status.maxhp;
        },
        changeHpBoss(state, value) {
            state.bossStatus.hp -= value;
        }
    }
}