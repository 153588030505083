import UpgradeBlock from "./upgrades/Upgrades.vue"

export default {
    components: {
        UpgradeBlock
    },
    data() {
        return {
        }
    }
}