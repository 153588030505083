import Phaser from "phaser";

import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods"
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateActions } from "@/shared/components-phaser/updateActions"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene2 extends Phaser.Scene {
    constructor(){
        super("external2");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.player;
        this.mapSize = {
            x: 12960,
            y: 2400
        };
        this.trapHurt = true;
        this.layer = {};
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/swamp/SFX_-_insects_night_LOOP.ogg');
        this.load.audio('music', '/resourcesInGame/sons/musics/swamp/Battle_-_Dancing_In_Flames.ogg');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/swamp/Tileset.png');
        this.load.image('objects1', '/resourcesInGame/title-set/tiles/swamp/Objects32x32.png');
        this.load.image('templo1', '/resourcesInGame/title-set/tiles/swamp/MainLevBuild.png');
        this.load.tilemapTiledJSON("mapSwamp2", "/resourcesInGame/maps/pantano/scene2-swamp.json");

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/swamp/1.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/swamp/5.png");
        this.load.image("trees", "/resourcesInGame/title-set/parallax/trees/swamp/2.png");
        this.load.image("trees2", "/resourcesInGame/title-set/parallax/trees/swamp/3.png");
        this.load.image("trees3", "/resourcesInGame/title-set/parallax/ground/swamp/4.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/clouds.png");

        //enemies
        this.load.spritesheet('lizardWarriorIdle', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-idle.png', {frameWidth: 68, frameHeight: 35});
        this.load.spritesheet('lizardWarriorWalk', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-walk.png', {frameWidth: 68, frameHeight: 35});
        this.load.spritesheet('lizardWarriorAttack', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-attack.png', {frameWidth: 68, frameHeight: 35});
        this.load.spritesheet('lizardWarriorDeath', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-dead.png', {frameWidth: 68, frameHeight: 35});
        
        this.load.spritesheet('mandrakeIdle', '/resourcesInGame/sprites/swamp/mandrake/mandrake-idle.png', {frameWidth: 57, frameHeight: 38});
        this.load.spritesheet('mandrakeWalk', '/resourcesInGame/sprites/swamp/mandrake/mandrake-walk.png', {frameWidth: 57, frameHeight: 38});
        this.load.spritesheet('mandrakeAttack', '/resourcesInGame/sprites/swamp/mandrake/mandrake-attack.png', {frameWidth: 57, frameHeight: 38});
        this.load.spritesheet('mandrakeDeath', '/resourcesInGame/sprites/swamp/mandrake/mandrake-dead.png', {frameWidth: 57, frameHeight: 38});
        
        this.load.spritesheet('grandLizardIdle', '/resourcesInGame/sprites/swamp/grandLizard/Idle.png', {frameWidth: 200, frameHeight: 200});
        this.load.spritesheet('grandLizardWalk', '/resourcesInGame/sprites/swamp/grandLizard/walk.png', {frameWidth: 200, frameHeight: 200});
        this.load.spritesheet('grandLizardAttack', '/resourcesInGame/sprites/swamp/grandLizard/Attack3.png', {frameWidth: 200, frameHeight: 200});
        this.load.spritesheet('grandLizardDeath', '/resourcesInGame/sprites/swamp/grandLizard/Death.png', {frameWidth: 200, frameHeight: 200});
        
        this.load.spritesheet('messengerIdle', '/resourcesInGame/sprites/swamp/messenger/Idle.png', {frameWidth: 200, frameHeight: 200});
        this.load.spritesheet('messengerWalk', '/resourcesInGame/sprites/swamp/messenger/walk.png', {frameWidth: 200, frameHeight: 200});
        this.load.spritesheet('messengerAttack', '/resourcesInGame/sprites/swamp/messenger/Attack1.png', {frameWidth: 200, frameHeight: 200});
        this.load.spritesheet('messengerDeath', '/resourcesInGame/sprites/swamp/messenger/Death.png', {frameWidth: 200, frameHeight: 200});
        
        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            // Enemy
            //lizard warrior
            vm.anims.create({
                key: "lizardWarriorIdle",
                frames: vm.anims.generateFrameNumbers("lizardWarriorIdle", {start: 0, end: 3}),
                frameRate: 7,
                repeat: -1
            }),
            vm.anims.create({
                key: "lizardWarriorWalk",
                frames: vm.anims.generateFrameNumbers("lizardWarriorWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "lizardWarriorAttack",
                frames: vm.anims.generateFrameNumbers("lizardWarriorAttack", {start: 0, end: 4}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "lizardWarriorDeath",
                frames: vm.anims.generateFrameNumbers("lizardWarriorDeath", {start: 0, end: 6}),
                frameRate: 10,
                repeat: 0
            })
            //mandrake
            vm.anims.create({
                key: "mandrakeIdle",
                frames: vm.anims.generateFrameNumbers("mandrakeIdle", {start: 0, end: 3}),
                frameRate: 7,
                repeat: -1
            }),
            vm.anims.create({
                key: "mandrakeWalk",
                frames: vm.anims.generateFrameNumbers("mandrakeWalk", {start: 0, end: 5}),
                frameRate: 8,
                repeat: -1
            }),
            vm.anims.create({
                key: "mandrakeAttack",
                frames: vm.anims.generateFrameNumbers("mandrakeAttack", {start: 0, end: 6}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "mandrakeDeath",
                frames: vm.anims.generateFrameNumbers("mandrakeDeath", {start: 0, end: 9}),
                frameRate: 10,
                repeat: 0
            })
            //grand lizard
            vm.anims.create({
                key: "grandLizardIdle",
                frames: vm.anims.generateFrameNumbers("grandLizardIdle", {start: 0, end: 9}),
                frameRate: 7,
                repeat: -1
            }),
            vm.anims.create({
                key: "grandLizardWalk",
                frames: vm.anims.generateFrameNumbers("grandLizardWalk", {start: 0, end: 7}),
                frameRate: 8,
                repeat: -1
            }),
            vm.anims.create({
                key: "grandLizardAttack",
                frames: vm.anims.generateFrameNumbers("grandLizardAttack", {start: 0, end: 8}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 150
            }),
            vm.anims.create({
                key: "grandLizardDeath",
                frames: vm.anims.generateFrameNumbers("grandLizardDeath", {start: 0, end: 9}),
                frameRate: 10,
                repeat: 0
            })
            //grand lizard
            vm.anims.create({
                key: "messengerIdle",
                frames: vm.anims.generateFrameNumbers("messengerIdle", {start: 0, end: 8}),
                frameRate: 7,
                repeat: -1
            }),
            vm.anims.create({
                key: "messengerWalk",
                frames: vm.anims.generateFrameNumbers("messengerWalk", {start: 0, end: 7}),
                frameRate: 8,
                repeat: -1
            }),
            vm.anims.create({
                key: "messengerAttack",
                frames: vm.anims.generateFrameNumbers("messengerAttack", {start: 0, end: 8}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 150
            }),
            vm.anims.create({
                key: "messengerDeath",
                frames: vm.anims.generateFrameNumbers("messengerDeath", {start: 0, end: 6}),
                frameRate: 10,
                repeat: 0
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 100, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(1920, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds3 = vm.add.image(3840, -250, 'clouds').setOrigin(0).setScrollFactor(.15);

            var mountainsBack = vm.add.image(0, -60, 'trees').setOrigin(0).setScrollFactor(.33).setScale(2.5);
            var mountainsBack2 = vm.add.image(1440, -60, 'trees').setOrigin(0).setScrollFactor(.33).setScale(2.5);
            var mountainsBack3 = vm.add.image(2880, -60, 'trees').setOrigin(0).setScrollFactor(.33).setScale(2.5);
            var mountainsBack4 = vm.add.image(4320, -60, 'trees').setOrigin(0).setScrollFactor(.33).setScale(2.5);
            var mountainsBack5 = vm.add.image(5760, -60, 'trees').setOrigin(0).setScrollFactor(.33).setScale(2.5);
            var mountainsBack6 = vm.add.image(7200, -60, 'trees').setOrigin(0).setScrollFactor(.33).setScale(2.5);

            var mountains = vm.add.image(0, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains2 = vm.add.image(1440, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains3 = vm.add.image(2880, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains4 = vm.add.image(4320, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains5 = vm.add.image(5760, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains6 = vm.add.image(7200, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains7 = vm.add.image(8640, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains8 = vm.add.image(10080, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains9 = vm.add.image(11520, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);
            var mountains10 = vm.add.image(12960, -120, 'trees2').setOrigin(0).setScrollFactor(.66).setScale(2.5);

            var bushes = vm.add.image(0, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes2 = vm.add.image(1440, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes3 = vm.add.image(2880, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes4 = vm.add.image(4320, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes5 = vm.add.image(5760, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes6 = vm.add.image(7200, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes7 = vm.add.image(8640, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes8 = vm.add.image(10080, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes9 = vm.add.image(11520, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);
            var bushes10 = vm.add.image(12960, -60, 'trees3').setOrigin(0).setScrollFactor(.75).setScale(2.5);

            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;
            clouds3.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;
            mountains3.displayHeight = vm.sys.game.config.height;
            mountains4.displayHeight = vm.sys.game.config.height;
            mountains5.displayHeight = vm.sys.game.config.height;
            mountains6.displayHeight = vm.sys.game.config.height;
            mountains7.displayHeight = vm.sys.game.config.height;
            mountains8.displayHeight = vm.sys.game.config.height;
            mountains9.displayHeight = vm.sys.game.config.height;
            mountains10.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;
            mountainsBack3.displayHeight = vm.sys.game.config.height;
            mountainsBack4.displayHeight = vm.sys.game.config.height;
            mountainsBack5.displayHeight = vm.sys.game.config.height;
            mountainsBack6.displayHeight = vm.sys.game.config.height;

            bushes.displayHeight = vm.sys.game.config.height;
            bushes2.displayHeight = vm.sys.game.config.height;
            bushes3.displayHeight = vm.sys.game.config.height;
            bushes4.displayHeight = vm.sys.game.config.height;
            bushes5.displayHeight = vm.sys.game.config.height;
            bushes6.displayHeight = vm.sys.game.config.height;
            bushes7.displayHeight = vm.sys.game.config.height;
            bushes8.displayHeight = vm.sys.game.config.height;
            bushes9.displayHeight = vm.sys.game.config.height;
            bushes10.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;

            var ground = vm.add.image(0, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground2 = vm.add.image(1440, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground3 = vm.add.image(2880, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground4 = vm.add.image(4320, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground5 = vm.add.image(5760, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground6 = vm.add.image(7200, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground7 = vm.add.image(8640, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground8 = vm.add.image(10080, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground9 = vm.add.image(11520, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);
            var ground10 = vm.add.image(12960, -30, 'ground').setOrigin(0).setScrollFactor(.8).setScale(2.5);

            ground.displayHeight = vm.sys.game.config.height;
            ground2.displayHeight = vm.sys.game.config.height;
            ground3.displayHeight = vm.sys.game.config.height;
            ground4.displayHeight = vm.sys.game.config.height;
            ground5.displayHeight = vm.sys.game.config.height;
            ground6.displayHeight = vm.sys.game.config.height;
            ground7.displayHeight = vm.sys.game.config.height;
            ground8.displayHeight = vm.sys.game.config.height;
            ground9.displayHeight = vm.sys.game.config.height;
            ground10.displayHeight = vm.sys.game.config.height;
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "nextScene":
                        vm.scene.restart(); vm.scene.start('internal1'); break;
                    case "tree":
                        vm.methods.workCommom(vm, vm.worked.item, vm.worked.time, vm.worked.message, vm.worked.tree); break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }
        
        function monsterSpawn(vm) {
            vm.enemyArray = [];
            vm.methods.createEnemy(vm, "lizardWarrior", "lizardWarriorIdle", "lizardWarriorIdle", 25, 2.5, 3, {bodyX: 23, bodyY: 30}, {x: 34, y: 4}, 100);
            vm.methods.createEnemy(vm, "mandrake", "mandrakeIdle", "mandrakeIdle", 40, 2.5, 3, {bodyX: 20, bodyY: 32}, {x: 26, y: 5}, 100);
            vm.methods.createEnemy(vm, "grandLizard", "grandLizardIdle", "grandLizardIdle", 5, 2.5, 3, {bodyX: 44, bodyY: 56}, {x: 71, y: 72}, 1200);
            vm.methods.createEnemy(vm, "messenger", "messengerIdle", "messengerIdle", 2, 2.5, 3, {bodyX: 45, bodyY: 53}, {x: 80, y: 59}, 2250);
        }
    
        function createScene(vm) {
            parallax(vm);

            vm.map = vm.make.tilemap({ key: "mapSwamp2", tileWidth: 32, tileHeight: 32});
            var tileset = vm.map.addTilesetImage("Tileset", "floor1");
            var objects1 = vm.map.addTilesetImage("Objects32x32", "objects1");
            var templo1 = vm.map.addTilesetImage("MainLevBuild", "templo1");
            vm.layer.background = vm.map.createLayer("background", tileset, 0, 0).setScale(1.5);
            vm.layer.backgroundTemplo = vm.map.createLayer("backgroundTemplo", templo1, 0, 0).setScale(1.5);
            vm.layer.camada2BackgroundTemplo = vm.map.createLayer("camada2BackgroundTemplo", templo1, 0, 0).setScale(1.5);
            vm.layer.objects = vm.map.createLayer("objects", objects1, 0, 0).setScale(1.5);
            vm.layer.floor = vm.map.createLayer("floor", [tileset, templo1], 0, 0).setScale(1.5);
            vm.layer.water = vm.map.createLayer("water", tileset, 0, 0).setScale(1.5).setDepth(15).setAlpha(0.85);

            vm.layer.floor.setCollisionBetween(0, 3000);
        }

        function createLimits(vm) {
            var initial; 
            vm.limits.add(vm.add.rectangle(50, 800, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];

            vm.physics.add.overlap(initial, vm.player, function() {
                vm.sounds.ambient.stop();
                vm.sounds.theme.stop();
                vm.scene.start('external1');
            });
        }

        this.sounds.ambient = this.sound.add('ambient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.ambient.play({loop: true, volume: 0.8});
        this.sounds.theme.play({loop: true, volume: 0.3});

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.animals = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        playerImport.createPlayer(this);
        createScene(this);
        createLimits(this);

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);
        this.physics.add.collider(this.animals, this.layer.floor);
        this.physics.add.collider(this.physicObject, this.layer.floor);
        this.physics.add.collider(this.limits, this.layer.floor);
        this.physics.add.collider(this.monsterAttack, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        // this.methods.createAnimal(this, "elk", "elkIdle", "elkIdle", {x: 2.5, y: 3}, {x: 350, y: 600}, true);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        
        monsterSpawn(this);
        
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        updateActions(this);
    }
}