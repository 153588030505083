import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;

export default function nordicBarbarian(vm) {
    vm.load.on('progress', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, true);
    });

    vm.load.on('complete', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, false);
    });
    
    // Sons
    vm.load.audio('attack', '/resourcesInGame/sons/actions/axe/Heavy_sword_woosh_1.wav'); 
    vm.load.audio('hit', '/resourcesInGame/sons/actions/hit/ESM_Fantasy_Game_Cloth_Armor_Hit_B_Weapon_Medieval.wav');
    vm.load.audio('monsterDead', '/resourcesInGame/sons/monsters/dead/ESM_Fantasy_Game_Weapon_Impact_Blood_Weapon_Medieval.wav');
    vm.load.audio('usePotion', '/resourcesInGame/sons/actions/use/potion/Alchemy-9.wav');
    vm.load.audio('openPotion', '/resourcesInGame/sons/actions/use/potion/Bottle_Open-1_1.wav');
    vm.load.audio('walk', '/resourcesInGame/sons/actions/walk/ESM_Fantasy_Game_Footstep_Grass_Light_H.wav');
    vm.load.audio('roll', '/resourcesInGame/sons/actions/roll/ESM_Fantasy_Game_Footstep_Grass_Heavy_D.wav');
    vm.load.audio('portal', '/resourcesInGame/sons/actions/portal/ESM_Fantasy_Game_Magic_Airy_Sting_Accent_Medieval__Spell_Cast.wav');

    // Sprites
    vm.load.spritesheet('nordicBarbarianIdle', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-idle.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianWalk', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-run.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianJump', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-jump.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianFall', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-fall.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianDead', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-dead.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianRoll', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-jump.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianRun', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-run.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianAttack1', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-attack1.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianAttack2', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-attack1.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianAttack3', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-attack1.png', { frameWidth: 100, frameHeight: 64 });
    vm.load.spritesheet('nordicBarbarianAttack4', '/resourcesInGame/sprites/main/nfts/swordman/nordicBarbarian/barbarian-attack1.png', { frameWidth: 100, frameHeight: 64 });
}