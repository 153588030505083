import LoginService from "@/core/services/LoginService";
import { connectWallet, loadContractNetworkCurrent, balanceOf } from "@/shared/nftInteract/interact.js";
import { mapGetters } from "vuex"

import contractsMock from "@/shared/nftInteract/mocks/contracts";
import abisMock from "@/shared/nftInteract/mocks/abis";

export default {
    data () {
        return {
            formRegister: {
                username: {
                    value: '',
                    error: ''
                },
                email: {
                    value: '',
                    error: ''
                },
                // dateofbirth: {
                //     value: '',
                //     error: ''
                // },
                password: {
                    value: '',
                    error: ''
                }
            }
        }
    },
    computed: {
        dataNewUser() {
            return this.$store.state.generalModule.dataNewUser;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        async initWalletAccess() {
            if(window.ethereum) {
              try {
                const accounts = await window.ethereum.request({
                  method: "eth_accounts"
                });
                if(accounts.length) {
                    this.$store.commit("changeIsConnected", true);
                    this.$store.commit("changeWalletAddress", accounts[0]);
                } else {
                  throw Error;
                }
              } catch(err) {
                this.$store.commit("changeIsConnected", false);
              }
            } else {
              alert(this.$store.getters.translateWords("Metamask não encontrada, por favor, instale em seu navegador"))
            }
        },
        checkForm (input) {
            if (!this.formRegister[input].value) {
                this.formRegister[input].error = `${input} ${this.$store.getters.translateWords('é obrigatório')}`;
                this.$store.commit("changeErrorForm", 1);
                return;
            }
            else if(input == 'email' && !this.validEmail(this.formRegister[input].value)) {
                this.formRegister[input].error = `Não é um email válido`;
                this.$store.commit("changeErrorForm", 1);
                return;
            }
            this.formRegister[input].error = null;
            this.$store.commit("changeErrorForm", 0);
        },
        async changeConnectWallet() {
            const walletResponse = await connectWallet();
            this.$store.commit("changeIsConnected", walletResponse.connectedStatus);
            if(this.$store.state.contractModule.isConnected) {
                this.$store.commit("changeWalletAddress", walletResponse.address);
            }
            Location.reload();
        },
        async changeContract(contractName) {
            var address = contractsMock[contractName];
            var abi = abisMock[contractName];
            this.$store.commit("changeNameContract", contractName);
            this.$store.commit("changeAddressContract", address);
            this.$store.commit("changeABI", abi);
            await this.refreshSearch();
        },
        async refreshSearch() {
            await loadContractNetworkCurrent(this.$store.state.contractModule.contractABI, this.$store.state.contractModule.addressContract);
        },
        async verifyNFTsWallet() {
            const contracts = ["ArmorBoxContract","SwordBoxContract","AxeBoxContract","ShieldBoxContract","PacksContract"];
            var contractModule = this.$store.state.contractModule;
            for(var i = 0; i < contracts.length; i++) {
                await this.changeContract(contracts[i]);
                await this.refreshSearch();
                var balance = await balanceOf(contractModule.address);
                if(balance > 0) {
                    return true;
                }
            }
            return false;
        },
        verifyAll () {
            var inputs = Object.keys(this.formRegister);
            inputs.forEach(input => {
                if(this.$store.state.generalModule.errorForm != 1) this.checkForm(input);
            })
        },
        registerNewUser() {
            this.verifyAll();
            if(this.$store.state.generalModule.errorForm != 1) {
                let data = {
                    username: this.formRegister.username.value,
                    email: this.formRegister.email.value,
                    password: this.formRegister.password.value
                };
                this.$store.commit("createIdentifier", data);
                data.identifier = this.$store.state.generalModule.identifier;
                delete data.password;
                LoginService.methods.postNewUser(data);
            }
        },
        validEmail (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    },
    async mounted() {
        // try {
        //     await loadContractNetworkCurrent(this.$store.state.contractModule.contractABI, this.$store.state.contractModule.addressContract);
        // } catch (error) {
        //     this.$store.commit("changeLogarComponent", 0);
        //     alert(this.$store.getters.translateWords("Você não possui NFTs para se registrar no jogo, vamos te redirecionar para nosso NFTStore"));
        //     window.location.href = "https://parallelium.io/#/presale";
        //     return;
        // }
        // await this.initWalletAccess();
        // var verifyWhitelist = await this.verifyNFTsWallet();
        // if(!verifyWhitelist) {
        //     this.$store.commit("changeLogarComponent", 0);
        //     alert(this.$store.getters.translateWords("Você não possui NFTs para se registrar no jogo, vamos te redirecionar para nosso NFTStore"));
        //     window.location.href = "https://parallelium.io/#/presale";
        // }
        // this.$store.commit("changeErrorForm", 0);
    }
}