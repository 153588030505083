export default {
    //botas
    botaNormal: {
        "groupEquip": "boots",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 7200,
            "base": 7200,
            "maxValue": 7200
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Botas Imperiais",
        "urlImg": "boots/4.png",
        "status": {
            "maxEnergy": 20,
            "maxHp": 15,
            "Defesa": 36
        },
        "price": 0
    },
    botaMelhorada: {
        "groupEquip": "boots",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 9000,
            "base": 9000,
            "maxValue": 9000
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Botas Imperiais",
        "urlImg": "boots/4.png",
        "status": {
            "maxEnergy": 25,
            "maxHp": 19,
            "Defesa": 45
        },
        "price": 0
    },
    botaPerfeita: {
        "groupEquip": "boots",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 10800,
            "base": 10800,
            "maxValue": 10800
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Botas Imperiais",
        "urlImg": "boots/4.png",
        "status": {
            "maxEnergy": 30,
            "maxHp": 23,
            "Defesa": 54
        },
        "price": 0
    },
    botaRara: {
        "groupEquip": "boots",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 12600,
            "base": 12600,
            "maxValue": 12600
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Botas Imperiais",
        "urlImg": "boots/4.png",
        "status": {
            "maxEnergy": 35,
            "maxHp": 27,
            "Defesa": 63
        },
        "price": 0
    },
    botaObraPrima: {
        "groupEquip": "boots",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 16200,
            "base": 16200,
            "maxValue": 16200
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Botas Imperiais",
        "urlImg": "boots/4.png",
        "status": {
            "maxEnergy": 45,
            "maxHp": 34,
            "Defesa": 81
        },
        "price": 0
    },
    botaLendario: {
        "groupEquip": "boots",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 19800,
            "base": 19800,
            "maxValue": 19800
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Botas Imperiais",
        "urlImg": "boots/4.png",
        "status": {
            "maxEnergy": 55,
            "maxHp": 42,
            "Defesa": 99
        },
        "price": 0
    },
    //Elmo
    elmoNormal: {
        "groupEquip": "helmet",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 7200,
            "base": 7200,
            "maxValue": 7200
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Elmo Imperial",
        "urlImg": "helmet/51.png",
        "status": {
            "maxMp": 10,
            "maxHp": 20,
            "Defesa": 36
        },
        "price": 0
    },
    elmoMelhorada: {
        "groupEquip": "helmet",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 9000,
            "base": 9000,
            "maxValue": 9000
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Elmo Imperial",
        "urlImg": "helmet/51.png",
        "status": {
            "maxMp": 13,
            "maxHp": 25,
            "Defesa": 45
        },
        "price": 0
    },
    elmoPerfeita: {
        "groupEquip": "helmet",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 10800,
            "base": 10800,
            "maxValue": 10800
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Elmo Imperial",
        "urlImg": "helmet/51.png",
        "status": {
            "maxMp": 15,
            "maxHp": 30,
            "Defesa": 54
        },
        "price": 0
    },
    elmoRara: {
        "groupEquip": "helmet",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 12600,
            "base": 12600,
            "maxValue": 12600
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Elmo Imperial",
        "urlImg": "helmet/51.png",
        "status": {
            "maxMp": 18,
            "maxHp": 35,
            "Defesa": 63
        },
        "price": 0
    },
    elmoObraPrima: {
        "groupEquip": "helmet",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 16200,
            "base": 16200,
            "maxValue": 16200
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Elmo Imperial",
        "urlImg": "helmet/51.png",
        "status": {
            "maxMp": 23,
            "maxHp": 45,
            "Defesa": 81
        },
        "price": 0
    },
    elmoLendario: {
        "groupEquip": "helmet",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 19800,
            "base": 19800,
            "maxValue": 19800
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Elmo Imperial",
        "urlImg": "helmet/51.png",
        "status": {
            "maxMp": 30,
            "maxHp": 55,
            "Defesa": 99
        },
        "price": 0
    },
    //Manopla
    manoplaNormal: {
        "groupEquip": "gauntlet",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 5600,
            "base": 5600,
            "maxValue": 5600
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Manopla Imperial",
        "urlImg": "gauntlets/20.png",
        "status": {
            "maxEnergy": 20,
            "Critico": 6,
            "Defesa": 32
        },
        "price": 0
    },
    manoplaMelhorada: {
        "groupEquip": "gauntlet",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 7000,
            "base": 7000,
            "maxValue": 7000
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Manopla Imperial",
        "urlImg": "gauntlets/20.png",
        "status": {
            "maxEnergy": 25,
            "Critico": 8,
            "Defesa": 40
        },
        "price": 0
    },
    manoplaPerfeita: {
        "groupEquip": "gauntlet",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 8400,
            "base": 8400,
            "maxValue": 8400
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Manopla Imperial",
        "urlImg": "gauntlets/20.png",
        "status": {
            "maxEnergy": 30,
            "Critico": 9,
            "Defesa": 48
        },
        "price": 0
    },
    manoplaRara: {
        "groupEquip": "gauntlet",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 9800,
            "base": 9800,
            "maxValue": 9800
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Manopla Imperial",
        "urlImg": "gauntlets/20.png",
        "status": {
            "maxEnergy": 35,
            "Critico": 11,
            "Defesa": 56
        },
        "price": 0
    },
    manoplaObraPrima: {
        "groupEquip": "gauntlet",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 12600,
            "base": 12600,
            "maxValue": 12600
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Manopla Imperial",
        "urlImg": "gauntlets/20.png",
        "status": {
            "maxEnergy": 45,
            "Critico": 14,
            "Defesa": 72
        },
        "price": 0
    },
    manoplaLendario: {
        "groupEquip": "gauntlet",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 15400,
            "base": 15400,
            "maxValue": 15400
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Manopla Imperial",
        "urlImg": "gauntlets/20.png",
        "status": {
            "maxEnergy": 55,
            "Critico": 17,
            "Defesa": 88
        },
        "price": 0
    },
    //Peitoral
    peitoralNormal: {
        "groupEquip": "armor",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 10400,
            "base": 10400,
            "maxValue": 10400
        },
        "tier": 4,
        "weight": 10,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Armadura Imperial",
        "urlImg": "armor/33.png",
        "status": {
            "maxHp": 25,
            "Critico": 6,
            "Defesa": 56
        },
        "price": 0
    },
    peitoralMelhorada: {
        "groupEquip": "armor",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 13000,
            "base": 13000,
            "maxValue": 13000
        },
        "tier": 4,
        "weight": 10,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Armadura Imperial",
        "urlImg": "armor/33.png",
        "status": {
            "maxHp": 32,
            "Critico": 8,
            "Defesa": 70
        },
        "price": 0
    },
    peitoralPerfeita: {
        "groupEquip": "armor",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 15600,
            "base": 15600,
            "maxValue": 15600
        },
        "tier": 4,
        "weight": 10,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Armadura Imperial",
        "urlImg": "armor/33.png",
        "status": {
            "maxHp": 38,
            "Critico": 9,
            "Defesa": 84
        },
        "price": 0
    },
    peitoralRara: {
        "groupEquip": "armor",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 18200,
            "base": 18200,
            "maxValue": 18200
        },
        "tier": 4,
        "weight": 10,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Armadura Imperial",
        "urlImg": "armor/33.png",
        "status": {
            "maxHp": 44,
            "Critico": 11,
            "Defesa": 98
        },
        "price": 0
    },
    peitoralObraPrima: {
        "groupEquip": "armor",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 23400,
            "base": 23400,
            "maxValue": 23400
        },
        "tier": 4,
        "weight": 10,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Armadura Imperial",
        "urlImg": "armor/33.png",
        "status": {
            "maxHp": 56,
            "Critico": 14,
            "Defesa": 126
        },
        "price": 0
    },
    peitoralLendario: {
        "groupEquip": "armor",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 28600,
            "base": 28600,
            "maxValue": 28600
        },
        "tier": 4,
        "weight": 10,
        "minLvl": 1,
        "class": "heavy armor",
        "seller": "none",
        "name": "Armadura Imperial",
        "urlImg": "armor/33.png",
        "status": {
            "maxHp": 69,
            "Critico": 17,
            "Defesa": 154
        },
        "price": 0
    },
    espadaNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 8325,
            "base": 8325,
            "maxValue": 8325
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "one-handed sword",
        "seller": "none",
        "name": "Espada Imperial",
        "urlImg": "weapons/swords/2/4.png",
        "status": {
            "Ataque": 36,
            "Critico": 3,
            "maxEnergy": 10
        },
        "price": 0
    },
    espadaMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 10407,
            "base": 10407,
            "maxValue": 10407
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "one-handed sword",
        "seller": "none",
        "name": "Espada Imperial",
        "urlImg": "weapons/swords/2/4.png",
        "status": {
            "Ataque": 45,
            "Critico": 4,
            "maxEnergy": 13
        },
        "price": 0
    },
    espadaPerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 12490,
            "base": 12490,
            "maxValue": 12490
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "one-handed sword",
        "seller": "none",
        "name": "Espada Imperial",
        "urlImg": "weapons/swords/2/4.png",
        "status": {
            "Ataque": 54,
            "Critico": 5,
            "maxEnergy": 15
        },
        "price": 0
    },
    espadaRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 14570,
            "base": 14570,
            "maxValue": 14570
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "one-handed sword",
        "seller": "none",
        "name": "Espada Imperial",
        "urlImg": "weapons/swords/2/4.png",
        "status": {
            "Ataque": 63,
            "Critico": 6,
            "maxEnergy": 18
        },
        "price": 0
    },
    espadaObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 18732,
            "base": 18732,
            "maxValue": 18732
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "one-handed sword",
        "seller": "none",
        "name": "Espada Imperial",
        "urlImg": "weapons/swords/2/4.png",
        "status": {
            "Ataque": 81,
            "Critico": 8,
            "maxEnergy": 23
        },
        "price": 0
    },
    espadaLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 22895,
            "base": 22895,
            "maxValue": 22895
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "one-handed sword",
        "seller": "none",
        "name": "Espada Imperial",
        "urlImg": "weapons/swords/2/4.png",
        "status": {
            "Ataque": 99,
            "Critico": 9,
            "maxEnergy": 28
        },
        "price": 0
    },
    axeNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 9600,
            "base": 9600,
            "maxValue": 9600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "one-handed axe",
        "seller": "none",
        "name": "Imperial Axe",
        "urlImg": "weapons/axes/31.png",
        "status": {
            "Ataque": 48,
            "Critico": 3,
            "maxEnergy": 25
        },
        "price": 0
    },
    axeMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 12000,
            "base": 12000,
            "maxValue": 12000
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "one-handed axe",
        "seller": "none",
        "name": "Imperial Axe",
        "urlImg": "weapons/axes/31.png",
        "status": {
            "Ataque": 60,
            "Critico": 4,
            "maxEnergy": 32
        },
        "price": 0
    },
    axePerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 14400,
            "base": 14400,
            "maxValue": 14400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "one-handed axe",
        "seller": "none",
        "name": "Imperial Axe",
        "urlImg": "weapons/axes/31.png",
        "status": {
            "Ataque": 72,
            "Critico": 5,
            "maxEnergy": 38
        },
        "price": 0
    },
    axeRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 16800,
            "base": 16800,
            "maxValue": 16800
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "one-handed axe",
        "seller": "none",
        "name": "Imperial Axe",
        "urlImg": "weapons/axes/31.png",
        "status": {
            "Ataque": 84,
            "Critico": 6,
            "maxEnergy": 44
        },
        "price": 0
    },
    axeObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 21600,
            "base": 21600,
            "maxValue": 21600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "one-handed axe",
        "seller": "none",
        "name": "Imperial Axe",
        "urlImg": "weapons/axes/31.png",
        "status": {
            "Ataque": 108,
            "Critico": 7,
            "maxEnergy": 57
        },
        "price": 0
    },
    axeLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 26400,
            "base": 26400,
            "maxValue": 26400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "one-handed axe",
        "seller": "none",
        "name": "Imperial Axe",
        "urlImg": "weapons/axes/31.png",
        "image": "https://gateway.pinata.cloud/ipfs/QmWkMh4nCnsx7ribs1mCQ5Jcj6o6pNKzYaTPrwJAC2CGs9",
        "status": {
            "Ataque": 132,
            "Critico": 9,
            "maxEnergy": 69
        },
        "price": 0
    },
    shieldNormal: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 6650,
            "base": 6650,
            "maxValue": 6650
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "shield",
        "seller": "none",
        "name": "Imperial Shield",
        "urlImg": "shields/21.png",
        "status": {
            "Defesa": 52,
            "Critico": 9,
            "maxHp": 25
        },
        "price": 0
    },
    shieldMelhorada: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 8314,
            "base": 8314,
            "maxValue": 8314
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "shield",
        "seller": "none",
        "name": "Imperial Shield",
        "urlImg": "shields/21.png",
        "status": {
            "Defesa": 65,
            "Critico": 11,
            "maxHp": 31
        },
        "price": 0
    },
    shieldPerfeita: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 9975,
            "base": 9975,
            "maxValue": 9975
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "shield",
        "seller": "none",
        "name": "Imperial Shield",
        "urlImg": "shields/21.png",
        "status": {
            "Defesa": 78,
            "Critico": 14,
            "maxHp": 38
        },
        "price": 0
    },
    shieldRara: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 11638,
            "base": 11638,
            "maxValue": 11638
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "shield",
        "seller": "none",
        "name": "Imperial Shield",
        "urlImg": "shields/21.png",
        "status": {
            "Defesa": 91,
            "Critico": 16,
            "maxHp": 44
        },
        "price": 0
    },
    shieldObraPrima: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 14963,
            "base": 14963,
            "maxValue": 14963
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "shield",
        "seller": "none",
        "name": "Imperial Shield",
        "urlImg": "shields/21.png",
        "status": {
            "Defesa": 117,
            "Critico": 21,
            "maxHp": 56
        },
        "price": 0
    },
    shieldLendario: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 18288,
            "base": 18288,
            "maxValue": 18288
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "shield",
        "seller": "none",
        "name": "Imperial Shield",
        "urlImg": "shields/21.png",
        "status": {
            "Defesa": 143,
            "Critico": 25,
            "maxHp": 69
        },
        "price": 0
    },

    // 
    // Archer
    // 

    bowNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 4500,
            "base": 4500,
            "maxValue": 4500
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Arco Imperial",
        "urlImg": "weapons/bows/86.png",
        "status": {
            "Ataque": 15,
            "Critico": 6,
            "Agility": 6,
            "maxEnergy": 15
        },
        "price": 0
    },
    bowMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 5625,
            "base": 5625,
            "maxValue": 5625
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Arco Imperial",
        "urlImg": "weapons/bows/86.png",
        "status": {
            "Ataque": 19,
            "Critico": 8,
            "Agility": 8,
            "maxEnergy": 19
        },
        "price": 0
    },
    bowPerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 6750,
            "base": 6750,
            "maxValue": 6750
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Arco Imperial",
        "urlImg": "weapons/bows/86.png",
        "status": {
            "Ataque": 23,
            "Critico": 9,
            "maxEnergy": 23
        },
        "price": 0
    },
    bowRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 7875,
            "base": 7875,
            "maxValue": 7875
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Arco Imperial",
        "urlImg": "weapons/bows/86.png",
        "status": {
            "Ataque": 27,
            "Critico": 11,
            "Agility": 11,
            "maxEnergy": 27
        },
        "price": 0
    },
    bowObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 10125,
            "base": 10125,
            "maxValue": 10125
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Arco Imperial",
        "urlImg": "weapons/bows/86.png",
        "status": {
            "Ataque": 34,
            "Critico": 14,
            "Agility": 14,
            "maxEnergy": 34
        },
        "price": 0
    },
    bowLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 12375,
            "base": 12375,
            "maxValue": 12375
        },
        "tier": 4,
        "weight": 4,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Arco Imperial",
        "urlImg": "weapons/bows/86.png",
        "status": {
            "Ataque": 42,
            "Critico": 17,
            "Agility": 17,
            "maxEnergy": 42
        },
        "price": 0
    },
    heavyBowNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 9600,
            "base": 9600,
            "maxValue": 9600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Imperial Heavy Bow",
        "urlImg": "weapons/bows/23.png",
        "status": {
            "Ataque": 32,
            "Critico": 7,
            "Agility": 1,
            "maxEnergy": 25
        },
        "price": 0
    },
    heavyBowMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 12000,
            "base": 12000,
            "maxValue": 12000
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Imperial Heavy Bow",
        "urlImg": "weapons/bows/23.png",
        "status": {
            "Ataque": 40,
            "Critico": 9,
            "Agility": 1,
            "maxEnergy": 32
        },
        "price": 0
    },
    heavyBowPerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 14400,
            "base": 14400,
            "maxValue": 14400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Imperial Heavy Bow",
        "urlImg": "weapons/bows/23.png",
        "status": {
            "Ataque": 48,
            "Critico": 11,
            "Agility": 2,
            "maxEnergy": 38
        },
        "price": 0
    },
    heavyBowRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 16800,
            "base": 16800,
            "maxValue": 16800
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Imperial Heavy Bow",
        "urlImg": "weapons/bows/23.png",
        "status": {
            "Ataque": 56,
            "Critico": 13,
            "Agility": 2,
            "maxEnergy": 44
        },
        "price": 0
    },
    heavyBowObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 21600,
            "base": 21600,
            "maxValue": 21600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Imperial Heavy Bow",
        "urlImg": "weapons/bows/23.png",
        "status": {
            "Ataque": 72,
            "Critico": 16,
            "Agility": 2,
            "maxEnergy": 57
        },
        "price": 0
    },
    heavyBowLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 26400,
            "base": 26400,
            "maxValue": 26400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "bow",
        "seller": "none",
        "name": "Imperial Heavy Bow",
        "urlImg": "weapons/bows/23.png",
        "image": "https://gateway.pinata.cloud/ipfs/QmWkMh4nCnsx7ribs1mCQ5Jcj6o6pNKzYaTPrwJAC2CGs9",
        "status": {
            "Ataque": 88,
            "Critico": 20,
            "Agility": 3,
            "maxEnergy": 69
        },
        "price": 0
    },
    arrowNormal: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 2000,
            "base": 2000,
            "maxValue": 2000
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "arrow",
        "seller": "none",
        "name": "Imperial Arrow",
        "urlImg": "weapons/arrows/18.png",
        "status": {
            "Ataque": 15,
            "Agility": 15
        },
        "price": 0
    },
    arrowMelhorada: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 2500,
            "base": 2500,
            "maxValue": 2500
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "arrow",
        "seller": "none",
        "name": "Imperial Arrow",
        "urlImg": "weapons/arrows/18.png",
        "status": {
            "Ataque": 19,
            "Agility": 19
        },
        "price": 0
    },
    arrowPerfeita: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 3000,
            "base": 3000,
            "maxValue": 3000
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "arrow",
        "seller": "none",
        "name": "Imperial Arrow",
        "urlImg": "weapons/arrows/18.png",
        "status": {
            "Ataque": 23,
            "Agility": 23
        },
        "price": 0
    },
    arrowRara: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 3500,
            "base": 3500,
            "maxValue": 3500
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "arrow",
        "seller": "none",
        "name": "Imperial Arrow",
        "urlImg": "weapons/arrows/18.png",
        "status": {
            "Ataque": 27,
            "Agility": 27
        },
        "price": 0
    },
    arrowObraPrima: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 4500,
            "base": 4500,
            "maxValue": 4500
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "arrow",
        "seller": "none",
        "name": "Imperial Arrow",
        "urlImg": "weapons/arrows/18.png",
        "status": {
            "Ataque": 34,
            "Agility": 34
        },
        "price": 0
    },
    arrowLendario: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 5500,
            "base": 5500,
            "maxValue": 5500
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "arrow",
        "seller": "none",
        "name": "Imperial Arrow",
        "urlImg": "weapons/arrows/18.png",
        "status": {
            "Ataque": 42,
            "Agility": 42
        },
        "price": 0
    },

    // 
    // Mage
    // 

    fireStaffNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 7000,
            "base": 7000,
            "maxValue": 7000
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "fire staff",
        "seller": "none",
        "name": "Imperial Fire Staff",
        "urlImg": "magic/staff/2/50.png",
        "status": {
            "Ataque": 20,
            "PoderMagico": 15,
            "maxMp": 20
        },
        "price": 0
    },
    fireStaffMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 8750,
            "base": 8750,
            "maxValue": 8750
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "fire staff",
        "seller": "none",
        "name": "Imperial Fire Staff",
        "urlImg": "magic/staff/2/50.png",
        "status": {
            "Ataque": 25,
            "PoderMagico": 19,
            "maxMp": 25
        },
        "price": 0
    },
    fireStaffPerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 10500,
            "base": 10500,
            "maxValue": 10500
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "fire staff",
        "seller": "none",
        "name": "Imperial Fire Staff",
        "urlImg": "magic/staff/2/50.png",
        "status": {
            "Ataque": 30,
            "PoderMagico": 23,
            "maxMp": 30
        },
        "price": 0
    },
    fireStaffRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 7875,
            "base": 7875,
            "maxValue": 7875
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "fire staff",
        "seller": "none",
        "name": "Imperial Fire Staff",
        "urlImg": "magic/staff/2/50.png",
        "status": {
            "Ataque": 35,
            "PoderMagico": 27,
            "maxMp": 35
        },
        "price": 0
    },
    fireStaffObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 15750,
            "base": 15750,
            "maxValue": 15750
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "fire staff",
        "seller": "none",
        "name": "Imperial Fire Staff",
        "urlImg": "magic/staff/2/50.png",
        "status": {
            "Ataque": 45,
            "PoderMagico": 34,
            "maxMp": 45
        },
        "price": 0
    },
    fireStaffLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 19250,
            "base": 19250,
            "maxValue": 19250
        },
        "tier": 4,
        "weight": 2,
        "minLvl": 1,
        "class": "fire staff",
        "seller": "none",
        "name": "Imperial Fire Staff",
        "urlImg": "magic/staff/2/50.png",
        "status": {
            "Ataque": 55,
            "PoderMagico": 34,
            "maxMp": 55
        },
        "price": 0
    },
    energyStaffNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 9600,
            "base": 9600,
            "maxValue": 9600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "energy staff",
        "seller": "none",
        "name": "Imperial Energy Staff",
        "urlImg": "magic/staff/2/42.png",
        "status": {
            "Ataque": 35,
            "PoderMagico": 25,
            "maxMp": 35
        },
        "price": 0
    },
    energyStaffMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 12000,
            "base": 12000,
            "maxValue": 12000
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "energy staff",
        "seller": "none",
        "name": "Imperial Energy Staff",
        "urlImg": "magic/staff/2/42.png",
        "status": {
            "Ataque": 44,
            "PoderMagico": 32,
            "maxMp": 44
        },
        "price": 0
    },
    energyStaffPerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 14400,
            "base": 14400,
            "maxValue": 14400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "energy staff",
        "seller": "none",
        "name": "Imperial Energy Staff",
        "urlImg": "magic/staff/2/42.png",
        "status": {
            "Ataque": 53,
            "PoderMagico": 38,
            "maxMp": 53
        },
        "price": 0
    },
    energyStaffRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 16800,
            "base": 16800,
            "maxValue": 16800
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "energy staff",
        "seller": "none",
        "name": "Imperial Energy Staff",
        "urlImg": "magic/staff/2/42.png",
        "status": {
            "Ataque": 62,
            "PoderMagico": 44,
            "maxMp": 62
        },
        "price": 0
    },
    energyStaffObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 21600,
            "base": 21600,
            "maxValue": 21600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "energy staff",
        "seller": "none",
        "name": "Imperial Energy Staff",
        "urlImg": "magic/staff/2/42.png",
        "status": {
            "Ataque": 79,
            "PoderMagico": 57,
            "maxMp": 79
        },
        "price": 0
    },
    energyStaffLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 26400,
            "base": 26400,
            "maxValue": 26400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "energy staff",
        "seller": "none",
        "name": "Imperial Energy Staff",
        "urlImg": "magic/staff/2/42.png",
        "status": {
            "Ataque": 97,
            "PoderMagico": 69,
            "maxMp": 97
        },
        "price": 0
    },
    energyOrbNormal: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 5500,
            "base": 5500,
            "maxValue": 5500
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "magic item",
        "seller": "none",
        "name": "Imperial Energy Orb",
        "urlImg": "magic/artifacts/3.png",
        "status": {
            "Ataque": 10,
            "PoderMagico": 15,
            "maxMp": 15
        },
        "price": 0
    },
    energyOrbMelhorada: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 6875,
            "base": 6875,
            "maxValue": 6875
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "magic item",
        "seller": "none",
        "name": "Imperial Energy Orb",
        "urlImg": "magic/artifacts/3.png",
        "status": {
            "Ataque": 13,
            "PoderMagico": 18,
            "maxMp": 18
        },
        "price": 0
    },
    energyOrbPerfeita: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 8250,
            "base": 8250,
            "maxValue": 8250
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "magic item",
        "seller": "none",
        "name": "Imperial Energy Orb",
        "urlImg": "magic/artifacts/3.png",
        "status": {
            "Ataque": 15,
            "PoderMagico": 23,
            "maxMp": 23
        },
        "price": 0
    },
    energyOrbRara: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 9625,
            "base": 9625,
            "maxValue": 9625
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "magic item",
        "seller": "none",
        "name": "Imperial Energy Orb",
        "urlImg": "magic/artifacts/3.png",
        "status": {
            "Ataque": 18,
            "PoderMagico": 27,
            "maxMp": 27
        },
        "price": 0
    },
    energyOrbObraPrima: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 12375,
            "base": 12375,
            "maxValue": 12375
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "magic item",
        "seller": "none",
        "name": "Imperial Energy Orb",
        "urlImg": "magic/artifacts/3.png",
        "status": {
            "Ataque": 23,
            "PoderMagico": 34,
            "maxMp": 34
        },
        "price": 0
    },
    energyOrbLendario: {
        "groupEquip": "second-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 15125,
            "base": 15125,
            "maxValue": 15125
        },
        "tier": 4,
        "weight": 1,
        "minLvl": 1,
        "class": "magic item",
        "seller": "none",
        "name": "Imperial Energy Orb",
        "urlImg": "magic/artifacts/3.png",
        "status": {
            "Ataque": 28,
            "PoderMagico": 42,
            "maxMp": 42
        },
        "price": 0
    },

    // 
    // Spearman
    // 

    lanceNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 7000,
            "base": 7000,
            "maxValue": 7000
        },
        "tier": 4,
        "weight": 3,
        "minLvl": 1,
        "class": "lance",
        "seller": "none",
        "name": "Imperial Lance",
        "urlImg": "weapons/lance/18.png",
        "status": {
            "Ataque": 32,
            "maxHp": 15
        },
        "price": 0
    },
    lanceMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 8750,
            "base": 8750,
            "maxValue": 8750
        },
        "tier": 4,
        "weight": 3,
        "minLvl": 1,
        "class": "lance",
        "seller": "none",
        "name": "Imperial Lance",
        "urlImg": "weapons/lance/18.png",
        "status": {
            "Ataque": 40,
            "maxHp": 18
        },
        "price": 0
    },
    lancePerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 10500,
            "base": 10500,
            "maxValue": 10500
        },
        "tier": 4,
        "weight": 3,
        "minLvl": 1,
        "class": "lance",
        "seller": "none",
        "name": "Imperial Lance",
        "urlImg": "weapons/lance/18.png",
        "status": {
            "Ataque": 48,
            "maxHp": 23
        },
        "price": 0
    },
    lanceRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 7875,
            "base": 7875,
            "maxValue": 7875
        },
        "tier": 4,
        "weight": 3,
        "minLvl": 1,
        "class": "lance",
        "seller": "none",
        "name": "Imperial Lance",
        "urlImg": "weapons/lance/18.png",
        "status": {
            "Ataque": 56,
            "maxHp": 27
        },
        "price": 0
    },
    lanceObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 15750,
            "base": 15750,
            "maxValue": 15750
        },
        "tier": 4,
        "weight": 3,
        "minLvl": 1,
        "class": "lance",
        "seller": "none",
        "name": "Imperial Lance",
        "urlImg": "weapons/lance/18.png",
        "status": {
            "Ataque": 72,
            "maxHp": 34
        },
        "price": 0
    },
    lanceLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 19250,
            "base": 19250,
            "maxValue": 19250
        },
        "tier": 4,
        "weight": 3,
        "minLvl": 1,
        "class": "lance",
        "seller": "none",
        "name": "Imperial Lance",
        "urlImg": "weapons/lance/18.png",
        "status": {
            "Ataque": 88,
            "maxHp": 42
        },
        "price": 0
    },
    halberdNormal: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "normal",
        "durability": {
            "value": 9600,
            "base": 9600,
            "maxValue": 9600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "halberd",
        "seller": "none",
        "name": "Imperial Halberd",
        "urlImg": "weapons/lance/34.png",
        "status": {
            "Ataque": 48,
            "maxHp": 30
        },
        "price": 0
    },
    halberdMelhorada: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "melhorada",
        "durability": {
            "value": 12000,
            "base": 12000,
            "maxValue": 12000
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "halberd",
        "seller": "none",
        "name": "Imperial Halberd",
        "urlImg": "weapons/lance/34.png",
        "status": {
            "Ataque": 60,
            "maxHp": 38
        },
        "price": 0
    },
    halberdPerfeita: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "perfeita",
        "durability": {
            "value": 14400,
            "base": 14400,
            "maxValue": 14400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "halberd",
        "seller": "none",
        "name": "Imperial Halberd",
        "urlImg": "weapons/lance/34.png",
        "status": {
            "Ataque": 72,
            "maxHp": 45
        },
        "price": 0
    },
    halberdRara: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "rara",
        "durability": {
            "value": 16800,
            "base": 16800,
            "maxValue": 16800
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "halberd",
        "seller": "none",
        "name": "Imperial Halberd",
        "urlImg": "weapons/lance/34.png",
        "status": {
            "Ataque": 84,
            "maxHp": 53
        },
        "price": 0
    },
    halberdObraPrima: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "obra-prima",
        "durability": {
            "value": 21600,
            "base": 21600,
            "maxValue": 21600
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "halberd",
        "seller": "none",
        "name": "Imperial Halberd",
        "urlImg": "weapons/lance/34.png",
        "status": {
            "Ataque": 108,
            "maxHp": 68
        },
        "price": 0
    },
    halberdLendario: {
        "groupEquip": "first-weapon",
        "type": "equip",
        "rarity": "lendario",
        "durability": {
            "value": 26400,
            "base": 26400,
            "maxValue": 26400
        },
        "tier": 4,
        "weight": 5,
        "minLvl": 1,
        "class": "halberd",
        "seller": "none",
        "name": "Imperial Halberd",
        "urlImg": "weapons/lance/34.png",
        "status": {
            "Ataque": 132,
            "maxHp": 83
        },
        "price": 0
    },
}