import AppSelection from "../../components/selection/Selection.vue";

export default {
    components: {
        AppSelection
    },
    data() {
        return {
            kidLike: {
                title: "Qual sua aspiração?",
                options: [
                    {
                        id: 1,
                        choice: "Lutas",
                        message: "Lutador desde criança (+1 defesa e +5 Estamina)",
                        status: {
                            Defesa: 1,
                            maxEnergy: 5
                        }
                    },
                    {
                        id: 1,
                        choice: "Ciências",
                        message: "Jovem cientista (+10 Magia)",
                        status: {
                            maxMp: 10
                        }
                    },
                    {
                        id: 1,
                        choice: "Ninjas",
                        message: "Sempre quis ser ninja (+1 Critico e +1 Agilidade)",
                        status: {
                            Critico: 1,
                            Agility: 1
                        }
                    },
                    {
                        id: 1,
                        choice: "Policiais",
                        message: "Quero defender os inocentes (+1 Força e +1 defesa)",
                        status: {
                            Forca: 1,
                            Defesa: 1
                        }
                    },
                    {
                        id: 1,
                        choice: "Explorar",
                        message: "Explorar e descobrir novas coisas (+5 Stamina e -5% Gasto de energia ao viajar)",
                        status: {
                            travelTime: 0.05,
                            maxEnergy: 5
                        }
                    },
                    {
                        id: 1,
                        choice: "Trabalhar",
                        message: "Tive de trabalhar desde pequeno (+15 Estamina)",
                        status: {
                            maxEnergy: 15
                        }
                    }
                ]
            },
            liveJob: {
                title: "Qual foi sua carreira?",
                options: [
                    {
                        id: 2,
                        choice: "Comerciante",
                        message: "Fui um comerciante bem sucedido (+2 Negociação e +1 Carisma)",
                        status: {
                            Negociacao: 2,
                            Carisma: 1
                        }
                    },
                    {
                        id: 2,
                        choice: "Policial",
                        message: "Meu sonho virou minha realidade (+1 defesa e +20 HP)",
                        status: {
                            Defesa: 1,
                            maxHp: 20
                        }
                    },
                    {
                        id: 2,
                        choice: "Assassino",
                        message: "A vida como um todo vale aquilo que me pagam (+2 Critico, -1 Carisma e +2 Agilidade)",
                        status: {
                            Critico: 2,
                            Agility: 2,
                            Carisma: -1
                        }
                    },
                    {
                        id: 2,
                        choice: "Médico",
                        message: "Salvar vidas é meu caminho (+25 HP)",
                        status: {
                            maxHp: 25
                        }
                    }
                ]
            },
            old: {
                title: "Quantos anos você tinha?",
                options: [
                    {
                        id: 3,
                        choice: "Muito jovem",
                        message: "Muito novo (+50 Estamina e -50 Magia)",
                        status: {
                            maxMp: -50,
                            maxEnergy: 50
                        }
                    },
                    {
                        id: 3,
                        choice: "Jovem",
                        message: "Começando a vida (+35 Estamina e -30 Magia)",
                        status: {
                            maxMp: -30,
                            maxEnergy: 35
                        }
                    },
                    {
                        id: 3,
                        choice: "Jovem Adulto",
                        message: "Adulto e responsável (+10 Estamina, +10 Magia e +1 Força)",
                        status: {
                            maxMp: 10,
                            maxEnergy: 10,
                            Forca: 1
                        }
                    },
                    {
                        id: 3,
                        choice: "Velho",
                        message: "Foi sem se aposentar (-25 Estamina e +25 Magia e +10 Poder Mágico)",
                        status: {
                            maxMp: 25,
                            maxEnergy: -25,
                            PoderMagico: 10
                        }
                    },
                    {
                        id: 3,
                        choice: "Muito Velho",
                        message: "Velho e cansado (-50 Estamina, +50 Magia +20 Poder Mágico)",
                        status: {
                            maxMp: 50,
                            maxEnergy: -50,
                            PoderMagico: 20
                        }
                    }
                ]
            },
            dead: {
                title: "Como você veio para cá?",
                options: [
                    {
                        id: 4,
                        choice: "Guerra",
                        message: "Com honra e glória (+3 força e +1 defesa)",
                        status: {
                            Forca: 3,
                            Defesa: 1
                        }
                    },
                    {
                        id: 4,
                        choice: "Fome",
                        message: "Situação complicada, sem dinheiro ou comida (-20 Estamina, +3 Critico, -1 Força e +2 Agilidade)",
                        status: {
                            maxEnergy: -20,
                            Critico: 2,
                            Agility: 2,
                            Forca: -1
                        }
                    },
                    {
                        id: 4,
                        choice: "Traido",
                        message: "Traido por quem mais confiava (-2 carisma, +1 Critico, +2 defesa e +1 Agilidade)",
                        status: {
                            Carisma: -2,
                            Defesa: 2,
                            Critico: 1,
                            Agility: 1
                        }
                    },
                    {
                        id: 4,
                        choice: "Acidente",
                        message: "Um acidente bobo, mas no fim, mortal (-1 defesa, -25 HP, +3 Critico e +3 Agilidade)",
                        status: {
                            Defesa: -1,
                            maxHp: -25,
                            Critico: 3,
                            Agility: 3
                        }
                    },
                    {
                        id: 4,
                        choice: "Velhice",
                        message: "Quando a natureza chamou, eu decidi ir (-20 Estamina, +40 Magia)",
                        status: {
                            maxEnergy: -20,
                            maxMp: 40
                        }
                    },
                    {
                        id: 4,
                        choice: "Saqueado",
                        message: "Odeio saqueadores, os destruirei (-30 HP, +3 Defesa, +2 Critico e +2 Força)",
                        status: {
                            maxHp: -30,
                            Defesa: 3,
                            Critico: 2,
                            Forca: 2
                        }
                    }
                ]
            }
        }
    }
}