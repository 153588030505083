import LootService from "@/core/services/LootService";

export default {
    props: [
        'lootData',
        'classeType'
    ],
    computed: {
        items() {
            return this.$store.state.itemsModule.items;
        },
        workingNow() {
            return this.$store.state.classWorkModule.workingNow;
        }
    },
    methods: {
        timeForCompletedWork(time, message) {
            if(!this.$store.state.generalModule.occupied) {
                this.$store.commit("changeOccupied", true);
                this.$store.commit("changeActionText", message);
                this.$store.dispatch("timeForCompletedActionWork", time);
            }
        },
        clickWorkCommon(item, time, message) {
            // var itemLooted = this.$store.state.itemsModule.items.find(i => i.name === item.name);
            if(!this.$store.state.generalModule.occupied) {
                if(this.$store.state.personModule.mainPerson.infos[0].li[2].value >= 2){
                    this.timeForCompletedWork(time, message);
                    const energyMethod = {
                        method: "soma",
                        value: 2
                    }
                    this.$store.commit("calculeEnergyCurrent", energyMethod);
                    var timeOut = setTimeout(() => {
                        LootService.methods.lootGeneral(item);
                        clearTimeout(timeOut);
                    }, time * 1000);
                    return;
                }
                alert("Sem energia suficiente!");
                return;
            }
        }
    }
}