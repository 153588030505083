import mutations from "./mutations";
// import * as Getters from "./getters";

export default {
    state: {
        wave: {
            current: 1,
            score: 0,
            monstersDead: 0,
            monstersAlive: 0,
            count: 0,
            text: "Let's Go!",
            showCount: false
        },
        current: {
            name: "slime",
            icon: "florest/slime/Slime.png",
            img: "florest/slime/Slime_idle.png",
            lvl: {
                initial: 1,
                end: 10
            },
            status: {
                hp: 100,
                maxhp: 100,
                mp: 1,
                energia: 20,
                atk: 1,
                def: 1,
                agility: 1,
                visionCamp: 50,
                fly: false,
                speed: 130,
                atkArea: {
                    x: 50,
                    y: 85
                }
            },
            loots: {
                exp: 15,
                gold: [0,0],
                items: [
                    {
                        name: "gosma de slime",
                        porcent: 60,
                        qtd: [1, 2]
                    }
                ]
            }
        },
        types: [
            {
                name: "slime",
                classScss: "slime--florest",
                icon: "florest/slime/Slime.png",
                img: "florest/slime/Slime_idle.png",
                lvl: {
                    initial: 1,
                    end: 10
                },
                status: {
                    hp: 100,
                    maxhp: 100,
                    mp: 1,
                    energia: 20,
                    atk: 3,
                    def: 1,
                    agility: 1,
                    visionCamp: 30,
                    fly: false,
                    speed: 90,
                    atkArea: {
                        x: 50,
                        y: 100
                    }
                },
                loots: {
                    exp: 15,
                    gold: [0,0],
                    items: [
                        {
                            name: "gosma de slime",
                            porcent: 60,
                            qtd: [1, 2]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 1,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "toad",
                classScss: "toad--florest",
                icon: "florest/toad/Toad.png",
                img: "florest/toad/Toad_idle.png",
                lvl: {
                    initial: 1,
                    end: 10
                },
                status: {
                    hp: 120,
                    maxhp: 120,
                    mp: 1,
                    energia: 20,
                    atk: 5,
                    def: 1,
                    agility: 3,
                    visionCamp: 60,
                    fly: false,
                    speed: 110,
                    atkArea: {
                        x: 50,
                        y: 85
                    }
                },
                loots: {
                    exp: 25,
                    gold: [0,0],
                    items: [
                        {
                            name: "sebo de sapo",
                            porcent: 50,
                            qtd: [1, 1]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 2,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "boar",
                classScss: "slime--florest",
                icon: "florest/boar/Boar.png",
                img: "florest/boar/Boar_idle.png",
                lvl: {
                    initial: 1,
                    end: 10
                },
                need: {
                    work: "hunt",
                    lvlWork: 1
                },
                status: {
                    hp: 250,
                    maxhp: 250,
                    mp: 1,
                    energia: 20,
                    atk: 10,
                    def: 3,
                    agility: 4,
                    visionCamp: 70,
                    fly: false,
                    speed: 130,
                    atkArea: {
                        x: 50,
                        y: 85
                    }
                },
                loots: {
                    exp: 60,
                    gold: [0,0],
                    items: [
                        {
                            name: "couro cru",
                            porcent: 75,
                            qtd: [1, 1]
                        },
                        {
                            name: "carne de javali",
                            porcent: 75,
                            qtd: [1, 1]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 2,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "wolf",
                icon: "florest/wolf/Wolf.png",
                img: "florest/wolf/Wolf_idle.png",
                lvl: {
                    initial: 1,
                    end: 10
                },
                status: {
                    hp: 350,
                    maxhp: 350,
                    mp: 1,
                    energia: 20,
                    atk: 23,
                    def: 9,
                    agility: 15,
                    visionCamp: 70,
                    fly: false,
                    speed: 150,
                    atkArea: {
                        x: 40,
                        y: 85
                    }
                },
                loots: {
                    exp: 130,
                    gold: [0,0],
                    items: [
                        {
                            name: "fragmento de alma",
                            porcent: 7,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "wasp",
                icon: "florest/wasp/Wasp.png",
                img: "florest/wasp/Wasp_idle.png",
                lvl: {
                    initial: 1,
                    end: 10
                },
                status: {
                    hp: 350,
                    maxhp: 350,
                    mp: 1,
                    energia: 20,
                    atk: 30,
                    def: 3,
                    agility: 8,
                    visionCamp: 25
                },
                loots: {
                    exp: 120,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "bear",
                icon: "mine/bear/Bear.png",
                img: "mine/bear/Bear_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 650,
                    maxhp: 650,
                    mp: 1,
                    energia: 20,
                    atk: 40,
                    def: 10,
                    agility: 3,
                    visionCamp: 90,
                    fly: false,
                    speed: 90,
                    atkArea: {
                        x: 60,
                        y: 110
                    }
                },
                loots: {
                    exp: 350,
                    gold: [0,0],
                    items: [
                        {
                            name: "fragmento de alma",
                            porcent: 12,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "spider",
                icon: "mine/spider/Spider.png",
                img: "mine/spider/Spider_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 150,
                    maxhp: 150,
                    mp: 1,
                    energia: 20,
                    atk: 20,
                    def: 4,
                    agility: 3,
                    visionCamp: 80,
                    fly: false,
                    speed: 130,
                    atkArea: {
                        x: 50,
                        y: 85
                    }
                },
                loots: {
                    exp: 120,
                    gold: [0,0],
                    items: [
                        {
                            name: "fragmento de alma",
                            porcent: 6,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "bat",
                icon: "swamp/bat/Bat.png",
                img: "swamp/bat/Bat_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 180,
                    maxhp: 180,
                    mp: 1,
                    energia: 20,
                    atk: 4,
                    def: 2,
                    agility: 3,
                    visionCamp: 80,
                    fly: true,
                    speed: 110,
                    atkArea: {
                        x: 35,
                        y: 60
                    }
                },
                loots: {
                    exp: 50,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "larva",
                icon: "swamp/larva/Larva.png",
                img: "swamp/larva/Larva_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 350,
                    maxhp: 350,
                    mp: 1,
                    energia: 20,
                    atk: 20,
                    def: 10,
                    agility: 2,
                    visionCamp: 20
                },
                loots: {
                    exp: 100,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "skeleton",
                icon: "ruins/skeleton/dead_far_1.png",
                img: "hell/skeleton/Skeleton_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 220,
                    maxhp: 220,
                    mp: 1,
                    energia: 20,
                    atk: 15,
                    def: 2,
                    agility: 4,
                    visionCamp: 45,
                    fly: false,
                    flip: true,
                    speed: 140,
                    atkArea: {
                        x: 50,
                        y: 85
                    },
                    collisions: {
                        size: {
                            x: 20,
                            y: 30
                        },
                        offset: {
                            flipxFalse: 14,
                            flipxTrue: 14,
                            y: 12
                        },
                        atk: {
                            flipxFalse: 60,
                            flipxTrue: -20,
                            y: 45
                        }
                    }
                },
                loots: {
                    exp: 100,
                    gold: [0,0],
                    items: [
                        {
                            name: "osso",
                            porcent: 30,
                            qtd: [1, 3]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 8,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "skeletonArcher",
                icon: "ruins/skeleton/dead_far_1.png",
                img: "hell/skeleton/Skeleton_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 200,
                    maxhp: 200,
                    mp: 1,
                    energia: 20,
                    atk: 14,
                    def: 2,
                    agility: 4,
                    visionCamp: 450,
                    fly: false,
                    flip: true,
                    speed: 140,
                    move: false,
                    projetil: {
                        name: "arrow",
                        type: "simple",
                        effect: null,
                        speed: 700,
                        time: 1200,
                        scale: 1.25,
                        size: {x: 15, y: 5},
                        posY: 20
                    },
                    atkArea: {
                        x: 50,
                        y: 120
                    },
                    collisions: {
                        size: {
                            x: 20,
                            y: 30
                        },
                        offset: {
                            flipxFalse: 14,
                            flipxTrue: 14,
                            y: 12
                        },
                        atk: {
                            flipxFalse: 60,
                            flipxTrue: -20,
                            y: 45
                        }
                    }
                },
                loots: {
                    exp: 100,
                    gold: [0,0],
                    items: [
                        {
                            name: "osso",
                            porcent: 30,
                            qtd: [1, 3]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 7,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "lizardWarrior",
                icon: "swamp/lizardWarrior/lizardWarrior.png",
                img: "swamp/lizardWarrior/lizardWarrior-idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 350,
                    maxhp: 350,
                    mp: 1,
                    energia: 20,
                    atk: 26,
                    def: 15,
                    agility: 4,
                    visionCamp: 55,
                    fly: false,
                    flip: false,
                    speed: 200,
                    atkArea: {
                        x: 85,
                        y: 85
                    },
                    collisions: {
                        size: {
                            x: 23,
                            y: 30
                        },
                        offset: {
                            flipxFalse: 34,
                            flipxTrue: 10,
                            y: 4
                        },
                        atk: {
                            flipxFalse: 80,
                            flipxTrue: -20,
                            y: 45
                        }
                    }
                },
                loots: {
                    exp: 250,
                    gold: [0,0],
                    items: [
                        {
                            name: "fragmento de alma",
                            porcent: 10,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "mandrake",
                icon: "swamp/mandrake/mandrake.png",
                img: "swamp/mandrake/mandrake-idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 180,
                    maxhp: 180,
                    mp: 1,
                    energia: 20,
                    atk: 12,
                    def: 3,
                    agility: 4,
                    visionCamp: 35,
                    fly: false,
                    flip: false,
                    speed: 200,
                    atkArea: {
                        x: 50,
                        y: 85
                    },
                    collisions: {
                        size: {
                            x: 20,
                            y: 35
                        },
                        offset: {
                            flipxFalse: 26,
                            flipxTrue: 12,
                            y: 5
                        },
                        atk: {
                            flipxFalse: 60,
                            flipxTrue: -10,
                            y: 55
                        }
                    }
                },
                loots: {
                    exp: 80,
                    gold: [0,0],
                    items: [
                        {
                            name: "pinha do pantano",
                            porcent: 40,
                            qtd: [1, 2]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 6,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "grandLizard",
                icon: "swamp/grandLizard/grandLizard.png",
                img: "swamp/grandLizard/grandLizard.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 2800,
                    maxhp: 2800,
                    mp: 1,
                    energia: 20,
                    atk: 90,
                    def: 40,
                    agility: 4,
                    visionCamp: 180,
                    fly: false,
                    flip: true,
                    speed: 170,
                    atkArea: {
                        x: 200,
                        y: 200
                    },
                    collisions: {
                        size: {
                            x: 44,
                            y: 56
                        },
                        offset: {
                            flipxFalse: 71,
                            flipxTrue: 89,
                            y: 72
                        },
                        atk: {
                            flipxFalse: 210,
                            flipxTrue: -70,
                            y: 72
                        }
                    }
                },
                loots: {
                    exp: 2500,
                    gold: [0,0],
                    items: [
                        {
                            name: "fragmento de alma",
                            porcent: 50,
                            qtd: [1, 2]
                        }
                    ]
                }
            },
            {
                name: "goldSkeleton",
                icon: "ruins/goldSkeleton/goldSkeleton.png",
                img: "hell/goldSkeleton/goldSkeleton.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 2200,
                    maxhp: 2200,
                    mp: 1,
                    energia: 20,
                    atk: 80,
                    def: 20,
                    agility: 4,
                    visionCamp: 50,
                    fly: false,
                    flip: true,
                    speed: 300,
                    atkArea: {
                        x: 50,
                        y: 85
                    }
                },
                loots: {
                    exp: 1700,
                    gold: [0,0],
                    items: [
                        {
                            name: "osso",
                            porcent: 30,
                            qtd: [1, 3]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 40,
                            qtd: [1, 2]
                        }
                    ]
                }
            },
            {
                name: "messenger",
                icon: "ruins/skeleton/dead_far_1.png",
                img: "hell/skeleton/Skeleton_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 5000,
                    maxhp: 5000,
                    mp: 1,
                    energia: 20,
                    atk: 280,
                    def: 110,
                    agility: 40,
                    visionCamp: 140,
                    fly: false,
                    flip: true,
                    speed: 100,
                    atkArea: {
                        x: 85,
                        y: 280
                    },
                    collisions: {
                        size: {
                            x: 45,
                            y: 53
                        },
                        offset: {
                            flipxFalse: 80,
                            flipxTrue: 74,
                            y: 59
                        },
                        atk: {
                            flipxFalse: 150,
                            flipxTrue: -40,
                            y: 70
                        }
                    }
                },
                loots: {
                    exp: 8000,
                    gold: [0,0],
                    items: [
                        {
                            name: "fragmento de alma",
                            porcent: 40,
                            qtd: [1, 5]
                        }
                    ]
                }
            },
            {
                name: "skeletonShield",
                icon: "ruins/skeleton_shield/skeleton_shield.png",
                img: "hell/skeleton_shield/skeleton_shield.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 250,
                    maxhp: 250,
                    mp: 1,
                    energia: 20,
                    atk: 17,
                    def: 9,
                    agility: 4,
                    visionCamp: 55,
                    fly: false,
                    flip: true,
                    speed: 120,
                    atkArea: {
                        x: 50,
                        y: 85
                    }
                },
                loots: {
                    exp: 130,
                    gold: [0,0],
                    items: [
                        {
                            name: "osso",
                            porcent: 30,
                            qtd: [1, 3]
                        },
                        {
                            name: "fragmento de alma",
                            porcent: 8,
                            qtd: [1, 1]
                        }
                    ]
                }
            },
            {
                name: "zombie",
                icon: "hell/zombie/Zombie.png",
                img: "hell/zombie/Zombie_idle.png",
                lvl: {
                    initial: 2,
                    end: 30
                },
                status: {
                    hp: 500,
                    maxhp: 500,
                    mp: 1,
                    energia: 20,
                    atk: 26,
                    def: 12,
                    agility: 3,
                    visionCamp: 20
                },
                loots: {
                    exp: 220,
                    gold: [0,0],
                    items: [
                        {
                            name: "carne podre",
                            porcent: 50,
                            qtd: [1, 3]
                        }
                    ]
                }
            },

            // Goblins
            {
                name: "goblin",
                icon: "camp/goblin-camp/Goblin-melee/Goblin_melee.png",
                img: "camp/goblin-camp/Goblin-melee/Goblin_melee_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 250,
                    maxhp: 250,
                    mp: 1,
                    energia: 20,
                    atk: 28,
                    def: 6,
                    agility: 25,
                    visionCamp: 45,
                    speed: 115,
                    atkArea: {
                        x: 50,
                        y: 85
                    },
                    collisions: {
                        size: {
                            x: 22,
                            y: 32
                        },
                        offset: {
                            flipxFalse: 23,
                            flipxTrue: 0,
                            y: 15
                        },
                        atk: {
                            flipxFalse: 60,
                            flipxTrue: -10,
                            y: 55
                        }
                    }
                },
                loots: {
                    exp: 90,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "goblin ranged",
                icon: "camp/goblin-camp/Goblin-range/Goblin_range.png",
                img: "camp/goblin-camp/Goblin-range/Goblin_range_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 270,
                    maxhp: 270,
                    mp: 1,
                    energia: 20,
                    atk: 35,
                    def: 8,
                    agility: 28,
                    visionCamp: 35,
                    speed: 110,
                    atkArea: {
                        x: 50,
                        y: 85
                    }
                },
                loots: {
                    exp: 140,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "troll",
                icon: "camp/goblin-camp/Troll/Troll.png",
                img: "camp/goblin-camp/Troll/Troll_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 1400,
                    maxhp: 1400,
                    mp: 1,
                    energia: 20,
                    atk: 90,
                    def: 30,
                    agility: 15,
                    visionCamp: 80,
                    speed: 70,
                    atkArea: {
                        x: 110,
                        y: 160
                    },
                    collisions: {
                        // Tamanho da colisão
                        size: {
                            x: 32,
                            y: 55
                        },
                        // Posição da colisão
                        offset: {
                            flipxFalse: 26,
                            flipxTrue: 12,
                            y: 15
                        },
                        // Posição da colisão do dano
                        atk: {
                            flipxFalse: 90,
                            flipxTrue: -20,
                            y: 80
                        }
                    }
                },
                loots: {
                    exp: 800,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "demonTroll",
                icon: "camp/goblin-camp/Demon-troll/Demon_Boss.png",
                img: "camp/goblin-camp/Demon-troll/Demon_Boss_sneer.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 3000,
                    maxhp: 3000,
                    mp: 1,
                    energia: 20,
                    atk: 180,
                    def: 90,
                    agility: 15,
                    visionCamp: 200,
                    speed: 30,
                    atkArea: {
                        x: 230,
                        y: 400
                    },
                    collisions: {
                        size: {
                            x: 40,
                            y: 55
                        },
                        offset: {
                            flipxFalse: 45,
                            flipxTrue: 12,
                            y: 41
                        },
                        atk: {
                            flipxFalse: 210,
                            flipxTrue: -70,
                            y: 65
                        }
                    }
                },
                loots: {
                    exp: 1300,
                    gold: [0,0],
                    items: []
                }
            },

            // Bandits
            {
                name: "fanatic",
                icon: "camp/bandit-camp/bandit/bandit.png",
                img: "camp/bandit-camp/bandit/Idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 400,
                    maxhp: 400,
                    mp: 1,
                    energia: 20,
                    atk: 35,
                    def: 15,
                    agility: 40,
                    visionCamp: 45,
                    speed: 220,
                    fly: false,
                    flip: true,
                    atkArea: {
                        x: 50,
                        y: 85
                    },
                    collisions: {
                        size: {
                            x: 22,
                            y: 32
                        },
                        offset: {
                            flipxFalse: 26,
                            flipxTrue: 0,
                            y: 15
                        },
                        atk: {
                            flipxFalse: 80,
                            flipxTrue: -25,
                            y: 25
                        }
                    }
                },
                loots: {
                    exp: 160,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "moonluckFollower",
                icon: "camp/bandit-camp/seguidor_moonluck/Brigand.png",
                img: "camp/bandit-camp/seguidor_moonluck/Brigand_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 600,
                    maxhp: 600,
                    mp: 1,
                    energia: 20,
                    atk: 45,
                    def: 25,
                    agility: 40,
                    visionCamp: 45,
                    speed: 190,
                    fly: false,
                    flip: false,
                    atkArea: {
                        x: 50,
                        y: 85
                    },
                    collisions: {
                        size: {
                            x: 22,
                            y: 34
                        },
                        offset: {
                            flipxFalse: 25,
                            flipxTrue: 0,
                            y: 13
                        },
                        atk: {
                            flipxFalse: 80,
                            flipxTrue: -25,
                            y: 25
                        }
                    }
                },
                loots: {
                    exp: 250,
                    gold: [0,0],
                    items: []
                }
            },
            {
                name: "berserkerMoonlucky",
                icon: "camp/bandit-camp/chief-bandit/Viking.png",
                img: "camp/bandit-camp/chief-bandit/Viking_idle.png",
                lvl: {
                    initial: 1,
                    end: 30
                },
                status: {
                    hp: 4000,
                    maxhp: 4000,
                    mp: 1,
                    energia: 20,
                    atk: 350,
                    def: 60,
                    agility: 40,
                    visionCamp: 70,
                    speed: 120,
                    fly: false,
                    flip: false,
                    atkArea: {
                        x: 150,
                        y: 280
                    },
                    collisions: {
                        size: {
                            x: 22,
                            y: 50
                        },
                        offset: {
                            flipxFalse: 34,
                            flipxTrue: 10,
                            y: 20
                        },
                        atk: {
                            flipxFalse: 150,
                            flipxTrue: -40,
                            y: 65
                        }
                    }
                },
                loots: {
                    exp: 2200,
                    gold: [0,0],
                    items: []
                }
            },


            {
                name: "mummy",
                icon: "desert/mummy/Mummy.png",
                img: "desert/mummy/Mummy_idle.png",
                lvl: {
                    initial: 5,
                    end: 30
                },
                status: {
                    hp: 600,
                    maxhp: 600,
                    mp: 1,
                    energia: 20,
                    atk: 90,
                    def: 45,
                    agility: 3,
                    visionCamp: 60,
                    speed: 90,
                    fly: false,
                    flip: false,
                    atkArea: {
                        x: 50,
                        y: 120
                    },
                    collisions: {
                        size: {
                            x: 17,
                            y: 40
                        },
                        offset: {
                            flipxFalse: 27,
                            flipxTrue: 2,
                            y: 8
                        },
                        atk: {
                            flipxFalse: 75,
                            flipxTrue: -25,
                            y: 65
                        }
                    }
                },
                loots: {
                    exp: 500,
                    gold: [0,0],
                    items: [
                        {
                            name: "pó de múmia",
                            porcent: 20,
                            qtd: [1, 2]
                        }
                    ]
                }
            },
            {
                name: "priestMummy",
                icon: "desert/priest-mummy/Deceased.png",
                img: "desert/priest-mummy/Deceased_idle.png",
                lvl: {
                    initial: 6,
                    end: 30
                },
                status: {
                    hp: 1000,
                    maxhp: 1000,
                    mp: 30,
                    energia: 20,
                    atk: 50,
                    def: 15,
                    agility: 7,
                    visionCamp: 500,
                    speed: 90,
                    fly: false,
                    flip: false,
                    move: false,
                    projetil: {
                        name: "priestMummyBall",
                        type: "simple",
                        effect: null,
                        speed: 300,
                        time: 2500,
                        scale: 2,
                        size: {x: 8, y: 5},
                        posY: 50
                    },
                    atkArea: {
                        x: 50,
                        y: 120
                    },
                    collisions: {
                        size: {
                            x: 17,
                            y: 40
                        },
                        offset: {
                            flipxFalse: 27,
                            flipxTrue: 2,
                            y: 8
                        },
                        atk: {
                            flipxFalse: 75,
                            flipxTrue: -25,
                            y: 65
                        }
                    }
                },
                loots: {
                    exp: 850,
                    gold: [0,0],
                    items: [
                        {
                            name: "pó de múmia",
                            porcent: 25,
                            qtd: [1, 2]
                        }
                    ]
                }
            }

        ]
    },
    mutations
}