import RangeComponent from "./components/range/Range.vue";
import MaterialComponent from "./modais/materiais/Materiais.vue";

import sha256 from 'crypto-js/sha256';

export default {
    data() {
        return {
            selectOption: '',
            mainRange: {
                id: "mainRangeQtd",
                value: {
                    id: "mainRangeQtdValue"
                }
            },
            slots: [],
            ranges: []
        }
    },
    computed: {
        recipesLearn() {
            return this.$store.state.personModule.mainPerson.recipes.filter(i => i.type === this.$store.state.craftModule.typeRecipe);
        },
        recipes() {
            return this.$store.state.craftModule.typeRecipe;
        },
        recipeCurrent() {
            return this.$store.state.personModule.mainPerson.recipes.find(i => i._id === this.$store.state.craftModule.recipeNow._id);
        },
        inventory() {
            return this.$store.state.personModule.mainPerson.Inventory.filter(i => i.type === "material");
        },
        craftMaterials() {
            return this.$store.state.craftModule.materials;
        },
        temporaryStatusCraftItem() {
            return this.$store.state.craftModule.temporaryStatus;
        },
        craftWork() {
            return this.$store.state.craftModule.work;
        },
        craftNow() {
            return this.$store.state.craftModule.recipeNow;
        }
    },
    components: {
        RangeComponent,
        MaterialComponent
    },
    methods: {
        getRecipeData() {
            let needMaterials = this.$store.state.craftModule.recipeNow.need;
            var cont = 0;
            this.slots = [];
            this.ranges = [];
            needMaterials.forEach(el => {
                this.slots.push(
                    {
                        index: cont,
                        img: "",
                        type: el.name,
                        required: el.required,
                        minQtd: el.minQtd,
                        acceptableActives: el.acceptableActives,
                        status: {}
                    }
                );
                this.ranges.push(
                    {
                        index: cont,
                        id: "qtdRange" + cont,
                        value: {
                            id: "qtdRange" + cont + "Value",
                            initial: el.minQtd ? el.minQtd : 0,
                            min: el.minQtd ? el.minQtd : 0,
                            max: this.$store.state.craftModule.statusAdd.maxMaterial ? el.maxQtd + Math.ceil(el.maxQtd * (this.$store.state.craftModule.statusAdd.maxMaterial / 100)) : el.maxQtd,
                            step: 1,
                            isMaterial: true
                        }
                    }
                );
                cont++;
            });
            if(this.$store.state.craftModule.addItemsMaterials.length > 0) {
                var addNewMaterials = this.$store.state.craftModule.addItemsMaterials;
                this.slots.push(
                    {
                        index: cont,
                        img: "",
                        type: "adicional",
                        required: false,
                        minQtd: 0,
                        acceptableActives: addNewMaterials,
                        status: {}
                    }
                );
                this.ranges.push(
                    {
                        index: cont,
                        id: "qtdRange" + cont,
                        value: {
                            id: "qtdRange" + cont + "Value",
                            initial: 0,
                            min: 0,
                            max: 1,
                            step: 1,
                            isMaterial: true
                        }
                    }
                );
            }
        },
        configMainQtdRange() {
            this.mainRange = {
                id: "mainRangeQtd",
                value: {
                    id: "mainRangeQtdValue",
                    initial: 1,
                    min: 1,
                    max: 100,
                    step: 1,
                    isMaterial: false
                }
            }
        },
        changeOption() {
            let optionSelected = document.querySelector("select.selectRecipes");
            this.$store.commit("cleanState");
            this.$store.dispatch("findStatusPerson");
            this.configMainQtdRange();
            if(!optionSelected) {
                this.$store.commit("changeRecipeNow", this.$store.state.personModule.mainPerson.recipes.find(i => i.type === this.$store.state.craftModule.typeRecipe));
                if(!this.$store.state.craftModule.recipeNow) {
                    this.close();
                    alert("Você não possui receitas desse tipo, vá na livraria e compre algumas");
                    return;
                }
                this.getRecipeData();
                return;
            }
            else if(optionSelected) {
                this.$store.commit("changeRecipeNow", this.$store.state.personModule.mainPerson.recipes.find(i => i.name === optionSelected.value));
                this.getRecipeData();
                return;
            } else if (this.$store.state.personModule.mainPerson.recipes[0]) {
                this.$store.commit("changeRecipeNow", this.$store.state.personModule.mainPerson.recipes[0]);
                this.getRecipeData();
                return;
            }
        },
        close() {
            this.$store.commit("cleanState");
            this.$store.commit("viewModalCraft", false);
        },
        identifyTypeItemCraft(value) {
            switch(value) {
                case 3:
                    return {groupEquip: "potion", type: "use", work: "alchemist"};
                case 2:
                    return {groupEquip: "food", type: "use", work: "cooker"};
                case 4:
                    return {groupEquip: "material", type: "material", work: "refiner"};
                case 1:
                    return {groupEquip: "weapon", type: "equip", work: "smithing"};
                case 5:
                    return {groupEquip: "armor", type: "equip", work: "armor forger"};
                case 6:
                    return {groupEquip: "jeweler", type: "material", work: "jeweler"};
                case 7:
                    return {groupEquip: "tool", type: "tool", work: "toolmaker"};
            }
        },
        addItemCraft(recipe) {
            const infos = this.identifyTypeItemCraft(recipe.type);
            this.addExpCraftWork(infos.work);
            var item = {
                "groupEquip": infos.groupEquip,
                "type": infos.type,
                "rarity": this.$store.state.craftModule.rarity.name,
                "seller": "market",
                "name": recipe.name,
                "urlImg": (recipe.img).replace(/^[a-z]+\//gi, ""),
                "status": this.$store.state.craftModule.temporaryStatus,
                "price": this.$store.state.craftModule.price,
                "weight": this.$store.state.craftModule.weight,
                "qtd": parseInt(document.getElementById(this.mainRange.id).value),
                "craftItem": true,
                "sign": this.$store.state.personModule.mainPerson.nickname + " " + this.$store.state.personModule.mainPerson.familyName
            };
            item.idCraft = this.generateIdCraft(item);
            this.$store.commit("addItemCraft", item);
        },
        addItemEquipCraft(recipe) {
            const infos = this.identifyTypeItemCraft(recipe.type);
            this.addExpCraftWork(infos.work);
            var item = {
                "groupEquip": recipe.groupEquip,
                "type": "equip",
                "rarity": this.$store.state.craftModule.rarity.name,
                "durability": {
                    "value": this.$store.state.craftModule.resistance * this.$store.state.craftModule.rarity.multiply,
                    "base": this.$store.state.craftModule.resistance * this.$store.state.craftModule.rarity.multiply,
                    "maxValue": this.$store.state.craftModule.resistance * this.$store.state.craftModule.rarity.multiply
                },
                "seller": "market",
                "tier": recipe.tier,
                "name": recipe.name,
                "class": recipe.class,
                "urlImg": (recipe.img).replace(/^[a-z]+\//gi, ""),
                "status": this.$store.state.craftModule.temporaryStatus,
                "price": this.$store.state.craftModule.price,
                "weight": this.$store.state.craftModule.weight,
                "qtd": 1,
                "craftItem": true,
                "sign": this.$store.state.personModule.mainPerson.nickname + " " + this.$store.state.personModule.mainPerson.familyName
            };
            item.idCraft = this.generateIdCraft(item);
            this.$store.commit("addItemCraft", item);
        },
        addItemToolEquipCraft(recipe) {
            const infos = this.identifyTypeItemCraft(recipe.type);
            this.addExpCraftWork(infos.work);
            var item = {
                "groupEquip": "tool",
                "type": "equipTool",
                "rarity": this.$store.state.craftModule.rarity.name,
                "durability": {
                    "value": this.$store.state.craftModule.resistance * this.$store.state.craftModule.rarity.multiply,
                    "base": this.$store.state.craftModule.resistance * this.$store.state.craftModule.rarity.multiply,
                    "maxValue": this.$store.state.craftModule.resistance * this.$store.state.craftModule.rarity.multiply
                },
                "seller": "market",
                "tier": recipe.tier,
                "name": recipe.name,
                "urlImg": (recipe.img).replace(/^[a-z]+\//gi, ""),
                "status": this.$store.state.craftModule.temporaryStatus,
                "price": this.$store.state.craftModule.price,
                "weight": this.$store.state.craftModule.weight,
                "workTool": recipe.workTool,
                "boost": recipe.boost * this.$store.state.craftModule.rarity.multiply,
                "qtd": 1,
                "craftItem": true,
                "sign": this.$store.state.personModule.mainPerson.nickname + " " + this.$store.state.personModule.mainPerson.familyName
            };
            item.idCraft = this.generateIdCraft(item);
            this.$store.commit("addItemCraft", item);
        },
        generateIdCraft(item) {
            let newId = item.groupEquip + item.type + item.rarity + item.name + item.urlImg + item.price;
            var idStatus = item.status;
            if(idStatus.length > 1) {
                idStatus.forEach(status => {
                    newId += status;
                })
            } else {
                newId += idStatus;
            }
            var sha256Hash = sha256(newId);
            var sha256NewHash = () => {
                var hash = (sha256Hash.words[0] + sha256Hash.words[2]).toString() + (sha256Hash.words[3] + sha256Hash.words[7] + sha256Hash.words[5]).toString();
                hash = hash.replace("-","").replace("-","");
                return hash;
            }
            var identifier = sha256NewHash();
            return identifier;
        },
        calculeMaxQtdMaker() {
            var materials = this.$store.state.craftModule.materials, item, idICrafttem, idItem, validate = true, count = 0;
            let mainRangeValue = parseInt(document.getElementById(this.mainRange.id).value);
            const recipeNow = this.$store.state.craftModule.recipeNow;
            materials.forEach(material => {
                if(material.idCraft) {
                    idICrafttem = this.$store.state.personModule.mainPerson.Inventory.find(i => i.idCraft === material.idCraft);
                } else {
                    idItem = this.$store.state.personModule.mainPerson.Inventory.find(i => i._id === material._id);
                }
                if(recipeNow.need[count].required) {
                    if(material.qtdCraft < recipeNow.need[count].minQtd) {
                        alert(`Você precisa de ${material.name} para criar esse item`);
                        validate = false;
                        return;
                    }
                }
                if(idItem) item = idItem;
                else if(idICrafttem) item = idICrafttem;
                if(item) {
                    if(item.qtd < (material.qtdCraft * mainRangeValue)) {
                        alert(`Você não possue quantidade de ${material.name} suficiente seu inventário para criar o item`);
                        validate = false;
                        return;
                    }
                } else {
                    alert(`Você não possue ${material.name} no seu inventário`);
                    validate = false;
                    return;
                }
                count++;
            });
            return validate;
        },
        removeMaterials() {
            var materials = this.$store.state.craftModule.materials;
            const mainRangeValue = this.$store.state.craftModule.recipeNow.groupEquip ? 1 : document.getElementById(this.mainRange.id).value;
            const priceStatusAdd = this.$store.state.craftModule.statusAdd.morePrice ? this.$store.state.craftModule.statusAdd.morePrice / 100 : 0;
            const dimMaterialStatusAdd = this.$store.state.craftModule.statusAdd.dimMaterial ? this.$store.state.craftModule.statusAdd.dimMaterial / 100 : 0;
            materials.forEach(material => {
                this.$store.commit("addPriceCraftItem", material.price * material.qtdCraft + (material.price * material.qtdCraft * priceStatusAdd));
                if(material.weight) {
                    this.$store.commit("addWeightCraftItem", material.weight * material.qtdCraft);
                }
                if(material.status) {
                    if(material.status.Resistencia && this.$store.state.craftModule.recipeNow.groupEquip) {
                        this.$store.commit("addResistancetCraftItem", material.status.Resistencia * material.qtdCraft);
                    }
                }
                var newItem = {
                    item: material,
                    quantidade: Math.ceil(material.qtdCraft * mainRangeValue - (material.qtdCraft * mainRangeValue * dimMaterialStatusAdd))
                };
                this.$store.commit("deleteItemInventory", newItem);
            });
        },
        addExpCraftWork(work) {
            let expWorkCraft;
            if(!this.$store.state.craftModule.recipeNow.groupEquip) {
                expWorkCraft = this.$store.state.craftModule.recipeNow.exp * parseInt(document.getElementById(this.mainRange.id).value);
            } else {
                expWorkCraft = this.$store.state.craftModule.recipeNow.exp;
            }
            let statusExpAddCraft = this.$store.state.craftModule.statusAdd.moreExp ? this.$store.state.craftModule.statusAdd.moreExp / 100 : 0;
            this.$store.commit("changeCraftWorkExp", {type: work, value: expWorkCraft + (expWorkCraft * statusExpAddCraft)});
        },
        addItemInventory() {
            const qtdMaterialBoolean = this.calculeMaxQtdMaker();
            const mainRangeValue = document.getElementById(this.mainRange.id).value;
            if(qtdMaterialBoolean && this.$store.state.craftModule.recipeNow.groupEquip === "tool") {
                for(var z = 0; z < mainRangeValue; z++) {
                    this.$store.dispatch("calculateRarity");
                    this.removeMaterials();
                    this.addItemToolEquipCraft(this.$store.state.craftModule.recipeNow);
                    this.$store.commit("addItemCraftInventory", this.$store.state.craftModule.itemCraft);
                    this.$store.commit("clearPriceCraftItem");
                    this.$store.commit("clearWeightCraftItem");
                    this.$store.commit("clearResistanceCraftItem");
                    this.$store.dispatch("reviewCraftItemStatus");
                }
                alert(`${mainRangeValue}x ${this.$store.state.craftModule.itemCraft.name} criado(s) com sucesso!`);
                this.$store.dispatch("reviewCraftItemStatus");
            }
            else if(qtdMaterialBoolean && this.$store.state.craftModule.recipeNow.groupEquip) {
                for(var i = 0; i < mainRangeValue; i++) {
                    this.$store.dispatch("calculateRarity");
                    this.removeMaterials();
                    this.addItemEquipCraft(this.$store.state.craftModule.recipeNow);
                    this.$store.commit("addItemCraftInventory", this.$store.state.craftModule.itemCraft);
                    this.$store.commit("clearPriceCraftItem");
                    this.$store.commit("clearWeightCraftItem");
                    this.$store.commit("clearResistanceCraftItem");
                    this.$store.dispatch("reviewCraftItemStatus");
                }
                alert(`${mainRangeValue}x ${this.$store.state.craftModule.itemCraft.name} criado(s) com sucesso!`);
                this.$store.dispatch("reviewCraftItemStatus");
            }
            else if(qtdMaterialBoolean && !this.$store.state.craftModule.recipeNow.groupEquip) {
                this.$store.dispatch("calculateRarity");
                this.removeMaterials();
                this.addItemCraft(this.$store.state.craftModule.recipeNow);
                this.$store.commit("addItemCraftInventory", this.$store.state.craftModule.itemCraft);
                this.$store.commit("clearPriceCraftItem");
                this.$store.commit("clearWeightCraftItem");
                this.$store.commit("clearResistanceCraftItem");
                alert(`${this.$store.state.craftModule.itemCraft.qtd}x ${this.$store.state.craftModule.itemCraft.name} criado(s) com sucesso!`);
                this.$store.dispatch("reviewCraftItemStatus");
            }
        },
        forceUpdate() {
            this.$forceUpdate();
        }
    },
    created() {
        let typeRecipe = this.identifyTypeItemCraft(this.$store.state.craftModule.typeRecipe);
        this.$store.dispatch("changeCraftWork", typeRecipe.work);
        this.changeOption();
    }
}