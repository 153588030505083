import Phaser from "phaser";
import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

import keyboardsImport from "@/shared/components-phaser/keyboards" 
import playerImport from "@/shared/components-phaser/player" 
import { methodsCamp } from "@/shared/components-phaser/methods" 
import { updateActionsCamp } from "@/shared/components-phaser/updateActions"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;

class SceneCamp extends Phaser.Scene {
    constructor(locationInfos, mainPerson, monsters){
        super("SceneCamp")
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.monsters = monsters;
        this.platforms;
        this.typeCamp = "goblin";
        this.mapSize = {
            x: 3000,
            y: 800
        };
        this.sounds = {};
        this.player;
        this.background;
        this.enemy = {};
        this.enemyArray = [];
        this.mainAtk;
        this.mainAtkMoreDamage;
        this.monsterAttack;
        this.mainAtkMoreDamage;
        this.damageText;
        this.startWave;
        this.limits;
        this.wave = {
            currentWave: 1,
            monsterNumber: 0
        }
        this.methods = methodsCamp;
    }
    
    preload ()
    {
        // Audios
        this.load.audio('music', '/resourcesInGame/sons/musics/camp/goblin/Action_-_Knightly_Fighting.ogg');

        // Tiles
        this.load.image("bloco1", "/resourcesInGame/title-set/tiles/florest/Tile_23.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/background/camp/sky.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/camp/grass&road.png");
        this.load.image("trees", "/resourcesInGame/title-set/parallax/trees/camp/trees&bushes.png");
        this.load.image("trees2", "/resourcesInGame/title-set/parallax/trees/camp/grasses.png");
        this.load.image("trees3", "/resourcesInGame/title-set/parallax/trees/camp/lianas.png");
        this.load.image("trees4", "/resourcesInGame/title-set/parallax/trees/camp/tree_face.png");

        //enemies
        this.load.spritesheet('goblinIdle', '/resourcesInGame/sprites/camp/goblin-camp/Goblin-melee/Goblin_melee_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('goblinWalk', '/resourcesInGame/sprites/camp/goblin-camp/Goblin-melee/Goblin_melee_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('goblinAttack', '/resourcesInGame/sprites/camp/goblin-camp/Goblin-melee/Goblin_melee_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('goblinHurt', '/resourcesInGame/sprites/camp/goblin-camp/Goblin-melee/Goblin_melee_hurt.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('goblinDeath', '/resourcesInGame/sprites/camp/goblin-camp/Goblin-melee/Goblin_melee_death.png', {frameWidth: 48, frameHeight: 48});

        this.load.spritesheet('trollIdle', '/resourcesInGame/sprites/camp/goblin-camp/Troll/Troll_idle.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('trollWalk', '/resourcesInGame/sprites/camp/goblin-camp/Troll/Troll_walk.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('trollHurt', '/resourcesInGame/sprites/camp/goblin-camp/Troll/Troll_hurt.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('trollAttack', '/resourcesInGame/sprites/camp/goblin-camp/Troll/Troll_attack1.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('trollDeath', '/resourcesInGame/sprites/camp/goblin-camp/Troll/Troll_death.png', {frameWidth: 72, frameHeight: 72});

        this.load.spritesheet('demonTrollWalk', '/resourcesInGame/sprites/camp/goblin-camp/Demon-troll/Demon_Boss_walk.png', {frameWidth: 96, frameHeight: 96});
        this.load.spritesheet('demonTrollHurt', '/resourcesInGame/sprites/camp/goblin-camp/Demon-troll/Demon_Boss_hurt.png', {frameWidth: 96, frameHeight: 96});
        this.load.spritesheet('demonTrollAttack', '/resourcesInGame/sprites/camp/goblin-camp/Demon-troll/Demon_Boss_attack2.png', {frameWidth: 96, frameHeight: 96});
        this.load.spritesheet('demonTrollDeath', '/resourcesInGame/sprites/camp/goblin-camp/Demon-troll/Demon_Boss_death.png', {frameWidth: 96, frameHeight: 96});
        this.load.spritesheet('demonTrollIdle', '/resourcesInGame/sprites/camp/goblin-camp/Demon-troll/Demon_Boss_sneer.png', {frameWidth: 96, frameHeight: 96});
    }

    create ()
    {
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);
    
            //Enemy
            //GoblinMelee
            vm.anims.create({
                key: "goblinIdle",
                frames: vm.anims.generateFrameNumbers("goblinIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "goblinWalk",
                frames: vm.anims.generateFrameNumbers("goblinWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "goblinAttack",
                frames: vm.anims.generateFrameNumbers("goblinAttack", {start: 0, end: 3}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 700
            }),
            vm.anims.create({
                key: "goblinHurt",
                frames: vm.anims.generateFrameNumbers("goblinHurt", {start: 0, end: 1}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "goblinDeath",
                frames: vm.anims.generateFrameNumbers("goblinDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })

            //Troll
            vm.anims.create({
                key: "trollIdle",
                frames: vm.anims.generateFrameNumbers("trollIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "trollWalk",
                frames: vm.anims.generateFrameNumbers("trollWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "trollAttack",
                frames: vm.anims.generateFrameNumbers("trollAttack", {start: 0, end: 3}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 500
            }),
            vm.anims.create({
                key: "trollHurt",
                frames: vm.anims.generateFrameNumbers("trollHurt", {start: 0, end: 1}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "trollDeath",
                frames: vm.anims.generateFrameNumbers("trollDeath", {start: 0, end: 4}),
                frameRate: 5,
                repeat: 0
            })

            //Demon Troll
            vm.anims.create({
                key: "demonTrollWalk",
                frames: vm.anims.generateFrameNumbers("demonTrollWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "demonTrollAttack",
                frames: vm.anims.generateFrameNumbers("demonTrollAttack", {start: 0, end: 7}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "demonTrollHurt",
                frames: vm.anims.generateFrameNumbers("demonTrollHurt", {start: 0, end: 1}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "demonTrollDeath",
                frames: vm.anims.generateFrameNumbers("demonTrollDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "demonTrollIdle",
                frames: vm.anims.generateFrameNumbers("demonTrollIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0,
                repeatDelay: 500
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var mountainsBack = vm.add.image(0, 0, 'trees').setOrigin(0).setScrollFactor(.33);
            var mountainsBack2 = vm.add.image(1920, 0, 'trees').setOrigin(0).setScrollFactor(.33);

            var trees = vm.add.image(0, 50, 'trees4').setOrigin(0).setScrollFactor(.75);

            var mountains = vm.add.image(0, 100, 'trees2').setOrigin(0).setScrollFactor(.66);
            var mountains2 = vm.add.image(1920, 100, 'trees2').setOrigin(0).setScrollFactor(.66);
            

            var bushes = vm.add.image(0, 0, 'trees3').setOrigin(0).setScrollFactor(.75);
            var bushes2 = vm.add.image(1920, 0, 'trees3').setOrigin(0).setScrollFactor(.75);
            var bushes3 = vm.add.image(3840, 0, 'trees3').setOrigin(0).setScrollFactor(.75);
            
            trees.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            bushes.displayHeight = vm.sys.game.config.height;
            bushes2.displayHeight = vm.sys.game.config.height;
            bushes3.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;

            var ground = vm.add.image(0, 120, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground2 = vm.add.image(1920, 120, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground3 = vm.add.image(3840, 120, 'ground').setOrigin(0).setScrollFactor(.8);

            ground.displayHeight = vm.sys.game.config.height;
            ground2.displayHeight = vm.sys.game.config.height;
            ground3.displayHeight = vm.sys.game.config.height;
        }
    
        function createScene(vm) {
            parallax(vm);
    
            vm.platforms = vm.physics.add.staticGroup();
    
            for(var i = 0; i < 60; i++) {
                vm.platforms.create(60 * i, 748, 'bloco1').setScale(2, 2.3).setAlpha(0).refreshBody();
            }
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 600, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(3000, 600, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(initial, vm.player);
            vm.physics.add.collider(end, vm.player);
        }

        this.sounds.theme = this.sound.add('music');

        this.sounds.theme.play({loop: true, volume: 0.8});

        this.methods.resetScore();
        this.methods.resetMonsterDead();
        this.methods.nextWave(this);
        this.methods.showCount();

        this.monsterAttack = this.add.group();
        this.mainAtk = this.add.group();
        this.damageText = this.add.group();
        this.limits = this.physics.add.group();

        this.physics.world.enable(this.mainAtk);
        this.physics.world.enable(this.monsterAttack);
        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        createAnimations(this);
        playerImport.createPlayer(this, 700, 600);
        createScene(this);
        createLimits(this);

        this.physics.add.collider(this.player, this.platforms);
        this.physics.add.collider(this.mainAtk, this.platforms);
        this.physics.add.collider(this.mainAtkMoreDamage, this.platforms);
        this.physics.add.collider(this.monsterAttack, this.platforms);
        this.physics.add.collider(this.limits, this.platforms);

        this.physics.world.setBounds(0, 0, this.mapSize.x, 800)

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, 800);

        this.methods.navegateWave(this, this.typeCamp);

        keyboardsImport(this);

        this.methods.forAttack(this);
    }

    update ()
    {
        updateActionsCamp(this);
    }
}

export default SceneCamp;