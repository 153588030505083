import Phaser from "phaser";
import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";

import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

import keyboardsImport from "@/shared/components-phaser/keyboards" 
import playerImport from "@/shared/components-phaser/player" 
import { methodsCamp } from "@/shared/components-phaser/methods" 
import { updateActionsCamp } from "@/shared/components-phaser/updateActions"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;

class SceneCamp extends Phaser.Scene {
    constructor(locationInfos, mainPerson, monsters){
        super("SceneCamp")
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.monsters = monsters;
        this.platforms;
        this.typeCamp = "bandit";
        this.mapSize = {
            x: 3000,
            y: 800
        };
        this.sounds = {};
        this.player;
        this.background;
        this.enemy = {};
        this.enemyArray = [];
        this.mainAtk;
        this.mainAtkMoreDamage;
        this.monsterAttack;
        this.mainAtkMoreDamage;
        this.damageText;
        this.startWave;
        this.limits;
        this.wave = {
            currentWave: 1,
            monsterNumber: 0
        }
        this.methods = methodsCamp;
    }
    
    preload ()
    {
        // Audios
        this.load.audio('music', '/resourcesInGame/sons/musics/camp/goblin/Action_-_Knightly_Fighting.ogg');

        // Tiles
        this.load.image("bloco1", "/resourcesInGame/title-set/tiles/florest/Tile_23.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/camp/bg.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/camp/stones.png");
        this.load.image("trees", "/resourcesInGame/title-set/parallax/trees/camp/bandit/trees_rocks_back.png");
        this.load.image("trees2", "/resourcesInGame/title-set/parallax/trees/camp/bandit/trees&rocks1.png");
        this.load.image("trees3", "/resourcesInGame/title-set/parallax/trees/camp/bandit/trees1.png");
        this.load.image("trees4", "/resourcesInGame/title-set/parallax/trees/camp/bandit/trees2.png");

        //enemies
        this.load.spritesheet('fanaticIdle', '/resourcesInGame/sprites/bandits/bandit/Idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('fanaticWalk', '/resourcesInGame/sprites/bandits/bandit/Walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('fanaticAttack', '/resourcesInGame/sprites/bandits/bandit/Attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('fanaticHurt', '/resourcesInGame/sprites/bandits/bandit/Hurt.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('fanaticDeath', '/resourcesInGame/sprites/bandits/bandit/Death.png', {frameWidth: 48, frameHeight: 48});

        this.load.spritesheet('moonluckFollowerIdle', '/resourcesInGame/sprites/bandits/seguidor_moonluck/Brigand_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('moonluckFollowerWalk', '/resourcesInGame/sprites/bandits/seguidor_moonluck/Brigand_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('moonluckFollowerHurt', '/resourcesInGame/sprites/bandits/seguidor_moonluck/Brigand_hurt.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('moonluckFollowerAttack', '/resourcesInGame/sprites/bandits/seguidor_moonluck/Brigand_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('moonluckFollowerDeath', '/resourcesInGame/sprites/bandits/seguidor_moonluck/Brigand_death.png', {frameWidth: 48, frameHeight: 48});

        this.load.spritesheet('berserkerMoonluckyWalk', '/resourcesInGame/sprites/bandits/chief-bandit/Viking_walk.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('berserkerMoonluckyAttack', '/resourcesInGame/sprites/bandits/chief-bandit/Viking_attack1.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('berserkerMoonluckyDeath', '/resourcesInGame/sprites/bandits/chief-bandit/Viking_death.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('berserkerMoonluckyIdle', '/resourcesInGame/sprites/bandits/chief-bandit/Viking_idle.png', {frameWidth: 72, frameHeight: 72});
    }

    create ()
    {
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);
    
            //Enemy
            //Fanatic
            vm.anims.create({
                key: "fanaticIdle",
                frames: vm.anims.generateFrameNumbers("fanaticIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "fanaticWalk",
                frames: vm.anims.generateFrameNumbers("fanaticWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "fanaticAttack",
                frames: vm.anims.generateFrameNumbers("fanaticAttack", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 600
            }),
            vm.anims.create({
                key: "fanaticHurt",
                frames: vm.anims.generateFrameNumbers("fanaticHurt", {start: 0, end: 1}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "fanaticDeath",
                frames: vm.anims.generateFrameNumbers("fanaticDeath", {start: 0, end: 5}),
                frameRate: 5,
                repeat: 0
            })

            //Troll
            vm.anims.create({
                key: "moonluckFollowerIdle",
                frames: vm.anims.generateFrameNumbers("moonluckFollowerIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "moonluckFollowerWalk",
                frames: vm.anims.generateFrameNumbers("moonluckFollowerWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "moonluckFollowerAttack",
                frames: vm.anims.generateFrameNumbers("moonluckFollowerAttack", {start: 0, end: 3}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 500
            }),
            vm.anims.create({
                key: "moonluckFollowerHurt",
                frames: vm.anims.generateFrameNumbers("moonluckFollowerHurt", {start: 0, end: 1}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "moonluckFollowerDeath",
                frames: vm.anims.generateFrameNumbers("moonluckFollowerDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })

            //Demon Troll
            vm.anims.create({
                key: "berserkerMoonluckyWalk",
                frames: vm.anims.generateFrameNumbers("berserkerMoonluckyWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "berserkerMoonluckyAttack",
                frames: vm.anims.generateFrameNumbers("berserkerMoonluckyAttack", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 500
            }),
            vm.anims.create({
                key: "berserkerMoonluckyDeath",
                frames: vm.anims.generateFrameNumbers("berserkerMoonluckyDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            }),
            vm.anims.create({
                key: "berserkerMoonluckyIdle",
                frames: vm.anims.generateFrameNumbers("berserkerMoonluckyIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0,
                repeatDelay: 500
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var mountainsBack = vm.add.image(0, 0, 'trees').setOrigin(0).setScrollFactor(.33);
            var mountainsBack2 = vm.add.image(1920, 0, 'trees').setOrigin(0).setScrollFactor(.33);

            var mountains = vm.add.image(0, 0, 'trees2').setOrigin(0).setScrollFactor(.66);
            var mountains2 = vm.add.image(1920, 0, 'trees2').setOrigin(0).setScrollFactor(.66);

            var bushes = vm.add.image(0, 0, 'trees3').setOrigin(0).setScrollFactor(.75);
            var bushes2 = vm.add.image(1920, 0, 'trees3').setOrigin(0).setScrollFactor(.75);
            var bushes3 = vm.add.image(3840, 0, 'trees3').setOrigin(0).setScrollFactor(.75);
            
            var trees = vm.add.image(0, 0, 'trees4').setOrigin(0).setScrollFactor(.75);
            
            trees.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            bushes.displayHeight = vm.sys.game.config.height;
            bushes2.displayHeight = vm.sys.game.config.height;
            bushes3.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;

            var ground = vm.add.image(0, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground2 = vm.add.image(1920, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground3 = vm.add.image(3840, 0, 'ground').setOrigin(0).setScrollFactor(.8);

            ground.displayHeight = vm.sys.game.config.height;
            ground2.displayHeight = vm.sys.game.config.height;
            ground3.displayHeight = vm.sys.game.config.height;
        }
    
        function createScene(vm) {
            parallax(vm);
    
            vm.platforms = vm.physics.add.staticGroup();
    
            for(var i = 0; i < 60; i++) {
                vm.platforms.create(60 * i, 780, 'bloco1').setScale(2, 2.3).setAlpha(0).refreshBody();
            }
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 600, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(3000, 600, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(initial, vm.player);
            vm.physics.add.collider(end, vm.player);
        }

        this.sounds.theme = this.sound.add('music');

        this.sounds.theme.play({loop: true, volume: 0.4});

        this.methods.resetScore();
        this.methods.resetMonsterDead();
        this.methods.nextWave(this);
        this.methods.showCount();

        this.monsterAttack = this.add.group();
        this.mainAtk = this.add.group();
        this.damageText = this.add.group();
        this.limits = this.physics.add.group();

        this.physics.world.enable(this.mainAtk);
        this.physics.world.enable(this.monsterAttack);
        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        createAnimations(this);
        playerImport.createPlayer(this, 700, 650);
        createScene(this);
        createLimits(this);

        this.physics.add.collider(this.player, this.platforms);
        this.physics.add.collider(this.mainAtk, this.platforms);
        this.physics.add.collider(this.mainAtkMoreDamage, this.platforms);
        this.physics.add.collider(this.monsterAttack, this.platforms);
        this.physics.add.collider(this.limits, this.platforms);

        this.physics.world.setBounds(0, 0, this.mapSize.x, 800);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, 800);

        this.methods.navegateWave(this, this.typeCamp);

        keyboardsImport(this);

        this.methods.forAttack(this);
    }

    update ()
    {
        updateActionsCamp(this);
    }
}

export default SceneCamp;