export default function nordicBarbarian(vm) {
    vm.anims.create({
        key: "roll",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianRoll', {start: 0, end: 7}),
        frameRate: 10,
        repeat: 0
    })

    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianJump', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "up",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianJump', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "down",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianFall', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianAttack1', {start: 0, end: 8}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianAttack2', {start: 0, end: 8}),
        frameRate: 30
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianAttack3', {start: 0, end: 8}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianIdle', {start: 0, end: 11}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianWalk', {start: 0, end: 9}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "run",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianRun', {start: 0, end: 9}),
        frameRate: 15,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('nordicBarbarianDead', {start: 0, end: 12}),
        frameRate: 5,
        repeat: 0
    })
}