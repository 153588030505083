var person = {
    "identifier": "",
    "lvl": 1,
    "nickname": "",
    "familyName": "",
    "race": {},
    "nfts": [],
    "statistics": {
        "monsterDead": [],
        "bossesDead": []
    },
    "adventureGuild": {
        "donations": 0,
        "level": {
            "letter": null,
            "exp": {
                "value": 0,
                "maxValue": 15000
            }
        },
        "quests": []
    },
    "sprite": "warrior",//Sprite que será usado para andar no mapa
    "personPath": "", //O que o char vai ser: mago, espadachin, lanceiro, arqueiro
    "pointsAttr": {
        "current": 0,
        "skillPoints": 0,
        "asignado": {
            "vitality": 0,
            "force": 0,
            "critical": 0,
            "mPower": 0
        }
    },
    "currentLocation": {
        "mapNow": "Odenlor",
        "place": "Mjölnir"
    },
    "religion": {
        "name": "",
        "skill": {},
        "mainGod": "",
        "donation": 0,
        "level": 0,
        "exp": {
            "value": 0,
            "maxValue": 1000
        }
    },
    "happiness": {
        "level": "aceitavel",
        "value": 65,
        "maxValue": 100
    },
    "class": {},
    "marketBank": 0,
    "gold": {
        "value": 1000,
        "bank": 0
    },
    "exp": {
        "value": 0,
        "maxValue": 1680
    },
    "inventorySize": 20,
    "travelTime": 0,
    "magicPaths": [],
    "spells": [],
    "specialties": [
        {
            "name": "desarmado",
            "level": {
                "value": 0,
                "exp": {
                    "value": 0,
                    "maxValue": 200
                }
            }
        }
    ], //Colocar especialidades de armas (Espadas, machados, arcos) e melhorar conforme o uso
    "weight": {
        "value": 0, //Peso que está carregando
        "maxValue": 0, //Peso máximo que aguenta
        "base": 0 //Peso máximo que aguenta sem modificações
    },
    "infos": [
        {
            "title": "Status",
            "li": [
                {
                    "title": "HP",
                    "value": 100,
                    "maxValue": 100,
                    "base": 100,
                    "boost": []
                },
                {
                    "title": "Magia",
                    "value": 100,
                    "maxValue": 100,
                    "base": 100,
                    "boost": []
                },
                {
                    "title": "Energia",
                    "value": 100,
                    "maxValue": 100,
                    "base": 100,
                    "boost": []
                }
            ]
        },
        {
            "title": "Fisico",
            "li": [
                {
                    "title": "Ataque",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Forca",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Defesa",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Critico",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Agilidade",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Poder mágico",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                }
            ]
        },
        {
            "title": "Oratória",
            "li": [
                {
                    "title": "Carisma",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Negociação",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                },
                {
                    "title": "Nobreza",
                    "value": 1,
                    "base": 1,
                    "maxValue": null,
                    "boost": []
                }
            ]
        }
    ],
    "propertiesAcquired": [],
    "propertiesRented": [],
    "Inventory": [],
    //Afinidade com os NPCs
    "affinity": [],
    "Equips": [
        {
            "groupEquip": "first-weapon",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "second-weapon",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "tool",
            "name": "",
            "urlImg": ""
        },
        {
            "groupEquip": "armor",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "helmet",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "gauntlet",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "boots",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "ringOne",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "ringTwo",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "necklace",
            "name": "",
            "urlImg": "",
            "status": {}
        },
        {
            "groupEquip": "horse",
            "name": "",
            "urlImg": "",
            "status": {}
        }
    ],
    "hotkeys": [],
    "recipes": [],
    "skills": [],
    "timer":[],
    //Action são ações que o player deve ou está executando em tempo de jogo, mas que devem ser salvas para evitar os jogadores que vão dar refresh ou fechar e reabrir o navegar para n cumprir o RandomEvent ou a Dungeon
    //Jogadores que fizerem isso para fugir de RandomEvents receberam a penalidade de morte do personagem e perda de 1-2 leveis
    //Jogadores que fizerem isso para fugir de Dungeons simplismente vão recomeça-lá do 0
    "action": ""
}

export default person;