const lvl = [
    1680,
    4200,
    17500,
    41620,
    65000,
    130000,
    260000,
    520000,
    1040000,
    1560000,
    2080000,
    3120000,
    4160000,
    5200000,
    7280000,
    8320000,
    9360000,
    11440000,
    13520000,
    15600000,
    16640000,
    17680000,
    20800000,
    23920000,
    27040000,
    30160000,
    33280000,
    36400000,
    39520000,
    43680000
]

export default lvl;