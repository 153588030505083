export default {
    props: ["rangeObj"],
    methods: {
        
    },
    mounted() {
        var range = document.getElementById(this.$props.rangeObj.id),
            value = document.getElementById(this.$props.rangeObj.value.id),
            index = this.$props.rangeObj.index,
            isMaterial = this.$props.rangeObj.value.isMaterial,
            vm = this;

        range.addEventListener('input', function() {
            if(isMaterial) {
                value.textContent = this.value;
                vm.$store.commit("changeMaterialQtd", {index: index, value: this.value});
                vm.$store.dispatch("reviewCraftItemStatus");
                return;
            }
            value.textContent = this.value;
        });
    }
}