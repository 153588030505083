import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;

export default function bloodHit(vm) {
    vm.load.on('progress', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, true);
    });

    vm.load.on('complete', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, false);
    });
    
    // Sons
    vm.load.audio('attack', '/resourcesInGame/sons/actions/sword/Sword_Woosh-16.wav'); 
    vm.load.audio('hit', '/resourcesInGame/sons/actions/hit/ESM_Fantasy_Game_Skill_Target_Weakness_Weapon_Medieval.wav');
    vm.load.audio('monsterDead', '/resourcesInGame/sons/monsters/dead/ESM_Fantasy_Game_Weapon_Impact_Blood_Weapon_Medieval.wav');
    vm.load.audio('usePotion', '/resourcesInGame/sons/actions/use/potion/Alchemy-9.wav');
    vm.load.audio('openPotion', '/resourcesInGame/sons/actions/use/potion/Bottle_Open-1_1.wav');
    vm.load.audio('walk', '/resourcesInGame/sons/actions/walk/ESM_Fantasy_Game_Footstep_Stone_Light_B.wav');
    vm.load.audio('roll', '/resourcesInGame/sons/actions/roll/ESM_Fantasy_Game_Footstep_Grass_Heavy_D.wav');
    vm.load.audio('portal', '/resourcesInGame/sons/actions/portal/ESM_Fantasy_Game_Magic_Airy_Sting_Accent_Medieval__Spell_Cast.wav');

    // Sprites
    vm.load.spritesheet('bloodHitIdle', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-idle.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitWalk', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-run.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitJump', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-jump.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitFall', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-fall.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitDead', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-dead.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitRoll', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-roll.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitRun', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-run.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitAttack1', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-attack1.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitAttack2', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-attack2.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitAttack3', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-attack3.png', { frameWidth: 130, frameHeight: 72 });
    vm.load.spritesheet('bloodHitAttack4', '/resourcesInGame/sprites/main/nfts/swordman/bloodHit/bloodHit-attack1.png', { frameWidth: 130, frameHeight: 72 });
}