// Types quests = 1- Coleta, 2- Entrega, 3- Matar monstros, 4- Matar boss ou mini boss
// Types need = 1- Item, 2- Monster, 3- Boss

export default [
    {
        id: 0,
        name: "Preciso de alguns morangos",
        description: "Pegue alguns morangos e nos entregue, estamos precisando para uma torta especial",
        lvl: {
            value: 0,
            letter: "D"
        },
        type: 1,
        need: [
            {
                name: "morango",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 150,
            exp: 50,
            // EXPGuild é a experiência ganha para subir seu level na Guild dos Aventureiros
            expGuild: 50 
        }
    },
    {
        id: 1,
        name: "Falta de poções de MP",
        description: "Precisamos urgentemente de gosmas de slimes para fazer mais poções, poderia resolver isso para nós, por favor?",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "gosma de slime",
                type: 1,
                amount: 20
            }
        ],
        loot: {
            gold: 250,
            exp: 100,
            expGuild: 100 
        }
    },
    {
        id: 2,
        name: "Canibais Malditos",
        description: "Quero o fim daqueles lagartos malditos, mate alguns para minha alegria que faço seu bolso sorrir",
        type: 3,
        lvl:  {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "lizardWarrior",
                validate: 0,
                type: 2,
                amount: 20
            }
        ],
        loot: {
            gold: 5600,
            exp: 2100,
            expGuild: 2100 
        }
    },
    {
        id: 3,
        name: "O senhor do medo",
        description: "Existe um ser que comanda a área em volta das pirâmides de Anúbis, acabe com esse ser, pois não conseguimos mais viver em paz",
        type: 4,
        lvl:  {
            value: 4,
            letter: "A"
        },
        need: [
            {
                name: "anubis",
                validate: 0,
                type: 3,
                amount: 1
            }
        ],
        loot: {
            gold: 150000,
            exp: 70000,
            expGuild: 120000 ,
            items: [
                {
                    name: "future grenade",
                    porcent: 100,
                    qtd: [5, 5]
                }
            ]
        }
    },
    {
        id: 4,
        name: "Como se faz espadas sem bronze?",
        description: "Preciso de lingotes de bronze para fazer algumas espadas para novos aventureiros como você, poderia me arrumar alguns?",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "lingote de bronze",
                type: 1,
                amount: 10
            }
        ],
        loot: {
            gold: 1700,
            exp: 350,
            expGuild: 350
        }
    },
    {
        id: 5,
        name: "O exército precisa de espadas",
        description: "Estamos nos preparando para uma grande invasão, não podemos falar muito, mas sabemos que precisamos nos armar, então se você puder trazer algumas espadas de bronze para armarmos uma vila próxima, ficariamos muito gratos",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "espada de bronze",
                type: 1,
                amount: 15
            }
        ],
        loot: {
            gold: 2500,
            exp: 1200,
            expGuild: 600
        }
    },
    {
        id: 6,
        name: "Malditos slimes",
        description: "Esses slimes desgraçados estão me atrapalhando a cortar madeira, e eu preciso disso para sobreviver. O que acha de me ajudar matando alguns desses vermes?",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "slime",
                validate: 0,
                type: 2,
                amount: 15
            }
        ],
        loot: {
            gold: 600,
            exp: 250,
            expGuild: 250
        }
    },
    {
        id: 7,
        name: "Lideres do culto canibal encontrados",
        description: "Temos informações que um grupo canibal reside nos pântanos, mas não conseguimos enviar ninguém para obter melhor essa resposta e voltar para nos contar se é real, você conseguiria trazer a cabeça de pelo menos 2 desses tais lideres do culto",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "grandLizard",
                validate: 0,
                type: 2,
                amount: 2
            }
        ],
        loot: {
            gold: 7000,
            exp: 3000,
            expGuild: 3000
        }
    },
    {
        id: 8,
        name: "Sapoterapia",
        description: "Sabia que os sapos possuem um sebo especial que ajuda muito na criação de óleos, poções e comidas especiais. Poderia conseguir um pouco para mim? Preciso para fazer alguns óleos especiais",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "sebo de sapo",
                type: 1,
                amount: 10
            }
        ],
        loot: {
            gold: 500,
            exp: 150,
            expGuild: 150
        }
    },
    {
        id: 9,
        name: "Uma praga na floresta",
        description: "Javalis não foram feitas para florestas como Holzfluss, eles devoram tudo que é plantação próxima, é terrivel conviver com esses demonios, bem que você poderia matar alguns para ver se a população deles diminui né?",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "boar",
                validate: 0,
                type: 2,
                amount: 14
            }
        ],
        loot: {
            gold: 1000,
            exp: 300,
            expGuild: 300
        }
    },
    {
        id: 10,
        name: "Os lobos mataram meu gato",
        description: "3 lobos desgraçados comeram o meu gato, e agora? O que eu faço? Não tenho mais meu gatinho comigo, preciso vinga-lo, mas como sou fraco, conto com você para essa tarefa, você consegue matar esses 3 lobos para mim?",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "wolf",
                validate: 0,
                type: 2,
                amount: 3
            }
        ],
        loot: {
            gold: 1500,
            exp: 450,
            expGuild: 450
        }
    },
    {
        id: 11,
        name: "Falta de couro em Eisendorf",
        description: "Estamos com falta de couro na vila, e Eisendorf sendo um grande polo de produção de armaduras de couro, isso pode ser um grande problema, poderia nos ajudar nessa grande caçador? Necessário classe de caçador",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "couro cru",
                type: 1,
                amount: 75
            }
        ],
        loot: {
            gold: 1200,
            exp: 500,
            expGuild: 500
        }
    },
    {
        id: 12,
        name: "Magos reinvindicam poções de mana",
        description: "Está tudo uma loucura, magos pedem por poções de mana, mas não temos pinhas do pântano suficientes para faze-las, você nobre aventureiro, poderia conseguir algumas para nós? Você as consegue matando Mandrakes que residem no pântano",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "pinha do pantano",
                type: 1,
                amount: 25
            }
        ],
        loot: {
            gold: 1800,
            exp: 1000,
            expGuild: 1000
        }
    },
    {
        id: 13,
        name: "Meu orb, minha vida",
        description: "O prefeito de Cormiles prometeu alguns orbs normais para os aventureiros de Cormiles, mas o que ele esqueceu é que orbs são extremamente dificeis de se conseguir em quantidade, mas o lado bom, é que ele paga bem quem os consegue para ele... O que acha de pegar alguns fragmentos de alma para nós?",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "fragmento de alma",
                type: 1,
                amount: 30
            }
        ],
        loot: {
            gold: 10000,
            exp: 4000,
            expGuild: 4000
        }
    },
    {
        id: 14,
        name: "O barão preguiçoso",
        description: "Lorde João VI gosta de Orbs, pois reconhece sua raridade e deseja fazer um colar muito bonito para sua esposa, por isso, ele pede para que um grande aventureiro traga 15 orbs para que seu joalheiro especial possa fazer a nobre joia",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "orb",
                type: 1,
                amount: 15
            }
        ],
        loot: {
            gold: 14000,
            exp: 5000,
            expGuild: 5000
        }
    },
    {
        id: 15,
        name: "Ritual secreto",
        description: "Um cara estranho que não passou seu nome veio aqui e pediu por alguns itens que acredito que sejam usados em algum ritual macabro, mas bem, não questionamos, afinal, dinheiro é dinheiro",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "fragmento de alma",
                type: 1,
                amount: 5
            },
            {
                name: "osso",
                type: 1,
                amount: 30
            }
        ],
        loot: {
            gold: 4000,
            exp: 1300,
            expGuild: 1300
        }
    },
    {
        id: 16,
        name: "A capa do gigante",
        description: "Um germiniano veio aqui e disse que precisa de 10 peles de urso para fazer sua capa especial de chefe tribal, não que ele seja chefe de alguma coisa, mas sua confiança me cativou tanto que dei até um desconto no cadastro dessa tarefa",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "pele de urso",
                type: 1,
                amount: 10
            }
        ],
        loot: {
            gold: 6500,
            exp: 2500,
            expGuild: 2500
        }
    },
    {
        id: 17,
        name: "Pessoas desaparecidas",
        description: "Alguns viajantes desapareceram nos caminhos que passam pelas ruinas Skull, mas nós realmente não sabemos o porque disso, o que acredito é que alguns mortos-vivos estejam lá para coletar alma fresca e não solidificada como um fragmento, ou algo mais macabro também, vai saber. Precisamos que você mate alguns desses mortos-vivos para tornar aquela área um pouco mais segura",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "skeleton",
                validate: 0,
                type: 2,
                amount: 15
            }
        ],
        loot: {
            gold: 3000,
            exp: 1000,
            expGuild: 1000
        }
    },
    {
        id: 18,
        name: "Madeira para muralhas",
        description: "Precisamos de madeira para a nova muralha de Eisendorf que estamos construindo, isso vai ajudar as nossas defesas na invasão que os oráculos avisaram que poderemos passar",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "tronco",
                type: 1,
                amount: 30
            }
        ],
        loot: {
            gold: 2500,
            exp: 1000,
            expGuild: 1000
        }
    },
    {
        id: 19,
        name: "Guilda dos arqueiros busca arco decente",
        description: "A guilda dos arqueiros fez um pedido de 10 troncos reforcados para construir arcos melhores para seus aprendizes, afinal, um arqueiro que se preze não pode usar qualquer porcaria que encontra por ai",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "tronco reforcado",
                type: 1,
                amount: 30
            }
        ],
        loot: {
            gold: 5000,
            exp: 2500,
            expGuild: 2500
        }
    },
    {
        id: 20,
        name: "Salame de javali",
        description: "Salame de javali é uma das iguarias mais apreciadas de toda Odenlor, pois pode ser servida desde a taverna da esquina com limão e cerveja, até nos pratos mais refinados da realeza, por isso a demanda por esse item é tão grande",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "carne de javali",
                type: 1,
                amount: 95
            }
        ],
        loot: {
            gold: 2000,
            exp: 1000,
            expGuild: 1000
        }
    },
    {
        id: 21,
        name: "Magias antigas",
        description: "Existe uma granada abençoada pelo deus da morte que em parte de seus ingredientes usasse 35 de pó de múmia, afinal, as múmias são guardiãs de um de seus filhos, e por esse motivo, um dos nossos maiores clientes pediu para que consiga a quatidade de 70 unidades para que ele possa fazer 2 dessas granadas, acha que consegue?",
        type: 1,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "pó de múmia",
                type: 1,
                amount: 70
            }
        ],
        loot: {
            gold: 35000,
            exp: 20000,
            expGuild: 16000
        }
    },
    {
        id: 22,
        name: "Estudos arqueológicos",
        description: "Existe uma magia poderosa nos guardiões do filho da morte, mas um deles fascina bastante, que é os antigos sacerdotes que ainda protegem aquele sarcófago. Gostariamos de alguns especimes para estudar melhor o quão poderosa é sua magia, poderia trazer alguns para estudarmos?",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "priestMummy",
                validate: 0,
                type: 2,
                amount: 10
            }
        ],
        loot: {
            gold: 19000,
            exp: 10000,
            expGuild: 10000
        }
    },
    {
        id: 23,
        name: "Convocando mineradores",
        description: "A vila de Eisendorf está tendo problemas em adquirir cobre bruto por causa da aranhas que tem nas minas, você poderia conseguir um pouco pra gente?",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "cobre bruto",
                type: 1,
                amount: 20
            }
        ],
        loot: {
            gold: 1200,
            exp: 200,
            expGuild: 200
        }
    },
    {
        id: 24,
        name: "Goblins assaltando viajantes",
        description: "Um grupo de goblins safados estão saqueando caravanas que viajam próximas a Cormiles, precisamos que você se livre de alguns deles, ou nossa guilda pode perder os contratos com a Guilda dos Comerciantes",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "goblin",
                validate: 0,
                type: 2,
                amount: 20
            }
        ],
        loot: {
            gold: 4000,
            exp: 1500,
            expGuild: 1500
        }
    },
    {
        id: 25,
        name: "Descobrimos Trolls em Odenlor",
        description: "Descobrimos que um grupo de trolls se uniram aos goblins e formaram os Greenskins Camp... Brincadeira, isso é meio maldoso até... Mas sim, eles se uniram, e precisamos que algum aventureiro(a) habilidoso possa dar cabo disso",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "troll",
                validate: 0,
                type: 2,
                amount: 3
            }
        ],
        loot: {
            gold: 7000,
            exp: 3500,
            expGuild: 3500
        }
    },
    {
        id: 26,
        name: "Sarah pede por blueberries",
        description: "Sarah, a alquimista de Mjolnir, pede por algumas blueberries para cumprir a demanda por poções que os novos recem-chegados andam pedindo",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "Blueberrie",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 100,
            exp: 50,
            expGuild: 50
        }
    },
    {
        id: 27,
        name: "Ferro bruto cada vez mais escasso",
        description: "Como ainda estamos nos desenvolvendo como reino, alguns recursos se mantêm muito escassos, um deles é o ferro, que precisa primeiramente passar por uma cacetada de aranhas e até ursos para ser coletado, não poderia trazer um pouco para nós? Pagamos bem",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "ferro bruto",
                type: 1,
                amount: 35
            }
        ],
        loot: {
            gold: 3500,
            exp: 1500,
            expGuild: 1500
        }
    },
    {
        id: 28,
        name: "Mineradores desaparecidos",
        description: "Alguns mineradores andam sumindo nas minas Luthios e não sabemos mais o que fazer, tudo o que sabemos é que rugidos foram ouvidos de dentro das cavernas. Poderia ver o que está acontecendo com os mineradores?",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "bear",
                validate: 0,
                type: 2,
                amount: 3
            }
        ],
        loot: {
            gold: 2500,
            exp: 1500,
            expGuild: 1500
        }
    },
    {
        id: 29,
        name: "Fanáticos roubando viajantes",
        description: "Os fanáticos de Moonluck andam pertubando viajantes que viajam ao norte em busca de novas terras, isso atrapalha a colonização do norte e impede novas áreas de seres criadas, poderia eliminar alguns fanaticos para que possamos viver novamente o sonho do gelo?",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "fanatic",
                validate: 0,
                type: 2,
                amount: 20
            }
        ],
        loot: {
            gold: 15000,
            exp: 7500,
            expGuild: 6000
        }
    },
    {
        id: 30,
        name: "Seguidores de Moonlucky causando problemas",
        description: "Os fanáticos de Moonluck andam pertubando viajantes que viajam ao norte em busca de novas terras, isso atrapalha a colonização do norte e impede novas áreas de seres criadas, poderia eliminar alguns fanaticos para que possamos viver novamente o sonho do gelo?",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "moonluckFollower",
                validate: 0,
                type: 2,
                amount: 10
            }
        ],
        loot: {
            gold: 16000,
            exp: 8000,
            expGuild: 6500
        }
    },
    {
        id: 31,
        name: "O Bersecker",
        description: "Os seguidores de Moonlucky possuem uma arma muito poderosa, seus berseckers que possuem força sobrenatural usada para defender o acampamento de atacantes como nós, causando grandes problemas e matando bons aventureiros. Acha que consegue eliminar alguns deles?",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "berserkerMoonlucky",
                validate: 0,
                type: 2,
                amount: 2
            }
        ],
        loot: {
            gold: 32000,
            exp: 17000,
            expGuild: 16000
        }
    },
    {
        id: 32,
        name: "Trolls possuidos pelo demônio",
        description: "Uma aventureira chegou aqui recentemente relatando que seu grupo foi destruido por uma besta fera gigantesca, que reside próxima ao acampamento do goblins, mas o estranho é que ela não se parecia com um troll, mas algo mais demoniaco, como se estivesse possuido pelos espiritos de Mávia. Poderia conferir se essa informação é real?",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "demonTroll",
                validate: 0,
                type: 2,
                amount: 2
            }
        ],
        loot: {
            gold: 18000,
            exp: 10000,
            expGuild: 10000
        }
    },
    {
        id: 33,
        name: "Piscina de slime",
        description: "Um cara estranho com cabelo colorido chegou aqui falando que precisava de gosma de slimes para fazer uma piscina de slimes para um tal de 'vídeo'... Não sei que tipo de ritual é esse, ou que deus 'Video' é esse, mas parece ser muito importante, pois ele paga muito bem pelas gosmas",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "gosma de slime",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 800,
            exp: 500,
            expGuild: 550 
        }
    },
    {
        id: 34,
        name: "Se não tem picanha, então vamos de osso",
        description: "Parece que algumas pessoas estão fazendo sopas de ossos, pois dizem que os mais velhos ficam fortalecidos por causa do cálcio, mas ainda sim é meio estranho, afinal, esses ossos vem de seres sobrenaturais. Bom, deve dar um gosto tipo exótico, sei lá, gente estranha",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "osso",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 5000,
            exp: 4000,
            expGuild: 4500
        }
    },
    {
        id: 35,
        name: "Pomada que vende na rua",
        description: "Um tal de Mr Endingo chegou aqui e disse que precisa de sebo de sapo para fazer seus óleos especial, ele faz para vender nas praças de Cormiles ou nas florestas de Holzfluss para os viajantes, não paga muito, mas como as pessoas gostam dele, isso faz você ganhar uma boa reputação com a Guilda",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "sebo de sapo",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 500,
            exp: 200,
            expGuild: 900
        }
    },
    {
        id: 36,
        name: "A grande pinha",
        description: "Um grupo de fanáticos colecionadores de pinas descobriu que é possivel você recriar a Grande Pinha fazendo um ritual com 99 pinhas do pântano... Se isso funciona eu não sei, só sei que estão pagando por isso",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "pinha do pantano",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 5500,
            exp: 3000,
            expGuild: 2000
        }
    },
    {
        id: 37,
        name: "Um pouco de pó para meu cajado",
        description: "Um dos nossos clientes gostaria de um pouco de pó de mumia para seu cajado, ele paga bem pelo item",
        type: 1,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "pó de múmia",
                type: 1,
                amount: 5
            }
        ],
        loot: {
            gold: 4000,
            exp: 3000,
            expGuild: 3000
        }
    },
    {
        id: 38,
        name: "Levando ferro",
        description: "A guilda dos ferreiros precisa de alguns lingotes de ferro para um pedido rápido, mas como a demanda vem aumentando, um de seus ferreiros acabou ficando sem, e precisa de apenas 9 lingotes para produzir 3 espadas de ferro simples, poderia forjar esses lingotes e nos entregar?",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "lingote de ferro",
                type: 1,
                amount: 9
            }
        ],
        loot: {
            gold: 10000,
            exp: 4000,
            expGuild: 4500
        }
    },
    {
        id: 39,
        name: "Por que tanto ferro?",
        description: "O recem instituido reino de Odenlor fez um demanda gigantesca por lingotes de ferro que atualmente não possuimos, mas o bom disso é que eles pagam muito bem pelo serviço... O que acha de conseguir esses lingotes para concluirmos esse pedido? Falta tão pouco",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "lingote de ferro",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 130000,
            exp: 80000,
            expGuild: 100000
        }
    },
    {
        id: 40,
        name: "Será que teremos uma invasão?",
        description: "Mais um pedido de Odenlor que estamos com dificuldades de entregar, não sabemos o porquê eles querem tantas armas, mas ainda sim, além de pagarem bem, ainda passam muito respeito na Guilda, então realmente vale a pena fazer essa entrega",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "espada de ferro (primária)",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 400000,
            exp: 250000,
            expGuild: 350000
        }
    },
    {
        id: 41,
        name: "Muralhas reforçadas",
        description: "Alguns bandidos quebraram parte da nossa muralha para invadirem a vila de noite e nos roubarem, mas felizmente os guardas conseguiram pega-los antes da hora, mesmo assim, queremos reforçar nossas muralhas para que isso não aconteça mais. Consegue trazer alguns troncos para que possamos melhorar nossas muralhas?",
        type: 1,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "tronco",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 3500,
            exp: 2500,
            expGuild: 3500
        }
    },
    {
        id: 42,
        name: "Algo errado, no lugar errado",
        description: "Alguns aventureiros não voltaram após mandarmos para uma exploração nas ruinas Skull. Mandamos um grupo para descobrir o que era aquele força pesada, um nível de magia diferente ao que já haviamos descoberto naquele lugar, mas ninguém voltou",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "goldSkeleton",
                validate: 0,
                type: 2,
                amount: 4
            }
        ],
        loot: {
            gold: 15000,
            exp: 7400,
            expGuild: 7400
        }
    },
    {
        id: 43,
        name: "Exterminador de slimes",
        description: "Você consegue exterminar mais de 100 slimes? Se conseguir, terá nosso respeito, afinal, essas coisas nos pertubam faz tempo",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "slime",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 3500,
            exp: 3000,
            expGuild: 3000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [1, 1]
                }
            ]
        }
    },
    {
        id: 44,
        name: "Sapos para aulas de biologia",
        description: "A escola de Cormiles pediu alguns sapos para as aulas de biologia, você poderia arrumar alguns para que possamos enviar para eles e formam novos biólogos veganos? Afinal, acho que cortar um sapo tira todo o barato de comer carne",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "toad",
                validate: 0,
                type: 2,
                amount: 15
            }
        ],
        loot: {
            gold: 450,
            exp: 200,
            expGuild: 200
        }
    },
    {
        id: 45,
        name: "Exterminador de sapos",
        description: "Não é só uma praga no Egito, é uma pragas nas florestas de Holzfluss também, os sapos daqui são grandes ao ponto de comer gatos e cachorros dos aldeões e p$%&@, um deles comeu meu cachorro, então é o minimo que possa fazer para me vingar, vá lá e mate esses filhos da p$%@",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "toad",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 4500,
            exp: 2500,
            expGuild: 2500,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [3, 3]
                }
            ]
        }
    },
    {
        id: 46,
        name: "Exterminador de javalis",
        description: "Eu gosto de javalis, gosto da carne, do couro, e porque eles nos dão muito dinheiro, pois os fazendeiros sempre vem nos pedir para caça-los, mas dessa vez, parece que esse fazendeiro realmente os odeia, afinal, está pagando uma fortuna pela morte deles",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "boar",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 10000,
            exp: 3500,
            expGuild: 5000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [5, 5]
                }
            ]
        }
    },
    {
        id: 47,
        name: "Exterminador de lobos",
        description: "Parece que um pessoal está querendo o exterminio total dos lobos, não sei qual a vantagem disso, mas pelo que parece, alguns aldeões estão sendo feridos gravemente por esses lobos da floresta, o que está gerando um caos enorme para o reino, e caos enorme, é dinheiro no nosso bolso",
        type: 3,
        lvl: {
            value: 0,
            letter: "D"
        },
        need: [
            {
                name: "wolf",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 25000,
            exp: 20000,
            expGuild: 20000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [10, 10]
                }
            ]
        }
    },
    {
        id: 48,
        name: "Exterminador de Lizards",
        description: "Os lizards da tribo canibal estão enchendo o saco de todos que passam pelos pântanos, é praticamente um inferno com eles lá, bem que um(a) aventureiro(a) bonzinho poderia destruir todos eles e salvar os viajantes que precisam passar por aquelas terras, não acha?",
        type: 3,
        lvl:  {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "lizardWarrior",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 35000,
            exp: 10000,
            expGuild: 12000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [15, 15]
                }
            ]
        }
    },
    {
        id: 49,
        name: "Exterminador de esqueletos",
        description: "Ainda não compreendemos exatamente o que está acontecendo nas ruinas Skull que faz com que os mortos possam renascer, mas o que sabemos é que eles andam atrapalhando os negócios da Guilda dos Comerciantes que precisam passar por lá para manter seus negócios. Precisamos daqueles esqueletos exterminados",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "skeleton",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 25000,
            exp: 12000,
            expGuild: 15000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [10, 10]
                }
            ]
        }
    },
    {
        id: 50,
        name: "Sim, um esqueleto de escudo",
        description: "Sim, nós encontramos um esqueleto de escudo, porque um esqueleto de espada não era o bastante para dificultar a vida, mas fazer o que, as vezes parece que o criador desse mundo não tem criatividade",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "skeletonShield",
                validate: 0,
                type: 2,
                amount: 10
            }
        ],
        loot: {
            gold: 3500,
            exp: 2000,
            expGuild: 2000
        }
    },
    {
        id: 51,
        name: "Exterminador de esqueletos de escudo",
        description: "Por que um esqueleto de escudo é diferente de um esqueleto sem escudo? Não sei, mas nos passaram essa missão e como sempre precisamos de coisas novas para fazer, não vamos recusar algo do tipo, não é verdade?",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "skeletonShield",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 30000,
            exp: 14000,
            expGuild: 17000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [18, 18]
                }
            ]
        }
    },
    {
        id: 52,
        name: "Exterminador de Grand Lizards",
        description: "Fizemos uma parceria com a marca de roupas Laboshe para mandar alguns corpos de Grand Lizards para eles, afinal, capitalismo é capitalismo, e nós gostamos de dinheiro",
        type: 3,
        lvl: {
            value: 3,
            letter: "A"
        },
        need: [
            {
                name: "grandLizard",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 130000,
            exp: 70000,
            expGuild: 100000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [30, 30]
                }
            ]
        }
    },
    {
        id: 53,
        name: "Arqueiros reais",
        description: "Recentemente a guarda das vilas tiveram um problema e muitos dos seus arcos quebraram, mas ainda sim, precisam de uma grande quantidade de troncos reforçados para que possam fazer arcos melhorados e defender a população do reino",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "tronco reforcado",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 15000,
            exp: 7500,
            expGuild: 8000
        }
    },
    {
        id: 54,
        name: "Exterminador de invasores esqueletos",
        description: "Cansamos desses invasores vindos de um mundo estranho que ainda não conseguimos acessar, mas o reino nos passou que um de seus cientistas descobriu uma forma de acessar o mundo desses seres e estancar essa invasão de uma vez por todas, mas para isso, precisamos sacrificar uma centena de invasores, será que conseguiremos tal façanha?",
        type: 3,
        lvl: {
            value: 3,
            letter: "A"
        },
        need: [
            {
                name: "goldSkeleton",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 100000,
            exp: 74000,
            expGuild: 74000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [30, 30]
                }
            ]
        }
    },
    {
        id: 55,
        name: "Exterminador de goblins",
        description: "Aquele acampamento goblin está atrapalhando todos os planos de expansão do reino, atacando soldados, caravanas e viajantes, fica muito dificil expandir para o deserto de Gaya, e por esse motivo, pediram para a gente dar cabo nessa palhaçada",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "goblin",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 20000,
            exp: 7500,
            expGuild: 10000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [10, 10]
                }
            ]
        }
    },
    {
        id: 56,
        name: "Exterminador de Trolls",
        description: "Os trolls ficaram malucos, destroem casas, fazendas, minas e não deixam o reino se expandir, a situação piorou muito após se unirem aos goblins, mas podemos dete-los, afinal, se acabarmos com a máquina de guerra dos goblins, destruir seu acampamento pode começar a tornar as coisas fáceis",
        type: 3,
        lvl: {
            value: 2,
            letter: "B"
        },
        need: [
            {
                name: "troll",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 70000,
            exp: 35000,
            expGuild: 35000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [20, 20]
                }
            ]
        }
    },
    {
        id: 57,
        name: "Preciso de orbs para upar",
        description: "Um novato veio até nós pedir para que alguém consiga alguns fragmentos de alma para ele, pois sem isso, ele nunca conseguirá oferecer um sacrificio de 3 orbs as entidades protetoras e receber sua benção, o que fez ele dar todo seu dinheiro para isso. O que acha de fazer essa caridade?",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "fragmento de alma",
                type: 1,
                amount: 9
            }
        ],
        loot: {
            gold: 2000,
            exp: 2000,
            expGuild: 4000
        }
    },
    {
        id: 58,
        name: "Estanho para bronze",
        description: "Estamos com falta de estanho na vila, sem ele não vamos conseguir fazer bronze para uma estátua que estamos construindo para o reino, poderia trazer um pouco para nós?",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "estanho bruto",
                type: 1,
                amount: 20
            }
        ],
        loot: {
            gold: 1500,
            exp: 550,
            expGuild: 550
        }
    },
    {
        id: 59,
        name: "Papel, pedra, tesoura",
        description: "Precisamos de pedras para a fundação das muralhas ficarem mais resistentes e termos menos escavadores tentando ir por baixo das muralhas",
        type: 1,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "pedra bruta",
                type: 1,
                amount: 99
            }
        ],
        loot: {
            gold: 1500,
            exp: 500,
            expGuild: 500
        }
    },
    {
        id: 60,
        name: "Exterminador de aranhas",
        description: "As aranhas são uma das maiores pragas das minas Luthios, extermina-las traia grande paz para aquele lugar e ajudaria o reino a possuir mais minérios e assim, itens mais baratos a venda",
        type: 3,
        lvl: {
            value: 1,
            letter: "C"
        },
        need: [
            {
                name: "spider",
                validate: 0,
                type: 2,
                amount: 100
            }
        ],
        loot: {
            gold: 20000,
            exp: 12000,
            expGuild: 15000,
            items: [
                {
                    name: "bronze grenade",
                    porcent: 100,
                    qtd: [10, 10]
                }
            ]
        }
    },
]