export default {
    setScale: {
        x: 2,
        y: 2.5
    },
    setSize: {
        x: 26,
        y: 42
    },
    offset: {
        y: 20
    }
}