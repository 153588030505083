import axios from "axios";

// Audios
import skillSound from '@/assets/music/UI/skill/ESM_Fantasy_Game_UI_Arcane_Confirm_Spell_Cast_Click_Switch_Lever_Latch.ogg';

const actions = {
    classEquipWeapon({state}, item) {
        var path = state.mainPerson.personPath.replace("Path of ", "").toLowerCase();
        var array, isEquip;
        const boolean = (array) => {
            if(item.class) {
                var include = array.includes(item.class);
                return include;
            }
            else return true;
        };
        switch(path) {
            case "archer":
                array = ["bow", "arrow", "dagger", "medium armor", "light armor", "heavy armor"];
                isEquip = boolean(array);
                return isEquip;
            case "mage":
                array = ["staff", "magic item", "magic book", "energy staff", "fire staff", "ice staff", "poison staff", "light armor", "heavy armor"];
                isEquip = boolean(array);
                return isEquip;
            case "spearman":
                array = ["lance", "halberd", "shield", "medium armor", "heavy armor"];
                isEquip = boolean(array);
                return isEquip;
            case "swordman":
                array = ["one-handed sword", "two-handed sword", "one-handed axe", "two-handed axe", "shield", "medium armor", "heavy armor"];
                isEquip = boolean(array);
                return isEquip;
        }
    },
    async changeEquipItem({state, commit, dispatch}, item) {
        var currentItem = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
        var classItem = await dispatch("classEquipWeapon", item);
        if(state.mainPerson.weight.value + item.weight > state.mainPerson.weight.maxValue) {
            alert(`Você não consegue equipar ${item.name}, pois ele é muito pesado`);
            return;
        }
        if(item.level > state.mainPerson.lvl) {
            alert(`Level ${item.level} minimo necessário`);
            return;
        }
        if(!classItem) {
            alert(`Sua classe não pode equipar esse item`);
            return;
        }
        if(currentItem) {
            const listAttr = [
                currentItem.status.maxHp ? parseFloat(currentItem.status.maxHp) * -1 : 0,
                currentItem.status.maxMp ? parseFloat(currentItem.status.maxMp) * -1 : 0,
                currentItem.status.maxEnergy ? parseFloat(currentItem.status.maxEnergy) * -1 : 0,
                currentItem.status.Ataque ? parseFloat(currentItem.status.Ataque) * -1 : 0,
                currentItem.status.Forca ? parseFloat(currentItem.status.Forca) * -1 : 0,
                currentItem.status.Defesa ? parseFloat(currentItem.status.Defesa) * -1 : 0,
                currentItem.status.Critico ? parseFloat(currentItem.status.Critico) * -1 : 0,
                currentItem.status.Agility ? parseFloat(currentItem.status.Agility) * -1 : 0,
                currentItem.status.PoderMagico ? parseFloat(currentItem.status.PoderMagico) * -1 : 0,
                currentItem.status.Carisma ? parseFloat(currentItem.status.Carisma) * -1 : 0,
                currentItem.status.Negociacao ? parseFloat(currentItem.status.Negociacao) * -1 : 0,
                currentItem.status.Nobreza ? parseFloat(currentItem.status.Nobreza) * -1 : 0,
                currentItem.status.travelTime ? parseFloat(currentItem.status.travelTime) * -1 : 0
            ]
            if(currentItem.weight) commit("changeWeightValue", currentItem.weight * -1);
            commit("changeAttr", listAttr);
        }
        const listAttr2 = [
            item.status.maxHp ? item.status.maxHp : 0,
            item.status.maxMp ? item.status.maxMp : 0,
            item.status.maxEnergy ? item.status.maxEnergy : 0,
            item.status.Ataque ? item.status.Ataque : 0,
            item.status.Forca ? item.status.Forca : 0,
            item.status.Defesa ? item.status.Defesa : 0,
            item.status.Critico ? item.status.Critico : 0,
            item.status.Agility ? item.status.Agility : 0,
            item.status.PoderMagico ? item.status.PoderMagico : 0,
            item.status.Carisma ? item.status.Carisma : 0,
            item.status.Negociacao ? item.status.Negociacao : 0,
            item.status.Nobreza ? item.status.Nobreza : 0,
            item.status.travelTime ? item.status.travelTime : 0
        ]
        if(item.weight) commit("changeWeightValue", item.weight);
        commit("changeAttr", listAttr2);
        commit("equipItem", item);
    },
    changeCreatePerson({commit, rootState, state}, choice) {
        let previousChoice = rootState.generalModule.createPersonInfos.find(i => i.id == choice.id);
        if(previousChoice) {
            const listAttr2 = [
                previousChoice.status.maxHp ? parseFloat(previousChoice.status.maxHp) * -1 : 0,
                previousChoice.status.maxMp ? parseFloat(previousChoice.status.maxMp) * -1 : 0,
                previousChoice.status.maxEnergy ? parseFloat(previousChoice.status.maxEnergy) * -1 : 0,
                previousChoice.status.Ataque ? parseFloat(previousChoice.status.Ataque) * -1 : 0,
                previousChoice.status.Forca ? parseFloat(previousChoice.status.Forca) * -1 : 0,
                previousChoice.status.Defesa ? parseFloat(previousChoice.status.Defesa) * -1 : 0,
                previousChoice.status.Critico ? parseFloat(previousChoice.status.Critico) * -1 : 0,
                previousChoice.status.Agility ? parseFloat(previousChoice.status.Agility) * -1 : 0,
                previousChoice.status.PoderMagico ? parseFloat(previousChoice.status.PoderMagico) * -1 : 0,
                previousChoice.status.Carisma ? parseFloat(previousChoice.status.Carisma) * -1 : 0,
                previousChoice.status.Negociacao ? parseFloat(previousChoice.status.Negociacao) * -1 : 0,
                previousChoice.status.Nobreza ? parseFloat(previousChoice.status.Nobreza) * -1 : 0,
                previousChoice.status.travelTime ? parseFloat(previousChoice.status.travelTime) * -1 : 0
            ]
            commit("changeAttr", listAttr2);
        }
        const listAttr = [
            choice.status.maxHp ? choice.status.maxHp : 0,
            choice.status.maxMp ? choice.status.maxMp : 0,
            choice.status.maxEnergy ? choice.status.maxEnergy : 0,
            choice.status.Ataque ? choice.status.Ataque : 0,
            choice.status.Forca ? choice.status.Forca : 0,
            choice.status.Defesa ? choice.status.Defesa : 0,
            choice.status.Critico ? choice.status.Critico : 0,
            choice.status.Agility ? choice.status.Agility : 0,
            choice.status.PoderMagico ? choice.status.PoderMagico : 0,
            choice.status.Carisma ? choice.status.Carisma : 0,
            choice.status.Negociacao ? choice.status.Negociacao : 0,
            choice.status.Nobreza ? choice.status.Nobreza : 0,
            choice.status.travelTime ? choice.status.travelTime : 0
        ];
        commit("changeAttr", listAttr);
        if(choice.id === 15) {
            state.mainPerson.personPath = choice.choice;
            switch(choice.choice) {
                case "Path of Swordman":
                    state.mainPerson.sprite = "swordman";
                    break;                
                case "Path of Mage":
                    state.mainPerson.sprite = "mage";
                    break;
                case "Path of Spearman":
                    state.mainPerson.sprite = "spearman";
                    break;
                case "Path of Archer":
                    state.mainPerson.sprite = "archer";
                    break;
            }
        }
    },
    levelHappiness({state, commit}, value) {
        if(state.personModule) state = state.personModule;
        if(value < 0) {
            var diferenca = state.mainPerson.happiness.maxValue * 2 * -1;
            if(state.mainPerson.happiness.value >= (diferenca)) {
                commit("changeValueHappiness", value);
            }
        }
        else if(value > 0) {
            if(state.mainPerson.happiness.value <= (state.mainPerson.happiness.maxValue * 2)) {
                commit("changeValueHappiness", value);
            }
        }
        if(state.mainPerson.happiness.value >= state.mainPerson.happiness.maxValue) {
            if(state.mainPerson.happiness.level != "euforico"){
                const boosts1 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: 0.05
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: 0.05
                    }
                ]
                commit("changeLevelHappiness", "euforico");
                for(let z = 0; z < boosts1.length; z++) {
                    commit("changeBoost", boosts1[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value >= (state.mainPerson.happiness.maxValue / 100 * 85)) {
            if(state.mainPerson.happiness.level != "contente"){
                const boosts2 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: 0.025
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: 0.025
                    }
                ]
                commit("changeLevelHappiness", "contente");
                for(let z = 0; z < boosts2.length; z++) {
                    commit("changeBoost", boosts2[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value >= (state.mainPerson.happiness.maxValue / 100 * 65)) {
            if(state.mainPerson.happiness.level != "aceitavel") {
                const boosts3 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: 0.01
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: 0.01
                    }
                ]
                commit("changeLevelHappiness", "aceitavel");
                for(let z = 0; z < boosts3.length; z++) {
                    commit("changeBoost", boosts3[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value >= (state.mainPerson.happiness.maxValue / 100 * 50)) {
            if(state.mainPerson.happiness.level != "cansado") {
                const boosts4 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: 0
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: 0
                    }
                ]
                commit("changeLevelHappiness", "cansado");
                for(let z = 0; z < boosts4.length; z++) {
                    commit("changeBoost", boosts4[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value >= (state.mainPerson.happiness.maxValue / 100 * 30)) {
            if(state.mainPerson.happiness.level != "bravo") {
                const boosts5 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: -0.025
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: -0.025
                    }
                ]
                commit("changeLevelHappiness", "bravo");
                for(let z = 0; z < boosts5.length; z++) {
                    commit("changeBoost", boosts5[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value >= (state.mainPerson.happiness.maxValue / 100 * 10)) {
            if(state.mainPerson.happiness.level != "raivoso") {
                const boosts6 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: -0.05
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: -0.05
                    }
                ]
                commit("changeLevelHappiness", "raivoso");
                for(let z = 0; z < boosts6.length; z++) {
                    commit("changeBoost", boosts6[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value >= (state.mainPerson.happiness.maxValue / 100 * 0)) {
            if(state.mainPerson.happiness.level != "insano") {
                const boosts7 = [
                    {
                        group: "happiness",
                        title: "Energia",
                        value: -0.075
                    },
                    {
                        group: "happiness",
                        title: "Forca",
                        value: -0.075
                    }
                ]
                commit("changeLevelHappiness", "insano");
                for(let z = 0; z < boosts7.length; z++) {
                    commit("changeBoost", boosts7[z]);
                }
            }
        }
        else if(state.mainPerson.happiness.value < 0){
            const boosts8 = [
                {
                    group: "happiness",
                    title: "Energia",
                    value: -0.1
                },
                {
                    group: "happiness",
                    title: "Forca",
                    value: -0.1
                }
            ]
            commit("changeLevelHappiness", "depressivo");
            for(let z = 0; z < boosts8.length; z++) {
                commit("changeBoost", boosts8[z]);
            }
        
        }
    },
    changeTool({state, commit}, item) {
        if(state.mainPerson.class.type == item.workTool || item.name == "") {
            var currentTool = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
            if(state.mainPerson.weight.value + item.weight > state.mainPerson.weight.maxValue) {
                alert(`Você não consegue equipar ${item.name}, pois ele é muito pesado`);
                return;
            }
            if(currentTool) {
                if(currentTool.status) {
                    const listAttr = [
                        currentTool.status.maxHp ? parseFloat(currentTool.status.maxHp) * -1 : 0,
                        currentTool.status.maxMp ? parseFloat(currentTool.status.maxMp) * -1 : 0,
                        currentTool.status.maxEnergy ? parseFloat(currentTool.status.maxEnergy) * -1 : 0,
                        currentTool.status.Ataque ? parseFloat(currentTool.status.Ataque) * -1 : 0,
                        currentTool.status.Forca ? parseFloat(currentTool.status.Forca) * -1 : 0,
                        currentTool.status.Defesa ? parseFloat(currentTool.status.Defesa) * -1 : 0,
                        currentTool.status.Critico ? parseFloat(currentTool.status.Critico) * -1 : 0,
                        currentTool.status.Agility ? parseFloat(currentTool.status.Agility) * -1 : 0,
                        currentTool.status.PoderMagico ? parseFloat(currentTool.status.PoderMagico) * -1 : 0,
                        currentTool.status.Carisma ? parseFloat(currentTool.status.Carisma) * -1 : 0,
                        currentTool.status.Negociacao ? parseFloat(currentTool.status.Negociacao) * -1 : 0,
                        currentTool.status.Nobreza ? parseFloat(currentTool.status.Nobreza) * -1 : 0,
                        currentTool.status.travelTime ? parseFloat(currentTool.status.travelTime) * -1 : 0
                    ];
                    if(currentTool.weight) commit("changeWeightValue", currentTool.weight * -1);
                    currentTool.boost = currentTool.boost * -1;
                    commit("changeBoostWorks", currentTool);
                    if(currentTool._id) commit("removeEquipReference", currentTool);
                    commit("changeAttr", listAttr);
                }
            }
            const listAttr2 = [
                item.status.maxHp ? item.status.maxHp : 0,
                item.status.maxMp ? item.status.maxMp : 0,
                item.status.maxEnergy ? item.status.maxEnergy : 0,
                item.status.Ataque ? item.status.Ataque : 0,
                item.status.Forca ? item.status.Forca : 0,
                item.status.Defesa ? item.status.Defesa : 0,
                item.status.Critico ? item.status.Critico : 0,
                item.status.Agility ? item.status.Agility : 0,
                item.status.PoderMagico ? item.status.PoderMagico : 0,
                item.status.Carisma ? item.status.Carisma : 0,
                item.status.Negociacao ? item.status.Negociacao : 0,
                item.status.Nobreza ? item.status.Nobreza : 0,
                item.status.travelTime ? item.status.travelTime : 0
            ]
            if(item.weight) commit("changeWeightValue", item.weight);
            commit("changeAttr", listAttr2);
            commit("changeBoostWorks", item);
            commit("equipItem", item);
        } else {
            alert("Você não pode trabalhar com isso!")
        }
    },
    changeMagicItem({state, commit}, item) {
        if(state.mainPerson.infos[1].li[4].value >= item.magicPowerNeed || !item.magicPowerNeed) {
            var currentItem = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
            if(state.mainPerson.weight.value + item.weight > state.mainPerson.weight.maxValue) {
                alert(`Você não consegue equipar ${item.name}, pois ele é muito pesado`);
                return;
            }
            if(currentItem && currentItem.name != "") {
                if(currentItem.status) {
                    const listAttr = [
                        currentItem.status.maxHp ? parseFloat(currentItem.status.maxHp) * -1 : 0,
                        currentItem.status.maxMp ? parseFloat(currentItem.status.maxMp) * -1 : 0,
                        currentItem.status.maxEnergy ? parseFloat(currentItem.status.maxEnergy) * -1 : 0,
                        currentItem.status.Ataque ? parseFloat(currentItem.status.Ataque) * -1 : 0,
                        currentItem.status.Forca ? parseFloat(currentItem.status.Forca) * -1 : 0,
                        currentItem.status.Defesa ? parseFloat(currentItem.status.Defesa) * -1 : 0,
                        currentItem.status.Critico ? parseFloat(currentItem.status.Critico) * -1 : 0,
                        currentItem.status.Agility ? parseFloat(currentItem.status.Agility) * -1 : 0,
                        currentItem.status.PoderMagico ? parseFloat(currentItem.status.PoderMagico) * -1 : 0,
                        currentItem.status.Carisma ? parseFloat(currentItem.status.Carisma) * -1 : 0,
                        currentItem.status.Negociacao ? parseFloat(currentItem.status.Negociacao) * -1 : 0,
                        currentItem.status.Nobreza ? parseFloat(currentItem.status.Nobreza) * -1 : 0,
                        currentItem.status.travelTime ? parseFloat(currentItem.status.travelTime) * -1 : 0
                    ];
                    if(currentItem.weight) commit("changeWeightValue", currentItem.weight * -1);
                    currentItem.boost = currentItem.boost * -1;
                    commit("changeBoostMagicPaths", currentItem);
                    if(currentItem._id) commit("removeEquipReference", currentItem);
                    commit("changeAttr", listAttr);
                }
            }
            const listAttr2 = [
                item.status.maxHp ? item.status.maxHp : 0,
                item.status.maxMp ? item.status.maxMp : 0,
                item.status.maxEnergy ? item.status.maxEnergy : 0,
                item.status.Ataque ? item.status.Ataque : 0,
                item.status.Forca ? item.status.Forca : 0,
                item.status.Defesa ? item.status.Defesa : 0,
                item.status.Critico ? item.status.Critico : 0,
                item.status.Agility ? item.status.Agility : 0,
                item.status.PoderMagico ? item.status.PoderMagico : 0,
                item.status.Carisma ? item.status.Carisma : 0,
                item.status.Negociacao ? item.status.Negociacao : 0,
                item.status.Nobreza ? item.status.Nobreza : 0,
                item.status.travelTime ? item.status.travelTime : 0
            ]
            if(item.weight) commit("changeWeightValue", item.weight);
            commit("changeAttr", listAttr2);
            commit("changeBoostMagicPaths", item);
            commit("equipItem", item);
        } else {
            alert("Você não tem Poder mágico suficiente para usar esse item")
        }
    },
    usePathScroll({commit, rootState}, scroll) {
        var pathScroll = rootState.spellModule.pathMagic.find(i => i.id == scroll.path);
        commit("addMagicPath", pathScroll);
        commit("useMagicPathScroll", scroll);
    },
    useMagicScroll({state, commit, rootState}, scroll) {
        var pathScroll = rootState.spellModule.pathMagic.find(i => i.id == scroll.path);
        var verifyMagicPath = state.mainPerson.magicPaths.find(i => i.id == scroll.path);
        if(verifyMagicPath) {
            var spell = rootState.spellModule.spells.find(i => i.spellName == scroll.magic);
            if(!state.mainPerson.spells.find(i => i.spellName == scroll.magic)) {
                commit("addSpell", spell);
                commit("useMagicPathScroll", scroll);
            } else {
                alert("Você já aprendeu essa magia");
            }
        } else {
            alert(`Você ainda não conhece ${pathScroll.name}`);
        }
    },
    changeReferenceEquipItem({state, commit, dispatch}, item) {
        let durability;
        var currentItem = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
        if(state.mainPerson.weight.value + item.weight > state.mainPerson.weight.maxValue) {
            alert(`Você não consegue equipar ${item.name}, pois ele é muito pesado`);
            return;
        }
        if(currentItem) {
            const listAttr = [
                currentItem.status.maxHp ? parseFloat(currentItem.status.maxHp) * -1 : 0,
                currentItem.status.maxMp ? parseFloat(currentItem.status.maxMp) * -1 : 0,
                currentItem.status.maxEnergy ? parseFloat(currentItem.status.maxEnergy) * -1 : 0,
                currentItem.status.Ataque ? parseFloat(currentItem.status.Ataque) * -1 : 0,
                currentItem.status.Forca ? parseFloat(currentItem.status.Forca) * -1 : 0,
                currentItem.status.Defesa ? parseFloat(currentItem.status.Defesa) * -1 : 0,
                currentItem.status.Critico ? parseFloat(currentItem.status.Critico) * -1 : 0,
                currentItem.status.Agility ? parseFloat(currentItem.status.Agility) * -1 : 0,
                currentItem.status.PoderMagico ? parseFloat(currentItem.status.PoderMagico) * -1 : 0,
                currentItem.status.Carisma ? parseFloat(currentItem.status.Carisma) * -1 : 0,
                currentItem.status.Negociacao ? parseFloat(currentItem.status.Negociacao) * -1 : 0,
                currentItem.status.Nobreza ? parseFloat(currentItem.status.Nobreza) * -1 : 0,
                currentItem.status.travelTime ? parseFloat(currentItem.status.travelTime) * -1 : 0
            ]
            if(currentItem.boost) {
                if(currentItem.groupEquip == "tool") {
                    currentItem.boost = currentItem.boost * -1;
                    commit("changeBoostWorks", currentItem);
                } else {
                    currentItem.boost = currentItem.boost * -1;
                    commit("changeBoostMagicPaths", currentItem);
                }
            }
            if(currentItem.durability) durability = currentItem.durability.maxValue - currentItem.durability.value;
            commit("changeAttr", listAttr);
        }
        const listAttr2 = [
            item.status.maxHp ? item.status.maxHp : 0,
            item.status.maxMp ? item.status.maxMp : 0,
            item.status.maxEnergy ? item.status.maxEnergy : 0,
            item.status.Ataque ? item.status.Ataque : 0,
            item.status.Forca ? item.status.Forca : 0,
            item.status.Defesa ? item.status.Defesa : 0,
            item.status.Critico ? item.status.Critico : 0,
            item.status.Agility ? item.status.Agility : 0,
            item.status.PoderMagico ? item.status.PoderMagico : 0,
            item.status.Carisma ? item.status.Carisma : 0,
            item.status.Negociacao ? item.status.Negociacao : 0,
            item.status.Nobreza ? item.status.Nobreza : 0,
            item.status.travelTime ? item.status.travelTime : 0
        ]
        commit("changeAttr", listAttr2);
        if(item.groupEquip == "tool") {
            commit("changeBoostWorks", item);
        } else {
            commit("changeBoostMagicPaths", item);
        }
        commit("changeBoostMagicPaths", item);
        dispatch("changeDurabilityItem", {item: item, value: durability});
    },
    learnRecipe({ state, rootState, commit }, recipeScroll) {
        let recipe = rootState.craftModule.recipes.find(i => i.name == recipeScroll.recipe);
        let ifRecipe = state.mainPerson.recipes.find(i => i.name == recipeScroll.recipe);
        if(!ifRecipe) {
            commit("addRecipe", recipe);
            commit("useMagicPathScroll", recipeScroll);
            return;
        }
        alert("Você já possui essa receita");
    },
    deleteItemEquipInventoryWorkItem({state, commit}, item) { 
        var currentTool = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
        const listAttr2 = [0,0,0,0,0,0,0,0,0,0,0,0]
        if(currentTool) {
            if(currentTool.status) {
                const listAttr = [
                    currentTool.status.maxHp ? parseFloat(currentTool.status.maxHp) * -1 : 0,
                    currentTool.status.maxMp ? parseFloat(currentTool.status.maxMp) * -1 : 0,
                    currentTool.status.maxEnergy ? parseFloat(currentTool.status.maxEnergy) * -1 : 0,
                    currentTool.status.Ataque ? parseFloat(currentTool.status.Ataque) * -1 : 0,
                    currentTool.status.Forca ? parseFloat(currentTool.status.Forca) * -1 : 0,
                    currentTool.status.Defesa ? parseFloat(currentTool.status.Defesa) * -1 : 0,
                    currentTool.status.Critico ? parseFloat(currentTool.status.Critico) * -1 : 0,
                    currentTool.status.Agility ? parseFloat(currentTool.status.Agility) * -1 : 0,
                    currentTool.status.PoderMagico ? parseFloat(currentTool.status.PoderMagico) * -1 : 0,
                    currentTool.status.Carisma ? parseFloat(currentTool.status.Carisma) * -1 : 0,
                    currentTool.status.Negociacao ? parseFloat(currentTool.status.Negociacao) * -1 : 0,
                    currentTool.status.Nobreza ? parseFloat(currentTool.status.Nobreza) * -1 : 0,
                    currentTool.status.travelTime ? parseFloat(currentTool.status.travelTime) * -1 : 0
                ];
                if(currentTool.weight) commit("changeWeightValue", currentTool.weight * -1);
                currentTool.boost = currentTool.boost * -1;
                commit("changeBoostWorks", currentTool);
                if(currentTool._id) commit("removeEquipReference", currentTool);
                commit("deleteItemEquip", currentTool);
                commit("changeAttr", listAttr);
            }
        }
        commit("changeAttr", listAttr2);
    },
    changeDurabilityItem({state, dispatch, commit}, object) {
        if(state.personModule) state = state.personModule;
        object.item.durability.value -= object.value;
        if(object.item.durability.value <= 0) {
            commit("addAlertMessage", {type: "default", imgUrl: "actions/359.png", message: "Um dos seus equipamentos quebrou"});
            let intervalCloseSaveAlert = setInterval(() => {
                commit("deleteAlert", {type: "default", imgUrl: "actions/359.png", message: "Um dos seus equipamentos quebrou"});
                clearInterval(intervalCloseSaveAlert);
            }, 5000);
            dispatch("deleteItemEquipInventoryWorkItem", object.item);
        }
    },
    putSaveChar({rootState, state}) {
        return new Promise((resolve, reject) => {
            axios.post(rootState.generalModule.apiUrl + '/saveChar', state.mainPerson)
            .then(res => {
                try {
                    resolve(res.json());
                } catch (err) {
                    reject(err);
                }
            });
        });
    },
    deadPlayer({commit, state, dispatch}) {
        commit("changeShowDeadView", true);
        if(state.personModule) state = state.personModule;
        commit("deadMain");
        dispatch("putSaveChar");
        commit("addAlertMessage", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
        commit("toogleSaveCharTime", false);
        let intervalCloseSaveAlert = setInterval(() => {
            commit("deleteAlert", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
            clearInterval(intervalCloseSaveAlert);
        }, 5000);
    },
    addPassiveSkill({commit, state, dispatch, rootState}, skill) {
        var orb = state.mainPerson.Inventory.find(i => i.name === "orb" && i.rarity === skill.orb.type), newItem, noNeed;
        if(skill.path !== state.mainPerson.personPath.replace("Path of ", "").toLowerCase()) {
            dispatch("showAlert", {type: "default", imgUrl: "actions/9.png", message: "Essa não é sua classe"});
            return;
        }
        switch(skill.orb.type) {
            case "melhorada":
                orb.rarity = "melhorada";break;
            case "perfeita":
                orb.rarity = "perfeita";break;
            case "rara":
                orb.rarity = "rara";break;
        }
        if(skill.need.length > 0) {
            skill.need.forEach(prev => {
                if(!state.mainPerson.skills.includes(prev)) {
                    dispatch("showAlert", {type: "default", imgUrl: "actions/9.png", message: `Você precisa da skill ${rootState.skillModule.skills.find(i => i.code === prev).name}`});
                    noNeed = true;
                }            
            })
        }
        if(noNeed) return;
        if(!orb) {
            dispatch("showAlert", {type: "default", imgUrl: "actions/9.png", message: "Você não possui orbs suficientes para essa skill"});
            return;
        }
        if(orb.qtd < skill.orb.amount){
            dispatch("showAlert", {type: "default", imgUrl: "actions/9.png", message: "Você não possui orbs suficientes para essa skill"});
            return;
        }
        if(state.mainPerson.pointsAttr.skillPoints < skill.cost) {
            dispatch("showAlert", {type: "default", imgUrl: "actions/9.png", message: "Você não possui pontos de skill suficientes"});
            return;
        }
        const listAttr = [
            skill.description.effect.maxHp ? skill.description.effect.maxHp : 0,
            skill.description.effect.maxMp ? skill.description.effect.maxMp : 0,
            skill.description.effect.maxEnergy ? skill.description.effect.maxEnergy : 0,
            skill.description.effect.Ataque ? skill.description.effect.Ataque : 0,
            skill.description.effect.Forca ? skill.description.effect.Forca : 0,
            skill.description.effect.Defesa ? skill.description.effect.Defesa : 0,
            skill.description.effect.Critico ? skill.description.effect.Critico : 0,
            skill.description.effect.Agility ? skill.description.effect.Agility : 0,
            skill.description.effect.PoderMagico ? skill.description.effect.PoderMagico : 0,
            skill.description.effect.Carisma ? skill.description.effect.Carisma : 0,
            skill.description.effect.Negociacao ? skill.description.effect.Negociacao : 0,
            skill.description.effect.Nobreza ? skill.description.effect.Nobreza : 0,
            skill.description.effect.travelTime ? skill.description.effect.travelTime : 0
        ];
        commit("changeAttr", listAttr);
        state.mainPerson.skills.push(skill.code);
        state.mainPerson.pointsAttr.skillPoints -= skill.cost;
        newItem = {
            item: orb,
            quantidade: skill.orb.amount
        };
        commit("deleteItemInventory", newItem);

        var audio = new Audio(skillSound);
        audio.play();

        dispatch("showAlert", {type: "default", imgUrl: "actions/9.png", message: `Você adquiriu ${skill.name}`});
    },
    useItem({state, commit}, item) {
        if(item.groupEquip == "potion") {
            if(item.status.hp !== undefined) {
                if((state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value) < item.status.hp) state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
                else state.mainPerson.infos[0].li[0].value += item.status.hp
            }
            if(item.status.mp !== undefined) {
                if((state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value) < item.status.mp) state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
                else state.mainPerson.infos[0].li[1].value += item.status.mp
            }
            if(item.status.energia !== undefined) {
                if((state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value) < item.status.energia) state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
                else state.mainPerson.infos[0].li[2].value += item.status.energia
            }
            if(item.status.exp !== undefined) {
                var amount = {
                    exp: parseInt(item.status.exp),
                    gold: 0
                }
                commit("changeXPandGold", amount);
            }
        }
        if(item.groupEquip == "food") {
            if(item.status.hp !== undefined) {
                if((state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value) < item.status.hp) state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
                else state.mainPerson.infos[0].li[0].value += item.status.hp
            }
            if(item.status.mp !== undefined) {
                if((state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value) < item.status.mp) state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
                else state.mainPerson.infos[0].li[1].value += item.status.mp
            }
            if(item.status.energia !== undefined) {
                if((state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value) < item.status.energia) state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
                else state.mainPerson.infos[0].li[2].value += item.status.energia
            }
        }
        let index = state.mainPerson.Inventory.indexOf(item);
        if(state.mainPerson.Inventory[index].qtd > 1) {
            state.mainPerson.Inventory[index].qtd -= 1;
        } else {
            state.mainPerson.Inventory.splice(index, 1);
        }
    },
    importPack({commit, rootState}, pack) {
        pack.item.items.map((item) => {
            var newItem = {};
            newItem.item = rootState.itemsModule.items.find(i => i.name === item.name);
            newItem.quantidade = item.amount;
            commit("addItemInventory", newItem);
        })
        commit("addNFTArray", pack.URIToken);
    },
    successfullQuest({state, commit, dispatch}, quest) {
        var amount = {exp: parseInt(quest.loot.exp), gold: 0}
        commit("removeQuest", quest);
        commit("changeMoney", quest.loot.gold);
        commit("changeXPandGold", amount);
        dispatch("moreReputationAdventureGuild", quest);
        quest.need.map((item) => {
            if(item.type === 1) {
                var itemInventory = state.mainPerson.Inventory.find(i => i.name === item.name);
                var newItem = {item: itemInventory,quantidade: item.amount};
                commit("deleteItemInventory", newItem);
            }
        });
        dispatch("showAlert", {type: "default", imgUrl: "actions/15.png", message: `Você concluiu a quest ${quest.name}`});
    },
    moreReputationAdventureGuild({commit, dispatch}, quest) {
        commit("changeReputationAdventureGuild", quest.loot.expGuild);
        dispatch("showAlert", {type: "default", imgUrl: "actions/star.png", message: `Você aumentou sua reputação na guilda`});
    }
}

export default actions;