import sha256 from 'crypto-js/sha256';
import axios from "axios";

import languages from '@/shared/languages/languages';
import translate from '@/shared/languages/translate';

export default {
    state: {
        urlCurrent: window.location.href,
        apiUrlDev: 'http://localhost:3000',
        apiUrlProd: 'https://parallelium-backend-1bd1d263e7cc.herokuapp.com',
        // apiUrl: window.location.href.indexOf("paralelium.com.br") > -1 ? 'https://paralelium.com.br' : 'https://parallelium-backend-1bd1d263e7cc.herokuapp.com',
        apiUrl: 'https://parallelium-backend-1bd1d263e7cc.herokuapp.com',
        errorForm: 0,
        createPerson: true,
        createPersonInfos: [],
        logged: false,
        identifier: '',
        logarComponent: 0,
        userData: {},
        sidebarMobile: '',
        saveCharTime: false,
        alertsMessages: [],
        occupied: false,
        deadView: false,
        loading: false,
        currentLanguage: "ptBR",
        language: languages,
        translate: translate,
        questPanel: false,
        timeNow: {
            day: 0,
            month: 0,
            year: 0,
            hour: 0,
            minute: 0,
            second: 0
        },
        dialog: {
            active: false,
            name: "",
            text: []
        }
    },
    getters: {
        translateWords: (state) => (word) => {
            var object = state.translate.find(i => i.name.toLowerCase() == word.toLowerCase());
            if(!object) object = state.translate.find(i => i.enUS.toLowerCase() == word.toLowerCase());
            if(object) return object[state.currentLanguage]
            else return word;
        }
    },
    mutations: {
        changeQuestPanel(state, boolean) {
            state.questPanel = boolean;
        },
        changeIsEnabledLoading(state, boolean) {
            state.loading = boolean;
        },
        changeShowDeadView(state, boolean) {
            state.deadView = boolean;
        },
        addDialog(state, object) {
            state.dialog.text = object;
        },
        changeNameDialog(state, name) {
            state.dialog.name = name;
        },
        changeDialogBox(state, boolean){
            state.dialog.active = boolean;
        },
        addInfos(state, value) {
            const index = state.createPersonInfos.find(i => i.id == value.id);
            if(index) state.createPersonInfos.splice(state.createPersonInfos.indexOf(index), 1);
            state.createPersonInfos.push(value);
        },
        changeCreatePerson(state, boolean) {
            state.createPerson = boolean;
        },
        changeLogarComponent(state, value) {
            state.logarComponent = value;
        },
        createIdentifier(state, dataUser) {
            var userPass = dataUser.username + dataUser.password;
            var sha256Hash = sha256(userPass);
            var sha256NewHash = () => {
                var hash = (sha256Hash.words[0] + sha256Hash.words[2]).toString() + (sha256Hash.words[3] + sha256Hash.words[7] + sha256Hash.words[5]).toString();
                hash = hash.replace("-","").replace("-","");
                return hash;
            }
            state.identifier = sha256NewHash();
        },
        addUserData(state, obj){
            state.userData = obj;
        },
        changeErrorForm(state, value) {
            state.errorForm = value;
        },
        toogleSidebar(state, text) {
            state.sidebarMobile = text;
        },
        toogleSaveCharTime(state, boolean) {
            state.saveCharTime = boolean;
        },
        addAlertMessage(state, alert) {
            state.alertsMessages.push(alert);
        },
        deleteAlert(state, alert) {
            var index = state.alertsMessages.indexOf(alert);
            state.alertsMessages.splice(index, 1);
        },
        refreshTime(state) {
            // "https://www.timeapi.io/api/Time/current/zone?timeZone=America/Sao_Paulo", {
            //     headers: {
            //       Accept: "application/json"
            //     }}
            axios.get(
                "http://worldtimeapi.org/api/timezone/America/Sao_Paulo"
            ).then((res) => {
                var APIData = res.data.utc_datetime;
                state.timeNow.day = parseInt(APIData.substr(8,2));
                state.timeNow.month = parseInt(APIData.substr(5,2));
                state.timeNow.year = parseInt(APIData.substr(0,4));
                state.timeNow.hour = parseInt(APIData.substr(11,2));
                state.timeNow.minute = parseInt(APIData.substr(14,2));
                state.timeNow.second = parseInt(APIData.substr(17,2));
                // console.log(state.timeNow);
                // console.log(APIData);
            }).catch(error => {
                console.log(error.message);
            });
        },
        changeOccupied(state, boolean) {
            state.occupied = boolean;
        },
        changeLanguage(state, language) {
            state.currentLanguage = language;
        }
    },
    actions: {
        showAlert({commit}, alert) {
            var type = alert.type, imgUrl = alert.imgUrl, message = alert.message;
            commit("addAlertMessage", {type, imgUrl, message});
            let intervalCloseSaveAlert = setInterval(() => {
                commit("deleteAlert", {type, imgUrl, message});
                clearInterval(intervalCloseSaveAlert);
            }, 5000);
        }
    }
}