import Store from "../store/store"

import BattleService from "./BattleService";

const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusGeneral = storeAcess.generalModule;

export default {
    methods: {
        lootGeneral: (item) => {
            var randomNumber = BattleService.methods.getRandomNumber(0, 100, 10000);
            let messageLoot;
            if(randomNumber <= item.porcent) {
                var newItem = {
                    item: statusItems.state.items.find(i => i.name == item.name)
                };
                if(item.work || item.work === null) {
                    var profile = statusPerson.state.mainPerson.class;
                    if(profile.name) {
                        profile.boost = profile.boost == undefined ? 0 : profile.boost;
                        newItem.quantidade = BattleService.methods.getRandomNumber(item.qtd[0], item.qtd[1] + 1);
                        newItem.quantidade += parseInt(Math.trunc(newItem.quantidade * profile.boost));
                    } else {
                        newItem.quantidade = BattleService.methods.getRandomNumber(item.qtd[0], item.qtd[1] + 1);
                    }
                    if(newItem.item.type === "material" || newItem.item.groupEquip === "food") messageLoot = {type: "default", imgUrl: "items/" + newItem.item.urlImg, message: `${newItem.quantidade}x de ${newItem.item.name}`};
                    else messageLoot = {type: "default", imgUrl: "equipments/" + newItem.item.urlImg, message: `${newItem.quantidade}x de ${newItem.item.name}`};
                    statusGeneral.mutations.addAlertMessage(statusGeneral.state, messageLoot);
                    let intervalCloseLootAlert = setInterval(() => {
                        statusGeneral.mutations.deleteAlert(statusGeneral.state, messageLoot);
                        clearInterval(intervalCloseLootAlert);
                    }, 3000);
                } else {
                    newItem.quantidade = BattleService.methods.getRandomNumber(item.qtd[0], item.qtd[1]);
                    if(newItem.item.type === "material" || newItem.item.type === "food") messageLoot = {type: "default", imgUrl: "items/" + newItem.item.urlImg, message: `${newItem.quantidade}x de ${newItem.item.name}`};
                    else messageLoot = {type: "default", imgUrl: "equipments/" + newItem.item.urlImg, message: `${newItem.quantidade}x de ${newItem.item.name}`};
                    statusGeneral.mutations.addAlertMessage(statusGeneral.state, messageLoot);
                    let intervalCloseLootAlert = setInterval(() => {
                        statusGeneral.mutations.deleteAlert(statusGeneral.state, messageLoot);
                        clearInterval(intervalCloseLootAlert);
                    }, 5000);
                }
                statusPerson.mutations.addItemInventory(statusPerson.state, newItem);
            }
        }
    }
}