import ModalVoyage from "./components/modalVoyage/Voyage.vue"
import DescMapTooltipComponent from "./components/descMapTooltip/DescMapTooltip.vue"
import RandomEventsMapComponent from "../../shared/components/randomEvents/randomEventsMap/RandomEventsMap.vue"

export default {
    data() {
        return {
            locationVoyage: "",
            costMoneyVoyage: 0
        }
    },
    computed: {
        componentModalVoyage() {
            return this.$store.state.mapModule.modalVoyage;
        },
        mapLocations() {
            return this.$store.state.mapModule.locationsInfos.find(i => i.mapName == this.$store.state.mapModule.mapNow).places;
        },
        mainPerson(){
            return this.$store.state.personModule.mainPerson;
        },
        personLocation(){
            return this.$store.state.mapModule.currentLocation;
        }
    },
    methods: {
        changeLocation (place){
            if(place.isEnabled) {
                this.locationVoyage = place.name;
                this.valueVoyage(place.name);
                this.$store.commit("changeShowModalVoyage", true);
                return;
            }
            alert("Este lugar ainda não está habilitado");
        },
        valueVoyage (place) {
            var indexLocationMap = this.$store.state.mapModule.locationsInfos.find(map => {return map.mapName === this.$store.state.mapModule.mapNow});
            var locationFind = indexLocationMap.places.find(obj => {return obj.name === place});
            var previous = this.$store.state.mapModule.currentLocation.coordinates;
            const distance = function() {
                let sumTop = locationFind.coordinates.top - previous.top < 0 ? (locationFind.coordinates.top - previous.top) * -1 : locationFind.coordinates.top - previous.top;
                let sumLeft = locationFind.coordinates.left - previous.left < 0 ? (locationFind.coordinates.left - previous.left) * -1 : locationFind.coordinates.left - previous.left;
                return sumTop + sumLeft;
            }
            this.costMoneyVoyage = distance() * 7;
        }
    },
    components: {
        ModalVoyage,
        DescMapTooltipComponent,
        RandomEventsMapComponent
    }
}