//Components
import BuyItems from "./components/buyItems/BuyItems.vue";
import SellItems from "./components/sellItems/SellItems.vue";

// import axios from "axios";

export default {
    data() {
        return {
            buyItems: this.$store.state.itemsModule.items.filter(i => i.seller == "market"),
            sellItems: this.$store.state.personModule.mainPerson.Inventory,
            buyColapse: true,
            sellColapse: true,
        }
    },
    computed: {
        cart(){
            return this.$store.state.marketModule.cart;
        }
    },
    components: {
        BuyItems,
        SellItems
    },
    methods: {
        toogleBuy() {
            this.buyColapse = !this.buyColapse;
        },
        toogleSell() {
            this.sellColapse = !this.sellColapse;
        }
    },
    created(){
        if(this.$store.state.itemsModule.items.length == 0) {
            this.$store.commit("getItemsBack");
            this.buyItems = this.$store.state.itemsModule.items.filter(i => i.seller == "market");
        }
    }
}