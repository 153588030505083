import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        workingNow: false,
        working: {
            initial: {},
            end: {}
        },
        workAction: 'Fazendo nada',
        timeLeft: 0,
        timeCurrent: 0,
        clock: {
            hour: 0,
            minute: 0,
            second: 0
        }
    },
    mutations,
    actions
}