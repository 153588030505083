export default function altmenerArcher(vm) {
    vm.anims.create({
        key: "roll",
        frames: vm.anims.generateFrameNumbers('altmenerArcherRoll', {start: 0, end: 7}),
        frameRate: 10,
        repeat: 0
    })

    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('altmenerArcherJump', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "up",
        frames: vm.anims.generateFrameNumbers('altmenerArcherJump', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "down",
        frames: vm.anims.generateFrameNumbers('altmenerArcherFall', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('altmenerArcherAttack1', {start: 0, end: 13}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('altmenerArcherAttack2', {start: 0, end: 11}),
        frameRate: 30
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('altmenerArcherAttack3', {start: 0, end: 11}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('altmenerArcherIdle', {start: 0, end: 11}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('altmenerArcherWalk', {start: 0, end: 9}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "run",
        frames: vm.anims.generateFrameNumbers('altmenerArcherRun', {start: 0, end: 9}),
        frameRate: 15,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('altmenerArcherDead', {start: 0, end: 13}),
        frameRate: 5,
        repeat: 0
    })
}