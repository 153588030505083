import { mapGetters } from "vuex"

export default {
    props: ['choices'],
    data() {
        return {
            optionCurrent: 0,
            option: ""
        }
    },
    computed: {
        ...mapGetters(['translateWords'])
    },
    methods: {
        insertOption(options) {
            this.option = options[this.optionCurrent].choice;
            this.$store.dispatch("changeCreatePerson", options[this.optionCurrent]);
            this.$store.commit("addInfos", options[this.optionCurrent]);
        },
        selectOption(side, options) {
            if(side === 0) {
                if(this.optionCurrent > 0) {
                    this.optionCurrent--;
                    this.insertOption(options);
                }
            }
            if(side === 1) {
                if(this.optionCurrent < options.length) {
                    this.optionCurrent++;
                    this.insertOption(options);
                }
            }
        }
    },
    mounted(){
        this.insertOption(this.$props.choices.options);
    }
}