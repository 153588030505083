import InventoryComponent from "../home/components/inventory/Inventory.vue";
import LootCardComponent from "./components/lootCard/LootCard.vue";
import ExecuteActionsComponent from "./components/executeActions/ExecuteActions.vue";

import WorkAreaComponent from "./components/workArea/WorkArea.vue";

//Mocks
import MockWorks from "./mocks/workMock.js";

export default {
    data() {
        return {
            workDatas: MockWorks,
            portal: 0
        }
    },
    computed: {
        works() {
            return this.$store.state.personModule.mainPerson.works;
        },
        worksCurrent() {
            return this.$store.state.personModule.mainPerson.class;
        },
        currentLocation() {
            return this.$store.state.mapModule.currentLocation;
        },
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        mapCurrent() {
            return this.$store.state.mapModule.currentLocation;
        },
        shop() {
            return this.$store.state.itemsModule.shop;
        },
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        },
        actionText() {
            return this.$store.state.classWorkModule.workAction;
        }
    },
    methods: {
        acessPortal(number) {
            this.portal = number;
        },
        generateBubble(number) {
            var effectContainer = document.getElementById(`effect${number}`);
            for(var i = 0; i < 100;i++) {
                var el = document.createElement("div");
                el.classList.add("bubble");
                effectContainer.appendChild(el);
                el.style.backgroundColor = '#2980b9';
            }
        },
        actions(action) {
            switch(action) {
                case 'sitFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue / 5)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue / 5);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 20)});
                    this.$store.dispatch("levelHappiness", -2);
                    break;
                case 'restFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue / 2)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue / 2);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 10)});
                    this.$store.dispatch("levelHappiness", -5);
                    break;
                case 'sleepFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 5)});
                    this.$store.dispatch("levelHappiness", -10);
                    break;
            }
        }
    },
    mounted(){
        // if(this.$store.state.mapModule.currentLocation.loots.find(i => i.work === null)) {
        // }
        this.generateBubble(1);
        this.generateBubble(2);
    },
    components: {
        LootCardComponent,
        InventoryComponent,
        ExecuteActionsComponent,
        WorkAreaComponent
    }
}