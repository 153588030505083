import { mapGetters } from "vuex"

//Mocks
import Link from "@/shared/components/sidebar/mocks/links";

//Helpers
import _cookieHelper from "@/core/helpers/CookieHelper";

//Store
import Store from "@/core/store/store.js";

//Services
import SaveCharService from "@/core/services/saveCharService";

export default {
    name: 'AppHeader',
    data() {
        return {
            links: Link.links,
            invisibleLinks: false
        }
    },
    computed: {
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        statusPerson() {
            return this.$store.state.personModule.mainPerson.infos[1].li;
        },
        hpCurrent() {
            return this.$store.state.personModule.mainPerson.infos[0].li[0];
        },
        mpCurrent() {
            return this.$store.state.personModule.mainPerson.infos[0].li[1];
        },
        typeLocation() {
            return this.$store.state.mapModule.currentLocation.group;
        },
        happiness() {
            return this.$store.state.personModule.mainPerson.happiness;
        },
        energyCurrent() {
            return this.$store.state.personModule.mainPerson.infos[0].li[2];
        },
        disableButtons() {
            return this.$store.state.mapModule.isExplorer;
        },
        changeDisableSaveButton() {
            return this.$store.state.generalModule.saveCharTime;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        sidebarToggle() {
            this.$store.commit("toogleSidebar", "mobileSidebar");
        },
        toggleButtons() {
            this.invisibleLinks = !this.invisibleLinks;
        },
        deslogarButton() {
            _cookieHelper.deleteAllCookies();
            window.location.href = '/#/';
        },
        quests() {
            this.$store.commit("changeQuestPanel", true);
        },
        saveCharCurrent() {
            SaveCharService.methods.putSaveChar(this.$store.state.personModule.mainPerson);
            this.$store.commit("addAlertMessage", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
            this.$store.commit("toogleSaveCharTime", false);
            let intervalCloseSaveAlert = setInterval(() => {
                this.$store.commit("deleteAlert", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
                clearInterval(intervalCloseSaveAlert);
            }, 5000);
        }
    },
    mounted() {      
        const storeAcess = Store._modules.root._rawModule.modules;
        const generalStore = storeAcess.generalModule;  
        this.$store.watch(
            (state) => { return state.personModule.mainPerson; },
            () => { generalStore.mutations.toogleSaveCharTime(generalStore.state, true); },
            { deep:true }
        )
    }
}