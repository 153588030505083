import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";
import { methodsDungeon } from "@/shared/components-phaser/methods";

import keyboardsImport from "@/shared/components-phaser/keyboards" 
import playerImport from "@/shared/components-phaser/player" 

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;

class SceneCamp extends Phaser.Scene {
    constructor(locationInfos, mainPerson){
        super("templeCormiles");
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.player;
        this.mapSize = {
            x: 1280,
            y: 800
        };
        this.layer = {};
        this.map;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.mainAtkMoreDamage;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.entities;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.audio('ambient2', '/resourcesInGame/sons/ambient/temple/Cave-2_Loop.wav');
        this.load.audio('music2', '/resourcesInGame/sons/musics/temple/Theme_-_A_Maiden_Fair.ogg');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/constructions/temple/tiles/MainLevBuild.png');
        this.load.image('entities1', '/resourcesInGame/title-set/constructions/temple/tiles/entities.png');
        this.load.image('background1', '/resourcesInGame/title-set/constructions/temple/tiles/templo.png');
        this.load.tilemapTiledJSON("templeCormilesMap", "/resourcesInGame/maps/cormiles/temple/temple.json");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            //NPC
        }
    
        function createScene(vm) {
            vm.map = vm.make.tilemap({ key: "templeCormilesMap", tileWidth: 32, tileHeight: 32});
            var floor = vm.map.addTilesetImage("MainLevBuild", "floor1");
            var entities = vm.map.addTilesetImage("entities", "entities1");
            var background = vm.map.addTilesetImage("templo", "background1");
            vm.layer.background = vm.map.createLayer("background", [background, floor], 0, 0);
            vm.layer.altar = vm.map.createLayer("altar", background, 0, 0);
            vm.layer.objects = vm.map.createLayer("objects", entities, 0, 0);
            vm.layer.floor = vm.map.createLayer("floor", floor, 0, -400).setScale(1.5);

            vm.layer.floor.setCollisionBetween(0, 5000);
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 380, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(1280, 380, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(end, vm.player);

            vm.physics.add.overlap(initial, vm.player, function() {
                vm.sounds.ambient.stop();
                vm.sounds.theme.stop();
                vm.scene.start('SceneCormiles');
            });
        }

        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.limits = this.physics.add.group();

        createAnimations(this);
        createScene(this);
        playerImport.createPlayer(this, 150, 680);
        createLimits(this);

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);
        this.physics.add.collider(this.limits, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        this.sounds.ambient = this.sound.add('ambient2');
        this.sounds.theme = this.sound.add('music2');

        this.sounds.ambient.play({loop: true});
        this.sounds.theme.play({loop: true, volume: 0.1});

        keyboardControls(this);
    }

    update ()
    {
        if (!this.player.body.embedded) {
            this.interage = "none";
        }
    }
}

export default SceneCamp;