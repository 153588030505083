export default {
    data() {
        return {
            attrBlocks: [
                {
                    name: "VITALIDADE",
                    click: "vitality"
                },
                {
                    name: "DESTREZA",
                    click: "critical"
                },
                {
                    name: "FORÇA",
                    click: "force"
                },
                {
                    name: "PODER MÁGICO",
                    click: "mPower"
                }
            ]
        }
    },
    computed: {
        pointsAttr() {
            return this.$store.state.personModule.mainPerson.pointsAttr;
        },
        mainPerson() {
            return this.$store.state.personModule.mainPerson;
        }
    },
    methods: {
        close() {
            this.$store.commit("toogleSidebar", 'disable');
        },
        handle_function_call(function_name) {
            this[function_name]()
        },
        vitality() {
            this.$store.commit("addAttr", "vitality");
        },
        force() {
            this.$store.commit("addAttr", "force");
        },
        critical() {
            this.$store.commit("addAttr", "critical");
        },
        mPower() {
            this.$store.commit("addAttr", "mPower");
        }
    }
}