export default function mainAnimationsAllMaps(vm) {
    vm.anims.create({
        key: "explosion",
        frames: vm.anims.generateFrameNumbers('explosion', {start: 0, end: 9}),
        frameRate: 10,
        repeat: 0
    })

    vm.anims.create({
        key: "blackHole",
        frames: vm.anims.generateFrameNumbers('blackHole', {start: 0, end: 7}),
        frameRate: 10,
        repeat: -1
    })
}