import Phaser from "phaser";

import playerClass from "@/core/models/inGame/player";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";
import keyboardsImport from "@/shared/components-phaser/keyboards"
import { updatePlayer } from "@/shared/components-phaser/updateActions" 
import { methodsDungeon } from "@/shared/components-phaser/methods";

import preloadAllResources from "@/shared/components-phaser/preload.js"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;

class SceneCamp extends Phaser.Scene {
    constructor(locationInfos, mainPerson){
        super("SceneCormiles")
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.platforms;
        this.mapSize = 3300;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.key;
        this.mainAtkMoreDamage;
        this.player;
        this.background;
        this.physicObject;
        this.sounds = {};
        this.startWave;
        this.npcs;
        this.interage;
        this.sounds = {};
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/Village_3_Loop.wav');
        this.load.audio('music', '/resourcesInGame/sons/musics/Town_-_Country_Market.ogg');

        // Resources all maps
        preloadAllResources(this);

        // Tiles
        this.load.image("bloco1", "/resourcesInGame/title-set/tiles/village/tile0032.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //Village Background
        this.load.image("greatHouseBack", "/resourcesInGame/title-set/constructions/scenary/back_building5.png");
        this.load.image("houseRedBack3", "/resourcesInGame/title-set/constructions/scenary/back_building4.png");
        this.load.image("houseRedBack2", "/resourcesInGame/title-set/constructions/scenary/back_building3.png");
        this.load.image("houseRedBack", "/resourcesInGame/title-set/constructions/scenary/back_building2.png");
        this.load.image("houseBlueBack", "/resourcesInGame/title-set/constructions/scenary/back_building1.png");
        this.load.image("wall", "/resourcesInGame/title-set/constructions/scenary/gray_back_buildings11.png");
        this.load.image("buildGray", "/resourcesInGame/title-set/constructions/scenary/gray_back_buildings7.png");

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/bg.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/road.png");
        this.load.image("mountains2", "/resourcesInGame/title-set/parallax/mountains/mountains&forest.png");
        this.load.image("mountains", "/resourcesInGame/title-set/parallax/rocks/rocks&bushes.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/clouds.png");

        //Constructions
        this.load.image("tower", "/resourcesInGame/title-set/constructions/tower/building28_color2.png");
        this.load.image("store", "/resourcesInGame/title-set/constructions/store/building6.png");
        this.load.image("store2", "/resourcesInGame/title-set/constructions/store/two-storey-house1.png");
        this.load.image("temple", "/resourcesInGame/title-set/constructions/temple/building7.png");
        this.load.image("stable", "/resourcesInGame/title-set/constructions/store/stable/two-storey-house5.png");

        //animatedConstructions
        this.load.spritesheet("blacksmith", "/resourcesInGame/title-set/constructions/store/blacksmith/House_animation.png", { frameWidth: 128, frameHeight: 96 });

        //smallStore
        this.load.image("smallStore", "/resourcesInGame/title-set/constructions/store/small/stall2_color1.png");

        //Objects
        this.load.image("lamp", "/resourcesInGame/title-set/objects/lamp/lamp2.png");
        this.load.image("box", "/resourcesInGame/title-set/objects/boxes/5.png");
        this.load.image("box2", "/resourcesInGame/title-set/objects/boxes/6.png");
        this.load.image("well", "/resourcesInGame/title-set/objects/others/well.png");

        //Trees
        this.load.image("tree1", "/resourcesInGame/title-set/objects/tree/tree2.png");
        this.load.image("tree2", "/resourcesInGame/title-set/objects/tree/tree3.png");

        //NPCs
        this.load.spritesheet('smith', '/resourcesInGame/sprites/npcs/blacksmith/Smith_hammer.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('guard', '/resourcesInGame/sprites/npcs/guard/Idle.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('mage', '/resourcesInGame/sprites/npcs/mage/Wizard_read.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('sellerMarketP2P', '/resourcesInGame/sprites/npcs/sellerMarketP2P/Idle.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('horseSeller', '/resourcesInGame/sprites/npcs/citizen/adult/man/Man_idle.png', { frameWidth: 48, frameHeight: 48 });

        //Animais
        this.load.spritesheet('horse', '/resourcesInGame/sprites/animals/horse/Idle.png', { frameWidth: 72, frameHeight: 72 });
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            //NPC
            vm.anims.create({
                key: "smith",
                frames: vm.anims.generateFrameNumbers('smith', {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "guard",
                frames: vm.anims.generateFrameNumbers('guard', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "mage",
                frames: vm.anims.generateFrameNumbers('mage', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "sellerMarketP2P",
                frames: vm.anims.generateFrameNumbers('sellerMarketP2P', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "horseSeller",
                frames: vm.anims.generateFrameNumbers('horseSeller', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })

            //Animals
            vm.anims.create({
                key: "horse",
                frames: vm.anims.generateFrameNumbers('horse', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })

            //Constructions
            vm.anims.create({
                key: "blacksmithBuilding",
                frames: vm.anims.generateFrameNumbers('blacksmith', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);

            var mountainsBack = vm.add.image(0, -60, 'mountains2').setOrigin(0).setScrollFactor(.33);
            var mountainsBack2 = vm.add.image(1920, -60, 'mountains2').setOrigin(0).setScrollFactor(.33);

            var mountains = vm.add.image(0, -120, 'mountains').setOrigin(0).setScrollFactor(.66);
            var mountains2 = vm.add.image(1920, -120, 'mountains').setOrigin(0).setScrollFactor(.66);
            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;
        }

        function objects(vm) {
            //Lamp
            vm.add.image(400, 640, 'lamp').setScale(2.3, 2.6);
            vm.add.image(740, 640, 'lamp').setScale(2.3, 2.6);
            vm.add.image(1060, 640, 'lamp').setScale(2.3, 2.6);
            vm.add.image(1420, 640, 'lamp').setScale(2.3, 2.6);
            vm.add.image(1680, 640, 'lamp').setScale(2.3, 2.6);
            vm.add.image(2150, 640, 'lamp').setScale(2.3, 2.6);
            vm.add.image(2460, 640, 'lamp').setScale(2.3, 2.6);

            vm.add.image(2300, 640, 'well').setScale(2, 2.3);

            //Boxes
            vm.add.image(450, 657, 'box').setScale(2, 2.3);
            vm.add.image(500, 657, 'box').setScale(2, 2.3);
        }

        function trees(vm) {
            vm.add.image(400, 520, 'tree1').setScale(2.5, 2.8);
            vm.add.image(1050, 520, 'tree1').setScale(2.5, 2.8);
            vm.add.image(2450, 520, 'tree1').setScale(2.5, 2.8);
            vm.add.image(700, 520, 'tree2').setScale(2.5, 2.8);
            vm.add.image(1670, 520, 'tree2').setScale(2.5, 2.8);
            vm.add.image(100, 520, 'tree2').setScale(2.5, 2.8);
            vm.add.image(2200, 520, 'tree2').setScale(2.5, 2.8);
        }

        function villageBackground(vm) {
            for(var i = 1; i < 20; i++) {
                var wall = vm.add.image(200 * i, 200, 'wall').setOrigin(0).setScale(2.3, 2.6).setScrollFactor(.6);
                wall.displayHeight = vm.sys.game.config.height;
            }
            vm.add.image(600, 600, 'buildGray').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);
            vm.add.image(1000, 600, 'buildGray').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);
            vm.add.image(1500, 600, 'buildGray').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);
            vm.add.image(1800, 600, 'buildGray').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);
            vm.add.image(2000, 600, 'buildGray').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);

            vm.add.image(400, 550, 'greatHouseBack').setScale(2, 2.3).setScrollFactor(.6);
            vm.add.image(850, 550, 'greatHouseBack').setScale(2, 2.3).setFlipX(true).setScrollFactor(.6);
            vm.add.image(1500, 550, 'greatHouseBack').setScale(2, 2.3).setFlipX(true).setScrollFactor(.6);
            vm.add.image(1900, 550, 'greatHouseBack').setScale(2, 2.3).setScrollFactor(.6);

            vm.add.image(700, 550, 'houseRedBack').setScale(2, 2.3).setFlipX(true).setScrollFactor(.6);
            vm.add.image(1300, 550, 'houseRedBack').setScale(2, 2.3).setScrollFactor(.6);
            vm.add.image(250, 600, 'houseRedBack2').setScale(2, 2.3).setScrollFactor(.6);
            vm.add.image(1000, 600, 'houseRedBack2').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);
            vm.add.image(1600, 600, 'houseRedBack2').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);
            vm.add.image(250, 600, 'houseRedBack3').setScale(2, 2.3).setScrollFactor(.6);
            vm.add.image(1750, 600, 'houseRedBack3').setScale(2, 2.3).setScrollFactor(.6);
            vm.add.image(2200, 600, 'houseRedBack3').setScale(2, 2.3).setScrollFactor(.6).setFlipX(true);

            var ground = vm.add.image(0, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground2 = vm.add.image(640, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground3 = vm.add.image(1280, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground4 = vm.add.image(1920, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground5 = vm.add.image(2560, 0, 'ground').setOrigin(0).setScrollFactor(.8);

            ground.displayHeight = vm.sys.game.config.height;
            ground2.displayHeight = vm.sys.game.config.height;
            ground3.displayHeight = vm.sys.game.config.height;
            ground4.displayHeight = vm.sys.game.config.height;
            ground5.displayHeight = vm.sys.game.config.height;
        }

        function animals(vm) {
            vm.add.sprite(2850, 600, 'horse').setScale(2.3, 2.6).setFlipX(true).play("horse", true);
        }

        function scenary(vm) {
            villageBackground(vm);
            vm.add.image(250, 395, 'tower').setScale(2, 2.3).setFlipX(true);
            vm.add.image(550, 510, 'store').setScale(2.3, 2.6);
            vm.add.image(1200, 510, 'store2').setScale(2.3, 2.6);
            vm.add.image(1530, 615, 'smallStore').setScale(3, 3.3).setDepth(3);
            vm.add.sprite(1900, 530, 'blacksmith').setScale(3.3, 3.5).play("blacksmithBuilding", true);
            vm.add.image(2900, 510, 'stable').setScale(2.3, 2.6);

            var temple = vm.physicObject.create(900, 545, 'temple').setScale(2.3, 2.6).setCollideWorldBounds(true).setOffset(0,-6);
            vm.physics.add.overlap(vm.player, temple, function() {
                if(vm.interage != "temple") vm.interage = "temple";
            });

            trees(vm);
            objects(vm);
            animals(vm);
        }
    
        function createScene(vm) {
            parallax(vm);
            scenary(vm);
    
            vm.platforms = vm.physics.add.staticGroup();
    
            for(var i = 0; i < 60; i++) {
                vm.platforms.create(60 * i, 748, 'bloco1').setScale(2, 2.3).refreshBody();
            }
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                switch(vm.interage) {
                    case "temple":
                        vm.scene.start('templeCormiles');
                        vm.sounds.ambient.stop();
                        vm.sounds.theme.stop();
                        break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }
    
        function createPlayer(vm) {
            //Player
            vm.player = new playerClass(vm, `${vm.mainPerson.sprite}Idle`, {x: 200,y: 500});
            vm.player.anims.play('turn', true);
        }

        function createNpc(vm, name, sprite, anime, scale, pos, flipX, haveShop) {
            var newNpc = vm.npcs.create(pos.x, pos.y, sprite);
            newNpc.setCollideWorldBounds(true).setScale(scale.x, scale.y).play(anime, true).setFlipX(flipX);
            
            vm.physics.add.overlap(vm.player, newNpc, function() {
                if(haveShop) {
                    if(vm.interage != name) vm.interage = name;
                }
            });
        }

        this.sounds.ambient = this.sound.add('ambient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.ambient.play({loop: true});
        this.sounds.theme.play({loop: true, volume: 0.1});

        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();

        this.physicObject = this.physics.add.group();

        createAnimations(this);
        createPlayer(this);
        createScene(this);

        this.methods.createKey(this, "enter");

        this.physics.add.collider(this.npcs, this.platforms);
        this.physics.add.collider(this.player, this.platforms);
        this.physics.add.collider(this.mainAtk, this.platforms);
        this.physics.add.collider(this.mainAtkMoreDamage, this.platforms);
        this.physics.add.collider(this.physicObject, this.platforms);

        this.physics.world.setBounds(0, 0, this.mapSize, 800);

        createNpc(this, "blacksmith", "smith", "smith", {x: 2.5, y: 3}, {x: 1800, y: 600}, false, true);
        createNpc(this, "guard", "guard", "guard", {x: 2.5, y: 3}, {x: 280, y: 600}, true, false);
        createNpc(this, "magicHouse", "mage", "mage", {x: 2.5, y: 3}, {x: 1160, y: 600}, true, true);
        createNpc(this, "sellerMarketP2P", "sellerMarketP2P", "sellerMarketP2P", {x: 2.3, y: 2.6}, {x: 1510, y: 600}, true, true);
        createNpc(this, "stable", "horseSeller", "horseSeller", {x: 2.5, y: 2.9}, {x: 2750, y: 600}, true, true);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize, 800);

        keyboardControls(this);
    }

    update ()
    {
        if (!this.player.body.embedded) {
            this.interage = "none";
        }
        updatePlayer(this);
    }
}

export default SceneCamp;