const mutations = {
    changeActionText(state, text) {
        state.workAction = text;
    },
    changeWorkingNow(state, boolean) {
        state.workingNow = boolean;
    },
    restartTimeLeft(state) {
        state.timeLeft = 0;
    },
    addTimeInitialEnd(state, time) {
        state.working.initial = time.initial;
        state.working.end = time.end;
    },
    restartTime(state) {
        state.working.initial = {};
        state.working.end = {};
    }
}

export default mutations;