import Store from "../store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusMonster = storeAcess.monstersModule;

var damage, defM, atkP, atkM, defP, defense;

export default {
    methods: {
        attackMagicMain: () => {
            defM = statusMonster.state.current.status.def;
            defense = Math.floor(Math.random() * (0.3 * 100 - 0.2 * 100) + 0.2 * 100) / (1 * 100);
            var magicPower = statusPerson.state.mainPerson.infos[1].li[4].value * 0.05;
            var atkMagicP = statusPerson.state.mainPerson.infos[1].li[0].value + statusPerson.state.mainPerson.infos[1].li[0].value * magicPower;
            damage = ((atkMagicP - (defM * defense)) < 0 ? 0.1 : atkMagicP - (defM * defense));
            return damage;
        },
        attackMain: (randomDamage, randomCritical, critical) => {
            defense = Math.floor(Math.random() * (0.3 * 100 - 0.2 * 100) + 0.2 * 100) / (1 * 100);
            atkP = statusPerson.state.mainPerson.infos[1].li[0].value + statusPerson.state.mainPerson.infos[1].li[0].value * (statusPerson.state.mainPerson.infos[1].li[1].value * 0.05);
            if(critical < randomCritical) atkP = atkP * randomDamage;
            else if(critical > randomCritical) atkP = atkP * 3;
            defM = statusMonster.state.current.status.def;
            damage = ((atkP - (defM * defense)) < 0 ? 0.1 : atkP - (defM * defense));
            return damage;
        },
        attackMainCalcule: (defMonster) => {
            defense = Math.floor(Math.random() * (0.3 * 100 - 0.2 * 100) + 0.2 * 100) / (1 * 100);
            atkP = statusPerson.state.mainPerson.infos[1].li[0].value + statusPerson.state.mainPerson.infos[1].li[0].value * (statusPerson.state.mainPerson.infos[1].li[1].value * 0.05);
            damage = ((atkP - (defMonster * defense)) < 0 ? 0.1 : atkP - (defMonster * defense));
            return damage;
        },
        attackMagicMainCalcule: (defMonster) => {
            defense = Math.floor(Math.random() * (0.3 * 100 - 0.2 * 100) + 0.2 * 100) / (1 * 100);
            atkP = statusPerson.state.mainPerson.infos[1].li[0].value + statusPerson.state.mainPerson.infos[1].li[0].value * (statusPerson.state.mainPerson.infos[1].li[5].value * 0.025);
            damage = ((atkP - (defMonster * defense)) < 0 ? 0.1 : atkP - (defMonster * defense));
            return damage;
        },
        attackMob: (randomNumber) => {
            defense = Math.floor(Math.random() * (0.3 * 100 - 0.2 * 100) + 0.2 * 100) / (1 * 100);
            atkM = statusMonster.state.current.status.atk * randomNumber;
            defP = statusPerson.state.mainPerson.infos[1].li[2].value;
            damage = ((atkM - (defP * defense)) < 0 ? 0.1 : atkM - (defP * defense));
            return damage;
        },
        attackMobCalcule: (atkMonster) => {
            defense = Math.floor(Math.random() * (0.3 * 100 - 0.2 * 100) + 0.2 * 100) / (1 * 100);
            atkP = atkMonster;
            defM = statusPerson.state.mainPerson.infos[1].li[2].value;
            damage = ((atkP - (defM * defense)) < 0 ? 0.1 : atkP - (defM * defense));
            return damage;
        },
        hurtPlayer: (damageHurt, randomNumber) => {
            var damageData = {
                sinal: "subtrair",
                value: damageHurt * randomNumber
            }
            statusPerson.mutations.calculeHPCurrent(statusPerson.state, damageData);
        },
        changeDurabilityArmor:() => {
            var fallResistance;
            switch(statusPerson.state.mainPerson.personPath.replace("Path of ", "").toLowerCase()) {
                case "swordman":
                    fallResistance = .5;
                    break;
                case "archer":
                    fallResistance = 1;
                    break;
                default:
                    fallResistance = .5;
                    break;
            }
            if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "armor").name !== "")
                statusPerson.actions.changeDurabilityItem(Store, {item: statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "armor"), value: fallResistance});
            if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "boots").name !== "")
                statusPerson.actions.changeDurabilityItem(Store, {item: statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "boots"), value: fallResistance});
            if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "gauntlet").name !== "")
                statusPerson.actions.changeDurabilityItem(Store, {item: statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "gauntlet"), value: fallResistance});
            if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "helmet").name !== "")
                statusPerson.actions.changeDurabilityItem(Store, {item: statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "helmet"), value: fallResistance});
        },
        changeDurabilityWeapon:() => {
            if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "first-weapon").name !== "")
                statusPerson.actions.changeDurabilityItem(Store, {item: statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "first-weapon"), value: .5});
            if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "second-weapon")) {
                if(statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "second-weapon").name !== "") {
                    statusPerson.actions.changeDurabilityItem(Store, {item: statusPerson.state.mainPerson.Equips.find(i => i.groupEquip == "second-weapon"), value: .5})
                }
            }
        },
        getRandomNumber: (min, max, precision) => {
            if(precision) return Math.floor(Math.random() * (max * precision - min * precision) + min * precision) / (1 * precision);
            else return Math.floor(Math.random() * (max - min)) + min;
        },
        getXPandGoldMonster: () => {
            const newGold = Math.floor(Math.random() * (statusMonster.state.current.loots.gold[1] - statusMonster.state.current.loots.gold[0] + 1)) + statusMonster.state.current.loots.gold[0];
            let loot = {
                exp: statusMonster.state.current.loots.exp,
                gold: newGold
            }
            return loot;
        }
    }
}