// Legendas type:
// all - Todos | none - Nenhum

export default {
    links: [
        {
            title: 'Personagem',
            class: 'person',
            route: '/Home',
            type: [
                "none"
            ]
        },
        {
            title: 'Minha Vila',
            class: 'house',
            route: '/House',
            type: [
                "none"
            ]
        },
        {
            title: 'Mapa',
            class: 'worldMap',
            route: '/World',
            type: [
                "all"
            ]
        },
        {
            title: 'Trabalhos',
            class: 'works',
            route: '/Works',
            type: [
                "Especial",
                "Mines",
                "Florest",
                "Ruins"
            ]
        },
        {
            title: 'Missões',
            class: 'quests',
            route: '/Missions',
            type: [
                "none"
            ]
        },
        {
            title: 'Cidade',
            class: 'maincity',
            route: '/Maincity',
            type: [
                "Village",
                "Cities"
            ]
        },
        {
            title: 'Acampamento',
            class: 'camp',
            route: '/camp',
            type: [
                "Camp"
            ]
        },
        {
            title: 'Mercado',
            class: 'market',
            route: '/Market',
            type: [
                "none"
            ]
        },
        {
            title: 'Explorar',
            class: 'attack',
            route: '/Attack',
            type: [
                "Arena"
            ]
        }
    ],
}