import lvl from "./lvlMock";
import worksModel from "@/pages/works/mocks/workMock.js";

const mutations = {
    calculeHPCurrent: (state, method) => {
        if(method.value > state.mainPerson.infos[0].li[0].maxValue && method.sinal == "soma") {
            state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
        } else if (state.mainPerson.infos[0].li[0].value > state.mainPerson.infos[0].li[0].maxValue || state.mainPerson.infos[0].li[0].value + method.value > state.mainPerson.infos[0].li[0].maxValue && method.sinal == "soma") {
            state.mainPerson.infos[0].li[0].value = state.mainPerson.infos[0].li[0].maxValue;
        } else {
            if(method.sinal == "soma") state.mainPerson.infos[0].li[0].value += method.value;
            else if(method.sinal != "soma" && state.mainPerson.infos[0].li[0].value - method.value < 0) state.mainPerson.infos[0].li[0].value = 0;
            else state.mainPerson.infos[0].li[0].value -= method.value;
        }
    },
    calculeEnergyCurrent: (state, method) => {
        if(method.value > state.mainPerson.infos[0].li[2].maxValue && method.sinal == "soma") {
            state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
        } else if (state.mainPerson.infos[0].li[2].value > state.mainPerson.infos[0].li[2].maxValue || state.mainPerson.infos[0].li[2].value + method.value > state.mainPerson.infos[0].li[2].maxValue && method.sinal == "soma") {
            state.mainPerson.infos[0].li[2].value = state.mainPerson.infos[0].li[2].maxValue;
        } else {
            if(method.sinal == "soma") state.mainPerson.infos[0].li[2].value += method.value
            else state.mainPerson.infos[0].li[2].value -= method.value
        }
    },
    changeMagicPoints: (state, value) => {
        if(value > state.mainPerson.infos[0].li[1].maxValue) {
            state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
        } else if (state.mainPerson.infos[0].li[1].value > state.mainPerson.infos[0].li[1].maxValue || state.mainPerson.infos[0].li[1].value + value > state.mainPerson.infos[0].li[1].maxValue) {
            state.mainPerson.infos[0].li[1].value = state.mainPerson.infos[0].li[1].maxValue;
        } else {
            state.mainPerson.infos[0].li[1].value += value;
        }
    },
    changeXPandGold: (state, loot) => {
        state.mainPerson.exp.value += loot.exp;
        state.mainPerson.gold.value += 0;
        if(state.mainPerson.exp.value >= state.mainPerson.exp.maxValue) {
            while(state.mainPerson.exp.value >= state.mainPerson.exp.maxValue){
                state.lvlUpAnimation = true;
                state.mainPerson.lvl++;
                state.mainPerson.pointsAttr.skillPoints++;
                state.mainPerson.pointsAttr.current += 7;
                state.mainPerson.exp.value = 0;
                state.mainPerson.exp.maxValue = lvl[state.mainPerson.lvl - 1];
                switch(state.mainPerson.personPath) {
                    case "Path of Swordman":
                        state.mainPerson.infos[0].li[0].maxValue += 2;
                        state.mainPerson.infos[0].li[2].maxValue += 5;
                        state.mainPerson.infos[0].li[0].base += 2;
                        state.mainPerson.infos[0].li[2].base += 5;
                        break;
                    case "Path of Mage":
                        state.mainPerson.infos[0].li[0].maxValue += 2;
                        state.mainPerson.infos[0].li[1].maxValue += 5;
                        state.mainPerson.infos[0].li[0].base += 2;
                        state.mainPerson.infos[0].li[1].base += 5;
                        break;
                    case "Path of Spearman":
                        state.mainPerson.infos[0].li[0].maxValue += 5;
                        state.mainPerson.infos[0].li[1].maxValue += 2;
                        state.mainPerson.infos[0].li[0].base += 5;
                        state.mainPerson.infos[0].li[1].base += 2;
                        break;
                    case "Path of Archer":
                        state.mainPerson.infos[0].li[2].maxValue += 2;
                        state.mainPerson.infos[0].li[2].base += 2;
                        state.mainPerson.infos[1].li[3].base += 1;
                        break;
                }
            }
            var setTimeOut = setTimeout(() => {
                state.lvlUpAnimation = false;
                clearTimeout(setTimeOut);
            }, 4200)
        }
    },
    deadMain (state) {
        state.mainPerson.infos[0].li[0].value = 0;
        var expLost = () => {
            var expCalc = 0;
            var lvlCalc = state.mainPerson.lvl - 2 > 0 ? state.mainPerson.lvl : 2;
            for(var i = lvlCalc - 2; i >= 0; i--) {
                expCalc += lvl[i];
            }
            return expCalc;
        }
        
        console.log("EXP Total", state.mainPerson.exp.value > 0 ? (expLost() + state.mainPerson.exp.value) * 0.1 : expLost() * 0.1);
        console.log("EXP atual", state.mainPerson.exp.value);

        state.mainPerson.exp.value -= state.mainPerson.exp.value > 0 ? (expLost() + state.mainPerson.exp.value) * 0.1 : expLost() * 0.1;
        state.mainPerson.infos[0].li[0].value = state.mainPerson.infos[0].li[0].maxValue;
        setTimeout(function(){
            window.location.href = "";
        }, 7000);
    },
    addAttr(state, attr) {
        if(state.mainPerson.pointsAttr.current < 1) {
            return
        }
        state.mainPerson.pointsAttr.current -= 1;
        if(attr == "vitality"){
            state.mainPerson.infos[0].li[0].maxValue += 5
            state.mainPerson.infos[0].li[0].base += 5
            state.mainPerson.infos[0].li[2].maxValue += 2
            state.mainPerson.infos[0].li[2].base += 2
            state.mainPerson.pointsAttr.asignado.vitality++;
        } else if (attr == "force") {
            state.mainPerson.infos[1].li[1].value += 1
            state.mainPerson.infos[1].li[1].base += 1
            state.mainPerson.pointsAttr.asignado.force++;
            if(state.mainPerson.weight) state.mainPerson.weight.base = 30 + Math.trunc(state.mainPerson.infos[1].li[1].value * 5);
            if(state.mainPerson.weight.maxValue != state.mainPerson.weight.base) state.mainPerson.weight.maxValue = state.mainPerson.weight.base;
        } else if (attr == "critical") {
            state.mainPerson.infos[1].li[3].value += 1
            state.mainPerson.infos[1].li[3].base += 1
            state.mainPerson.infos[1].li[4].value += 1
            state.mainPerson.infos[1].li[4].base += 1
            state.mainPerson.pointsAttr.asignado.critical++;
        } else if (attr == "mPower") {
            state.mainPerson.infos[0].li[1].maxValue += 5
            state.mainPerson.infos[0].li[1].base += 5
            state.mainPerson.infos[1].li[5].value += 2
            state.mainPerson.infos[1].li[5].base += 2
            state.mainPerson.pointsAttr.asignado.mPower++;
        }
    },
    deleteItemInventory(state, item) {
        if(state.mainPerson.Inventory.includes(item.item)){
            let index = state.mainPerson.Inventory.indexOf(item.item);
            if(state.mainPerson.Inventory[index].qtd > item.quantidade){
                state.mainPerson.Inventory[index].qtd -= item.quantidade;
            } else if (state.mainPerson.Inventory[index].qtd <= item.quantidade) {
                state.mainPerson.Inventory.splice(index, 1);
            } else {
                alert("Você não tem quantidade suficiente desse item!")
            }
        } else {
            alert("Esse item não existe no seu inventário!")
        }
    },
    deleteItemEquip(state, item) {
        var newEquip = {
            "groupEquip": item.groupEquip,
            "name": "",
            "urlImg": "",
            "status": {}
        }
        var index = state.mainPerson.Equips.indexOf(item);
        state.mainPerson.Equips[index] = newEquip;
    },
    lessMoneyWallet(state, value) {
        state.mainPerson.gold.value -= value
    },
    // addItemInventoryWeight(state, item) {

    // },
    addItemInventory(state, item) {
        var itemInventory = state.mainPerson.Inventory.filter(i => i.name == item.item.name);
        if(state.mainPerson.Inventory.length + 1 > state.mainPerson.inventorySize) {
            alert("Seu inventário já está cheio");
            return;
        }
        if(item.item.type === "equip" || item.item.type === "equipMagic" || item.item.type === "equipTool") {
            item.item.qtd = parseInt(item.quantidade);
            state.mainPerson.Inventory.push(item.item);
            return;
        }
        else if(itemInventory.length > 0) {
            if(itemInventory.length === 1) {
                var totalAmount = parseInt(itemInventory[0].qtd) + parseInt(item.quantidade);
                if(totalAmount < 99) {
                    let itemIndex2 = state.mainPerson.Inventory.indexOf(itemInventory[0]);
                    state.mainPerson.Inventory.splice(itemIndex2, 1);
                    item.item.qtd = totalAmount;
                    state.mainPerson.Inventory.push(item.item);
                }
                else {
                    let itemIndex3 = state.mainPerson.Inventory.indexOf(itemInventory[0]);
                    state.mainPerson.Inventory.splice(itemIndex3, 1);
                    var pushItemInventory = {
                        groupEquip: item.item.groupEquip,
                        type: item.item.type,
                        rarity: item.item.rarity,
                        category: item.item.category,
                        weight: item.item.weight,
                        seller: item.item.seller,
                        name: item.item.name,
                        urlImg: item.item.urlImg,
                        price: item.item.price,
                        status: item.item.status
                    }
                    pushItemInventory.qtd = 99;
                    state.mainPerson.Inventory.push(pushItemInventory);
                    totalAmount = totalAmount - 99;
                    item.item.qtd = totalAmount;
                    state.mainPerson.Inventory.push(item.item);
                }
                return;
            } else {
                var totalAmount2 = 0;
                var addItemUnique = item.item;
                itemInventory.map((itemQTD) => {
                    totalAmount2 += parseInt(itemQTD.qtd);
                    let itemIndex = state.mainPerson.Inventory.indexOf(itemQTD);
                    state.mainPerson.Inventory.splice(itemIndex, 1);
                })
                totalAmount2 += parseInt(item.quantidade);
                let itemIndex = state.mainPerson.Inventory.indexOf(itemInventory[0]);
                if(itemIndex > -1) state.mainPerson.Inventory.splice(itemIndex, 1);
                while(totalAmount2 > 99) {
                    var addItem = {
                        groupEquip: item.item.groupEquip,
                        type: item.item.type,
                        rarity: item.item.rarity,
                        category: item.item.category,
                        weight: item.item.weight,
                        seller: item.item.seller,
                        name: item.item.name,
                        urlImg: item.item.urlImg,
                        price: item.item.price,
                        status: item.item.status
                    }
                    addItem.qtd = 99;
                    totalAmount2 -= 99;
                    state.mainPerson.Inventory.push(addItem);
                }
                addItemUnique.qtd = totalAmount2;
                state.mainPerson.Inventory.push(addItemUnique);
                return;
            }
        }
        item.item.qtd = parseInt(item.quantidade);
        state.mainPerson.Inventory.push(item.item);
    },
    addItemCraftInventory(state, item) {
        var itemInventory = state.mainPerson.Inventory.find(i => i.idCraft === item.idCraft);
        const itemProductionCost = item.price / 20;
        if(state.mainPerson.Inventory.length + 1 > state.mainPerson.inventorySize) {
            alert("Seu inventário já está cheio");
            return;
        }
        if(itemProductionCost) {
            state.mainPerson.gold.value -= itemProductionCost;
            if(itemInventory && !item.durability) {
                itemInventory.qtd += item.qtd;
                return;
            } else if(itemInventory) {
                itemInventory.qtd++;
                return;
            }
            state.mainPerson.Inventory.push(item);
            return;
        }
        alert("Você não possui dinheiro suficiente para produzir esse item");
    },
    useItem(state, item) {
        if(item.groupEquip == "potion") {
            if(item.status.hp !== undefined) {
                if((state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value) < item.status.hp) state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
                else state.mainPerson.infos[0].li[0].value += item.status.hp
            }
            if(item.status.mp !== undefined) {
                if((state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value) < item.status.mp) state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
                else state.mainPerson.infos[0].li[1].value += item.status.mp
            }
            if(item.status.energia !== undefined) {
                if((state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value) < item.status.energia) state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
                else state.mainPerson.infos[0].li[2].value += item.status.energia
            }
        }
        if(item.groupEquip == "food") {
            if(item.status.hp !== undefined) {
                if((state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value) < item.status.hp) state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
                else state.mainPerson.infos[0].li[0].value += item.status.hp
            }
            if(item.status.mp !== undefined) {
                if((state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value) < item.status.mp) state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
                else state.mainPerson.infos[0].li[1].value += item.status.mp
            }
            if(item.status.energia !== undefined) {
                if((state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value) < item.status.energia) state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
                else state.mainPerson.infos[0].li[2].value += item.status.energia
            }
        }
        let index = state.mainPerson.Inventory.indexOf(item);
        if(state.mainPerson.Inventory[index].qtd > 1) {
            state.mainPerson.Inventory[index].qtd -= 1;
        } else {
            state.mainPerson.Inventory.splice(index, 1);
        }
    },
    changeMoneyBank(state, method) {
        if(method.sinal == "soma") {
            if(state.mainPerson.gold.value < method.value) {
                alert("Você não tem dinheiro suficiente!");
            } else {
                state.mainPerson.gold.value -= method.value;
                state.mainPerson.gold.bank += method.value;
            }
        } else {
            if(state.mainPerson.gold.value < method.value) {
                alert("Você não tem dinheiro suficiente no banco!");
            } else {
                state.mainPerson.gold.value += method.value;
                state.mainPerson.gold.bank -= method.value;
            }
        }
    },
    changeReligion(state, religion) {
        state.mainPerson.religion.name = religion.name;
        state.mainPerson.religion.skill = religion.skill;
        state.mainPerson.religion.mainGod = religion.mainGod;
    },
    donationTemple(state, donation) {
        state.mainPerson.gold.value -= donation;
        state.mainPerson.religion.donation += donation;
    },
    purchaseProperty(state, property) {
        state.mainPerson.propertiesAcquired.push(property);
    },
    moreSpace(state, value) {
        state.mainPerson.inventorySize += value;
    },
    //Pode usar para remover propriedades ao vende-las no mercado
    removeProperty(state, property) {
        let index = state.mainPerson.propertiesAcquired.indexOf(property);
        state.mainPerson.propertiesAcquired.splice(index, 1);
    },
    addEquipReference(state, item) {
        state.mainPerson.referenceEquips.push(item._id);
    },
    removeEquipReference(state, item) {
        var index = state.mainPerson.referenceEquips.indexOf(item._id);
        state.mainPerson.referenceEquips.splice(index, 1);
    },
    equipItem(state, item) {
        const currentItem = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
        var indexInv, indexEquip;
        if(item.name != "") {
            indexInv = state.mainPerson.Inventory.indexOf(item);
            state.mainPerson.Inventory.splice(indexInv, 1);
        }
        
        if(currentItem.name != ""){
            state.mainPerson.Inventory.push(currentItem);
        }
        
        indexEquip = state.mainPerson.Equips.indexOf(currentItem);
        state.mainPerson.Equips.splice(indexEquip, 1);
        state.mainPerson.Equips.push(item);
    },
    referenceEquipItem(state, item) {
        const currentItem = state.mainPerson.Equips.find(i => i.groupEquip == item.groupEquip);
        if(currentItem){
            const index = state.mainPerson.Equips.indexOf(currentItem);
            state.mainPerson.Equips.splice(index, 1);
        }
        state.mainPerson.Equips.push(item);
    },
    changeAttr(state, listAttr) {
        let listIndex = 0;
        for(let i = 0; i < state.mainPerson.infos.length; i++) {
            for(let z = 0; z< state.mainPerson.infos[i].li.length; z++) {
                if(state.mainPerson.infos[i].li[z].maxValue != null) {
                    if(state.mainPerson.infos[i].li[z].value) state.mainPerson.infos[i].li[z].value += listAttr[listIndex];
                    state.mainPerson.infos[i].li[z].maxValue += listAttr[listIndex];
                    state.mainPerson.infos[i].li[z].base += listAttr[listIndex];
                    listIndex++;
                } else {
                    state.mainPerson.infos[i].li[z].base += listAttr[listIndex];
                    state.mainPerson.infos[i].li[z].value += listAttr[listIndex];
                    listIndex++;
                }
            }
        }
        if(state.mainPerson.weight) state.mainPerson.weight.base = 20 + Math.trunc(state.mainPerson.infos[1].li[1].value * 5);
        if(state.mainPerson.weight.maxValue != state.mainPerson.weight.base) state.mainPerson.weight.maxValue = state.mainPerson.weight.base;
        state.mainPerson.travelTime += listAttr[listIndex];
    },
    changeWeightValue(state, value) {
        if(state.mainPerson.weight.value + value > state.mainPerson.weight.maxValue) return false;
        state.mainPerson.weight.value += value;
    },
    changeWorkExp(state, value) {
        const workCurrent = state.mainPerson.class;
        workCurrent.level.exp.value += value;
        if(workCurrent.level.exp.value >= workCurrent.level.exp.maxValue) {
            while(workCurrent.level.exp.value >= workCurrent.level.exp.maxValue){
                workCurrent.level.exp.value = 0;
                workCurrent.level.value++;
                workCurrent.coin += workCurrent.base;
                workCurrent.exp += workCurrent.base;
                workCurrent.level.exp.maxValue = workCurrent.level.exp.maxValue * lvl[
                    Math.floor(workCurrent.level.value / 20)
                ]
            }
        }
    },
    changeCraftWorkExp(state, workObj) {
        const craftWorkCurrent = state.mainPerson.class;
        craftWorkCurrent.level.exp.value += workObj.value;
        if(craftWorkCurrent.level.exp.value >= craftWorkCurrent.level.exp.maxValue) {
            while(craftWorkCurrent.level.exp.value >= craftWorkCurrent.level.exp.maxValue){
                craftWorkCurrent.level.exp.value = 0;
                craftWorkCurrent.level.value++;
                craftWorkCurrent.points++;
                craftWorkCurrent.coin += craftWorkCurrent.base;
                craftWorkCurrent.exp += craftWorkCurrent.base;
                craftWorkCurrent.level.exp.maxValue = craftWorkCurrent.level.exp.maxValue * lvl[
                    Math.floor(craftWorkCurrent.level.value / 20)
                ]
            }
        }
    },
    useWorkScroll(state, scroll) {
        state.mainPerson.class = worksModel.find(i => i.type == scroll.licenseFor);
        const index = state.mainPerson.Inventory.indexOf(scroll);
        state.mainPerson.Inventory.splice(index, 1);
    },
    lvlWorkCreatePerson(state, value) {
        if(state.mainPerson.works[0].level.value + value < 2 && value < 0) {
            state.mainPerson.works[0].level.exp.maxValue = 100;
        } else if (state.mainPerson.works[0].level.value == 2 && value < 0) {
            state.mainPerson.works[0].level.exp.maxValue = 100 * ((state.mainPerson.works[0].level.value  + value) * 2);
        } else {
            state.mainPerson.works[0].level.exp.maxValue = 100 * ((state.mainPerson.works[0].level.value  + value - 1) * 2);
        }
        state.mainPerson.works[0].level.value += value;
        state.mainPerson.works[0].coin += 5 * value;
        state.mainPerson.works[0].exp += 5 * value;
    },
    changeRace(state, race) {
        state.mainPerson.race = race;
    },
    setNickname(state, name) {
        state.mainPerson.nickname = name;
    },
    seFamilyName(state, name) {
        state.mainPerson.familyName = name;
    },
    changeCharCurrent(state, char) {
        state.mainPerson = char;
    },
    changeHashPerson(state, hash) {
        state.mainPerson.identifier = hash;
    },
    changeValueHappiness(state, value) {
        state.mainPerson.happiness.value += value;
    },
    changeLevelHappiness(state, text) {
        state.mainPerson.happiness.level = text;
    },
    changeBoost(state, object) {
        for(var i = 0; i < state.mainPerson.infos.length; i++) {
            if(state.mainPerson.infos[i].li.find(i => i.title == object.title)) {
                var attr = state.mainPerson.infos[i].li.find(i => i.title == object.title);
                if(attr.boost.find(i => i.group == i.group)) {
                    let boostLocation = attr.boost.find(i => i.group == i.group);
                    if(attr.maxValue == null) attr.value = attr.base - attr.base * boostLocation;
                    else attr.maxValue = attr.base - attr.base * attr.boost;
                    let boostIndex = attr.boost.indexOf(boostLocation);
                    attr.boost.splice(boostIndex, 1);
                }
                let newObject = {group: object.group, value: object.value};
                attr.boost.push(newObject);
                let newBoost = 0;
                for(let f = 0; f < attr.boost.length; f++) {newBoost += attr.boost[f].value;}
                if(attr.maxValue == null) attr.value = attr.base + attr.base * newBoost;
                else attr.maxValue = attr.base + attr.base * newBoost;
                return;
            }
        }
    },
    changeLocationNow(state, place){
        state.mainPerson.currentLocation.place = place;
    },
    changeBoostWorks(state, tool) {
        if(state.mainPerson.class) {
            if(state.mainPerson.class.type == tool.workTool) {
                var work = state.mainPerson.class;
                if(work) {
                    work.boost += tool.boost;
                }
            }
        }
    },
    changeBoostMagicPaths(state, item) {
        if(state.mainPerson.magicPaths) {
            var newPathMagic = state.mainPerson.magicPaths.find(i => i.name == item.path);
            if(newPathMagic) {
                switch(newPathMagic.name) {
                    case "Magia do Fogo Eterno":
                        newPathMagic.boost += !item.status.powerOfPath ? 0 : item.status.powerOfPath;
                        break;
                    case "Caminhos obscuros dos Daemisses":
                        newPathMagic.boost += !item.status.powerOfPath ? 0 : item.status.powerOfPath;
                        break;
                    case "Caminhos do Gigante Jigorat":
                        newPathMagic.boost += !item.status.powerOfPath ? 0 : item.status.powerOfPath;
                        break;
                    case "Sabedoria dos Guerreiros Lufienes":
                        newPathMagic.boost += !item.status.powerOfPath ? 0 : item.status.powerOfPath;
                        break;
                    case "O Vazio":
                        newPathMagic.boost += !item.status.powerOfPath ? 0 : item.status.powerOfPath;
                        break;
                }
            }
        }
    },
    addMagicPath(state, path) {
        state.mainPerson.magicPaths.push(path);
    },
    useMagicPathScroll(state, scroll) {
        var inventoryScroll = state.mainPerson.Inventory.find(i => i.name == scroll.name);
        if(inventoryScroll.qtd > 1) {
            inventoryScroll.qtd -= 1;
        } else {
            const index = state.mainPerson.Inventory.indexOf(scroll);
            state.mainPerson.Inventory.splice(index, 1);
        }
    },
    addSpell(state, spell) {
        state.mainPerson.spells.push(spell);
    },
    addProperty(state, property) {
        delete property.qtd;
        property.buildingArea = 0;
        state.mainPerson.propertiesAcquired.push(property);
    },
    useMaterials(state, material) {
        var inventoryMaterial = state.mainPerson.Inventory.find(i => i.name == material.name);
        if(inventoryMaterial.qtd > material.value) {
            inventoryMaterial.qtd -= material.value;
        } else {
            const index = state.mainPerson.Inventory.indexOf(inventoryMaterial);
            state.mainPerson.Inventory.splice(index, 1);
        }
    },
    addConstruction(state, object) {
        var landSelected = state.mainPerson.propertiesAcquired.find(i => i._id == object.land._id);
        landSelected.constructions.push(object.building);
        landSelected.buildingArea += object.building.size.width * object.building.size.height;
        landSelected.inventorySize += object.building.addInventorySize;
        state.mainPerson.inventorySize += object.building.addInventorySize;
    },
    addRecipe(state, recipe) {
        state.mainPerson.recipes.push(recipe);
    },
    changeWorkPoints(state, object) {
        const workSearchPoints = state.mainPerson.class;
        workSearchPoints.points -= object.value;
    },
    addSkillPerson(state, skill) {
        if(skill.description.effect) var skillsStatus = Object.keys(skill.description.effect);
        if(skill.description.add) var descAddMaterials = skill.description.add;
        var newSkill = {name: skill.work};
        if(state.mainPerson.skills) {
            if(state.mainPerson.skills.names) {
                state.mainPerson.skills.names.push(skill.name);
            } else {
                state.mainPerson.skills.names = [];
                state.mainPerson.skills.names.push(skill.name);
            }
        } else {
            state.mainPerson.skills = {};
            state.mainPerson.skills.names = [];
            state.mainPerson.skills.names.push(skill.name);
        }
        if(state.mainPerson.skills.attr) {
            var workSkill = state.mainPerson.skills.attr.find(i => i.name === skill.work);
            if(workSkill) {
                if(skillsStatus) {
                    skillsStatus.forEach(el => {
                        if(workSkill.status[skillsStatus[el]]) {
                            workSkill.status[el] += skill.description.effect[el];
                            return;
                        }
                        workSkill.status[el] = skill.description.effect[el];
                        return;
                    })
                } else if(skill.description.add) {
                    descAddMaterials.forEach(material => {
                        workSkill.add.push(material);
                    });
                }
                return;
            }
            newSkill.status = {};
            newSkill.add = [];
            state.mainPerson.skills.attr.push(newSkill);
            if(skillsStatus && skillsStatus !== undefined) {
                skillsStatus.forEach(el => {
                    newSkill.status[el] = skill.description.effect[el];
                });
            }
            if(descAddMaterials && descAddMaterials !== undefined) {
                descAddMaterials.forEach(material => {
                    newSkill.add.push(material);
                });
            }
            state.mainPerson.skills.attr.push(newSkill);
            return;
        }
        newSkill.status = {};
        skillsStatus.forEach(el => {
            newSkill.status[el] = skill.description.effect[el];
        });
        state.mainPerson.skills.attr = [];
        state.mainPerson.skills.attr.push(newSkill);
    },
    addTimer(state, action) {
        state.mainPerson.timer.push(action);
    },
    deleteTimer(state, action) {
        state.mainPerson.timer.splice(action, 1);
    },
    addHotkey(state, item) {
        if(!state.mainPerson.hotkeys) state.mainPerson.hotkeys = [];
        if(state.mainPerson.hotkeys[parseInt(item.hotkey)]) state.mainPerson.Inventory.push(state.mainPerson.hotkeys[parseInt(item.hotkey)]);
        state.mainPerson.hotkeys[parseInt(item.hotkey)] = item;
    },
    useHotkey(state, hotkey) {
        var consumivelItem = (item) => {
            if(item.groupEquip == "potion") {
                if(item.status.hp !== undefined) {
                    if((state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value) < item.status.hp) state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
                    else state.mainPerson.infos[0].li[0].value += item.status.hp
                }
                if(item.status.mp !== undefined) {
                    if((state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value) < item.status.mp) state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
                    else state.mainPerson.infos[0].li[1].value += item.status.mp
                }
                if(item.status.energia !== undefined) {
                    if((state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value) < item.status.energia) state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
                    else state.mainPerson.infos[0].li[2].value += item.status.energia
                }
            }
            if(item.groupEquip == "food") {
                if(item.status.hp !== undefined) {
                    if((state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value) < item.status.hp) state.mainPerson.infos[0].li[0].value += state.mainPerson.infos[0].li[0].maxValue - state.mainPerson.infos[0].li[0].value
                    else state.mainPerson.infos[0].li[0].value += item.status.hp
                }
                if(item.status.mp !== undefined) {
                    if((state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value) < item.status.mp) state.mainPerson.infos[0].li[1].value += state.mainPerson.infos[0].li[1].maxValue - state.mainPerson.infos[0].li[1].value
                    else state.mainPerson.infos[0].li[1].value += item.status.mp
                }
                if(item.status.energia !== undefined) {
                    if((state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value) < item.status.energia) state.mainPerson.infos[0].li[2].value += state.mainPerson.infos[0].li[2].maxValue - state.mainPerson.infos[0].li[2].value
                    else state.mainPerson.infos[0].li[2].value += item.status.energia
                }
            }
        }
        if(state.mainPerson.hotkeys[hotkey].qtd > 1) {
            state.mainPerson.hotkeys[hotkey].qtd--;
            consumivelItem(state.mainPerson.hotkeys[hotkey]);
            return;
        }
        state.mainPerson.hotkeys[hotkey] = null;
        consumivelItem(state.mainPerson.hotkeys[hotkey]);
    },
    addTemporaryDamage(state, damage) {
        state.mainPerson.infos[1].li[0].value = damage;
        var interval = setInterval(() => {
            state.mainPerson.infos[1].li[0].value = state.mainPerson.infos[1].li[0].base;
            clearInterval(interval);
        }, 900);
    },
    changeAvatar(state, avatar) {
        state.mainPerson.race = {
            "idRace": 9,
            "nameRace": avatar.item.race,
            "url": avatar.item.urlInternal
        }
        if(!state.mainPerson.nfts) state.mainPerson.nfts = [];
        state.mainPerson.nfts.push(avatar.URIToken);
    },
    addNFTURI(state, uri) {
        if(!state.mainPerson.nfts) state.mainPerson.nfts = [];
        state.mainPerson.nfts.push(uri);
    },
    changeSkin(state, skin) {
        state.mainPerson.sprite = skin;
    },
    addItemNFTInventory(state, item) {
        item.qtd = 1;
        state.mainPerson.Inventory.push(item);
    },
    addNFTArray(state, uri) {
        if(!state.mainPerson.nfts) state.mainPerson.nfts = [];
        state.mainPerson.nfts.push(uri);
    },
    changeMoney(state, money) {
        state.mainPerson.gold.value += money;
    },
    addDonationAdventureGuild(state, value) {
        state.mainPerson.adventureGuild.donations += value;
    },
    addQuest(state, quest) {
        state.mainPerson.adventureGuild.quests.push(quest);
    },
    enterAdventureGuild(state) {
        state.mainPerson.adventureGuild.level.letter = 0;
    },
    removeQuest(state, quest) {
        var questIsRemove = state.mainPerson.adventureGuild.quests.find(i => i.id === quest.id);
        var index = state.mainPerson.adventureGuild.quests.indexOf(questIsRemove);
        state.mainPerson.adventureGuild.quests.splice(index, 1);
    },
    addMonsterStatistics(state, monster) {
        var isMonster = state.mainPerson.statistics.monsterDead.find(i => i.name === monster);
        if(!isMonster) {
            var monsterObject = {
                name: monster,
                value: 1
            }
            state.mainPerson.statistics.monsterDead.push(monsterObject);
            return;
        }
        isMonster.value++;
    },
    changeReputationAdventureGuild(state, reputation) {
        state.mainPerson.adventureGuild.level.exp.value += reputation;
        if(state.mainPerson.adventureGuild.level.exp.value >= state.mainPerson.adventureGuild.level.exp.maxValue) {
            while(state.mainPerson.adventureGuild.level.exp.value >= state.mainPerson.adventureGuild.level.exp.maxValue){
                state.mainPerson.adventureGuild.level.letter++;
                state.mainPerson.adventureGuild.level.exp.value = 0;
                state.mainPerson.adventureGuild.level.exp.maxValue = state.mainPerson.adventureGuild.level.exp.maxValue * 8;
            }
        }
    },
    changeMarketBank(state, value) {
        if(!state.mainPerson.marketBank) state.marketBank = 0;
        state.mainPerson.marketBank = value;
    }
};

export default mutations;