export default function warriorClassic(vm) {
    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('warriorJump', {start: 0, end: 7}),
        frameRate: 15,
        repeat: 2
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('warriorAttack1', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('warriorAttack2', {start: 0, end: 3}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('warriorAttack3', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('warriorIdle', {start: 0, end: 3}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('warriorWalk', {start: 0, end: 4}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('warriorDead', {start: 0, end: 6}),
        frameRate: 5,
        repeat: 0
    })
}