const npc = {
    createNpc(vm, name, sprite, anime, scale, pos, flipX, haveShop) {
        var newNpc = vm.npcs.create(pos.x, pos.y, sprite);
        newNpc.setCollideWorldBounds(true).setScale(scale.x, scale.y).play(anime, true).setFlipX(flipX);
        
        vm.physics.add.overlap(vm.player, newNpc, function() {
            if(haveShop) {
                if(vm.interage != name) vm.interage = name;
            }
        });
    }
}

export default npc;