import Vue from 'vue'
import App from './App.vue'
import router from './router'

//Stores
import store from './core/store/store'

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
