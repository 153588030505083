import archer from "./archer";
import warriorClassic from "./warriorClassic";
import swordman from "./swordman";
import mage from "./mage";
import spearman from "./spearman";
import altmenerArcher from "./altmenerArcher";
import nordicBarbarian from "./nordicBarbarian";
import bloodHit from "./bloodHit";

function animationsMain(vm, sprite) {
    switch(sprite) {
        case "archer":
            return archer(vm);
        case "warriorClassic":
            return warriorClassic(vm);
        case "swordman":
            return swordman(vm);
        case "spearman":
            return spearman(vm);
        case "mage":
            return mage(vm);
        case "altmenerArcher":
            return altmenerArcher(vm);
        case "nordicBarbarian":
            return nordicBarbarian(vm);
        case "bloodHit":
            return bloodHit(vm);
    }
}

export default animationsMain;