import warriorClassic from "./warrior/warriorClassic";
import swordman from "./swordman/swordman";
import spearman from "./spearman/spearman";
import mage from "./mage/mage";
import archer from "./archer/archer";
import altmenerArcher from "./archer/altmenerArcher";
import nordicBarbarian from "./swordman/nordicBarbarian";
import bloodHit from "./swordman/bloodHit";

function spritesMain(vm, sprite) {
    switch(sprite) {
        case "archer":
            return archer(vm);
        case "warriorClassic":
            return warriorClassic(vm);
        case "swordman":
            return swordman(vm);
        case "spearman":
            return spearman(vm);
        case "mage":
            return mage(vm);
        case "altmenerArcher":
            return altmenerArcher(vm);
        case "nordicBarbarian":
            return nordicBarbarian(vm);
        case "bloodHit":
            return bloodHit(vm);
    }
}

export default spritesMain;