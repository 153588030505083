import AppHeader from './shared/components/header/Header';
import AppFooter from './shared/components/footer/Footer';
import Sidebar from './shared/components/sidebar/Sidebar';

import LoadingComponent from './shared/loading/Loading.vue';
import ComponentQuests from './shared/components/quests/Quests.vue';

import ComponentDeadView from './shared/components/deadView/DeadView.vue';

import AlertComponent from './shared/components/alert/Alert.vue';

import _cookieHelper from './core/helpers/CookieHelper';

import SaveCharService from './core/services/saveCharService';

import axios from "axios";

import DialogComponent from "@/shared/components/dialog/Dialog.vue";

// import Store from "./core/store/store.js";

export default {
    name: 'App',
    components: {
        AppHeader,
        AppFooter,
        Sidebar,
        AlertComponent,
        DialogComponent,
        ComponentDeadView,
        LoadingComponent,
        ComponentQuests
    },
    data() {
        return {
            timeActive: true,
        }
    },
    computed: {
        mainPerson() {
            return this.$store.state.personModule.mainPerson;
        },
        lvlUpAnimation() {
            return this.$store.state.personModule.lvlUpAnimation;
        },
        saveCharTime() {
            return this.$store.state.generalModule.saveCharTime;
        },
        alertsMessages() {
            return this.$store.state.generalModule.alertsMessages;
        },
        mapCurrent() {
            return this.$store.state.mapModule.currentLocation;
        },
        dialogs() {
            return this.$store.state.generalModule.dialog;
        },
        questPanel() {
            return this.$store.state.generalModule.questPanel;
        }
    },
    methods: {
        refreshTime() {
            this.$store.commit("refreshTime");
        },
        changeTimeActive(boolean) {
            this.timeActive = boolean;
        }
    },
    created(){
        var hash = _cookieHelper.getCookie("lgTkn");
        if(hash) {
            this.$store.commit("changeIsEnabledLoading", true);
            axios.get(
                this.$store.state.generalModule.apiUrl + "/login/" + hash
            ).then((res) => {
                if(res.data.length == 0) {
                    this.$store.commit("changeIsEnabledLoading", false);
                    return alert("Nenhum usuário encontrado!");
                } else {
                    _cookieHelper.setCookie("lgTkn", res.data[0].identifier, 1);
                    axios.get(
                        this.$store.state.generalModule.apiUrl + "/searchChar/" + hash
                    ).then((res) => {
                        if(res.data.length == 0) {
                            this.$router.push('/createperson');
                            this.$store.commit("changeCurrentLocation", this.$store.state.personModule.mainPerson.currentLocation);
                            this.$store.commit("changeIsEnabledLoading", false);
                        } else {
                            this.$store.commit("changeCharCurrent", res.data[0]);
                            this.$store.commit("changeCurrentLocation", this.$store.state.personModule.mainPerson.currentLocation);
                            this.$store.commit("changeCreatePerson", false);
                            axios.get(
                                "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getItems"
                            ).then((res) => {
                                this.$store.state.itemsModule.items = res.data;
                                this.$router.push('/World');
                                this.$store.commit("changeIsEnabledLoading", false);
                            }).catch(error => {
                                console.log(error.message);
                            });
                        }
                    }).catch(error => {
                        console.log(error.message);
                    });
                }
            }).catch(error => {
                console.log(error.message);
            });
        } else {
            this.$router.push('/');
            this.$store.commit("changeIsEnabledLoading", false);
        }
    },
    mounted() {
        // const storeAcess = Store._modules.root._rawModule.modules;
        // const generalStore = storeAcess.generalModule;
        const vm = this;
        
        var language = localStorage.getItem("language");

        if(language) this.$store.commit("changeLanguage", language);

        this.$store.watch(
          (state)=>{
            return state.personModule.mainPerson;
          },
          (newVal) => {
            if(vm.timeActive) {
              vm.changeTimeActive(false);
              console.log("Salvou!");
              SaveCharService.methods.putSaveChar(newVal);
              var saveTimeOut = setTimeout(() => {
                vm.changeTimeActive(true);
                clearTimeout(saveTimeOut);
              }, 30000);
            }
          },
          {deep:true}
        )

        this.refreshTime();
        setInterval(() => {
            this.refreshTime();
        }, 30000);

        window.addEventListener('keydown', function (e) {
            var code = e.which || e.keyCode;
            switch (code) {
              case 116 :  
                 e.preventDefault();
                 e.keyCode = 0;           
                 return false;             
               case 82 : 
                if (e.ctrlKey) {
                    e.preventDefault();
                    e.keyCode = 0;             
                    return false;
                }
            }
            // fazer algo aqui para quando a tecla F5 for premida
        });
    }
}