import mutations from "./mutations";
import actions from "./actions";

// Mocks
import abisMock from "@/shared/nftInteract/mocks/abis";
import contractsMock from "@/shared/nftInteract/mocks/contracts";

export default {
    state: {
        addressBUSD: "",
        nameContract: "ArmorBoxContract",
        addressContract: contractsMock.ArmorBoxContract,
        contractABI: abisMock.ArmorBoxContract,
        supply: 0,
        address: "",
        isConnected: false,
        whiteList: [],
        loading: false,
        nfts: {
            uri: [],
            newURI: [],
            minted: []
        },
        importNFTsView: false,
        typeContract: 1
    },
    mutations,
    actions
}