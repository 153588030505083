export default {
    setScale: {
        x: 2.5,
        y: 3
    },
    setSize: {
        x: 20,
        y: 32
    },
    offset: {
        y: 10
    }
}