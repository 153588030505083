import { mapGetters } from "vuex"

export default {
    props: ['item'],
    computed: {
        language() {
            return this.$store.state.generalModule.currentLanguage;
        },
        ...mapGetters(['translateWords'])
    }
}