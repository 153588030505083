const normalDialog = {
    steps: [
        {
            number: 1,
            message: "Fale logo o que quer, que te dou o que precisa",
            options: [
                {
                    message: "Vim barganhar",
                    type: "default",
                    goStep: 2,
                    function: ""
                },
                {
                    message: "Preciso de equipamento novo, disseram que você é o cara para me ajudar nisso",
                    type: "default",
                    goStep: 3,
                    function: ""
                },
                {
                    message: "Só queria puxar papo mesmo, disseram que você é uma pessoa legal",
                    type: "default",
                    goStep: 3,
                    function: ""
                },
                {
                    message: "Nada não, valeu",
                    type: "default",
                    goStep: 10,
                    function: ""
                }
            ]
        },
        {
            number: 2,
            message: "Então veio ao lugar errado, aqui não tem descontos, ou respeita meus preços, ou cai fora",
            options: [
                {
                    message: "Me desculpe, não quis ofende-lo, só quero comprar equipamento",
                    type: "default",
                    goStep: 6,
                    function: ""
                },
                {
                    message: "Ih, já to vendo que é papo chato, tchau",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 3,
            message: "Não estou com paciência para conversar, preciso trabalhar e colocar comida na mesa",
            options: [
                {
                    message: "Desculpe, só queria puxar papo, quero comprar equipamento",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Desculpe, vou indo embora",
                    type: "default",
                    goStep: 10
                },
                {
                    message: "Você é grosso em, vai se ferrar",
                    type: "default",
                    goStep: 4
                }
            ]
        },
        {
            number: 4,
            message: "Você não aguenta 10 minutos de porrada comigo, vai encarar?",
            options: [
                {
                    message: "Que isso professor, to tranquilo",
                    type: "default",
                    goStep: 6,
                    function: ""
                },
                {
                    message: "Tem sorte que sou da paz",
                    type: "default",
                    goStep: 5
                }
            ]
        },
        {
            number: 5,
            message: "Vai comprar ou não?",
            options: [
                {
                    message: "Vou sim, mostre-me o que tem",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Depois de tudo isso, to fora",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 6,
            message: "Tudo bem, desculpe, ando estressado ultimamente",
            options: [
                {
                    message: "Sem problemas, podemos voltar aos negócios?",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Ih, já to vendo que é papo chato, tchau",
                    type: "default",
                    goStep: 9
                }
            ]
        },
        {
            number: 9,
            message: "Tudo bem então, obrigado e volte... Sempre?",
            options: [
                {
                    message: "Tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        },
        {
            number: 10,
            message: "Saia daqui, por favor, tenho muito trabalho para fazer",
            options: [
                {
                    message: "Tudo bem, tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        }
    ]
}

export default normalDialog;