export default {
    state: {
        pathMagic: [
            {
                id: 0,
                name: "Magia do Fogo Eterno",
                description: "Criado pelos Nisirianos e melhorado pelos Altmeners, baseado em controle da energia continda no universo, controle temperaturas, gere fogo e bolas de plasma, quem sabe algo NUCLEAR?",
                level: 0,
                exp: {
                    value: 0,
                    maxValue: 250,
                    base: 250
                },
                boost: 0,
                color: "red"
            },
            {
                id: 1,
                name: "Caminhos obscuros dos Daemisses",
                description: "O caminho demoniaco, desenvolvido por criaturas além da fronteira cósmica, tudo pelo qual sempre lutamos contra, mas bem, a escolha é sua, só peço que pelo menos, não use esse caminho para o propósito no qual ele foi criada",
                level: 0,
                exp: {
                    value: 0,
                    maxValue: 300,
                    base: 300
                },
                boost: 0,
                color: "purple"
            },
            {
                id: 2,
                name: "Caminhos do Gigante Jigorat",
                description: "Convoque a natureza ao seu lado, controle o vivo, todo o vivo, assim como os Reptilianos, primeiro povo da terra e do mar, aprendeu",
                level: 0,
                exp: {
                    value: 0,
                    maxValue: 400,
                    base: 400
                },
                boost: 0,
                color: "green"
            },
            {
                id: 3,
                name: "Sabedoria dos Guerreiros Lufienes",
                description: "O primeiro povo guerreiro, vindo de um dos maiores e mais antigo Altmor que já existiu, Kailolan e seus 1000 guerreiros Lufienes. Conheça a sabedoria levada por bilhões de anos, mas do conhecimento de poucos",
                level: 0,
                exp: {
                    value: 0,
                    maxValue: 500,
                    base: 500
                },
                boost: 0,
                color: "yellow"
            },
            {
                id: 4,
                name: "O Vazio",
                description: "O triste, gelado, vazio e esquecido, obtenha o conhecimento do mais profundo ponto da alma e tudo aquilo que a seita do Vazio pode te ensinar",
                level: 0,
                exp: {
                    value: 0,
                    maxValue: 600,
                    base: 600
                },
                boost: 0,
                color: "blue"
            }
        ],
        spells: [
            {
                name: "Infernal",
                spellName: "infernal",
                img: "skill icon 31.png",
                path: 0,
                damage: 80,
                mpCost: 35,
                xpUse: 10, //Exp ganho no caminho ao soltar a magia
                minPowerMagic: 30, //Min de poder mágico para ser usado
                target: "enemy",
                status: {}
            },
            {
                name: "Corrupção da mente",
                spellName: "corruption",
                img: "skill icon 10.png",
                path: 1,
                damage: 120,
                mpCost: 60,
                xpUse: 10,
                target: "enemy",
                status: {}
            }
        ]
    }
}