import BattleService from "@/core/services/BattleService"
import SaveCharService from "@/core/services/saveCharService"

export default {
    data() {
        return {
            diceBoolean: false,
            diceText: "",
            diceNumber: 0,
            eventRandomObject: {},
            result: ""
        }
    },
    computed: {
        eventNow() {
            return this.$store.state.randomEventsModule.eventNow;
        },
        randomEvent() {
            return this.$store.state.randomEventsModule.randomEventCurrent;
        }
    },
    methods: {
        rollDice() {
            this.diceBoolean = true;
            this.diceText = "Rolando os dados...";
            var delay = setTimeout(() => {
                this.diceText = "Análisando resultado...";
                clearTimeout(delay);
            }, 2000);
            var delay2 = setTimeout(() => {
                this.diceText = "Seu número foi:";
                this.diceNumber = BattleService.methods.getRandomNumber(1,21);
                clearTimeout(delay2);
            }, 4000);
        },
        writerMachineEffect(id) {
            var i = 0;
            var tag = document.getElementById(id);
            var html = document.getElementById(id).innerHTML;
            var speed = 30;
        
            function typeWriter() {
                if (i <= html.length) {
                    tag.innerHTML = html.slice(0 , i + 1);
                    i++;
                    setTimeout(typeWriter, speed);
                }
            }
            typeWriter();
        },
        conclusionResult(result) {
            if(result.type !== "") {
                switch(result.type) {
                    case "thief":
                        var randomItem = BattleService.methods.getRandomNumber(0,this.$store.state.personModule.mainPerson.Inventory.length);
                        var newItem = {
                            item: this.$store.state.personModule.mainPerson.Inventory[randomItem],
                            quantidade: this.$store.state.personModule.mainPerson.Inventory[randomItem].qtd
                        };
                        this.$store.commit("deleteItemInventory", newItem);
                        this.$store.dispatch("levelHappiness", -150);
                        break;
                    case "hp":
                        var damage = {
                            sinal: "subtrair",
                            value: this.$store.state.personModule.mainPerson.infos[0].li[0].value / 100 * result.number * - 1
                        }
                        this.$store.dispatch("levelHappiness", -150);
                        this.$store.commit("calculeHPCurrent", damage);
                        break;
                    case "Energia":
                        var tiredness = {
                            sinal: "soma",
                            value: this.$store.state.personModule.mainPerson.infos[0].li[2].value / 100 * result.number * - 1
                        }
                        this.$store.commit("calculeEnergyCurrent", tiredness);
                        break;
                    case "dead":
                        this.$store.commit("deadMain");
                        SaveCharService.methods.putSaveChar(this.$store.state.personModule.mainPerson);
                        this.$store.commit("addAlertMessage", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
                        this.$store.commit("toogleSaveCharTime", false);
                        this.$store.dispatch("levelHappiness", -250);
                        var intervalCloseSaveAlert = setInterval(() => {
                            this.$store.commit("deleteAlert", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
                            clearInterval(intervalCloseSaveAlert);
                        }, 5000);
                        break;
                    default:
                        break;
                }
            }
        },
        activeEvent(choice) {
            var groupAction, response, infoIndex, typeResponse;
            var personInfos = this.$store.state.personModule.mainPerson.infos;
            this.eventRandomObject = this.$store.state.randomEventsModule.randomEventCurrent;
            this.rollDice();
            typeResponse = BattleService.methods.getRandomNumber(0,2);
            var delayResult = setTimeout(() => {
                groupAction = this.eventRandomObject.tree.find(i => i.type === choice);
                if(groupAction.changes) {
                    response = groupAction.changes.find(i => i.numbers >= this.diceNumber);
                }
                personInfos.forEach(function(group) {
                    var searchInfo = group.li.find(i => i.title === groupAction.status);
                    if(searchInfo) {infoIndex = searchInfo}
                })
                if(response) {
                    if(infoIndex.value >= response.minNeed) {
                        if(typeResponse < 1) {
                            this.result = response.text.success.text;
                            this.conclusionResult(response.text.success.result);
                        } else {
                            this.result = response.text.successAlternative.text;
                            this.conclusionResult(response.text.successAlternative.result);
                        }
                        clearTimeout(delayResult);
                        return;
                    }
                    if(typeResponse < 1) {
                        this.result = response.text.failed.text;
                        this.conclusionResult(response.text.failed.result);
                    } else {
                        this.result = response.text.failedAlternative.text;
                        this.conclusionResult(response.text.failedAlternative.result);
                    }
                    clearTimeout(delayResult);
                    return;
                }
                this.result = groupAction.text;
                this.conclusionResult(groupAction.result);
                clearTimeout(delayResult);
            }, 4500)
        },
        close() {
            this.$store.commit("changeIsExplorer", false);
            this.$store.commit("changeEventNow", false);
        }
    },
    updated() {
        this.writerMachineEffect("ballon");
    }
}