import { render, staticRenderFns } from "./Skills.vue?vue&type=template&id=801d86f0&"
import script from "./Component-Skills.js?vue&type=script&lang=js&"
export * from "./Component-Skills.js?vue&type=script&lang=js&"
import style0 from "./Skills.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports