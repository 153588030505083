export default {
    computed: {
        alertsMessages() {
            return this.$store.state.generalModule.alertsMessages;
        }
    },
    methods: {
        close(alert) {
            this.$store.commit("deleteAlert", alert);
            // e.target.classList.remove("active");
        }
    }
}