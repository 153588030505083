export default {
    setScale: {
        x: 2,
        y: 2.5
    },
    setSize: {
        x: 28,
        y: 38
    },
    offset: {
        y: 32
    }
}