import BuyItemsComponent from "@/pages/market/components/buyItems/BuyItems.vue";
import SellItemsComponent from "@/pages/market/components/sellItems/SellItems.vue";
import TradeItemsComponent from "@/pages/market/components/tradeItems/TradeItems.vue";

import itemsCraftMock from "@/pages/market/mocks/itemsHerbalist"

export default {
    data() {
        return {
            colapse: "craft",
            itemsCraft: itemsCraftMock
        }
    },
    components: {
        BuyItemsComponent,
        TradeItemsComponent,
        SellItemsComponent
    },
    computed: {
        buyItems() {
            return this.$store.state.itemsModule.items.filter(i => i.seller == "alchemist" && i.rarity == "normal");
        },
        sellItems() {
            return this.$store.state.personModule.mainPerson.Inventory.filter(i => i.seller == "alchemist");
        },
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        }
    },
    methods: {
        toogleBuy(name) {
            this.colapse = name;
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        }
    }
}