import { mapGetters } from "vuex";

//Components
import Statusblock from "./components/StatusBlock.vue";
import Equip from "@/pages/home/components/equip/Equip.vue";
import Inventory from "@/pages/home/components/inventory/Inventory.vue";
import Attributes from "@/pages/home/components/attributes/Attributes.vue";

import addHotkeysComponent from "./components/hotkeys/Hotkey.vue";

export default {
    name: 'Sidebar',
    data() {
        return {
            count: 0,
            toggle: false,
            component: ""
        }
    },
    computed: {
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        equips () {
            return this.$store.state.personModule.mainPerson.Equips;
        },
        personInfos() {
            return this.$store.state.personModule.mainPerson.infos;
        },
        createPersonBoolean() {
            return this.$store.state.generalModule.createPerson;
        },
        happiness() {
            return this.$store.state.personModule.mainPerson.happiness;
        },
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        sidebarToggle() {
            return this.$store.state.generalModule.sidebarMobile;
        },
        InventorySpace() {
            return this.$store.state.personModule.mainPerson.inventorySize;
        },
        hotkeys() {
            return this.$store.state.personModule.mainPerson.hotkeys;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        sidebarToggleMethod() {
            if(this.$store.state.generalModule.sidebarMobile === "disable") this.toggle = false;
            this.toggle = !this.toggle;
            if(this.toggle) this.$store.commit("toogleSidebar", 'active');
            else  this.$store.commit("toogleSidebar", 'disable');
        },
        closeSidebar() {
            this.$store.commit("toogleSidebar", 'disable');
        },
        sidebarComponentShow(component) {
            if(this.component !== component) {
                this.component = component;
                if(!this.toggle || this.$store.state.generalModule.sidebarMobile === "disable") this.sidebarToggleMethod();
                return;
            }
            this.component = component;
            this.sidebarToggleMethod();
        },
        actions(action) {
            switch(action) {
                case 'sitFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue / 5)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue / 5);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 20)});
                    this.$store.dispatch("levelHappiness", -5);
                    break;
                case 'restFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue / 2)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue / 2);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 10)});
                    this.$store.dispatch("levelHappiness", -8);
                    break;
                case 'sleepFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 5)});
                    this.$store.dispatch("levelHappiness", -15);
                    break;
            }
        }
    },
    components: {
        Statusblock,
        Inventory,
        Equip,
        Attributes,
        addHotkeysComponent
    },
    mounted() {      
        const main = document.querySelector("main");
        main.addEventListener("click", (event) => {
            var sidebar = document.getElementById('sidebar');
            // var hotkeysBlock = document.getElementById('hotkeysBlock');
            var targetEl = event.target.closest("#sidebar")
            if (event.target != sidebar
                && !targetEl) {
                this.closeSidebar();
            }
        });
        this.$store.commit("toogleSidebar", "disable");
    }
}