export default {
    data() {
        return {
            key: 0
        }
    },
    props: ["item"],
    methods: {
        addHotkey(item) {
            item.hotkey = this.key;
            this.$store.commit("addHotkey", item);
            this.$store.commit("deleteItemInventory", {item: item, quantidade: item.qtd});
            this.$forceUpdate();
        }
    }
}