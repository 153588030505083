import Phaser from "phaser";

import LootService from "@/core/services/LootService";
import BattleService from "@/core/services/BattleService";

import HealthBar from "../../legacy-code/healthBar";

import Store from "@/core/store/store"

import traps from "@/shared/mocks/traps";

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusGeneral = storeAcess.generalModule;
const statusClass = storeAcess.classWorkModule;
const statusMonster = storeAcess.monstersModule;
const statusBosses = storeAcess.bossesModule;

export const methodsDungeon = {
    newMineral(vm, mineral, pos, sprite) {
        var item;
        switch(mineral) {
            case "cooper":
                var cooper = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
                cooper.body.moves = false;
                item = {
                    work: "miner",
                    name: "cobre bruto",
                    porcent: 85,
                    qtd: [1, 2]
                }
                vm.physics.add.overlap(vm.player, cooper, function(player, mineral) {
                    if(vm.interage != "mineral") {
                        vm.interage = "mineral";
                        vm.worked = {item, time: 45, message: "Minerando cobre", mineral};
                    }
                });
                break;
            case "tin":
                var tin = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
                tin.body.moves = false;
                item = {
                    work: "miner",
                    name: "estanho bruto",
                    porcent: 80,
                    qtd: [1, 1]
                }
                vm.physics.add.overlap(vm.player, tin, function(player, mineral) {
                    if(vm.interage != "mineral") {
                        vm.interage = "mineral";
                    }
                    vm.worked = {item, time: 35, message: "Minerando estanho", mineral};
                });
                break;
            case "iron":
                var iron = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
                iron.body.moves = false;
                item = {
                    work: "miner",
                    name: "ferro bruto",
                    porcent: 55,
                    qtd: [1, 2]
                }
                vm.physics.add.overlap(vm.player, iron, function(player, mineral) {
                    if(vm.interage != "mineral") {
                        vm.interage = "mineral";
                    }
                    vm.worked = {item, time: 70, message: "Minerando ferro", mineral};
                });
                break;
            case "rock":
                var rock = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
                rock.body.moves = false;
                item = {
                    work: "miner",
                    name: "pedra bruta",
                    porcent: 95,
                    qtd: [1, 2]
                }
                vm.physics.add.overlap(vm.player, rock, function(player, mineral) {
                    if(vm.interage != "mineral") {
                        vm.interage = "mineral";
                    }
                    vm.worked = {item, time: 30, message: "Coletando pedra", mineral};
                });
                break;
        }
    },
    workCommom(vm, item, time, message, object) {
        if(!statusGeneral.state.occupied) {
            vm.player.body.moves = false;
            if(statusPerson.state.mainPerson.infos[0].li[2].value >= 2){
                vm.methods.timeForCompletedWork(time, message);
                const energyMethod = {method: "soma",value: 2}
                statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyMethod);
                var setinterval = setInterval(() => {
                    vm.player.play('turn', true)
                }, 1000)
                var timeout = setTimeout(() => {
                    object.destroy();
                    LootService.methods.lootGeneral(item);
                    vm.player.body.moves = true;
                    clearTimeout(timeout);
                    clearInterval(setinterval);
                }, time * 1000);
                return;
            }
            alert("Sem energia suficiente!");
            return;
        }
    },
    timeForCompletedWork(time, message) {
        if(!statusGeneral.state.occupied) {
            statusGeneral.mutations.changeOccupied(statusGeneral.state, true);
            statusClass.mutations.changeActionText(statusClass.state, message);
            statusClass.actions.timeForCompletedActionWork(Store, time);
        }
    },
    newTree(vm, tree, pos, sprite) {
        var item;
        switch(tree) {
            case "oak":
                var oak = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true);
                oak.body.moves = false;
                item = {
                    work: "woodcutting",
                    name: "tronco",
                    porcent: 100,
                    qtd: [1, 2]
                }
                vm.physics.add.overlap(vm.player, oak, function(player, tree) {
                    if(vm.interage != "tree") {
                        vm.interage = "tree";
                    }
                    vm.worked = {item, time: 60, message: "Cortando um carvalho", tree};
                });
                break;
            case "birch":
                var birch = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true);
                birch.body.moves = false;
                item = {
                    work: "woodcutting",
                    name: "tronco reforcado",
                    porcent: 100,
                    qtd: [1, 2]
                }
                vm.physics.add.overlap(vm.player, birch, function(player, tree) {
                    if(vm.interage != "tree") {
                        vm.interage = "tree";
                    }
                    vm.worked = {item, time: 90, message: "Cortando uma bétula", tree};
                });
                break;
        }
    },
    spawnTrap(vm, type, sprite, x, y, time) {
        var trap = vm.traps.create(x, y, sprite);
        var findTrap = traps.allTraps.find(i => i.name === type);

        trap.status = findTrap;
        trap.setScale(findTrap.scale);
        
        vm.physics.add.overlap(trap, vm.player, function() {
            var interval = setInterval(() => {
                var newDamage = BattleService.methods.attackMobCalcule(trap.status.damage);
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(newDamage);
                clearInterval(interval);
            }, 250);
        });

        vm.physics.add.collider(trap, vm.player, function() {
            var interval = setInterval(() => {
                var newDamage = BattleService.methods.attackMobCalcule(trap.status.damage);
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(newDamage);
                clearInterval(interval);
            }, 250);
        });

        var timeOut = setTimeout(() => {
            trap.destroy();
            clearTimeout(timeOut);
        }, time);
    },
    spawnBoss(vm, name, sprite, x, y, scale) {
        var boss = vm.boss.create(x, y, sprite);
        var findBoss = statusBosses.state.bosses.find(i => i.description = name);
        var bodyCollision = findBoss.status.collisions;

        statusBosses.mutations.changeBoss(statusBosses.state, findBoss);
        statusBosses.mutations.changeHpBossEnabled(statusBosses.state, true);

        boss.play(sprite, true);

        boss.setScale(scale.x, scale.y);
        boss.body.setSize(bodyCollision.size.x, bodyCollision.size.y, true);
        boss.body.offset.x = bodyCollision.offset.flipxFalse;
        boss.body.offset.y = bodyCollision.offset.y;

        boss.status = findBoss.status;
        boss.loots = findBoss.loots;
        boss.hp = findBoss.status.maxhp;
        boss.description = findBoss.description

        boss.isAttack = false;
        vm.physics.add.collider(boss, vm.platforms);
        // vm.physics.add.collider(boss, vm.player);
        if(vm.layer) {
            if(vm.layer.floor) vm.physics.add.collider(boss, vm.layer.floor);
        }

        vm.physics.add.overlap(vm.mainAtk, boss, function(rectangle, enemy) {
            rectangle.destroy();
            vm.sounds.hit.play({volume: 1});
            enemy.alpha = 0.8;
            enemy.setTint(0xff0000);
            var interval = setTimeout(() => {
                enemy.setTint(0xffffff);
                enemy.alpha = 1;
                vm.methods.getDamage(vm, enemy, findBoss.description, true);
                clearTimeout(interval);
            }, 250);
        });

        vm.physics.add.collider(vm.mainAtk, boss, function(rectangle, enemy) {
            rectangle.destroy();
            vm.sounds.hit.play({volume: 1});
            enemy.alpha = 0.8;
            enemy.setTint(0xff0000);
            var interval = setTimeout(() => {
                enemy.setTint(0xffffff);
                enemy.alpha = 1;
                vm.methods.getDamage(vm, enemy, findBoss.description, true); 
                clearTimeout(interval);
            }, 250);
        });

        vm.physics.add.overlap(vm.player, boss, function() {
            vm.player.y = vm.player.y - 150;
            var interval = setInterval(() => {
                var newDamage = BattleService.methods.attackMobCalcule(boss.status.atk);
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(newDamage);
                clearInterval(interval);
            }, 250);
        });
    },
    createKey(vm, key) {
        vm.key = vm.add.image(0, 0, key);
        vm.key.setAlpha(0).setDepth(15);
    },
    spawnEnemys(vm, name, sprite, anime, positions, scale) {
        vm.enemy[name] = vm.physics.add.group();
        var index = 0, enemySearch = vm.monsters.find(i => i.name === name);

        positions.forEach(pos => {
            var newEnemy = vm.enemy[name].create(pos.x, pos.y, sprite);

            newEnemy.class = name;
            newEnemy.identify = name + index;
            newEnemy.index = index;
            newEnemy.status = enemySearch.status;
            newEnemy.loots = enemySearch.loots;
            newEnemy.hp = newEnemy.status.maxhp;

            newEnemy.play(anime).setCollideWorldBounds(true).setScale(scale.x, scale.y);
            newEnemy.body.setSize(newEnemy.status.collisions.size.x, newEnemy.status.collisions.size.y, true);
            if(newEnemy.status.flip) newEnemy.body.offset.x = newEnemy.status.collisions.offset.flipxTrue;
            else newEnemy.body.offset.x = newEnemy.status.collisions.offset.flipxFalse;
            newEnemy.body.offset.y = newEnemy.status.collisions.offset.y;
            if(vm.lightPlayer) newEnemy.setPipeline('Light2D');
            if(enemySearch.need) newEnemy.need = enemySearch.need;

            // newEnemy.body.moves = false;

            newEnemy.isAttack = false;

            vm.physics.add.collider(newEnemy, vm.platforms);
            vm.physics.add.collider(newEnemy, vm.player);
            if(vm.layer) {
                if(vm.layer.floor) vm.physics.add.collider(newEnemy, vm.layer.floor);
            }

            vm.physics.add.overlap(vm.mainAtk, newEnemy, function(rectangle, enemy) {
                rectangle.destroy();
                vm.sounds.hit.play({volume: 1});
                enemy.alpha = 0.8;
                enemy.setTint(0xff0000);
                var interval = setTimeout(() => {
                    enemy.setTint(0xffffff);
                    enemy.alpha = 1;
                    vm.methods.getDamage(vm, enemy, name);
                    clearTimeout(interval);
                }, 250);
            });

            vm.physics.add.collider(vm.mainAtk, newEnemy, function(rectangle, enemy) {
                rectangle.destroy();
                vm.sounds.hit.play({volume: 1});
                enemy.alpha = 0.8;
                enemy.setTint(0xff0000);
                var interval = setTimeout(() => {
                    enemy.setTint(0xffffff);
                    enemy.alpha = 1;
                    vm.methods.getDamage(vm, enemy, name);
                    clearTimeout(interval);
                }, 250);
            });
            
            vm.physics.add.overlap(vm.mainAtkMoreDamage, newEnemy, function(rectangle, enemy) {
                if(rectangle.isDamage) {
                    vm.sounds.hit.play({volume: 1});
                    enemy.alpha = 0.8;
                    enemy.setTint(0xff0000);
                    var interval = setTimeout(() => {
                        enemy.setTint(0xffffff);
                        enemy.alpha = 1;
                        vm.methods.getDamage(vm, enemy, name, false, rectangle.damage);
                        clearTimeout(interval);
                    }, 250);
                }
            });
            
            vm.physics.add.collider(vm.mainAtkMoreDamage, newEnemy, function(rectangle, enemy) {
                if(rectangle.isDamage) {
                    vm.sounds.hit.play({volume: 1});
                    enemy.alpha = 0.8;
                    enemy.setTint(0xff0000);
                    var interval = setTimeout(() => {
                        enemy.setTint(0xffffff);
                        enemy.alpha = 1;
                        vm.methods.getDamage(vm, enemy, name, false, rectangle.damage);
                        clearTimeout(interval);
                    }, 250);
                }
            });

            vm.physics.add.overlap(vm.player, newEnemy, function() {
                vm.player.x = vm.player.x - 150;
                var interval = setInterval(() => {
                    var newDamage = BattleService.methods.attackMobCalcule(newEnemy.status.atk);
                    BattleService.methods.changeDurabilityArmor();
                    vm.methods.hurtPlayer(newDamage);
                    clearInterval(interval);
                }, 250);
            });

            vm.enemyArray.push(newEnemy);
        })
    },
    createEnemy(vm, name, sprite, anime, repeat, width, height, bodySize, offset, posY) {
        var newName = (Math.random() * (10000 - 1) + 1).toFixed(0), i;
        newName = newName.toString();
        if(vm.enemy[newName]) {
            for(i = 0; i < repeat; i++) {
                vm.methods.addEnemy(vm, newName, name, sprite, anime, width, height, bodySize, offset, posY, i);
            }
            return;
        }
        vm.enemy[newName] = vm.physics.add.group();

        for(i = 0; i < repeat; i++) {
            vm.methods.addEnemy(vm, newName, name, sprite, anime, width, height, bodySize, offset, posY, i);
        }
    },
    addEnemy(vm, name, monster,sprite, anime, width, height, bodySize, offset, posY, index) {
        var x = Phaser.Math.Between(550, vm.mapSize.x - 300);
        var enemySearch = vm.monsters.find(i => i.name === monster);

        var newEnemy = vm.enemy[name].create(x, posY, sprite);
        newEnemy.play(anime).setCollideWorldBounds(true).setScale(width, height);
        newEnemy.body.setSize(bodySize.bodyX, bodySize.bodyY, true);
        newEnemy.body.offset.x = offset.x;
        newEnemy.body.offset.y = offset.y;
        if(vm.lightPlayer) newEnemy.setPipeline('Light2D');
        if(enemySearch.need) newEnemy.need = enemySearch.need;

        // newEnemy.body.moves = false;
        
        newEnemy.class = monster;
        newEnemy.identify = monster + index;
        newEnemy.index = index;
        newEnemy.status = enemySearch.status;
        newEnemy.loots = enemySearch.loots;
        newEnemy.hp = newEnemy.status.maxhp;

        newEnemy.isAttack = false;
        vm.physics.add.collider(newEnemy, vm.platforms);
        vm.physics.add.collider(newEnemy, vm.player);
        if(vm.layer) {
            if(vm.layer.floor) vm.physics.add.collider(newEnemy, vm.layer.floor);
        }

        vm.physics.add.overlap(vm.mainAtk, newEnemy, function(rectangle, enemy) {
            rectangle.destroy();
            vm.sounds.hit.play({volume: 1});
            enemy.alpha = 0.8;
            enemy.setTint(0xff0000);
            var interval = setTimeout(() => {
                enemy.setTint(0xffffff);
                enemy.alpha = 1;
                vm.methods.getDamage(vm, enemy, monster);
                clearTimeout(interval);
            }, 250);
        });

        vm.physics.add.collider(vm.mainAtk, newEnemy, function(rectangle, enemy) {
            rectangle.destroy();
            vm.sounds.hit.play({volume: 1});
            enemy.alpha = 0.8;
            enemy.setTint(0xff0000);
            var interval = setTimeout(() => {
                enemy.setTint(0xffffff);
                enemy.alpha = 1;
                vm.methods.getDamage(vm, enemy, monster);
                clearTimeout(interval);
            }, 250);
        });

        vm.physics.add.overlap(vm.mainAtkMoreDamage, newEnemy, function(rectangle, enemy) {
            if(rectangle.isDamage) {
                vm.sounds.hit.play({volume: 1});
                enemy.alpha = 0.8;
                enemy.setTint(0xff0000);
                var interval = setTimeout(() => {
                    enemy.setTint(0xffffff);
                    enemy.alpha = 1;
                    vm.methods.getDamage(vm, enemy, monster, false, rectangle.damage);
                    clearTimeout(interval);
                }, 250);
            }
        });

        vm.physics.add.collider(vm.mainAtkMoreDamage, newEnemy, function(rectangle, enemy) {
            if(rectangle.isDamage) {
                vm.sounds.hit.play({volume: 1});
                enemy.alpha = 0.8;
                enemy.setTint(0xff0000);
                var interval = setTimeout(() => {
                    enemy.setTint(0xffffff);
                    enemy.alpha = 1;
                    vm.methods.getDamage(vm, enemy, monster, false, rectangle.damage);
                    clearTimeout(interval);
                }, 250);
            }
        });

        vm.physics.add.overlap(vm.player, newEnemy, function() {
            if(vm.player.x < 200) {
                vm.player.x = vm.player.x + 150;
                vm.player.y = vm.player.y - 50;
            } else {
                vm.player.x = vm.player.x - 150;
                vm.player.y = vm.player.y - 50;
            }
            var interval = setInterval(() => {
                var newDamage = BattleService.methods.attackMobCalcule(newEnemy.status.atk);
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(newDamage);
                clearInterval(interval);
            }, 250);
        });
        vm.enemyArray.push(newEnemy);
    },
    createAnimal(vm, name, sprite, anime, repeat, width, height, bodySize, offset, posY) {
        vm.animals[name] = vm.physics.add.group();

        for(var i = 0; i < repeat; i++) {
            vm.methods.addAnimal(vm, name, sprite, anime, width, height, bodySize, offset, posY, i);
        }
    },
    addAnimal(vm, name, sprite, anime, width, height, bodySize, offset, posY, index) {
        var x = Phaser.Math.Between(700, vm.mapSize.x - 1000);

        var newAnimal = vm.enemy[name].create(x, posY, sprite);
        newAnimal.play(anime).setCollideWorldBounds(true).setScale(width, height);
        newAnimal.body.setSize(bodySize.bodyX, bodySize.bodyY, true);
        newAnimal.body.offset.x = offset.x;
        newAnimal.body.offset.y = offset.y;
        newAnimal;
        // newAnimal.body.moves = false;
        newAnimal.class = name;
        newAnimal.identify = name + index;
        newAnimal.index = index;
        newAnimal.status = vm.monsters.find(i => i.name === name).status;
        newAnimal.loots = vm.monsters.find(i => i.name === name).loots;
        newAnimal.hp = newAnimal.status.maxhp;

        newAnimal.isAttack = false;
        vm.physics.add.collider(newAnimal, vm.platforms);
        vm.physics.add.collider(newAnimal, vm.player);

        vm.physics.add.overlap(vm.mainAtk, newAnimal, function(rectangle, enemy) {
            rectangle.destroy();
            var interval = setTimeout(() => {
                vm.methods.getDamage(vm, enemy, name);
                clearTimeout(interval);
            }, 250);
        });

        vm.physics.add.collider(vm.mainAtk, newAnimal, function(rectangle, enemy) {
            rectangle.destroy();
            var interval = setTimeout(() => {
                vm.methods.getDamage(vm, enemy, name);
                clearTimeout(interval);
            }, 250);
        });

        vm.physics.add.overlap(vm.player, newAnimal, function() {
            vm.player.y = vm.player.y - 150;
            var interval = setInterval(() => {
                var newDamage = BattleService.methods.attackMobCalcule(newAnimal.status.atk);
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(newDamage);
                clearInterval(interval);
            }, 250);
        });

        vm.animalArray.push(newAnimal);
    },
    getDamage(vm, enemy, name, boss, damage) {
        var damageMain;
        if(damage) damageMain = damage;
        else damageMain = vm.methods.damage(vm, enemy);
        enemy.hp -= damageMain;
        vm.methods.viewDamage(vm, enemy, damageMain.toFixed(0));
        if(boss) statusBosses.mutations.changeHpBoss(statusBosses.state, damageMain);
        if(enemy.hp < 0) {
            if(enemy.body.enable) {
                var index = vm.enemyArray.indexOf(vm.enemyArray.find(i => i.identify === enemy.identify));
                statusPerson.actions.levelHappiness(Store, 2);
                statusPerson.mutations.addMonsterStatistics(statusPerson.state, enemy.class);
                enemy.isAttack = false;
                vm.enemyArray.splice(index, 1);
                enemy.play(`${name}Death`, true);
                vm.sounds.monsterDead.play({volume: 1});
                if(enemy.need) {
                    if(vm.mainPerson.class && vm.mainPerson.class.name) {
                        if(vm.mainPerson.class.type === enemy.need.work && vm.mainPerson.class.level.value >= enemy.need.lvlWork) {
                            enemy.loots.items.forEach(item => {
                                LootService.methods.lootGeneral(item);
                            })
                        } else {
                            vm.alert.imgUrl = "actions/359.png"
                            vm.alert.message = "Você não é um bom caçador";
                            statusGeneral.actions.showAlert(Store, vm.alert);
                        }    
                    } else {
                        vm.alert.imgUrl = "actions/19.png"
                        vm.alert.message = "Você não possui nenhuma classe";
                        statusGeneral.actions.showAlert(Store, vm.alert);
                    }                            
                } else {
                    enemy.loots.items.forEach(item => {
                        LootService.methods.lootGeneral(item);
                    })
                }
                vm.methods.addXpAndGold(enemy.loots.exp, 0);
                enemy.body.enable = false;
                var timeOut = setTimeout(() => {
                    if(enemy.body) {
                        enemy.hp = enemy.status.maxhp;
                        enemy.body.enable = true;
                        enemy.play(`${name}Idle`, true);
                        vm.enemyArray.push(enemy);
                        clearTimeout(timeOut);
                        return;
                    }
                    clearTimeout(timeOut);
                }, 90000);
            }
        }
    },
    viewDamage(vm, enemy, damage) {
        var style = { font: "20px Arial", fill: "#ff0044", align: "center" };
        vm.damageText.add(vm.add.text(enemy.x, enemy.y - 60, damage, style));
        for(var i = 0; i < vm.mainAtk.getChildren().length; i++) {
            vm.damageText.getChildren()[i].alpha = 0;
            vm.damageText.getChildren()[i].collideWorldBounds = true;
            vm.physics.add.existing(vm.damageText.getChildren()[i]);
            vm.damageText.getChildren()[i].body.moves = false;
        }    
        var interval = setInterval(() => {
            for(var i = 0; i < vm.damageText.getChildren().length; i++) vm.damageText.getChildren()[i].destroy();
            clearInterval(interval);
        }, 500);
    },
    addXpAndGold(exp, gold) {
        let lootObj = {
            exp: exp,
            gold: gold
        }
        statusPerson.mutations.changeXPandGold(statusPerson.state, lootObj);
    },
    distanceAttackEnemy(vm, enemy, type, sprite, anime, speed, size, offsetX, scale, time, posY) {
        var projetil, interval;
        switch(type) {
            case "guided":
                projetil = vm.monsterAttack.create(enemy.body.position.x, enemy.body.position.y - 50, sprite);
                projetil.damage = enemy.status.atk;
                
                projetil.collideWorldBounds = true;
                projetil.setScale(scale);
                if(anime) projetil.play(anime, true);
                vm.physics.add.existing(projetil);
                if(!size) projetil.body.setSize(20, 10, true);
                else projetil.body.setSize(size.x, size.y, true)
                if(!offsetX) projetil.body.offset.x = 0;
                else projetil.body.offset.x = offsetX;
                projetil.body.setAllowGravity(false);

                vm.physics.moveToObject(projetil, vm.player, speed);

                interval = setTimeout(() => {
                    projetil.destroy();
                    clearTimeout(interval);
                }, time);
                break;
            case "simple":
                if(posY) projetil = vm.monsterAttack.create(enemy.body.position.x, enemy.body.position.y + posY, sprite);
                else projetil = vm.monsterAttack.create(enemy.body.position.x, enemy.body.position.y + 50, sprite);
                projetil.damage = enemy.status.atk;

                if (enemy.flipX && enemy.status.flip) speed = speed * -1;
                else if(!enemy.flipX && !enemy.status.flip) speed = speed * -1;

                projetil.collideWorldBounds = true;
                if(anime) projetil.play(anime, true);
                projetil.setScale(2);
                vm.physics.add.existing(projetil);
                projetil.body.velocity.x = speed;
                if(speed < 0) projetil.setFlip(true, false);
                if(!size) projetil.body.setSize(20, 10, true);
                else projetil.body.setSize(size.x, size.y, true)
                if(!offsetX) projetil.body.offset.x = 0;
                else projetil.body.offset.x = offsetX;
                projetil.body.setAllowGravity(false);

                interval = setTimeout(() => {
                    projetil.destroy();
                    clearTimeout(interval);
                }, time);
                break;
        }
    },
    attackEnemy(vm, enemy) {
        if(enemy.status.projetil) {
            vm.methods.distanceAttackEnemy(
                vm, 
                enemy, 
                enemy.status.projetil.type, 
                enemy.status.projetil.name, 
                enemy.status.projetil.effect, 
                enemy.status.projetil.speed, 
                {x: enemy.status.projetil.size.x, y: enemy.status.projetil.size.y}, 
                0, 
                enemy.status.projetil.scale, 
                enemy.status.projetil.time,
                enemy.status.projetil.posY
            );
            return;
        }
        if(!enemy.flipX && !enemy.status.flip) {
            if(enemy.status.collisions) {
                if(enemy.status.collisions.atk) {
                    vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + enemy.status.collisions.atk.flipxTrue, enemy.body.position.y + enemy.status.collisions.atk.y, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
                }
            }
            else vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + -20, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
        }
        else if(enemy.flipX && enemy.status.flip) {
            if(enemy.status.collisions) {
                if(enemy.status.collisions.atk) {
                    vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + enemy.status.collisions.atk.flipxTrue, enemy.body.position.y + enemy.status.collisions.atk.y, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
                }
            }
            else vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + -20, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
        }
        else {
            if(enemy.status.collisions) {
                if(enemy.status.collisions.atk) {
                    vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + enemy.status.collisions.atk.flipxFalse, enemy.body.position.y + enemy.status.collisions.atk.y, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
                }
            }
            else vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + 90, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
        }
        vm.monsterAttack.getChildren().forEach(atk => {
            atk.alpha = 0;
            atk.collideWorldBounds = true;
            vm.physics.add.existing(atk);
            atk.body.moves = false;
            atk.damage = enemy.status.atk;
        })
        var interval = setTimeout(() => {
            vm.methods.destroyRectMonster(vm)
            clearTimeout(interval);
        }, 400);
    },
    destroyRectMonster(vm) {
        vm.monsterAttack.getChildren().forEach(mobAtk => {
            mobAtk.destroy();
        })           
    },
    hurtPlayer(damage) {
        var randomAttack = Phaser.Math.Between(0.6, 1.2);
        BattleService.methods.hurtPlayer(damage, randomAttack);
    },
    damage(vm, monster) {
        var damage = statusPerson.state.mainPerson.personPath === "Path of Mage" ? BattleService.methods.attackMagicMainCalcule(monster.status.def) : BattleService.methods.attackMainCalcule(monster.status.def);
        const randomNumber = BattleService.methods.getRandomNumber(0.2, 100, 100);
        const randomNumberDamage = BattleService.methods.getRandomNumber(0.6, 1.2, 100);
        let criticalDamage = (vm.mainPerson.infos[1].li[3].value) * 0.2;
        let totalDamage;
        if(criticalDamage < randomNumber) {
            totalDamage = damage * randomNumberDamage;
            return totalDamage;
        } else {
            totalDamage = damage * 4;
            return totalDamage;
        }
    },
    forAttack(vm) {
        setInterval(() => {
            vm.enemyArray.forEach(enemy => {
                if(enemy.body) {
                    if(enemy.isAttack) vm.methods.attackEnemy(vm, enemy);
                }
            })
        }, 900)
    }
}

// export const methodsDungeonMatter = {
//     newMineral(vm, mineral, pos, sprite) {
//         var item;
//         switch(mineral) {
//             case "cooper":
//                 var cooper = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
//                 cooper.body.moves = false;
//                 item = {
//                     work: "miner",
//                     name: "cobre bruto",
//                     porcent: 85,
//                     qtd: [1, 2]
//                 }
//                 vm.physics.add.overlap(vm.player, cooper, function(player, mineral) {
//                     if(vm.interage != "mineral") {
//                         vm.interage = "mineral";
//                         vm.worked = {item, time: 45, message: "Minerando cobre", mineral};
//                     }
//                 });
//                 break;
//             case "tin":
//                 var tin = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
//                 tin.body.moves = false;
//                 item = {
//                     work: "miner",
//                     name: "estanho bruto",
//                     porcent: 80,
//                     qtd: [1, 1]
//                 }
//                 vm.physics.add.overlap(vm.player, tin, function(player, mineral) {
//                     if(vm.interage != "mineral") {
//                         vm.interage = "mineral";
//                     }
//                     vm.worked = {item, time: 35, message: "Minerando estanho", mineral};
//                 });
//                 break;
//             case "iron":
//                 var iron = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
//                 iron.body.moves = false;
//                 item = {
//                     work: "miner",
//                     name: "ferro bruto",
//                     porcent: 55,
//                     qtd: [1, 2]
//                 }
//                 vm.physics.add.overlap(vm.player, iron, function(player, mineral) {
//                     if(vm.interage != "mineral") {
//                         vm.interage = "mineral";
//                     }
//                     vm.worked = {item, time: 70, message: "Minerando ferro", mineral};
//                 });
//                 break;
//             case "rock":
//                 var rock = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true).setPipeline('Light2D');
//                 rock.body.moves = false;
//                 item = {
//                     work: "miner",
//                     name: "pedra bruta",
//                     porcent: 95,
//                     qtd: [1, 2]
//                 }
//                 vm.physics.add.overlap(vm.player, rock, function(player, mineral) {
//                     if(vm.interage != "mineral") {
//                         vm.interage = "mineral";
//                     }
//                     vm.worked = {item, time: 30, message: "Coletando pedra", mineral};
//                 });
//                 break;
//         }
//     },
//     workCommom(vm, item, time, message, object) {
//         if(!statusGeneral.state.occupied) {
//             vm.player.body.moves = false;
//             if(statusPerson.state.mainPerson.infos[0].li[2].value >= 2){
//                 vm.methods.timeForCompletedWork(time, message);
//                 const energyMethod = {method: "soma",value: 2}
//                 statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyMethod);
//                 var setinterval = setInterval(() => {
//                     vm.player.play('turn', true)
//                 }, 1000)
//                 var timeout = setTimeout(() => {
//                     object.destroy();
//                     LootService.methods.lootGeneral(item);
//                     vm.player.body.moves = true;
//                     clearTimeout(timeout);
//                     clearInterval(setinterval);
//                 }, time * 1000);
//                 return;
//             }
//             alert("Sem energia suficiente!");
//             return;
//         }
//     },
//     timeForCompletedWork(time, message) {
//         if(!statusGeneral.state.occupied) {
//             statusGeneral.mutations.changeOccupied(statusGeneral.state, true);
//             statusClass.mutations.changeActionText(statusClass.state, message);
//             statusClass.actions.timeForCompletedActionWork(Store, time);
//         }
//     },
//     newTree(vm, tree, pos, sprite) {
//         var item;
//         switch(tree) {
//             case "oak":
//                 var oak = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true);
//                 oak.body.moves = false;
//                 item = {
//                     work: "woodcutting",
//                     name: "tronco",
//                     porcent: 100,
//                     qtd: [1, 2]
//                 }
//                 vm.physics.add.overlap(vm.player, oak, function(player, tree) {
//                     if(vm.interage != "tree") {
//                         vm.interage = "tree";
//                     }
//                     vm.worked = {item, time: 60, message: "Cortando um carvalho", tree};
//                 });
//                 break;
//             case "birch":
//                 var birch = vm.physicObject.create(pos.x, pos.y, sprite).setScale(3, 3.3).setCollideWorldBounds(true);
//                 birch.body.moves = false;
//                 item = {
//                     work: "woodcutting",
//                     name: "tronco reforcado",
//                     porcent: 100,
//                     qtd: [1, 2]
//                 }
//                 vm.physics.add.overlap(vm.player, birch, function(player, tree) {
//                     if(vm.interage != "tree") {
//                         vm.interage = "tree";
//                     }
//                     vm.worked = {item, time: 90, message: "Cortando uma bétula", tree};
//                 });
//                 break;
//         }
//     },
//     createEnemy(vm, name, sprite, anime, repeat, width, height, bodySize, offset, posY) {
//         for(var i = 0; i < repeat; i++) {
//             vm.methods.addEnemy(vm, name, sprite, anime, width, height, bodySize, offset, posY, i);
//         }
//     },
//     addEnemy(vm, name, sprite, anime, width, height, bodySize, offset, posY, index) {
//         var x = Phaser.Math.Between(700, vm.mapSize.x - 300);
//         var enemySearch = vm.monsters.find(i => i.name === name);

//         // var newEnemy = vm.enemy[name].create(x, posY, sprite);
//         var newEnemy = vm.matter.add.sprite(x, posY, sprite);
//         newEnemy.play(anime).setScale(width, height);
//         // newEnemy.body.setSize(bodySize.bodyX, bodySize.bodyY, true);
//         // newEnemy.body.offset.x = offset.x;
//         // newEnemy.body.offset.y = offset.y;
//         if(enemySearch.need) newEnemy.need = enemySearch.need;

//         // newEnemy.body.moves = false;
        
//         newEnemy.class = name;
//         newEnemy.identify = name + index;
//         newEnemy.index = index;
//         newEnemy.status = enemySearch.status;
//         newEnemy.loots = enemySearch.loots;
//         newEnemy.hp = newEnemy.status.maxhp;
//         newEnemy.body.label = 'enemy';
//         // newEnemy.setCollisionGroup(vm.groupsCollisions.world);

//         newEnemy.isAttack = false;

//         vm.matter.world.on("collisionactive", (e) => {
//             var interval;
            
//             if( e.pairs.some (pair => pair.bodyA.label == 'mainAtk' && pair.bodyB.label =='enemy' )) {
//                 vm.mainAtk.forEach(atk => {atk.destroy()});
//                 interval = setTimeout(() => {
//                     vm.methods.getDamage(vm, newEnemy, name);
//                     clearTimeout(interval);
//                 }, 250);
//             }
            
//             if( e.pairs.some (pair => pair.bodyA.label == 'enemy' && pair.bodyB.label =='player' )) {
//                 vm.player.y = vm.player.y - 30;
//                 vm.player.x = vm.player.x - 30;
//                 interval = setInterval(() => {
//                     var newDamage = BattleService.methods.attackMobCalcule(newEnemy.status.atk);
//                     BattleService.methods.changeDurabilityArmor();
//                     vm.methods.hurtPlayer(newDamage);
//                     clearInterval(interval);
//                 }, 250);
//             }
            
//             if( e.pairs.some (pair => pair.bodyA.label == 'enemy' && pair.bodyB.label =='mainAtk' )) {
//                 vm.mainAtk.forEach(atk => {atk.destroy()});
//                 interval = setTimeout(() => {
//                     vm.methods.getDamage(vm, newEnemy, name);
//                     clearTimeout(interval);
//                 }, 250);
//             }        
//         });
//         vm.enemyArray.push(newEnemy);
//     },
//     getDamage(vm, enemy, name, damage) {
//         var damageMain;
//         if(damage) damageMain = damage;
//         else damageMain = vm.methods.damage(vm, enemy);
//         enemy.hp -= damageMain;
//         vm.methods.viewDamage(vm, enemy, damageMain.toFixed(0));
//         vm.methods.attackEnemy(vm, enemy);
//         console.log(enemy);
//         if(enemy.hp < 0) {
//             if(enemy.body.enable) {
//                 var index = vm.enemyArray.indexOf(vm.enemyArray.find(i => i.identify === enemy.identify));
//                 vm.enemyArray.splice(index, 1);
//                 enemy.play(`${name}Death`, true);
//                 if(enemy.need) {
//                     if(vm.mainPerson.class && vm.mainPerson.class.name) {
//                         if(vm.mainPerson.class.type === enemy.need.work && vm.mainPerson.class.level.value >= enemy.need.lvlWork) {
//                             enemy.loots.items.forEach(item => {
//                                 LootService.methods.lootGeneral(item);
//                             })
//                         } else {
//                             vm.alert.imgUrl = "actions/359.png"
//                             vm.alert.message = "Você não é um bom caçador";
//                             statusGeneral.actions.showAlert(Store, vm.alert);
//                         }    
//                     } else {
//                         vm.alert.imgUrl = "actions/19.png"
//                         vm.alert.message = "Você não possui nenhuma classe";
//                         statusGeneral.actions.showAlert(Store, vm.alert);
//                     }                            
//                 } else {
//                     enemy.loots.items.forEach(item => {
//                         LootService.methods.lootGeneral(item);
//                     })
//                 }
//                 vm.methods.addXpAndGold(enemy.loots.exp, 0);
//                 enemy.body.enable = false;
//                 var timeOut = setTimeout(() => {
//                     if(enemy.body) {
//                         enemy.hp = enemy.status.maxhp;
//                         enemy.body.enable = true;
//                         enemy.play(`${name}Idle`, true);
//                         vm.enemyArray.push(enemy);
//                         clearTimeout(timeOut);
//                         return;
//                     }
//                     clearTimeout(timeOut);
//                 }, 90000);
//             }
//         }
//     },
//     viewDamage(vm, enemy, damage) {
//         var style = { font: "20px Arial", fill: "#ff0044", align: "center" };
//         vm.damageText.push(vm.add.text(enemy.x, enemy.y - 60, damage, style));
//         for(var i = 0; i < vm.mainAtk.length; i++) {
//             vm.damageText[i].alpha = 0;
//             vm.damageText[i].collideWorldBounds = true;
//             vm.physics.add.existing(vm.damageText[i]);
//             vm.damageText[i].body.moves = false;
//         }    
//         var interval = setInterval(() => {
//             for(var i = 0; i < vm.damageText.length; i++) vm.damageText[i].destroy();
//             clearInterval(interval);
//         }, 500);
//     },
//     addXpAndGold(exp, gold) {
//         let lootObj = {
//             exp: exp,
//             gold: gold
//         }
//         statusPerson.mutations.changeXPandGold(statusPerson.state, lootObj);
//     },
//     attackEnemy(vm, enemy) {
//         if(!enemy.flipX) vm.monsterAttack.push(vm.matter.add.rectangle(enemy.body.position.x + -20, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
//         else vm.monsterAttack.push(vm.matter.add.rectangle(enemy.body.position.x + 90, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
//         for(var i = 0; i < vm.monsterAttack.length; i++) {
//             vm.mainAtk[i].body.label = 'enemyAtk';
//             vm.monsterAttack[i].alpha = 0;
//             vm.monsterAttack[i].collideWorldBounds = true;
//             vm.physics.add.existing(vm.monsterAttack[i]);
//             vm.monsterAttack[i].body.moves = false;
//             vm.monsterAttack[i].damage = enemy.status.atk;
//         }
//         var interval = setTimeout(() => {
//             this.destroyRectMonster(vm)
//             clearTimeout(interval);
//         }, 400);
//     },
//     destroyRectMonster(vm) {
//         for(var i = 0; i < vm.monsterAttack.length; i++) vm.monsterAttack[i].destroy();            
//     },
//     hurtPlayer(damage) {
//         var randomAttack = Phaser.Math.Between(0.6, 1.2);
//         BattleService.methods.hurtPlayer(damage, randomAttack);
//     },
//     damage(vm, monster) {
//         const damage = BattleService.methods.attackMainCalcule(monster.status.def);
//         const randomNumber = BattleService.methods.getRandomNumber(0.2, 100, 100);
//         const randomNumberDamage = BattleService.methods.getRandomNumber(0.6, 1.2, 100);
//         let criticalDamage = (vm.mainPerson.infos[1].li[3].value) * 0.2;
//         let totalDamage;
//         if(criticalDamage < randomNumber) {
//             totalDamage = damage * randomNumberDamage;
//             return totalDamage;
//         } else {
//             totalDamage = damage * 4;
//             return totalDamage;
//         }
//     },
//     forAttack(vm) {
//         setInterval(() => {
//             vm.enemyArray.forEach(enemy => {
//                 if(enemy.body) {
//                     if(enemy.isAttack) vm.methods.attackEnemy(vm, enemy);
//                 }
//             })
//         }, 900)
//     }
// }

export const methodsCamp = {
    navegateWave(vm, typeCamp) {
        var spawn, timeOut;
        vm.startWave = true;
        switch(typeCamp) {
            case "goblin":
                timeOut = setTimeout(() => {
                    vm.wave.monsterNumber = 0;
                    if(vm.wave.currentWave < 5) {
                        spawn = Phaser.Math.Between(1, 3);
                        vm.methods.createEnemy(vm, "goblin", "goblinWalk", "goblinWalk", spawn * vm.wave.currentWave, 2, 2.5, {bodyX: 22, bodyY: 32}, {x: 23, y: 15}, 650);
                        vm.wave.monsterNumber = spawn * vm.wave.currentWave;
                    } else if (vm.wave.currentWave < 10) {
                        spawn = Phaser.Math.Between(0, 2);
                        vm.methods.createEnemy(vm, "goblin", "goblinWalk", "goblinWalk", spawn * vm.wave.currentWave, 2, 2.5, {bodyX: 22, bodyY: 32}, {x: 23, y: 15}, 650);
                        vm.methods.createEnemy(vm, "troll", "trollWalk", "trollWalk", 1 * (vm.wave.currentWave - 4), 2.5, 3, {bodyX: 30, bodyY: 55}, {x: 32, y: 17}, 590);
                        vm.wave.monsterNumber = (spawn * vm.wave.currentWave) + (1 * (vm.wave.currentWave - 4));
                    } else {
                        spawn = Phaser.Math.Between(0, 1);
                        vm.methods.createEnemy(vm, "goblin", "goblinWalk", "goblinWalk", spawn * vm.wave.currentWave, 2, 2.5, {bodyX: 22, bodyY: 32}, {x: 23, y: 15}, 650);
                        vm.methods.createEnemy(vm, "troll", "trollWalk", "trollWalk", 1 * (vm.wave.currentWave - 4), 2.5, 3, {bodyX: 30, bodyY: 55}, {x: 32, y: 17}, 590);
                        vm.methods.createEnemy(vm, "demonTroll", "demonTrollWalk", "demonTrollWalk", 1 * (vm.wave.currentWave - 9), 4, 4.5, {bodyX: 40, bodyY: 55}, {x: 45, y: 41}, 495);
                        vm.wave.monsterNumber = (spawn * vm.wave.currentWave) + (1 * (vm.wave.currentWave - 4)) + (1 * (vm.wave.currentWave - 9));
                    }
                    clearTimeout(timeOut);
                }, 6500);
                break;
            case "bandit":
                timeOut = setTimeout(() => {
                    vm.wave.monsterNumber = 0;
                    if(vm.wave.currentWave < 4) {
                        spawn = Phaser.Math.Between(1, 3);
                        vm.methods.createEnemy(vm, "fanatic", "fanaticWalk", "fanaticWalk", spawn * vm.wave.currentWave, 2.5, 3, {bodyX: 22, bodyY: 32}, {x: 23, y: 15}, 610);
                        vm.wave.monsterNumber = spawn * vm.wave.currentWave;
                    } else if (vm.wave.currentWave < 9) {
                        spawn = Phaser.Math.Between(1, 2);
                        vm.methods.createEnemy(vm, "fanatic", "fanaticWalk", "fanaticWalk", spawn * vm.wave.currentWave, 2.5, 3, {bodyX: 22, bodyY: 32}, {x: 23, y: 15}, 610);
                        vm.methods.createEnemy(vm, "moonluckFollower", "moonluckFollowerWalk", "moonluckFollowerWalk", 1 * (vm.wave.currentWave - 3), 2.5, 3, {bodyX: 30, bodyY: 55}, {x: 32, y: 17}, 520);
                        vm.wave.monsterNumber = (spawn * vm.wave.currentWave) + (1 * (vm.wave.currentWave - 3));
                    } else {
                        spawn = Phaser.Math.Between(0, 2);
                        vm.methods.createEnemy(vm, "fanatic", "fanaticWalk", "fanaticWalk", spawn * vm.wave.currentWave, 2.5, 3, {bodyX: 22, bodyY: 32}, {x: 23, y: 15}, 600);
                        vm.methods.createEnemy(vm, "moonluckFollower", "moonluckFollowerWalk", "moonluckFollowerWalk", 1 * (vm.wave.currentWave - 3), 2.5, 3, {bodyX: 30, bodyY: 55}, {x: 32, y: 17}, 520);
                        vm.methods.createEnemy(vm, "berserkerMoonlucky", "berserkerMoonluckyWalk", "berserkerMoonluckyWalk", 1 * (vm.wave.currentWave - 8), 4, 4.5, {bodyX: 40, bodyY: 55}, {x: 45, y: 41}, 495);
                        vm.wave.monsterNumber = (spawn * vm.wave.currentWave) + (1 * (vm.wave.currentWave - 3)) + (1 * (vm.wave.currentWave - 8));
                    }
                    clearTimeout(timeOut);
                }, 6500);
                break;
        }
    },
    nextWave(vm) {
        statusMonster.mutations.changeWave(statusMonster.state, vm.wave.currentWave);
        statusMonster.mutations.countNewWave(statusMonster.state);
    },
    addPointsScore(points) {
        statusMonster.mutations.addScore(statusMonster.state, points);
    },
    resetScore() {
        statusMonster.mutations.changeScore(statusMonster.state, 0);
    },
    showCount() {
        statusMonster.mutations.changeShowCount(statusMonster.state, true);
    },
    getDamage(vm, enemy, name, damage) {
        var damageMain;
        if(damage) damageMain = damage;
        else damageMain = vm.methods.damage(vm, enemy);
        enemy.hp -= damageMain;
        enemy.hpBar.decrease(damageMain);
        vm.methods.viewDamage(vm, enemy, damageMain.toFixed(0));
        // vm.methods.attackEnemy(vm, enemy);
        if(enemy.hp <= 0) {
            var index = vm.enemyArray.indexOf(vm.enemyArray.find(i => i.identify === enemy.identify));
            enemy.hpBar.deleteHealtBar();
            if(enemy.body.enable) {
                statusPerson.actions.levelHappiness(Store, 2);
                vm.enemyArray.splice(index, 1);
                enemy.play(`${name}Death`, true);
                statusPerson.mutations.addMonsterStatistics(statusPerson.state, enemy.class);
                vm.sounds.monsterDead.play({volume: 1});
                if(enemy.need) {
                    if(vm.mainPerson.class && vm.mainPerson.class.name) {
                        if(vm.mainPerson.class.type === enemy.need.work && vm.mainPerson.class.level.value >= enemy.need.lvlWork) {
                            enemy.loots.items.forEach(item => {
                                LootService.methods.lootGeneral(item);
                            })
                        } else {
                            vm.alert.imgUrl = "actions/359.png"
                            vm.alert.message = "Você não é um bom caçador";
                            statusGeneral.actions.showAlert(Store, vm.alert);
                        }    
                    } else {
                        vm.alert.imgUrl = "actions/19.png"
                        vm.alert.message = "Você não possui nenhuma classe";
                        statusGeneral.actions.showAlert(Store, vm.alert);
                    }                            
                } else {
                    enemy.loots.items.forEach(item => {
                        LootService.methods.lootGeneral(item);
                    })
                }
                vm.methods.addXpAndGold(enemy.loots.exp, 0);
                enemy.body.enable = false;
                vm.methods.addPointsScore(enemy.loots.exp);
                vm.methods.addMonsterDead();
                vm.wave.monsterNumber--;
                if(vm.wave.monsterNumber == 0) vm.startWave = false;
                return;
            }
        }
    },
    viewDamage(vm, enemy, damage) {
        var style = { font: "20px Arial", fill: "#ff0044", align: "center" };
        vm.damageText.add(vm.add.text(enemy.x, enemy.y - 60, damage, style));
        for(var i = 0; i < vm.mainAtk.getChildren().length; i++) {
            vm.damageText.getChildren()[i].alpha = 0;
            vm.damageText.getChildren()[i].collideWorldBounds = true;
            vm.physics.add.existing(vm.damageText.getChildren()[i]);
            vm.damageText.getChildren()[i].body.moves = false;
        }    
        var interval = setInterval(() => {
            for(var i = 0; i < vm.damageText.getChildren().length; i++) vm.damageText.getChildren()[i].destroy();
            clearInterval(interval);
        }, 500);
    },
    attackEnemy(vm, enemy) {
        if(!enemy.flipX && !enemy.status.flip) {
            if(enemy.status.collisions) {
                if(enemy.status.collisions.atk) {
                    vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + enemy.status.collisions.atk.flipxTrue, enemy.body.position.y + enemy.status.collisions.atk.y, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
                }
            }
            else vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + -20, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
        }
        else if(enemy.flipX && enemy.status.flip) {
            if(enemy.status.collisions) {
                if(enemy.status.collisions.atk) {
                    vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + enemy.status.collisions.atk.flipxTrue, enemy.body.position.y + enemy.status.collisions.atk.y, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
                }
            }
            else vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + -20, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
        }
        else {
            if(enemy.status.collisions) {
                if(enemy.status.collisions.atk) {
                    vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + enemy.status.collisions.atk.flipxFalse, enemy.body.position.y + enemy.status.collisions.atk.y, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff))
                }
            }
            else vm.monsterAttack.add(vm.add.rectangle(enemy.body.position.x + 90, enemy.body.position.y + 40, enemy.status.atkArea.x, enemy.status.atkArea.y, 0xffffff));
        }
        vm.monsterAttack.getChildren().forEach(atk => {
            atk.alpha = 0;
            atk.collideWorldBounds = true;
            vm.physics.add.existing(atk);
            atk.body.moves = false;
            atk.damage = enemy.status.atk;
        })
        var interval = setTimeout(() => {
            vm.methods.destroyRectMonster(vm)
            clearTimeout(interval);
        }, 400);
    },
    destroyRectMonster(vm) {
        vm.monsterAttack.getChildren().forEach(mobAtk => {
            mobAtk.destroy();
        })           
    },
    hurtPlayer(damage) {
        var randomAttack = Phaser.Math.Between(0.6, 1.2);
        BattleService.methods.hurtPlayer(damage, randomAttack);
    },
    addXpAndGold(exp, gold) {
        let lootObj = {
            exp: parseInt(exp),
            gold: gold
        }
        statusPerson.mutations.changeXPandGold(statusPerson.state, lootObj);
    },
    resetMonsterDead() {
        statusMonster.mutations.changeMonstersDead(statusMonster.state, 0);
    },
    addMonsterDead() {
        statusMonster.mutations.addMonstersDead(statusMonster.state, 1);
    },
    damage(vm, monster) {
        var damage = statusPerson.state.mainPerson.personPath === "Path of Mage" ? BattleService.methods.attackMagicMainCalcule(monster.status.def) : BattleService.methods.attackMainCalcule(monster.status.def);
        const randomNumber = BattleService.methods.getRandomNumber(0.2, 100, 100);
        const randomNumberDamage = BattleService.methods.getRandomNumber(0.6, 1.2, 100);
        let criticalDamage = (vm.mainPerson.infos[1].li[3].value) * 0.2;
        let totalDamage;
        if(criticalDamage < randomNumber) {
            totalDamage = damage * randomNumberDamage;
            return totalDamage;
        } else {
            totalDamage = damage * 4;
            return totalDamage;
        }
    },
    forAttack(vm) {
        setInterval(() => {
            vm.enemyArray.forEach(enemy => {
                if(enemy.body) {
                    if(enemy.isAttack) vm.methods.attackEnemy(vm, enemy);
                }
            })
        }, 900)
    },
    addEnemy(vm, name, sprite, anime, width, height, bodySize, offset, posY, index) {
        var x = Phaser.Math.Between(300, vm.mapSize.x - 400);

        var newEnemy = vm.enemy[name].create(x, posY, sprite);
        while(newEnemy.body) {
            if(newEnemy.body) {
                newEnemy.play(anime).setCollideWorldBounds(true).setScale(width, height);
                
                newEnemy.class = name;
                newEnemy.identify = name + index;
                newEnemy.index = index;
                newEnemy.status = vm.monsters.find(i => i.name === name).status;
                newEnemy.loots = vm.monsters.find(i => i.name === name).loots;
                newEnemy.hp = newEnemy.status.maxhp;

                if(newEnemy.status.collisions) {
                    newEnemy.body.setSize(newEnemy.status.collisions.size.x, newEnemy.status.collisions.size.y, true);
                    if(newEnemy.status.flip) newEnemy.body.offset.x = newEnemy.status.collisions.offset.flipxTrue;
                    else newEnemy.body.offset.x = newEnemy.status.collisions.offset.flipxFalse;
                    newEnemy.body.offset.y = newEnemy.status.collisions.offset.y;
                } else {
                    newEnemy.body.setSize(bodySize.bodyX, bodySize.bodyY, true);
                    newEnemy.body.offset.x = offset.x;
                    newEnemy.body.offset.y = offset.y;
                }
        
                // newEnemy.body.moves = false;
        
                newEnemy.hpBar = new HealthBar(vm, newEnemy.x, newEnemy.y - 50, newEnemy.status.maxhp);
        
                newEnemy.isAttack = false;
        
                // newEnemy.body.immovable = true;
                vm.physics.add.collider(newEnemy, vm.platforms);
                vm.physics.add.collider(newEnemy, vm.player);
        
                vm.physics.add.overlap(vm.mainAtk, newEnemy, function(rectangle, enemy) {
                    rectangle.destroy();
                    vm.sounds.hit.play({volume: 1});
                    enemy.alpha = 0.8;
                    enemy.setTint(0xff0000);
                    var interval = setTimeout(() => {
                        enemy.setTint(0xffffff);
                        enemy.alpha = 1;
                        vm.methods.getDamage(vm, enemy, name);
                        clearTimeout(interval);
                    }, 250);
                });
        
                vm.physics.add.collider(vm.mainAtk, newEnemy, function(rectangle, enemy) {
                    rectangle.destroy();
                    vm.sounds.hit.play({volume: 1});
                    enemy.alpha = 0.8;
                    enemy.setTint(0xff0000);
                    var interval = setTimeout(() => {
                        enemy.setTint(0xffffff);
                        enemy.alpha = 1;
                        vm.methods.getDamage(vm, enemy, name);
                        clearTimeout(interval);
                    }, 250);
                });

                vm.physics.add.overlap(vm.mainAtkMoreDamage, newEnemy, function(rectangle, enemy) {
                    if(rectangle.isDamage) {
                        vm.sounds.hit.play({volume: 1});
                        enemy.alpha = 0.8;
                        enemy.setTint(0xff0000);
                        var interval = setTimeout(() => {
                            enemy.setTint(0xffffff);
                            enemy.alpha = 1;
                            vm.methods.getDamage(vm, enemy, name, rectangle.damage);
                            clearTimeout(interval);
                        }, 250);
                    }
                });

                vm.physics.add.collider(vm.mainAtkMoreDamage, newEnemy, function(rectangle, enemy) {
                    if(rectangle.isDamage) {
                        vm.sounds.hit.play({volume: 1});
                        enemy.alpha = 0.8;
                        enemy.setTint(0xff0000);
                        var interval = setTimeout(() => {
                            enemy.setTint(0xffffff);
                            enemy.alpha = 1;
                            vm.methods.getDamage(vm, enemy, name, rectangle.damage);
                            clearTimeout(interval);
                        }, 250);
                    }
                });
        
                vm.physics.add.overlap(vm.player, newEnemy, function() {
                    vm.player.x = vm.player.x - 150;
                    var interval = setInterval(() => {
                        var newDamage = BattleService.methods.attackMobCalcule(newEnemy.status.atk);
                        BattleService.methods.changeDurabilityArmor();
                        vm.methods.hurtPlayer(newDamage);
                        clearInterval(interval);
                    }, 250);
                });
        
                vm.enemyArray.push(newEnemy);
                return;
            }
            newEnemy.destroy();
        }
    },
    createEnemy(vm, name, sprite, anime, repeat, width, height, bodySize, offset, posY) {
        vm.enemy[name] = vm.physics.add.group();

        for(var i = 0; i < repeat; i++) {
            vm.methods.addEnemy(vm, name, sprite, anime, width, height, bodySize, offset, posY, i);
        }
    }
}