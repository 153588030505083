const normalDialog = {
    steps: [
        {
            number: 1,
            message: "Olá aventureiro, seja bem vindo a nossa guilda, o que deseja?",
            options: [
                {
                    message: "Como faço para entrar?",
                    type: "default",
                    goStep: 2,
                },
                {
                    message: "Preciso de trabalho, o que tem para mim?",
                    type: "default",
                    goStep: 3,
                    function: "openShop"
                },
                {
                    message: "Só vim de visita, obrigado",
                    type: "default",
                    goStep: 10,
                }
            ]
        },
        {
            number: 2,
            message: "Que bacana, já sabe como funciona a guilda?",
            options: [
                {
                    message: "Sim, já vi em alguns animes",
                    type: "default",
                    goStep: 3,
                },
                {
                    message: "Não, não conheço, mas fui recomendado, pois disseram que aqui tem trabalho",
                    type: "default",
                    goStep: 4,
                }
            ]
        },
        {
            number: 3,
            message: "Não sei o que seriam animes, mas pela sua cara, da pra ver que realmente quer ser um aventureiro",
            options: [
                {
                    message: "Sim, estou extremamente empolgado",
                    type: "default",
                    goStep: 4
                },
                {
                    message: "Na verdade só preciso de trabalho",
                    type: "default",
                    goStep: 4
                },
                {
                    message: "Para ser sincero, vou esperar um pouco mais",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 4,
            message: "Bom, vou te explicar como funcionamos: Somos a Guilda dos Aventureiros, lendária nesses territórios recem explorados de Odenlor, mas com muita história. Por aqui passaram lendas de muita fama local e mundial, sendo um lugar onde corajosos aventureiros e aventureiras vem para conseguir grandes aventuras, ou também para fazer um dinheiro extra",
            options: [
                {
                    message: "Uau, quero ser lendário, to certo",
                    type: "default",
                    goStep: 5,
                    function: ""
                },
                {
                    message: "Só quero um trabalho, qualquer coisa serve, o que preciso saber?",
                    type: "default",
                    goStep: 6
                }
            ]
        },
        {
            number: 5,
            message: "Adoro sua empolgação, assine aqui e depois disso, você será um aventureiro de Rank D",
            options: [
                {
                    message: "???? Não entendi? O que seria esse Rank D?",
                    type: "default",
                    goStep: 7
                }
            ]
        },
        {
            number: 6,
            message: "Nossa, mas como você é deprimente... P#$% QUE P$%&#... Desse jeito não vai sair nunca do ranking D",
            options: [
                {
                    message: "Desculpe, só estou com pressa, preciso muito de dinheiro",
                    type: "default",
                    goStep: 7
                }
            ]
        },
        {
            number: 7,
            message: "Continuando... Você será um membro de Rank D, isso significa que apenas trabalhos mais simples serão liberados para você, como caçar uns slimes ou pegar uns morangos, coisas fáceis, mas que dão dinheiro, afinal, mais da metade de Odenlor pede por serviços pela nossa guilda",
            options: [
                {
                    message: "Como subo de ranking?",
                    type: "default",
                    goStep: 8
                }
            ]
        },
        {
            number: 8,
            message: "Fazendo novos trabalhos, ganhando reputação com a guilda, você vai recebendo trabalhos melhores e mais bem remunerados, claro, também podendo ser mais perigosos",
            options: [
                {
                    message: "Uhu, estou animado, me passa aqui esse caderno para que eu possa assinar",
                    function: "registerInGuild",
                    goStep: 9
                },
                {
                    message: "Ok, me passa logo para que eu possa assinar e iniciar logo os trabalhos",
                    function: "registerInGuild",
                    goStep: 9
                }
            ]
        },
        {
            number: 9,
            message: "Seja bem vindo(a) a Guilda dos Aventureiros, prepare-se para construir um legado para as futuras gerações... Mas antes, todos devem começar por baixo, assim como você... Parabéns pelo Rank D",
            options: [
                {
                    message: "Bora começar essa P$%*&, BIRIL",
                    function: "openShop",
                },
                {
                    message: "Hora de caçar, muito obrigado!",
                    function: "openShop",
                }
            ]
        },
        {
            number: 10,
            message: "Sem problemas, estaremos aqui para quando precisar, conte com a Guilda dos Aventureiros",
            options: [
                {
                    message: "Tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        }
    ]
}

export default normalDialog;