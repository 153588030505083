import Vue from "vue";
import Router from "vue-router";

//Pages
import Login from "./pages/Login/Index.vue";

import Home from "./pages/home/index"
import House from "./pages/house/index"
import Maincity from "./pages/maincity/index"
import Attack from "./pages/attack/index"
import World from "./pages/map/index"
import Missions from "./pages/missions/index"
import Training from "./pages/training/index"
import Works from "./pages/works/index"
import CreatePerson from "./pages/createPerson/index"
import Market from "./pages/market/Index"
import Camp from "./pages/camp/index"

import myHouse from "./pages/house/index"
import myCommerce from "./pages/commerce/index"

import newHud from "./pages/newLayout/Index"

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            component: Login
        },
        {
            path: "/newLayout",
            component: newHud
        },
        {
            path: "/myHouse",
            component: myHouse
        },
        {
            path: "/myCommerce",
            component: myCommerce
        },
        {
            path: "/Home",
            component: Home
        },
        {
            path: "/createperson",
            component: CreatePerson
        },
        {
            path: "/house",
            component: House
        },
        {
            path: "/Maincity",
            component: Maincity
        },
        {
            path: "/Attack",
            component: Attack
        },
        {
            path: "/World",
            component: World
        },
        {
            path: "/Missions",
            component: Missions
        },
        {
            path: "/Training",
            component: Training
        },
        {
            path: "/Works",
            component: Works
        },
        {
            path: "/Market",
            component: Market
        },
        {
            path: "/camp",
            component: Camp
        }
    ]
})