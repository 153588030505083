import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        skills: [],
        category: "",
        work: "smithing",
        skillModal: false,
        path: "",
        class: "",
        masterSkill: {
            viewsSkills: false,
            nameMaster: ""
        }
    },
    mutations,
    actions
}