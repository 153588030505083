import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods" 
import npcImport from "@/shared/components-phaser/createNpc" 
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateActions } from "@/shared/components-phaser/updateActions" 

import preloadAllResources from "@/shared/components-phaser/preload.js"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene2 extends Phaser.Scene {
    constructor(){
        super("internal1")
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.platforms;
        this.mapSize = {
            x: 5120,
            y: 2048
        };
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.disableBodys = false;
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.limits;
        this.key;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.enemy = {};
        this.enemyArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        // Tiles
        this.load.image("blocoMine1", "/resourcesInGame/title-set/tiles/mine/Tile_02.png");
        this.load.image("blocoMine2", "/resourcesInGame/title-set/tiles/mine/Tile_22.png");

        // Resources all maps
        preloadAllResources(this);

        //cave background
        this.load.image("backgroundBlockCave2", "/resourcesInGame/title-set/tiles/mine/tile5.png");

        //cave door
        this.load.image("doorMine", "/resourcesInGame/title-set/objects/mine/door.png");

        //Minerals
        this.load.image("cooperMineral", "/resourcesInGame/title-set/rocks/miners/Vein2.png");
        this.load.image("tinMineral", "/resourcesInGame/title-set/rocks/miners/Vein8.png");
        this.load.image("ironMineral", "/resourcesInGame/title-set/rocks/miners/Vein4.png");

        //Rocks
        this.load.image("rock1", "/resourcesInGame/title-set/rocks/simple/2.png");
        this.load.image("rock2", "/resourcesInGame/title-set/rocks/simple/3.png");
        this.load.image("rock3", "/resourcesInGame/title-set/rocks/simple/4.png");
        this.load.image("rock4", "/resourcesInGame/title-set/rocks/simple/5.png");
        this.load.image("rock5", "/resourcesInGame/title-set/rocks/simple/7.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //NPCs
        this.load.spritesheet('miner', '/resourcesInGame/sprites/npcs/miner/Miner_Mining.png', { frameWidth: 48, frameHeight: 48 });
        
        //enemies
        this.load.spritesheet('spiderIdle', '/resourcesInGame/sprites/mine/spider/Spider_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('spiderWalk', '/resourcesInGame/sprites/mine/spider/Spider_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('spiderAttack', '/resourcesInGame/sprites/mine/spider/Spider_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('spiderDeath', '/resourcesInGame/sprites/mine/spider/Spider_death.png', {frameWidth: 48, frameHeight: 48});
        
        this.load.spritesheet('bearIdle', '/resourcesInGame/sprites/mine/bear/Bear_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('bearWalk', '/resourcesInGame/sprites/mine/bear/Bear_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('bearAttack', '/resourcesInGame/sprites/mine/bear/Bear_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('bearDeath', '/resourcesInGame/sprites/mine/bear/Bear_death.png', {frameWidth: 48, frameHeight: 48});
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            //Enemies
            //Spider
            vm.anims.create({
                key: "spiderIdle",
                frames: vm.anims.generateFrameNumbers("spiderIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "spiderWalk",
                frames: vm.anims.generateFrameNumbers("spiderWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "spiderAttack",
                frames: vm.anims.generateFrameNumbers("spiderAttack", {start: 0, end: 3}),
                frameRate: 10,
                repeat: -1,
                repeatDelay: 700
            }),
            vm.anims.create({
                key: "spiderDeath",
                frames: vm.anims.generateFrameNumbers("spiderDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })
            
            //Bear
            vm.anims.create({
                key: "bearIdle",
                frames: vm.anims.generateFrameNumbers("bearIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "bearWalk",
                frames: vm.anims.generateFrameNumbers("bearWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "bearAttack",
                frames: vm.anims.generateFrameNumbers("bearAttack", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1,
                repeatDelay: 1000
            }),
            vm.anims.create({
                key: "bearDeath",
                frames: vm.anims.generateFrameNumbers("bearDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })

            //NPC
            vm.anims.create({
                key: "miner",
                frames: vm.anims.generateFrameNumbers('miner', {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            })
        }

        function door(vm) {
            //1 floor
            var doorMine1 = vm.physicObject.create(200, 355, 'doorMine').setScale(2, 2.3).setCollideWorldBounds(true).setSize(60, 90).setPipeline('Light2D');
            var doorMine2 = vm.physicObject.create(4950, 355, 'doorMine').setScale(2, 2.3).setCollideWorldBounds(true).setSize(60, 90).setPipeline('Light2D');
            //2 floor
            var doorMine3 = vm.physicObject.create(200, 1000, 'doorMine').setScale(2, 2.3).setCollideWorldBounds(true).setSize(60, 90).setPipeline('Light2D');
            var doorMine4 = vm.physicObject.create(4950, 1000, 'doorMine').setScale(2, 2.3).setCollideWorldBounds(true).setSize(60, 90).setPipeline('Light2D');
            // 3 floor
            var doorMine5 = vm.physicObject.create(200, 1765, 'doorMine').setScale(2, 2.3).setCollideWorldBounds(true).setSize(60, 90).setPipeline('Light2D');
            var doorMine6 = vm.physicObject.create(4950, 1765, 'doorMine').setScale(2, 2.3).setCollideWorldBounds(true).setSize(60, 90).setPipeline('Light2D');

            doorMine1.body.moves = false;
            doorMine2.body.moves = false;
            doorMine3.body.moves = false;
            doorMine4.body.moves = false;
            doorMine5.body.moves = false;
            doorMine6.body.moves = false;

            // doorMine;
            vm.physics.add.overlap(vm.player, doorMine1, function() {if(vm.interage != "doorMine1") vm.interage = "doorMine1";});
            vm.physics.add.overlap(vm.player, doorMine2, function() {if(vm.interage != "doorMine2") vm.interage = "doorMine2";});
            vm.physics.add.overlap(vm.player, doorMine3, function() {if(vm.interage != "doorMine3") vm.interage = "doorMine3";});
            vm.physics.add.overlap(vm.player, doorMine4, function() {if(vm.interage != "doorMine4") vm.interage = "doorMine4";});
            vm.physics.add.overlap(vm.player, doorMine5, function() {if(vm.interage != "doorMine5") vm.interage = "doorMine5";});
            vm.physics.add.overlap(vm.player, doorMine6, function() {if(vm.interage != "doorMine6") vm.interage = "doorMine6";});
        }

        function rocks(vm) {
            var i;
            vm.methods.newMineral(vm, "rock", {x: 350, y: 400}, "rock1");
            vm.methods.newMineral(vm, "cooper", {x: Phaser.Math.Between(450, 4800), y: 400}, "cooperMineral");
            vm.methods.newMineral(vm, "tin", {x: Phaser.Math.Between(450, 4800), y: 400}, "tinMineral");
            for(i = 0; i < 2; i++) {
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(450, 4800), y: 400}, "rock1");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(450, 4800), y: 400}, "rock2");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(450, 4800), y: 400}, "rock3");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(450, 4800), y: 400}, "rock4");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(450, 4800), y: 400}, "rock5");
            }
            //2 floor
            for(i = 0; i < 8; i++) {
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1040}, "rock1");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1040}, "rock2");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1040}, "rock3");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1040}, "rock4");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1040}, "rock5");
            }
            for(i = 0; i < 4; i++) {
                vm.methods.newMineral(vm, "cooper", {x: Phaser.Math.Between(250, 4800), y: 1040}, "cooperMineral");
            }
            for(i = 0; i < 2; i++) {
                vm.methods.newMineral(vm, "tin", {x: Phaser.Math.Between(250, 4800), y: 1040}, "tinMineral");
            }
            //3 floor
            for(i = 0; i < 16; i++) {
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1820}, "rock1");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1820}, "rock2");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1820}, "rock3");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1820}, "rock4");
                vm.methods.newMineral(vm, "rock", {x: Phaser.Math.Between(350, 4800), y: 1820}, "rock5");
            }
            for(i = 0; i < 8; i++) {
                vm.methods.newMineral(vm, "cooper", {x: Phaser.Math.Between(250, 4800), y: 1820}, "cooperMineral");
            }
            for(i = 0; i < 4; i++) {
                vm.methods.newMineral(vm, "tin", {x: Phaser.Math.Between(250, 4800), y: 1820}, "tinMineral");
            }
            for(i = 0; i < 2; i++) {
                vm.methods.newMineral(vm, "iron", {x: Phaser.Math.Between(800, 4800), y: 1820}, "ironMineral");
            }
        }

        function scenary(vm) {
            door(vm);
            rocks(vm);
        }

        function createBackgroundCave(vm) {
            var z, i;
            for(z = 0; z < 8; z++) {
                for(i = 0; i < 21; i++) {
                    vm.add.image(256 * i, 256 * z, 'backgroundBlockCave2').setScale(4, 4).setPipeline('Light2D');
                }
            }
        }
    
        function createScene(vm) {
            createBackgroundCave(vm);
            scenary(vm);

            vm.platforms = vm.physics.add.staticGroup();
    
            for(var i = 0; i < 40; i++) {
                //Subsolo
                vm.platforms.create(128 * i, 0, 'blocoMine2').setScale(4, 4).setPipeline('Light2D').refreshBody();
                //1º Andar
                vm.platforms.create(128 * i, 512, 'blocoMine1').setScale(4,4).setPipeline('Light2D').refreshBody();
                vm.platforms.create(128 * i, 640, 'blocoMine2').setScale(4,4).setPipeline('Light2D').refreshBody();
                // 2º Andar
                vm.platforms.create(128 * i, 1152, 'blocoMine1').setScale(4,4).setPipeline('Light2D').refreshBody();
                vm.platforms.create(128 * i, 1280, 'blocoMine2').setScale(4,4).setPipeline('Light2D').refreshBody();
                // 3º Andar
                vm.platforms.create(128 * i, 1920, 'blocoMine1').setScale(4,4).setPipeline('Light2D').refreshBody();
                vm.platforms.create(128 * i, 2048, 'blocoMine2').setScale(4,4).setPipeline('Light2D').refreshBody();
            }
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "doorMine1":
                        vm.scene.restart(); vm.scene.start('external1'); break;
                    case "doorMine2":
                        vm.player.x = 200; vm.player.y = 900; break;
                    case "doorMine3":
                        vm.player.x = 4950; vm.player.y = 350; break;
                    case "doorMine4":
                        vm.player.x = 200; vm.player.y = 1600; break;
                    case "doorMine5":
                        vm.player.x = 4950; vm.player.y = 900; break;
                    case "doorMine6":
                        vm.scene.start('internal2'); break;
                    case "mineral":
                        vm.methods.workCommom(vm, vm.worked.item, vm.worked.time, vm.worked.message, vm.worked.mineral); break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 150, 80, vm.mapSize.y + 100, 0xffffff));
            vm.limits.add(vm.add.rectangle(vm.mapSize.x - 50, 150, 80, vm.mapSize.y + 100, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
                vm.limits.getChildren()[i].body.setAllowGravity(false);
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(initial, vm.player);
            vm.physics.add.collider(end, vm.player);
        }

        function monsterSpawn(vm) {
            vm.enemyArray = [];
            vm.methods.createEnemy(vm, "spider", "spiderIdle", "spiderIdle", 2, 2, 2.5, {bodyX: 35, bodyY: 32}, {x: 5, y: 15}, 200);
            vm.methods.createEnemy(vm, "spider", "spiderIdle", "spiderIdle", 6, 2, 2.5, {bodyX: 35, bodyY: 32}, {x: 5, y: 15}, 1000);
            vm.methods.createEnemy(vm, "spider", "spiderIdle", "spiderIdle", 10, 2, 2.5, {bodyX: 35, bodyY: 32}, {x: 5, y: 15}, 1600);
        }

        this.lights.enable().setAmbientColor(0x020202);

        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.rocksMiner = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        playerImport.createPlayer(this, 250, 250);
        createScene(this);
        createLimits(this);

        this.methods.createKey(this, "enter");

        statusMap.mutations.changeIsExplorer(statusMap.state, true);

        this.physics.add.collider(this.npcs, this.platforms);
        this.physics.add.collider(this.player, this.platforms);
        this.physics.add.collider(this.mainAtk, this.platforms);
        this.physics.add.collider(this.mainAtkMoreDamage, this.platforms);
        this.physics.add.collider(this.physicObject, this.platforms);
        this.physics.add.collider(this.monsterAttack, this.platforms);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        npcImport.createNpc(this, "miner", "miner", "miner", {x: 2.5, y: 3}, {x: 350, y: 300}, false, true);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        
        this.lightPlayer = this.lights.addLight().setRadius(90);
        this.lights.addLight(400, 350, 150).setIntensity(3);

        monsterSpawn(this);
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        updateActions(this);
    }
}