const imgs = [
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "1.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "3.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "4.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "9.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "15.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "14.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "18.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "7.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "8.png"
    },
    {
        idRace: 1,
        nameRace: "Sapiens",
        url: "13.png"
    },
    {
        idRace: 2,
        nameRace: "Nisiriano",
        url: "2.png"
    },
    {
        idRace: 2,
        nameRace: "Nisiriano",
        url: "5.png"
    },
    {
        idRace: 2,
        nameRace: "Nisiriano",
        url: "6.png"
    },
    {
        idRace: 3,
        nameRace: "Germiniano",
        url: "19.png"
    },
    {
        idRace: 4,
        nameRace: "Reptilianos",
        url: "newRaces/4.png"
    },
    {
        idRace: 4,
        nameRace: "Reptilianos",
        url: "newRaces/5.png"
    },
    {
        idRace: 5,
        nameRace: "Uriuns",
        url: "newRaces/14.png"
    },
    {
        idRace: 5,
        nameRace: "Uriuns",
        url: "newRaces/15.png"
    },
    {
        idRace: 6,
        nameRace: "Altmeners",
        url: "10.png"
    },
    {
        idRace: 6,
        nameRace: "Altmeners",
        url: "11.png"
    },
    {
        idRace: 6,
        nameRace: "Altmeners",
        url: "17.png"
    },
    {
        idRace: 7,
        nameRace: "Ignis",
        url: "16.png"
    },
    {
        idRace: 7,
        nameRace: "Ignis",
        url: "12.png"
    },
    {
        idRace: 7,
        nameRace: "Ignis",
        url: "20.png"
    },
    {
        idRace: 8,
        nameRace: "Supreme Uriun",
        url: "newRaces/19.png"
    }
]

export default imgs;