import axios from "axios";

export default {
    state: {
        items: [],
        marketItems: [],
        shop: {
            active: false,
            name: "",
            window: false
        }
    },
    mutations: {
        getItemsBack(state) {
            axios.get(
                "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getItems"
            ).then((res) => {
                state.items = res.data;
            }).catch(error => {
                console.log(error.message);
            });
        },
        async getMarketItemsBack(state) {
            await axios.get(
                "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getMarketItems"
            ).then((res) => {
                state.marketItems = res.data;
            }).catch(error => {
                console.log(error.message);
            });
        },
        changeShop(state, name) {
            state.shop.name = name;
        },
        changeActiveShop(state, boolean) {
            state.shop.active = boolean;
            if(!boolean) {
                state.shop.window = boolean;
            }
        },
        changeOpenWindow(state, boolean) {
            state.shop.window = boolean;
        }
    }
}