import DescItemTooltipComponent from "../../../../shared/components/descItemTooltip/DescItemTooltip.vue";

export default {
    props: [
        'equipData'
    ],
    components: {
        DescItemTooltipComponent
    },
    methods: {
        desequip(item) {
            var newEquip = {
                "groupEquip": item.groupEquip,
                "name": "",
                "urlImg": "",
                "status": {}
            };
            if(item.type == "equipMagic") {
                this.$store.dispatch("changeMagicItem", newEquip);
            } else if(item.groupEquip == "tool") {
                this.$store.dispatch("changeTool", newEquip);
            } else {
                this.$store.dispatch("changeEquipItem", newEquip);
            }
        }
    }
}