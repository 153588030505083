// import {WorkArea2D, destroyGame} from "./components-game/workAreaPhaser";
import {minasLuthios, minasLuthiosdestroyGame} from "@/shared/dungeons/minasLuthios/workAreaPhaser.js";
import {holzfluss, holzflussdestroyGame} from "@/shared/dungeons/holzfluss/workAreaPhaser.js";
import {ruinasSkull, ruinasSkulldestroyGame} from "@/shared/dungeons/ruinasSkull/workAreaPhaser.js";
import {swamp, swampdestroyGame} from "@/shared/dungeons/swamp/workAreaPhaser.js";
import {pyramids, pyramidsdestroyGame} from "@/shared/dungeons/pyramids/workAreaPhaser.js";
import {unknown, unknowndestroyGame} from "@/shared/dungeons/unknown/workAreaPhaser.js";

import ComponentWoodCutting from "./npcs/woodcutting/WoodCutting.vue";
import ComponentHunter from "./npcs/hunter/Hunter.vue";
import ComponentMiner from "./npcs/miner/Miner.vue";

import InventoryComponent from "@/pages/home/components/inventory/Inventory.vue";

import ComponentHpBoss from "./hpBoss/HpBoss.vue";

export default {
    data() {
        return {}
    },
    computed: {
        currentLocation() {
            return this.$store.state.mapModule.currentLocation.name;
        },
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        shop() {
            return this.$store.state.itemsModule.shop;
        },
        hotkeys() {
            return this.$store.state.personModule.mainPerson.hotkeys;
        },
        hpBossEnabled() {
            return this.$store.state.bossesModule.hpBossEnabled;
        }
    },
    components: {
        ComponentWoodCutting,
        ComponentHunter,
        ComponentMiner,
        InventoryComponent,
        ComponentHpBoss
    },
    methods: {},
    mounted() {
        switch(this.$store.state.mapModule.currentLocation.name) {
            case "Minas Luthios":
                minasLuthios(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
            case "Holzfluss":
                holzfluss(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
            case "Ruinas Skull":
                ruinasSkull(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
            case "Pântano":
                swamp(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
            case "Pirâmides":
                pyramids(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
            case "Unknown":
                unknown(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
        }
    },
    destroyed() {
        this.$store.commit("changeShop", "");
        this.$store.commit("changeActiveShop", false);
        this.$store.commit("changeDialogBox", false);
        this.$store.commit("changeHpBossEnabled", false);
        switch(this.$store.state.mapModule.currentLocation.name) {
            case "Minas Luthios":
                minasLuthiosdestroyGame(); break;
            case "Holzfluss":
                holzflussdestroyGame(); break;
            case "Ruinas Skull":
                ruinasSkulldestroyGame(); break;
            case "Pântano":
                swampdestroyGame(); break;
            case "Pirâmides":
                pyramidsdestroyGame(); break;
            case "Unknown":
                unknowndestroyGame(); break;
        }
    }
}