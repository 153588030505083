import { mapGetters } from "vuex"

export default {
    data() {
        return {
            currentTip: 0,
            currentSprite: 0,
            currentBackground: 0,
            sprites: [
                "swordman",
                "spearman",
                "mage",
                "archer"
            ],
            tip: [
                "Hotkeys ajudam muito no fogo da batalha, você pode colocar poções, comidas, spells e orações",
                "Arqueiros são os que possuem o ataque mais longo, mas o menor dano entre as classes, mago possui menor alcance, mas o dano é bem maior",
                "Agilidade aumenta sua velocidade de ataque e velocidade de corrida",
                "A magia funciona como uma força que traz a vida, todos os seres vivos possuem certa quantidade dela e conforme você consegue domina-la, seu poder de domar o universo aumenta",
                "Apenas caçadores podem tirar peles e carne de animais e monstros",
                "Muitas sociedades controlam os submundos de Parallelium, muito cuidado ao conhece-las",
                "Não saia a noite, você pode não voltar",
                "Cuidado com os random events, não arrisque em enganar quem está te atacando se seu carisma for de uma batata",
                "Cuidado! Cultistas de Moonluck foram avistados nesse lugar",
                "O mal teme a Corte, assim como a escuridão teme a luz"
            ]
        }
    },
    computed: {
        isEnable() {
            return this.$store.state.generalModule.loading;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        loadingRandomTipAndImage() {
            this.currentTip = Math.floor(Math.random() * (this.tip.length - 0 + 1)) + 0;
            this.currentSprite = Math.floor(Math.random() * (3 - 0 + 1)) + 0;
            this.currentBackground = Math.floor(Math.random() * (4 - 1 + 1)) + 1;
        }
    },
    mounted() {
        this.loadingRandomTipAndImage();
    }
}