export default [
    {
        idRace: 9,
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/demon/33.png",
        urlInternal: "nfts/demon/33.png",
        name: "Demon #1",
        race: "demon"
    },
    {
        idRace: 9,
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/demon/25.png",
        urlInternal: "nfts/demon/25.png",
        name: "Demon #4",
        race: "demon"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/demon/38.png",
        urlInternal: "nfts/demon/38.png",
        name: "Demon #2"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/demon/41.png",
        urlInternal: "nfts/demon/41.png",
        name: "Demon #3"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/demon/17.png",
        urlInternal: "nfts/demon/17.png",
        name: "Demon #5"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/goblin/47.png",
        urlInternal: "nfts/goblin/47.png",
        name: "Goblin #1"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/goblin/41.png",
        urlInternal: "nfts/goblin/41.png",
        name: "Goblin #2"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/humans/6.png",
        urlInternal: "nfts/humans/6.png",
        name: "Sapiens #1"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/humans/16.png",
        urlInternal: "nfts/humans/16.png",
        name: "Sapiens #2"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/humans/48.png",
        urlInternal: "nfts/humans/48.png",
        name: "Sapiens #3"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/humans/23.png",
        urlInternal: "nfts/humans/23.png",
        name: "Sapiens #4"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/undead/1.png",
        urlInternal: "nfts/undead/1.png",
        name: "Undead #1"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/undead/43.png",
        urlInternal: "nfts/undead/43.png",
        name: "Undead #2"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/undead/48.png",
        urlInternal: "nfts/undead/48.png",
        name: "Undead #3"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/undead/5.png",
        urlInternal: "nfts/undead/5.png",
        name: "Undead #4"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/undead/22.png",
        urlInternal: "nfts/undead/22.png",
        name: "Undead #5"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/darkelf/Icon30.png",
        urlInternal: "nfts/pixelart/darkelf/Icon30.png",
        name: "Special Altmener #5"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/darkelf/Icon26.png",
        urlInternal: "nfts/pixelart/darkelf/Icon26.png",
        name: "Special Altmener #6"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/darkelf/Icon13.png",
        urlInternal: "nfts/pixelart/darkelf/Icon13.png",
        name: "Special Altmener #7"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/darkelf/Icon48.png",
        urlInternal: "nfts/pixelart/darkelf/Icon48.png",
        name: "Special Altmener #8"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/altmener/Icon39.png",
        urlInternal: "nfts/pixelart/altmener/Icon39.png",
        name: "Special Altmener #1"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/altmener/Icon17.png",
        urlInternal: "nfts/pixelart/altmener/Icon17.png",
        name: "Special Altmener #2"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/altmener/Icon22.png",
        urlInternal: "nfts/pixelart/altmener/Icon22.png",
        name: "Special Altmener #3"
    },
    {
        ipfs: "QmdHhgbumhTGhmJNPZDrkaiehquiVcXgf3mPWQLYyfYD7N/pixelart/altmener/Icon4.png",
        urlInternal: "nfts/pixelart/altmener/Icon4.png",
        name: "Special Altmener #4"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/poison_sword_639f76a721.gif",
        urlInternal: "weapons/swords/poison_sword_639f76a721.gif",
        name: "Poison Sword #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/swordsice_sword_3cfeaf1996.gif",
        urlInternal: "weapons/swords/swordsice_sword_3cfeaf1996.gif",
        name: "Ice Sword #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/fire_sword_fcde39c3af.gif",
        urlInternal: "weapons/swords/fire_sword_fcde39c3af.gif",
        name: "Fire Sword #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/65.png",
        urlInternal: "weapons/swords/65.png",
        name: "Iron Sword #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/33.png",
        urlInternal: "weapons/swords/33.png",
        name: "Stell Sword #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/16.png",
        urlInternal: "weapons/swords/16.png",
        name: "Stell Sword #2"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/9.png",
        urlInternal: "weapons/swords/9.png",
        name: "Iron Sword #2"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/swords/71.png",
        urlInternal: "weapons/swords/71.png",
        name: "Special Sword #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/axe/4.png",
        urlInternal: "weapons/axes/4.png",
        name: "Iron Axe #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/axe/22.png",
        urlInternal: "weapons/axes/22.png",
        name: "Stell Axe #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/axe/43.png",
        urlInternal: "weapons/axes/43.png",
        name: "Special Axe #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/axe/13.png",
        urlInternal: "weapons/axes/13.png",
        name: "Special Axe #2"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/axe/28.png",
        urlInternal: "weapons/axes/28.png",
        name: "Gold Axe #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/lance/1.png",
        urlInternal: "weapons/lance/1.png",
        name: "Rock Lance #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/lance/38.png",
        urlInternal: "weapons/lance/38.png",
        name: "Trident Lance #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/lance/16.png",
        urlInternal: "weapons/lance/16.png",
        name: "Stell Lance #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/lance/2.png",
        urlInternal: "weapons/lance/2.png",
        name: "Iron Lance #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/lance/35.png",
        urlInternal: "weapons/lance/35.png",
        name: "Special Lance #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/bows/1.png",
        urlInternal: "weapons/bows/1.png",
        name: "Simple Bow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/bows/88.png",
        urlInternal: "weapons/bows/88.png",
        name: "Asian Special War Bow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/bows/10.png",
        urlInternal: "weapons/bows/10.png",
        name: "Long Bow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/bows/2.png",
        urlInternal: "weapons/bows/2.png",
        name: "Hunt Bow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/bows/22.png",
        urlInternal: "weapons/bows/22.png",
        name: "Future Bow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/bows/20.png",
        urlInternal: "weapons/bows/20.png",
        name: "Angel Bow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/arrows/1.png",
        urlInternal: "weapons/arrows/20.png",
        name: "Simple Arrow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/arrows/18.png",
        urlInternal: "weapons/arrows/18.png",
        name: "Heavy Stell Arrow #1"
    },
    {
        ipfs: "QmbzjXBUzbxr9e7MvNSEhN7xnJumnRPDEiDRYyn78VsSuh/arrows/15.png",
        urlInternal: "weapons/arrows/15.png",
        name: "Elfic Arrow #1"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/1.png",
        urlInternal: "shields/1.png",
        name: "wodden shield #1"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/8.png",
        urlInternal: "shields/8.png",
        name: "wodden shield #2"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/32.png",
        urlInternal: "shields/32.png",
        name: "wodden shield #3"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/12.png",
        urlInternal: "shields/12.png",
        name: "Iron Shield #1"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/18.png",
        urlInternal: "shields/18.png",
        name: "Iron Shield #2"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/50.png",
        urlInternal: "shields/50.png",
        name: "Special Shield #1"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/28.png",
        urlInternal: "shields/28.png",
        name: "Demon Shield #1"
    },
    {
        ipfs: "Qme8wd4e2RXCsdrLahFScgJPdETJmy919fu6Bq6ftyPcsM/14.png",
        urlInternal: "shields/14.png",
        name: "Special Shield #2"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/2.png",
        urlInternal: "magic/staff/2/2.png",
        name: "Wood Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/4.png",
        urlInternal: "magic/staff/2/4.png",
        name: "Wood Staff #2"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/11.png",
        urlInternal: "magic/staff/2/11.png",
        name: "Gold Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/8.png",
        urlInternal: "magic/staff/2/8.png",
        name: "Healer Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/49.png",
        urlInternal: "magic/staff/2/49.png",
        name: "Special Fire Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/46.png",
        urlInternal: "magic/staff/2/46.png",
        name: "Special Ice Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/36.png",
        urlInternal: "magic/staff/2/36.png",
        name: "Special Demon Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/84.png",
        urlInternal: "magic/staff/84.png",
        name: "Special Witcher Staff #1"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/83.png",
        urlInternal: "magic/staff/83.png",
        name: "Special Staff #2"
    },
    {
        ipfs: "QmQ865qSshVuY4swGUScWZLWzb9jn4QV8QBhkVhQEpj9Nm/2/29.png",
        urlInternal: "magic/staff/2/29.png",
        name: "Special Greed #1"
    },
    {
        ipfs: "QmTzm5K4Kvk92apJ7rsRQ51A7XDLR7DFrfZjEQXY7E7kUc/1.png",
        urlInternal: "magic/artifacts/1.png",
        name: "Crystal Ball #1"
    },
    {
        ipfs: "QmTzm5K4Kvk92apJ7rsRQ51A7XDLR7DFrfZjEQXY7E7kUc/42.png",
        urlInternal: "magic/artifacts/42.png",
        name: "Animal Head #1"
    },
    {
        ipfs: "QmTzm5K4Kvk92apJ7rsRQ51A7XDLR7DFrfZjEQXY7E7kUc/16.png",
        urlInternal: "magic/artifacts/16.png",
        name: "Mask #1"
    },
    {
        ipfs: "QmTzm5K4Kvk92apJ7rsRQ51A7XDLR7DFrfZjEQXY7E7kUc/48.png",
        urlInternal: "magic/artifacts/48.png",
        name: "Crystal #1"
    },
    {
        ipfs: "QmNYzxhWnKBQpAnUPZ3j4RnAoxM85der5QN2DRacBNS3Dx/11.png",
        urlInternal: "magic/demonArtifacts/11.png",
        name: "Demon Collection #1"
    },
    {
        ipfs: "QmNYzxhWnKBQpAnUPZ3j4RnAoxM85der5QN2DRacBNS3Dx/22.png",
        urlInternal: "magic/demonArtifacts/22.png",
        name: "Demon Collection #2"
    },
    {
        ipfs: "QmNYzxhWnKBQpAnUPZ3j4RnAoxM85der5QN2DRacBNS3Dx/48.png",
        urlInternal: "magic/demonArtifacts/48.png",
        name: "Demon Collection #3"
    },
    {
        ipfs: "QmNYzxhWnKBQpAnUPZ3j4RnAoxM85der5QN2DRacBNS3Dx/45.png",
        urlInternal: "magic/demonArtifacts/45.png",
        name: "Demon Collection #4"
    },
    {
        ipfs: "QmNYzxhWnKBQpAnUPZ3j4RnAoxM85der5QN2DRacBNS3Dx/13.png",
        urlInternal: "magic/demonArtifacts/13.png",
        name: "Demon Collection #5"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/1.png",
        urlInternal: "armor/2/1.png",
        name: "Simple Shirt #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/9.png",
        urlInternal: "armor/2/9.png",
        name: "Medium Armor Reforced #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/30.png",
        urlInternal: "armor/2/30.png",
        name: "Special Stell Armor #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/22.png",
        urlInternal: "armor/2/22.png",
        name: "Special Asian Medium Armor #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/33.png",
        urlInternal: "armor/2/33.png",
        name: "Special Demom Armor #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/26.png",
        urlInternal: "armor/2/26.png",
        name: "Stell Armor #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/10.png",
        urlInternal: "armor/2/10.png",
        name: "Special Medieval #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/27.png",
        urlInternal: "armor/2/27.png",
        name: "Special Asian Heavy Armor #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/13.png",
        urlInternal: "armor/2/13.png",
        name: "Leather Armor #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/31.png",
        urlInternal: "armor/2/31.png",
        name: "Special Paladin #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/42.png",
        urlInternal: "armor/2/42.png",
        name: "Special Robe #1"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/47.png",
        urlInternal: "armor/2/47.png",
        name: "Special Robe #2"
    },
    {
        ipfs: "QmXfbuYrMuXGFRySxaK9BDWCEtZYbNj9etTias5P5KS9ch/2/43.png",
        urlInternal: "armor/2/43.png",
        name: "Special Greed #2"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/95.png",
        urlInternal: "acessories/rings/95.png",
        name: "Gold Ring #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/96.png",
        urlInternal: "acessories/rings/96.png",
        name: "Silver Ring #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/101.png",
        urlInternal: "acessories/rings/101.png",
        name: "White Gold Ring #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/90.png",
        urlInternal: "acessories/rings/90.png",
        name: "Special Demon Ring #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/8.png",
        urlInternal: "acessories/rings/8.png",
        name: "Gold Ring #2"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/91.png",
        urlInternal: "acessories/rings/91.png",
        name: "Gold Ring #3"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/rings/16.png",
        urlInternal: "acessories/rings/16.png",
        name: "Silver Ring #2"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/necklace/110.png",
        urlInternal: "acessories/necklace/110.png",
        name: "Special Demon Necklace #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/necklace/25.png",
        urlInternal: "acessories/necklace/25.png",
        name: "Nature Necklace #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/necklace/27.png",
        urlInternal: "acessories/necklace/27.png",
        name: "Pixie Necklace #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/necklace/115.png",
        urlInternal: "acessories/necklace/115.png",
        name: "Blood Necklace #1"
    },
    {
        ipfs: "QmV8ADvc7oTC4eFeYxXHFCmTLxYBSuWWeyjQTUvwb35zPG/necklace/109.png",
        urlInternal: "acessories/necklace/109.png",
        name: "Shield Necklace #1"
    },
    {
        ipfs: "QmSBnH648DjuLbfRG84TJxnZoJcPkENXhB82KziLWebLWa",
        urlInternal: "nfts/skins/nightTank.gif",
        name: "Night Tank",
        sprite: "nightTank"
    },
    {
        ipfs: "QmUW7dfFroKUicyLNHVHFeXwaUvej3n7MyFcwmF2kF4hTM",
        urlInternal: "nfts/skins/nordicBerserker.gif",
        name: "Nordic Berserker",
        sprite: "nordicBarbarian"
    },
    {
        ipfs: "QmZPy1KCVuUsUsN1FsC59M9no9YdcYvD13G4rbsVCU9kxt",
        urlInternal: "nfts/skins/infernalMage.gif",
        name: "Infernal Mage",
        sprite: "infernalMage"
    },
    {
        ipfs: "QmebBpBf6MbJVW4J6aZAp1y3BTQQrhrduzRKFGtuzBbCaU",
        urlInternal: "nfts/skins/orc.gif",
        name: "Orc",
        sprite: "orc"
    },
    {
        ipfs: "QmUrrfzQEyv7DFFcuaFoypGzVQybA83JQvvhKvEzDdhwC8",
        urlInternal: "nfts/skins/bloodhit.gif",
        name: "Blood Hit",
        sprite: "bloodHit"
    },
    {
        ipfs: "QmUTS3mjRdeCjVujnyUfA9K6MBpESTDmc7dd3MStZQzSGv",
        urlInternal: "nfts/skins/altmenerArcher.gif",
        name: "Altmener Archer",
        sprite: "altmenerArcher"
    },
    {
        ipfs: "QmVSAcYTxixx25EPZpHSbVFsL3dwyCg7Wxx9JwJG5kbH2a",
        urlInternal: "nfts/skins/bloodQueen.gif",
        name: "Blood Queen",
        sprite: "bloodQueen"
    },
    {
        ipfs: "QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/starterPack.json",
        urlInternal: "86.png",
        name: "Starter Pack",
        items: [
            {
                name: "life potion",
                amount: 30
            },
            {
                name: "energy potion",
                amount: 30
            },
            {
                name: "Mana potion",
                amount: 30
            },
            {
                name: "espada de bronze",
                amount: 1
            },
            {
                name: "wodden shield",
                amount: 1
            }
        ]
    },
    {
        ipfs: "QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/levelUpPack.json",
        urlInternal: "27.png",
        name: "Level up Pack",
        "items": [
            {
                "name": "level up potion",
                "amount": 5
            }
        ]
    },
    {
        ipfs: "QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/adventurePack.json",
        urlInternal: "89.png",
        name: "Adventure Pack",
        items: [
            {
                name: "life potion",
                amount: 150
            },
            {
                name: "energy potion",
                amount: 100
            },
            {
                name: "Mana potion",
                amount: 100
            },
            {
                name: "espada de bronze",
                amount: 1
            },
            {
                name: "wodden shield",
                amount: 1
            },
            {
                name: "leather armor",
                amount: 1
            },
            {
                name: "leather gaunlets",
                amount: 1
            },
            {
                name: "leather boots",
                amount: 1
            },
            {
                name: "leather helmet",
                amount: 1
            },
            {
                name: "simple horse",
                amount: 1
            },
            {
                name: "level up potion",
                amount: 3
            }
        ]
    },
    {
        ipfs: "QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/archerPack.json",
        urlInternal: "45.png",
        name: "Archer Pack",
        items: [
            {
                name: "life potion",
                amount: 150
            },
            {
                name: "energy potion",
                amount: 150
            },
            {
                name: "Mana potion",
                amount: 50
            },
            {
                name: "simple bow",
                amount: 1
            },
            {
                name: "iron arrow",
                amount: 3
            },
            {
                name: "leather armor",
                amount: 1
            },
            {
                name: "leather gaunlets",
                amount: 1
            },
            {
                name: "leather boots",
                amount: 1
            },
            {
                name: "leather helmet",
                amount: 1
            },
            {
                name: "simple horse",
                amount: 1
            },
            {
                name: "level up potion",
                amount: 3
            }
        ]
    },
    {
        ipfs: "QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/spearmanPack.json",
        urlInternal: "7.png",
        name: "Spearman Pack",
        items: [
            {
                name: "life potion",
                amount: 150
            },
            {
                name: "energy potion",
                amount: 150
            },
            {
                name: "Mana potion",
                amount: 50
            },
            {
                name: "rock lance",
                amount: 1
            },
            {
                name: "wodden shield",
                amount: 3
            },
            {
                name: "leather armor",
                amount: 1
            },
            {
                name: "leather gaunlets",
                amount: 1
            },
            {
                name: "leather boots",
                amount: 1
            },
            {
                name: "leather helmet",
                amount: 1
            },
            {
                name: "simple horse",
                amount: 1
            },
            {
                name: "level up potion",
                amount: 3
            }
        ]
    },
    {
        ipfs: "QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/magePack.json",
        urlInternal: "26.png",
        name: "Mage Pack",
        items: [
            {
                name: "life potion",
                amount: 150
            },
            {
                name: "energy potion",
                amount: 50
            },
            {
                name: "Mana potion",
                amount: 150
            },
            {
                name: "Cajado de fogo (Primária)",
                amount: 1
            },
            {
                name: "novice book",
                amount: 1
            },
            {
                name: "Armadura do Mago Iniciante",
                amount: 1
            },
            {
                name: "Manopla do Mago Iniciante",
                amount: 1
            },
            {
                name: "Botas do Mago Iniciante",
                amount: 1
            },
            {
                name: "Chápeu do Mago Iniciante",
                amount: 1
            },
            {
                name: "simple horse",
                amount: 1
            },
            {
                name: "level up potion",
                amount: 3
            }
        ]
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    },
    {
        ipfs: "",
        urlInternal: "",
        name: ""
    }
]