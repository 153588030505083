import ComponentDescSkill from "../../components/descSkill/DescSkill.vue";

export default {
    components: {
        ComponentDescSkill
    },
    computed: {
        skills() {
            return this.$store.state.skillModule.skills.filter(i => i.path === "spearman" && i.class === "main");
        },
        allSkills() {
            return this.$store.state.personModule.mainPerson.skills;
        }
    },
    methods: {
        addPassiveSkill(skill) {
            if(this.$store.state.personModule.mainPerson.skills.includes(skill.code)) return;
            this.$store.dispatch("addPassiveSkill", skill);
        }
    }
}