import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";
import { methodsDungeon } from "@/shared/components-phaser/methods";

import keyboardsImport from "@/shared/components-phaser/keyboards" 
import playerImport from "@/shared/components-phaser/player" 
import { updatePlayer } from "@/shared/components-phaser/updateActions" 

import npcImport from "@/shared/components-phaser/createNpc" 

import preloadAllResources from "@/shared/components-phaser/preload.js"

import AnimatedTiles from 'phaser-animated-tiles/dist/AnimatedTiles.min.js'

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;

class SceneTavern extends Phaser.Scene {
    constructor(locationInfos, mainPerson){
        super("tavernScene");
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.player;
        this.ladders;
        this.key;
        this.mapSize = {
            x: 1280,
            y: 2000
        };
        this.layer = {};
        this.map;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.scenePlugin('animatedTiles', AnimatedTiles, 'animatedTiles', 'animatedTiles');

        // Resources all maps
        preloadAllResources(this);

        this.load.audio('ambientTavern', '/resourcesInGame/sons/ambient/tavern/Tavern_1_Loop.wav');
        this.load.audio('musicTavern', '/resourcesInGame/sons/musics/village/tavern/Theme_-_Knights_Tale.ogg');

        // Tilemap
        this.load.image('floorTavern1', '/resourcesInGame/title-set/tiles/village/set/Tavern.png');
        this.load.image('objectsTavern1', '/resourcesInGame/title-set/tiles/village/set/VP2_Interriors.png');
        this.load.image('objects2', '/resourcesInGame/title-set/objects/animated/Light2.png');
        this.load.tilemapTiledJSON("tavernMap", "/resourcesInGame/maps/eisendorf/taverna.json");

        //Constructions
        this.load.image("swordmanMonument", "/resourcesInGame/title-set/monuments/swordman.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/village/Green_Montains_BGa.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/road.png");
        this.load.image("mountains2", "/resourcesInGame/title-set/parallax/mountains/village/Green_Montains_BGc.png");
        this.load.image("mountains", "/resourcesInGame/title-set/parallax/trees/village/Green_Montains_BGd.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/village/Green_Montains_BGe.png");

        //animatedConstructions

        //NPCs
        this.load.spritesheet('musician', '/resourcesInGame/sprites/npcs/tavern/Tavern_Musician.png', { frameWidth: 32, frameHeight: 64 });
        this.load.spritesheet('Guest2', '/resourcesInGame/sprites/npcs/tavern/Tavern_Guest2.png', { frameWidth: 32, frameHeight: 64 });
        this.load.spritesheet('Guest1', '/resourcesInGame/sprites/npcs/tavern/Tavern_Guest1.png', { frameWidth: 32, frameHeight: 64 });
        this.load.spritesheet('Guest3', '/resourcesInGame/sprites/npcs/tavern/Tavern_Guest3.png', { frameWidth: 32, frameHeight: 64 });
        this.load.spritesheet('bartender', '/resourcesInGame/sprites/npcs/tavern/Tavern_Bartender.png', { frameWidth: 32, frameHeight: 64 });
        this.load.spritesheet('adventureMaster', '/resourcesInGame/sprites/npcs/tavern/NPC_Admurin.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('axelMan', '/resourcesInGame/sprites/npcs/tavern/NPC_Axel_B_Look_Right.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('mageMan', '/resourcesInGame/sprites/npcs/tavern/NPC_Benedict.png', { frameWidth: 64, frameHeight: 64 });
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            //NPC
            vm.anims.create({
                key: "musician",
                frames: vm.anims.generateFrameNumbers('musician', {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "Guest2",
                frames: vm.anims.generateFrameNumbers('Guest2', {start: 0, end: 4}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "Guest1",
                frames: vm.anims.generateFrameNumbers('Guest1', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "Guest3",
                frames: vm.anims.generateFrameNumbers('Guest1', {start: 0, end: 5}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "bartender",
                frames: vm.anims.generateFrameNumbers('bartender', {start: 0, end: 10}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "adventureMaster",
                frames: vm.anims.generateFrameNumbers('adventureMaster', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "axelMan",
                frames: vm.anims.generateFrameNumbers('axelMan', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "mageMan",
                frames: vm.anims.generateFrameNumbers('mageMan', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })
        }

        function createScene(vm) {

            vm.map = vm.make.tilemap({ key: "tavernMap", tileWidth: 16, tileHeight: 16});
            var floor = vm.map.addTilesetImage("Tavern", "floorTavern1");
            var objects1 = vm.map.addTilesetImage("VP2_Interriors", "objectsTavern1");
            var objects2 = vm.map.addTilesetImage("Light2", "objects2");
            vm.layer.background = vm.map.createLayer("background", [floor, objects1], 0, -400).setScale(2, 2.5);
            vm.layer.floor = vm.map.createLayer("floor", floor, 0, -400).setScale(2, 2.5);
            vm.layer.objects2 = vm.map.createLayer("objects2", [floor ,objects1, objects2], 0, -400).setScale(2, 2.5);
            vm.layer.objects = vm.map.createLayer("objects", [floor, objects1], 0, -400).setDepth(9).setScale(2, 2.5);

            vm.sys.animatedTiles.init(vm.map);

            vm.layer.floor.setCollisionBetween(0, 50000);
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "subir":
                        vm.player.x = 1050; 
                        vm.player.y = 0;
                        break;
                    case "descer":
                        vm.player.x = 1000;
                        vm.player.y = 700;
                        break;
                    case "exit":
                        vm.game.sound.stopAll();
                        vm.scene.start('eisendorfScene');
                        break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }

        function ladders(vm) {
            var initial, end, exit; 
            vm.ladders.add(vm.add.rectangle(1050, 700, 80, 150, 0xffffff));
            vm.ladders.add(vm.add.rectangle(1000, 120, 120, 180, 0xffffff));
            vm.ladders.add(vm.add.rectangle(130, 640, 90, 250, 0xffffff));
            for(var i = 0; i < vm.ladders.getChildren().length; i++) {
                vm.ladders.getChildren()[i].alpha = 0;
                vm.ladders.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.ladders.getChildren()[i]);
                vm.ladders.getChildren()[i].body.moves = false;
                vm.ladders.getChildren()[i].body.setAllowGravity(false);
            }
            
            initial = vm.ladders.getChildren()[0];
            end = vm.ladders.getChildren()[1];
            exit = vm.ladders.getChildren()[2];

            vm.physics.add.overlap(vm.player, exit, function() {
                if(vm.interage != "exit") vm.interage = "exit";
            });

            vm.physics.add.overlap(vm.player, initial, function() {
                if(vm.interage != "subir") vm.interage = "subir";
            });
            
            vm.physics.add.overlap(vm.player, end, function() {
                if(vm.interage != "descer") vm.interage = "descer";
            });
        }

        var ambient = this.sound.add('ambientTavern');
        var theme = this.sound.add('musicTavern');

        ambient.play({loop: true, volume: 0.6});
        theme.play({loop: true, volume: 0.4});

        this.mainAtk = this.add.group();
        this.physicObject = this.physics.add.group();
        this.physics.world.enable(this.mainAtk);
        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);
        this.ladders = this.physics.add.group();

        this.npcs = this.physics.add.group();

        createAnimations(this);
        playerImport.createPlayer(this, 130, 600);
        createScene(this);
        ladders(this);

        this.methods.createKey(this, "enter");

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);

        this.physics.add.collider(this.physicObject, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        npcImport.createNpc(this, "musician", "musician", "musician", {x: 1.8, y: 2.1}, {x: 430, y: 705}, false, false);
        npcImport.createNpc(this, "Guest1", "Guest1", "Guest1", {x: 1.8, y: 2.1}, {x: 520, y: 705}, false, false);
        npcImport.createNpc(this, "Guest2", "Guest2", "Guest2", {x: 1.8, y: 2.1}, {x: 630, y: 705}, false, false);
        npcImport.createNpc(this, "Guest3", "Guest3", "Guest3", {x: 1.8, y: 2.1}, {x: 500, y: 0}, true, false);
        // createNpc(this, "spearmanGuard", "spearmanGuard", "spearmanGuard", {x: 2.5, y: 3}, {x: 170, y: 710}, true, false, {x: 35, y: 35});
        npcImport.createNpc(this, "bartender", "bartender", "bartender", {x: 1.8, y: 2.1}, {x: 850, y: 700}, false, true, {x: 50, y: 70});
        npcImport.createNpc(this, "adventureMaster", "adventureMaster", "adventureMaster", {x: 2.5, y: 2.7}, {x: 750, y: 0}, true, true);
        npcImport.createNpc(this, "axelMan", "axelMan", "axelMan", {x: 2.5, y: 2.7}, {x: 320, y: 0}, false, false);
        npcImport.createNpc(this, "mageMan", "mageMan", "mageMan", {x: 2.5, y: 2.7}, {x: 1050, y: 0}, false, false);
        // createNpc(this, "fisherman", "fisherman", "fisherman", {x: 2.5, y: 2.9}, {x: 7150, y: 600}, false, false);

        this.cameras.main.startFollow(this.player).setBounds(0, -400, this.mapSize.x, this.mapSize.y - 750);
        this.cameras.main.zoom = 1.5;

        keyboardControls(this);
    }

    update ()
    {
        if (!this.player.body.embedded) {
            this.interage = "none";
        }
        updatePlayer(this);
    }
}

export default SceneTavern;