import DescItemTooltipComponent from "@/shared/components/descItemTooltip/DescItemTooltip.vue";
import { mapGetters } from "vuex"

export default {
    data(){
        return {
            priceSell: 1,
        }
    },
    computed: {
        itemSell() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        itemQtd() {
            var amount = 0;
            this.$store.state.personModule.mainPerson.Inventory.forEach(item => {
                if(item.name === this.$props.items.name) {
                    amount += item.qtd;
                }
            })
            return amount;
        },
        ...mapGetters(['translateWords'])
    },
    props: [
        'items'
    ],
    components: {
        DescItemTooltipComponent
    },
    methods: {
        sellItem(item){
            var price = item.price - item.price * ((this.$store.state.personModule.mainPerson.infos[2].li[2].value == 0 ? 1 : this.$store.state.personModule.mainPerson.infos[2].li[2].value) / 200);
            let newItem = {
                item: item,
                quantidade: this.priceSell
            };
            var alert = {
                type: "default",
                imgUrl: "",
                message: ""
            }
            let index = this.$store.state.personModule.mainPerson.Inventory.indexOf(item);
            if(this.$store.state.personModule.mainPerson.Inventory[index].qtd < newItem.quantidade || !this.$store.state.personModule.mainPerson.Inventory[index].qtd){
                alert.imgUrl = "actions/6.png"
                alert.message = "Você não possui itens suficientes!";
                this.$store.dispatch("showAlert", alert);
                return;
            } else {
                // var loot = {
                //     exp: price * newItem.quantidade / 50,
                //     gold: 0
                // }
                alert.imgUrl = "actions/bag.png"
                alert.message = `Você vendeu x${newItem.quantidade} ${item.name}`;
                // this.$store.commit("changeXPandGold", loot);
                this.$store.commit("changeMoney", ((price * 0.6) * newItem.quantidade));
                this.$store.commit("deleteItemInventory", newItem);
                this.$store.dispatch("showAlert", alert);
                this.$forceUpdate();
            }
        },
        sellAllItem(item) {
            var price = item.price - item.price * ((this.$store.state.personModule.mainPerson.infos[2].li[2].value == 0 ? 1 : this.$store.state.personModule.mainPerson.infos[2].li[2].value) / 200);
            let newItem = {
                item: item,
                quantidade: item.qtd
            };
            var alert = {
                type: "default",
                imgUrl: "",
                message: ""
            }
            let index = this.$store.state.personModule.mainPerson.Inventory.indexOf(item);
            if(this.$store.state.personModule.mainPerson.Inventory[index].qtd < newItem.quantidade){
                alert.imgUrl = "actions/6.png"
                alert.message = "Você não possui itens suficientes!";
                this.$store.dispatch("showAlert", alert);
            } else {
                // var loot = {
                //     exp: price * newItem.quantidade / 50,
                //     gold: 0
                // }
                alert.imgUrl = "actions/bag.png"
                alert.message = `Você comprou x${newItem.quantidade} ${item.name}`;
                // this.$store.commit("changeXPandGold", loot);
                // this.$store.commit("changeXPandGold", ((price * newItem.quantidade) * 0.6));
                this.$store.commit("changeMoney", ((price * newItem.quantidade) * 0.6));
                this.$store.commit("deleteItemInventory", newItem);
                this.$store.dispatch("showAlert", alert);
                this.$forceUpdate();
            }
        }
    }
}