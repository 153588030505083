const works = [
    {
        id: 0,
        name: "Coletor",
        type: "collector",
        urlImg: 'https://biovip.pt/media/ingredients/CogumeloDoSol.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 500
            }
        },
        points: 0,
        boost: 0,
        base: 5,
        exp: 5
    },
    {
        id: 1,
        name: "Lenhador",
        type: "woodcutting",
        urlImg: 'https://www.trovareaventura.com.br/wp-content/uploads/2016/10/machado_dan_02_09_2015_ntk_322020_78965584189151.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 500
            }
        },
        points: 0,
        boost: 0,
        base: 10,
        exp: 5
    },
    {
        id: 2,
        name: "Caçador",
        type: "hunt",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 700
            }
        },
        points: 0,
        boost: 0,
        base: 15,
        exp: 5
    },
    {
        id: 3,
        name: "Pescador",
        type: "fishing",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 350
            }
        },
        points: 0,
        boost: 0,
        base: 12,
        exp: 5
    },
    {
        id: 4,
        name: "Minerador",
        type: "miner",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1000
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 5,
        name: "Alquimista",
        type: "alchemist",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1000
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 6,
        name: "Cozinheiro",
        type: "cooker",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1000
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 7,
        name: "Refinador",
        type: "refiner",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1000
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 8,
        name: "Ferreiro",
        type: "smithing",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1500
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 9,
        name: "Forjador de armaduras",
        type: "armor forger",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1500
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 10,
        name: "Joalheiro",
        type: "jeweler",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1500
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 10,
        name: "Ferramenteiro",
        type: "toolmaker",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1500
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    },
    {
        id: 11,
        name: "Aventureiro",
        type: "adventurer",
        urlImg: 'https://www.abcdobebe.com/wp-content/uploads/2017/06/O-ca%C3%A7ador-furunfunfor.png',
        level: {
            value: 1,
            exp: {
                value: 0,
                maxValue: 1500
            }
        },
        points: 0,
        boost: 0,
        base: 25,
        exp: 5
    }
]

export default works;