import Phaser from "phaser";

import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods" 
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateActions } from "@/shared/components-phaser/updateActions" 

import BattleService from "@/core/services/BattleService";

import AnimatedTiles from 'phaser-animated-tiles/dist/AnimatedTiles.min.js'

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene1 extends Phaser.Scene {
    constructor(){
        super("external1");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.player;
        this.mapSize = {
            x: 17280,
            y: 2400
        };
        this.waterHurt = true;
        this.layer = {};
        this.map;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.traps;
        this.sounds = {};
        this.player;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.scenePlugin('animatedTiles', AnimatedTiles, 'animatedTiles', 'animatedTiles');

        // Ambient Sons
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/ruins/Cave-2_Loop.wav');
        this.load.audio('music', '/resourcesInGame/sons/musics/pyramids/Theme_-_Ritual_Rites.ogg');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/piramids/ThePyramidsTilesV3.png');
        this.load.image('objects1', '/resourcesInGame/title-set/tiles/piramids/ThePyramidsObjectsV3.png');
        this.load.image('background1', '/resourcesInGame/title-set/tiles/piramids/ThePyramidsBG.png');
        this.load.tilemapTiledJSON("mapPyramids", "/resourcesInGame/maps/pyramids/scene1-pyramids.json");

        //enemies
        this.load.spritesheet('mummyIdle', '/resourcesInGame/sprites/desert/mummy/Mummy_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('mummyWalk', '/resourcesInGame/sprites/desert/mummy/Mummy_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('mummyAttack', '/resourcesInGame/sprites/desert/mummy/Mummy_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('mummyHurt', '/resourcesInGame/sprites/desert/mummy/Mummy_hurt.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('mummyDeath', '/resourcesInGame/sprites/desert/mummy/Mummy_death.png', {frameWidth: 48, frameHeight: 48});
        
        this.load.spritesheet('priestMummyIdle', '/resourcesInGame/sprites/desert/priest-mummy/Deceased_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('priestMummyWalk', '/resourcesInGame/sprites/desert/priest-mummy/Deceased_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('priestMummyAttack', '/resourcesInGame/sprites/desert/priest-mummy/Deceased_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('priestMummyHurt', '/resourcesInGame/sprites/desert/priest-mummy/Deceased_hurt.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('priestMummyDeath', '/resourcesInGame/sprites/desert/priest-mummy/Deceased_death.png', {frameWidth: 48, frameHeight: 48});

        this.load.spritesheet('priestMummyBall', '/resourcesInGame/effects/ball/Ball.png', {frameWidth: 8, frameHeight: 6});

        // Portal
        this.load.spritesheet("portal", "/resourcesInGame/effects/portal/Portal.png", {frameWidth: 64, frameHeight: 64});

        // Traps
        this.load.image('rock', '/resourcesInGame/sprites/traps/rock.png');
        
        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);
    }

    create ()
    {
        var keyboard = {};

        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            vm.anims.create({
                key: "portalAnim",
                frames: vm.anims.generateFrameNumbers("portal", {start: 0, end: 4}),
                frameRate: 10,
                repeat: -1
            })

            // Enemy
            //slime
            vm.anims.create({
                key: "mummyIdle",
                frames: vm.anims.generateFrameNumbers("mummyIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "mummyWalk",
                frames: vm.anims.generateFrameNumbers("mummyWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "mummyAttack",
                frames: vm.anims.generateFrameNumbers("mummyAttack", {start: 0, end: 3}),
                frameRate: 4,
                repeat: -1
            }),
            vm.anims.create({
                key: "mummyHurt",
                frames: vm.anims.generateFrameNumbers("mummyHurt", {start: 0, end: 1}),
                frameRate: 5
            }),
            vm.anims.create({
                key: "mummyDeath",
                frames: vm.anims.generateFrameNumbers("mummyDeath", {start: 0, end: 5}),
                frameRate: 8
            }),

            //priestMummy
            vm.anims.create({
                key: "priestMummyIdle",
                frames: vm.anims.generateFrameNumbers("priestMummyIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "priestMummyWalk",
                frames: vm.anims.generateFrameNumbers("priestMummyWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "priestMummyAttack",
                frames: vm.anims.generateFrameNumbers("priestMummyAttack", {start: 0, end: 3}),
                frameRate: 4,
                repeat: -1
            }),
            vm.anims.create({
                key: "priestMummyHurt",
                frames: vm.anims.generateFrameNumbers("priestMummyHurt", {start: 0, end: 1}),
                frameRate: 5
            }),
            vm.anims.create({
                key: "priestMummyDeath",
                frames: vm.anims.generateFrameNumbers("priestMummyDeath", {start: 0, end: 5}),
                frameRate: 8
            })
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            function timeout(x, y) {
                var timeout = setTimeout(() => {
                    vm.player.x = x; 
                    vm.player.y = y;
                    vm.player.setTint(0xffffff);
                    vm.player.setAlpha(1);
                    clearTimeout(timeout);
                }, 1500)
            }

            keyboard.enter.on('down', function () {
                switch(vm.interage) {
                    case "portal":
                        vm.sounds.portal.play({volume: 1});
                        vm.player.setTint(0x000000);
                        vm.player.setAlpha(0.3);
                        timeout(6750, 490);
                        break;

                    case "portal2":
                        vm.sounds.portal.play({volume: 1});
                        vm.player.setTint(0x000000);
                        vm.player.setAlpha(0.3);
                        timeout(7110, 910);
                        break;
                }
            });
        }
        
        function monsterSpawn(vm) {
            var spawnLocations = {
                mummy: [{x: 900, y: 350}, {x: 2750, y: 350}, {x: 1200, y: 1350}, {x: 2500, y: 1350}, {x: 3500, y: 1350}, {x: 5800, y: 900}, {x: 6900, y: 1300}, {x: 7700, y: 1300}, {x: 8100, y: 1300}, {x: 12000, y: 800}, {x: 11500, y: 800}, {x: 11500, y: 1200}, {x: 12500, y: 700}, {x: 13500, y: 700} ],
                priestMummy: [{x: 1100, y: 350}, {x: 3600, y: 300}, {x: 500, y: 1350}, {x: 1800, y: 1350}, {x: 2500, y: 1350}, {x: 6000, y: 900}, {x: 7300, y: 1300}, {x: 9490, y: 1320}, {x: 11800, y: 1200}, {x: 12300, y: 1200}, {x: 13000, y: 1200}]
            }
            vm.enemyArray = [];
            vm.methods.spawnEnemys(vm, "mummy", "mummyWalk", "mummyWalk", spawnLocations.mummy, {x: 3, y: 3.5});
            vm.methods.spawnEnemys(vm, "priestMummy", "priestMummyWalk", "priestMummyWalk", spawnLocations.priestMummy, {x: 3, y: 3.5});
            // vm.methods.createEnemy(vm, "skeleton", "skeletonIdle", "skeletonIdle", 20, 2.5, 3, {bodyX: 20, bodyY: 30}, {x: 14, y: 12}, 200);
        }

        function rockSpawn(vm, disable) {
            var interval = setInterval(() => {
                vm.methods.spawnTrap(vm, "rock", "rock", 6380, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 8650, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 9000, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 9300, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 9640, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 10000, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 14065, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 14400, 0, 3000);
                vm.methods.spawnTrap(vm, "rock", "rock", 15200, 0, 3000);
            }, 1800)
            if(disable) clearInterval(interval);
        }
    
        function createScene(vm) {
            vm.map = vm.make.tilemap({ key: "mapPyramids"});
            var objects1 = vm.map.addTilesetImage("ThePyramidsObjectsV3", "objects1");
            var tileset = vm.map.addTilesetImage("ThePyramidsTilesV3", "floor1");
            var background1 = vm.map.addTilesetImage("ThePyramidsBG", "background1");
            vm.layer.background = vm.map.createLayer("background", [tileset, background1]).setScale(3);
            vm.layer.objects = vm.map.createLayer("objects", [objects1, tileset]).setScale(3);
            vm.layer.animatedObjects = vm.map.createLayer("animatedObjects", [objects1, tileset]).setScale(3);
            vm.layer.water = vm.map.createLayer("water", tileset).setScale(3).setDepth(15);
            vm.layer.floor = vm.map.createLayer("floor", tileset).setScale(3);

            vm.layer.floor.setCollisionBetween(0, 500);

            vm.sys.animatedTiles.init(vm.map);

            vm.layer.waterCollider = [];

            vm.layer.water.forEachTile(function (tile){
                if(tile.tileset) vm.layer.waterCollider.push(tile);
            })

            var portal = vm.physicObject.create(7110, 910, 'portal').setScale(2.7, 3).setCollideWorldBounds(true);
            var portal2 = vm.physicObject.create(6750, 490, 'portal').setScale(2.7, 3).setCollideWorldBounds(true);

            portal.play("portalAnim", true);
            portal2.play("portalAnim", true);

            // portal;
            vm.physics.add.overlap(vm.player, portal, function() {
                if(vm.interage != "portal") vm.interage = "portal";
            });
            vm.physics.add.overlap(vm.player, portal2, function() {
                if(vm.interage != "portal2") vm.interage = "portal2";
            });
        }

        function createLimits(vm) {
            var end; 
            vm.limits.add(vm.add.rectangle(50, 380, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(17200, 380, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            end = vm.limits.getChildren()[1];

            vm.physics.add.overlap(end, vm.player, function() {
                vm.game.sound.stopAll();
                vm.scene.start('bossRoom');
            });
        }

        this.sounds.ambient = this.sound.add('ambient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.ambient.play({loop: true, volume: 0.8});
        this.sounds.theme.play({loop: true, volume: 0.5});

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.animals = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.traps = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        // playerImport.createPlayer(this, 16000, 1050);
        // playerImport.createPlayer(this, 10500, 1150);
        // playerImport.createPlayer(this, 7300, 850);
        playerImport.createPlayer(this, 120, 250);
        createScene(this);
        createLimits(this);

        rockSpawn(this);

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);
        this.physics.add.collider(this.animals, this.layer.floor);
        this.physics.add.collider(this.physicObject, this.layer.floor);
        this.physics.add.collider(this.limits, this.layer.floor);
        this.physics.add.collider(this.monsterAttack, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        // this.cameras.main.zoom = 0.25;
        
        monsterSpawn(this);
        
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        var vm = this;
        this.physics.world.overlapTiles(this.player, this.layer.waterCollider, function() {
            if(vm.waterHurt) {
                vm.waterHurt = false;
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(25);
                var interval = setInterval(() => {
                    vm.waterHurt = true;
                    clearInterval(interval);
                }, 250);
            }
        });
        updateActions(this);
    }
}