//Components
import StepOne from "./steps/one/StepOne.vue";
import StepTwo from "./steps/two/StepTwo.vue";
import StepThree from "./steps/three/StepThree.vue";
import ViewStatus from "./viewStatus/ViewStatus.vue";
import CreatePersonService from "../../core/services/CreatePersonService";

import _cookieHelper from "../../core/helpers/CookieHelper.js";

export default {
    data() {
        return {
            stepCurrent: 1
        }
    },
    components: {
        StepOne,
        StepTwo,
        StepThree,
        ViewStatus
    },
    methods: {
        changeStep(value) {
            this.stepCurrent += value;
            window.scrollTo(0, 0);
        },
        finish() {
            let char = JSON.stringify(this.$store.state.personModule.mainPerson);
            CreatePersonService.methods.postNewPerson(char);
            this.$store.commit("changeCharCurrent", this.$store.state.personModule.mainPerson);
            this.$store.commit("changeCreatePerson", false);
            this.$router.push('/Home');
        }
    },
    mounted() {
        var hash = _cookieHelper.getCookie("lgTkn");
        this.$store.commit("changeHashPerson", hash);
    }
}