export default {
    data() {
        return {
        }
    },
    props: [
        'status'
    ],
    methods: {
    },
    beforeMount() {
    }
}