export default function bloodHit(vm) {
    vm.anims.create({
        key: "roll",
        frames: vm.anims.generateFrameNumbers('bloodHitRoll', {start: 0, end: 9}),
        frameRate: 10,
        repeat: 0
    })

    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('bloodHitJump', {start: 0, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "up",
        frames: vm.anims.generateFrameNumbers('bloodHitJump', {start: 0, end: 1}),
        frameRate: 5
    })
    vm.anims.create({
        key: "down",
        frames: vm.anims.generateFrameNumbers('bloodHitFall', {start: 1, end: 2}),
        frameRate: 5
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('bloodHitAttack1', {start: 0, end: 9}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('bloodHitAttack2', {start: 0, end: 9}),
        frameRate: 30
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('bloodHitAttack3', {start: 0, end: 7}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('bloodHitIdle', {start: 0, end: 5}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('bloodHitWalk', {start: 0, end: 9}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "run",
        frames: vm.anims.generateFrameNumbers('bloodHitRun', {start: 0, end: 9}),
        frameRate: 15,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('bloodHitDead', {start: 0, end: 16}),
        frameRate: 5,
        repeat: 0
    })
}