const items = [
    {
        name: "lingote de bronze",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "gosma de slime",
                qtd: 6
            }
        ],
        price: 0
    },
    {
        name: "espada de bronze",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "lingote de bronze",
                qtd: 3
            }
        ],
        price: 0
    },
    {
        name: "orb",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "fragmento de alma",
                qtd: 3
            }
        ],
        price: 0
    },
    {
        name: "lingote de ferro",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "ferro bruto",
                qtd: 8
            }
        ],
        price: 800
    },
    {
        name: "espada de ferro (primária)",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "lingote de ferro",
                qtd: 3
            }
        ],
        price: 1500
    },
    {
        name: "espada de ferro (Second)",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "lingote de ferro",
                qtd: 3
            }
        ],
        price: 3000
    }
]

export default items;