const actions = {
    reviewCraftItemStatus({ state }) {
        state.temporaryStatus = {};
        var materialsArray = state.materials, index;
        let deleteStatus;
        if(state.recipeNow.deleteStatus) {
            deleteStatus = state.recipeNow.deleteStatus;
        }
        materialsArray.forEach(el => {
            var statusNames = [];
            if (el.status) {
                statusNames = Object.keys(el.status);
                if(state.recipeNow.deleteStatus) {
                    deleteStatus.forEach(dStatus => {
                        index = statusNames.indexOf(dStatus);
                        if(index > -1) {
                            statusNames.splice(index, 1);
                        }
                    })
                }
                if(statusNames.length > 0) {
                    statusNames.forEach(add => {
                        if(state.temporaryStatus[add] === "undefined" || !state.temporaryStatus[add]) {
                            if(state.statusAdd.maxStatus) {
                                state.temporaryStatus[add] = el.status[add] * el.qtdCraft + (el.status[add] * el.qtdCraft * (state.statusAdd.maxStatus / 100));
                                return;
                            }
                            state.temporaryStatus[add] = el.status[add] * el.qtdCraft;
                            return;
                        }
                        state.temporaryStatus[add] = state.temporaryStatus[add] + el.status[add] * el.qtdCraft;
                    })
                }
            }
        });
    },
    changeCraftWork({ state, rootState, commit }, value) {
        const work = rootState.personModule.mainPerson.class.type === value;
        if(!work) {
            alert("Você não possui autorização para realizar esse tipo de trabalho");
            state.craftModal = false;
            return;
        }
        commit("changeObjectCraftWork", work);
    },
    findStatusPerson({state, rootState, commit}) {
        let workCraftStatus, addMAterialsCraftPerson;
        if(rootState.personModule.mainPerson.skills.attr.find(i => i.name === state.work.type)) {
            workCraftStatus = rootState.personModule.mainPerson.skills.attr.find(i => i.name === state.work.type).status;
            addMAterialsCraftPerson = rootState.personModule.mainPerson.skills.attr.find(i => i.name === state.work.type).add;
            commit("changeStatusAdd", workCraftStatus);
            commit("changeAddMaterialsCraft", addMAterialsCraftPerson);
            return;
        }
        commit("changeStatusAdd", {});
        commit("changeAddMaterialsCraft", {});
    },
    calculateRarity({state, commit}) {
        let rarityPossCraft = state.statusAdd.rarityPoss ? state.statusAdd.rarityPoss : 0;
        let statusTemporaryCraft = Object.keys(state.temporaryStatus);
        var calc = state.work.level.value * 2.5 + rarityPossCraft;
        calc = calc > 100 ? 100 : calc;
        var randomNumber = Math.floor(Math.random() * (calc - 0 + 1)) + 0;
        if(randomNumber < 10) {
            commit("changeRarityCraft", {name: "normal", multiply: 1});
        }
        else if(randomNumber < 35) {
            commit("changeRarityCraft", {name: "melhorada", multiply: 1.25});
        }
        else if(randomNumber < 70) {
            commit("changeRarityCraft", {name: "perfeita", multiply: 1.5});
        }
        else if(randomNumber < 94) {
            commit("changeRarityCraft", {name: "rara", multiply: 1.75});
        }
        else if(randomNumber < 99) {
            commit("changeRarityCraft", {name: "obra-prima", multiply: 2.25});
        }
        else if(randomNumber < 100) {
            commit("changeRarityCraft", {name: "lendario", multiply: 2.75});
        }
        statusTemporaryCraft.forEach(status => {
            state.temporaryStatus[status] = state.temporaryStatus[status] * state.rarity.multiply;
        })
    },
}

export default actions;