import questsMock from "@/shared/mocks/quests";
import normalDialog from "./dialogs/normalDialog";

import LootService from "@/core/services/LootService";

export default {
    data() {
        return {
            quests: questsMock,
            questCurrent: null,
            isValidate: false,
            isRegisterGuild: true
        }
    },
    computed: {
        questsChar() {
            return this.$store.state.personModule.mainPerson.adventureGuild.quests;
        },
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        }
    },
    methods: {
        getItem(itemName) {
            return this.$store.state.itemsModule.items.find(i => i.name === itemName);
        },
        getMonster(monsterName) {
            return this.$store.state.monstersModule.types.find(i => i.name === monsterName);
        },
        getBoss(bossName) {
            return this.$store.state.bossesModule.bosses.find(i => i.identification === bossName);
        },
        getImage(typeQuest) {
            switch(typeQuest) {
                case 1:
                    return "bread.png"
                case 2:
                    return "horse.png"
                case 3:
                    return "lizard.png"
                case 4:
                    return "monster.png"
            }
        },
        filterQuests(value) {
            if(
                value.lvl.value <= this.$store.state.personModule.mainPerson.adventureGuild.level.letter &&
                !this.$store.state.personModule.mainPerson.adventureGuild.quests.find(i => i.id === value.id)
            ) {
                return true;
            }
            return false;
        },
        verifyNeed(quest) {
            if(quest) {
                var validate = true;
                quest.need.map((need) => {
                    var itemOrMonster;
                    switch(need.type) {
                        case 1:
                            if(validate) {
                                itemOrMonster = this.$store.state.personModule.mainPerson.Inventory.find(i => i.name === need.name);
                                if(!itemOrMonster) {
                                    validate = false;
                                    return;
                                }
                                if(itemOrMonster.qtd < need.amount) {
                                    validate = false;
                                    return;
                                }
                            }
                            break;
                        case 2:
                            if(validate) {
                                itemOrMonster = this.$store.state.personModule.mainPerson.statistics.monsterDead.find(i => i.name === need.name);
                                if(itemOrMonster) {
                                    if(need.validate === undefined) {
                                        need.validate = 0;
                                    }
                                    if(itemOrMonster.value - need.validate < need.amount) {
                                        validate = false;
                                        return;
                                    }
                                }
                                if(!itemOrMonster) {
                                    validate = false;
                                    return;
                                }
                            }
                            break;
                    }
                })
                return validate;
            }
        },
        changeIsValidate() {
            this.isValidate = this.verifyNeed(this.questCurrent);
        },
        addQuest(quest) {
            this.questCurrent = null;
            if(quest.type === 3) {
                quest.need.map((need) => {
                    var monster = this.$store.state.personModule.mainPerson.statistics.monsterDead.find(i => i.name === need.name);
                    if(monster) {
                        if(monster.value >= 0) {
                            need.validate = monster.value;
                            return;
                        }
                    }
                })
            }
            this.$store.commit("addQuest", quest);
            this.$forceUpdate();
        },
        selectQuest(quest) {
            this.questCurrent = quest;
            this.changeIsValidate();
            this.$forceUpdate();
            // console.log("this.$store.state.personModule.mainPerson.adventureGuild.quests", this.$store.state.personModule.mainPerson.adventureGuild.quests);
        },
        cancelQuest(quest) {
            this.$store.commit("removeQuest", quest);
            this.$forceUpdate();
        },
        confirmQuest(quest) {
            this.$store.dispatch("successfullQuest", quest);
            quest.loot.items.map((item) => {
                LootService.methods.lootGeneral(item);
            })
            this.questCurrent = null;
            this.$forceUpdate();
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        },
        shuffleArray(arr) {
                // Loop em todos os elementos
            for (let i = arr.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [arr[i], arr[j]] = [arr[j], arr[i]];
            }
            return arr;
        },
        sortQuests() {
            var quests;
            quests = questsMock.filter(this.filterQuests);
            quests = this.shuffleArray(quests);
            quests = quests.filter((quest, index) => {
                if (index < 4 && quest) {
                  return true;
                }
                return false;
            })
            return quests;
        },
        validateGuildEnter() {
            if(this.$store.state.personModule.mainPerson.adventureGuild.level.letter === null) {
                this.$store.dispatch("showAlert", {type: "default", imgUrl: "actions/19.png", message: `Você não se registrou na guilda para acessar o livro de trabalhos`});
                this.isRegisterGuild = false;
            }
        },
        initQuests() {
            this.quests = this.sortQuests();
            this.changeIsValidate();
            this.$forceUpdate();
            this.validateGuildEnter();
        }
    },
    mounted() {
        this.$store.commit("changeDialogBox", true);
        this.$store.commit("changeNameDialog", "Mr. Adam, O Mestre da Guilda");
        this.$store.commit("addDialog", normalDialog);
        this.initQuests();
    }
}