import DescItemTooltipComponent from "../../../../shared/components/descItemTooltip/DescItemTooltip.vue";

export default {
    data(){
        return {
            priceBuy: 1,
            ingredients: [],
            result: {},
            enableButton: true
        }
    },
    props: [
        'items'
    ],
    components: {
        DescItemTooltipComponent
    },
    computed: {
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        itemQtd() {
            var amount = 0;
            this.$store.state.personModule.mainPerson.Inventory.forEach(item => {
                if(item.name === this.$props.items.name) {
                    amount += item.qtd;
                }
            })
            return amount;
        }
    },
    methods: {
        verifyInput() {
            if(this.priceBuy > 99) {
                this.enableButton = false;
                return;
            }
            this.enableButton = true;
            return;
        },
        calculeItemsQuantity(item, qtd) {
            var materials = item.ingredients, validate = true, itemInventory, amountItem;
            var alert = {type: "default", imgUrl: "", message: ""};
            materials.forEach(material => {
                itemInventory = this.$store.state.personModule.mainPerson.Inventory.filter(i => i.name === material.name);
                if(itemInventory.length > 0) {
                    if(itemInventory.length > 1) {
                        itemInventory.forEach(item => {
                            amountItem += item.qtd;
                        })
                    } else {
                        amountItem = item.qtd;
                    }
                    if(material.qtd * qtd > parseInt(amountItem) * qtd) {
                        alert.imgUrl = "actions/bag.png"
                        alert.message = `Você não possue quantidade de ${material.name} suficiente seu inventário para criar o item`;
                        this.$store.dispatch("showAlert", alert);
                        validate = false;
                        return validate;
                    }
                } else {
                    alert.imgUrl = "actions/bag.png"
                    alert.message = `Você não possue ${material.name} no seu inventário`;
                    this.$store.dispatch("showAlert", alert);
                    validate = false;
                    return validate;
                }
            });
            return validate;
        },
        removeMaterials(item, qtd) {
            var materials = item.ingredients, itemInventory, amount, searchItem, newItem;
            materials.forEach(material => {
                amount = (material.qtd * qtd) / 99;
                itemInventory = this.$store.state.personModule.mainPerson.Inventory.filter(i => i.name === material.name);
                itemInventory.forEach(item => {
                    if(amount > 1) {
                        for(var i = 0;i < Math.trunc(amount); i++) {
                            newItem = {
                                item: item,
                                quantidade: Math.ceil(99)
                            };
                            this.$store.commit("deleteItemInventory", newItem);
                            amount--;
                        }
                        searchItem = this.$store.state.personModule.mainPerson.Inventory.find(i => i.name === material.name);
                        newItem = {
                            item: searchItem,
                            quantidade: amount * 99
                        };
                        this.$store.commit("deleteItemInventory", newItem);
                    }
                    newItem = {
                        item: item,
                        quantidade: Math.ceil(material.qtd * qtd)
                    };
                    this.$store.commit("deleteItemInventory", newItem);
                })
            });
        },
        craftItem(item) {
            var priceItem = item.price;
            var alert = {type: "default", imgUrl: "", message: ""};
            var newItem = {item: this.result, quantidade: this.priceBuy};
            var validate = this.calculeItemsQuantity(item, newItem.quantidade);
            if(validate) {
                if(this.$store.state.personModule.mainPerson.gold.value < priceItem * newItem.quantidade){
                    alert.imgUrl = "actions/bag.png"
                    alert.message = "Você não possui dinheiro suficiente!";
                    this.$store.dispatch("showAlert", alert);
                } else if(this.$store.state.personModule.mainPerson.Inventory.length + 1 > this.$store.state.personModule.mainPerson.inventorySize) {
                    alert.imgUrl = "actions/bag.png"
                    alert.message = "Seu inventário já está lotado!";
                    this.$store.dispatch("showAlert", alert);
                } else {
                    this.removeMaterials(item, newItem.quantidade);
                    this.$store.commit("lessMoneyWallet", priceItem * newItem.quantidade);
                    this.$store.commit("addItemInventory", newItem);
                    this.priceBuy = 1;
                }
            }
        },
        getIngredients(array) {
            array.forEach(el => {
                var item = this.$store.state.itemsModule.items.find(i => i.name === el.name);
                this.ingredients.push(item);
            });
        }
    },
    created() {
        this.getIngredients(this.$props.items.ingredients);
        this.result = this.$store.state.itemsModule.items.find(i => i.name === this.$props.items.name);
    }
}