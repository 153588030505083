export default function mage(vm) {
    vm.anims.create({
        key: "roll",
        frames: vm.anims.generateFrameNumbers('mageRoll', {start: 0, end: 5}),
        frameRate: 10,
        repeat: 0
    })

    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('mageJump', {start: 0, end: 4}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "up",
        frames: vm.anims.generateFrameNumbers('mageJump', {start: 2, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "down",
        frames: vm.anims.generateFrameNumbers('mageJump', {start: 3, end: 3}),
        frameRate: 8,
        repeat: 0
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('mageAttack1', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('mageAttack2', {start: 0, end: 3}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('mageAttack3', {start: 0, end: 2}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('mageIdle', {start: 0, end: 1}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('mageWalk', {start: 0, end: 5}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "run",
        frames: vm.anims.generateFrameNumbers('mageRun', {start: 0, end: 5}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('mageDead', {start: 0, end: 3}),
        frameRate: 5,
        repeat: 0
    })

    vm.anims.create({
        key: "fireball",
        frames: vm.anims.generateFrameNumbers('fireball', {start: 0, end: 9}),
        frameRate: 12,
        repeat: -1
    })
}