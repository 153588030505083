export default (grenade) => {
    switch(grenade) {
        case "bronze grenade":
            return {
                x: 0,
                y: 40,
                speedY: -450,
                speedX: 300,
                sprite: "bronzeGrenade",
                size: {x: 1, y: 1},
                offsetX: 30,
                anime: "explosion",
                timeGrenade: 1200,
                timeDamage: 1000,
                explosionSize: {x: 50, y: 50},
                scale: 1,
                scaleDamage: 3,
                damage: 5,
                som: "smallExplosion"
            }
        case "future grenade":
            return {
                x: 0,
                y: 40,
                speedY: -500,
                speedX: 300,
                sprite: "futureGrenade",
                size: {x: 1, y: 1},
                offsetX: 30,
                anime: "blackHole",
                timeGrenade: 1500,
                timeDamage: 6000,
                explosionSize: {x: 50, y: 50},
                scale: 1,
                scaleDamage: 3,
                damage: 7,
                som: "blackHole"
            }
    }
}