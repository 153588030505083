import sha256 from 'crypto-js/sha256';

import _cookieHelper from "@/core/helpers/CookieHelper.js";

import LoginService from "@/core/services/LoginService";
import { mapGetters } from "vuex"

export default {
    data () {
        return {
            formLogin: {
                username: {
                    value: '',
                    error: ''
                },
                password: {
                    value: '',
                    error: ''
                }
            }
        }
    },
    computed: {
        dataLogin() {
            return this.$store.state.generalModule.dataLogin;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        checkForm (input) {
            if (!this.formLogin[input].value) {
                this.formLogin[input].error = `${input} é obrigatório.`;
                this.$store.commit("changeErrorForm", 1);
                return;
            }
            this.formLogin[input].error = null;
            this.$store.commit("changeErrorForm", 0);
        },
        verifyAll () {
            var inputs = Object.keys(this.formLogin);
            inputs.forEach(input => {
                if(this.$store.state.generalModule.errorForm != 1) this.checkForm(input);
            })
        },
        logar () {
            this.verifyAll();
            if(this.$store.state.generalModule.errorForm != 1) {
                let loginCookie = _cookieHelper.getCookie("lgTkn");
                if(loginCookie == "") {
                    if(this.formLogin.username.value != "") {
                        var userPass = this.formLogin.username.value + this.formLogin.password.value;
                        var sha256Hash = sha256(userPass);
                        var sha256NewHash = () => {
                            var hash = (sha256Hash.words[0] + sha256Hash.words[2]).toString() + (sha256Hash.words[3] + sha256Hash.words[7] + sha256Hash.words[5]).toString();
                            hash = hash.replace("-","").replace("-","");
                            return hash;
                        }
                        LoginService.methods.getUser(sha256NewHash());
                        return;
                    }
                    return null;
                }
                LoginService.methods.getUser(loginCookie);
            }
        },
        goRegisterComponent() {
            this.$store.commit("changeLogarComponent", 1);
        }
    },
    mounted() {
        this.$store.commit("changeErrorForm", 0);
    }
}