import axios from "axios";

const mutations = {
    getRecipes(state) {
        axios.get(
            "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getRecipes"
        ).then((res) => {
            state.recipes = res.data;
        }).catch(error => {
            console.log(error.message);
        });
    },
    viewModalCraft(state, boolean) {
        state.craftModal = boolean;
    },
    changeTypeCraft(state, value) {
        state.typeRecipe = value;
    },
    addItemCraft(state, item) {
        state.itemCraft = item;
    },
    addMaterialCraft(state, object) {
        state.materials[object.index] = object.material;
        state.materials[object.index].qtdCraft = object.minQtd;
    },
    changeMaterialQtd(state, object) {
        state.materials[object.index].qtdCraft = parseInt(object.value);
    },
    addStatusItemCraft(state, status) {
        state.itemCraft.status[status.name] += status.value;
    },
    cleanState(state) {
        state.materials = [];
        state.itemCraft = {};
        state.temporaryStatus = {};
        state.price = 0;
    },
    changeObjectCraftWork(state, object) {
        state.work = object;
    },
    addPriceCraftItem(state, value) {
        var rarityMulti = state.rarity ? state.rarity.multiply : 0;
        state.price += value * 1.15 * rarityMulti;
    },
    addWeightCraftItem(state, value) {
        state.weight += value * 0.75;
    },
    addResistancetCraftItem(state, value) {
        state.resistance += value;
    },
    clearResistanceCraftItem(state) {
        state.resistance = 0;
    },
    clearWeightCraftItem(state) {
        state.weight = 0;
    },
    clearPriceCraftItem(state) {
        state.price = 0;
    },
    changeRecipeNow(state, recipe) {
        state.recipeNow = recipe;
    },
    deleteStatusEquipCraft(state, deleteStatus) {
        deleteStatus.forEach(status => {
            delete state.temporaryStatus[status]; 
        })
    },
    changeStatusAdd(state, status) {
        state.statusAdd = status;
    },
    changeAddMaterialsCraft(state, materials) {
        state.addItemsMaterials = materials;
    },
    changeRarityCraft(state, object) {
        state.rarity = object;
    }
}

export default mutations;