import Waves from "./waves/Waves.vue";

export default {
    data() {
        return {
        }
    },
    components: {
        Waves
    }
}