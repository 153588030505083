// import ArmorBoxContract from "@/core/abis/ArmorBoxContract.json";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

const ethers = require('ethers');

const web3 = createAlchemyWeb3(`https://eth-mainnet.alchemyapi.io/v2/-gM7z_4eeyvUty9SRyngHEo1NGlGMLSx`);

// const networkData = ArmorBoxContract.networks["56"];
var getNFTContract;

var getTokenContract;

// Função para iniciar o contrato de acordo com a ABI
export const loadBoxContract = async (contractABI, contractAddress) => {
    if(contractABI && contractAddress) {
        window.contract = new web3.eth.Contract(contractABI, contractAddress);
        return {
            success: true,
            contractAddress
        }
    } else {
        return {
            success: false
        }
    }
};

// Função para abrir a opção de conectar carteira na Metamask
export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const address = await window.ethereum.request({
                method: "eth_requestAccounts"
            });
            const obj = {
                connectedStatus: true,
                status: "",
                address
            }
            return obj;
        }
        catch(err) {
            return {
                connectedStatus: false,
                status: "Connect your wallet to Metamask"
            };
        }
    } else {
        return {
            connectedStatus: false,
            status: "Please, install metamask"
        }
    }
};

//Aprovar negociação com o token
const approveTransactionToken = async (tokenContract) => {
    var signerAddress;

    const abi = ['function approve(address spender, uint256 amount) external returns (bool)'];
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();

    await window.ethereum.request({ method: 'eth_requestAccounts'});
    await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x38'}]});
    signerAddress = await signer.getAddress();
    signerAddress;
    
    getTokenContract = await new ethers.Contract(tokenContract, abi, signer);

    return true;
}

export const approveTransfer = async (amount, contractAddress) => {
    var newAmount = amount.toString() + "000000000000000000";
    try {
        var txHash = await getTokenContract.approve(contractAddress, newAmount);
        return txHash.hash;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// Função para realizar o Mint de um NFT a partir dos parâmetros
export const mintNFT = async (tokenContract, mintAmount, value, contractABI, newContractAddress) => {
    //Pegando dados do token
    await approveTransactionToken(tokenContract);
    //Aprovando token
    var approve = await approveTransfer(mintAmount * value, newContractAddress);
    if(approve !== false) {
        alert("Wait 25 seconds for transfer approval");
        await timeout(25000);
    
        // Iniciando o contratos
        const { contractAddress } = await loadBoxContract(contractABI, newContractAddress);
    
        var transactionsParameters = {
            to: contractAddress,
            from: window.ethereum.selectedAddress,
            data: window.contract.methods.mintNFT(window.ethereum.selectedAddress, mintAmount).encodeABI()
        }
    
        try {
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionsParameters]
            });
    
            return {
                success: true,
                status: "NFT mint successfully! txHash: " + txHash
            }
        } catch(err) {
            return {
                success: false,
                status: "Error: No mint NFT"
            }
        }
    } else {
        alert("Unfinished purchase");
        return {
            success: false,
            status: "Error: No mint NFT"
        }
    }
};

export const loadContractNetworkCurrent = async (contractABI, contractAddress) => {
    var signerAddress;

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();

    await window.ethereum.request({ method: 'eth_requestAccounts'});
    await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x38'}]});
    signerAddress = await signer.getAddress();
    signerAddress;
    
    getNFTContract = await new ethers.Contract(contractAddress, contractABI, signer);
}

export const getWhitelist = async () => {
    return await getNFTContract.getWhitelist();
}

export const balanceOf = async (address) => {
    var balance = await getNFTContract.balanceOf(address);
    var int = parseInt(balance._hex);
    return int;
}

export const ownerOf = async (tokenId) => {
    return await getNFTContract.ownerOf(tokenId);
}

export const tokenURI = async (tokenId) => {
    return await getNFTContract.tokenURI(tokenId);
}

export const supply = async () => {
  var supply = await getNFTContract.getSupply();
  var int = parseInt(supply._hex);
  return int;
}

export const NFTsURI = async (address) => {
    var supply;
    try {
        supply = await getNFTContract.getSupply();
        supply = parseInt(supply._hex);
    } catch (error) {
        supply = 80
    }
    var balance = await balanceOf(address);
    var arrayNFTIds = [];
    var arrayNFTs = [];
    if(balance > 0) {
      for(var i = 1; i < (supply + 1); i++) {
          var NFTOwner = await ownerOf(i);
          if(NFTOwner.toLowerCase() == address) {
              arrayNFTIds.push(i);
          }
          if(arrayNFTIds.length >= balance) {
              break;
          }
      }
      if(arrayNFTIds.length > 0) {
          for(var z = 0; z < arrayNFTIds.length; z++) {
            var nft = {};
            nft.item = await tokenURI(arrayNFTIds[z]);
            nft.URIToken = arrayNFTIds[z];
            arrayNFTs.push(nft);
          }
          return arrayNFTs;
      }
    }
    return null;
}