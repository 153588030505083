import DescItemTooltipComponent from "../../../../../shared/components/descItemTooltip/DescItemTooltip.vue";

export default {
    props: ['materiais', 'indexSlot', 'minQuantidade'],
    components: {
        DescItemTooltipComponent
    },
    methods: {
        addMaterial(material, index, minQtd) {
            this.$store.commit("addMaterialCraft", {index: index, material: material, minQtd: minQtd});
            this.$store.dispatch("reviewCraftItemStatus");
        }
    }
}