import Phaser from "phaser";

class HealthBar {

    constructor (vm, x, y, hp)
    {
        this.bar = new Phaser.GameObjects.Graphics(vm);

        this.x = x;
        this.y = y;
        this.value = hp;
        this.totalValue = hp;
        this.p = 76 / 100;

        this.draw();

        vm.add.existing(this.bar);
    }

    decrease (amount)
    {
        this.value -= amount;

        if (this.value < 0)
        {
            this.value = 0;
        }

        this.draw();

        return (this.value === 0);
    }

    move(x, y) {
        this.bar.clear();
        this.x = x;
        this.y = y;

        this.draw();
    }

    deleteHealtBar() {
        this.bar.clear();
    }

    draw ()
    {
        this.bar.clear();

        //  BG
        // this.bar.fillStyle(0x555555);
        // this.bar.fillRect(this.x, this.y, 80, 16);

        //  Health

        var size = 100 - ((this.value - this.totalValue) / this.totalValue * 100 * -1);

        this.bar.fillStyle(0xecf0f1);
        this.bar.fillRect(this.x + 2, this.y + 2, (76 / 2), 12);

        if (size < 30)
        {
            this.bar.fillStyle(0xc0392b);
        }
        else
        {
            this.bar.fillStyle(0x27ae60);
        }

        var d = Math.floor((this.p * size) / 2);

        this.bar.fillRect(this.x + 2, this.y + 2, d, 12);
    }

}

export default HealthBar;