import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;

export default function spearman(vm) {
    vm.load.on('progress', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, true);
    });

    vm.load.on('complete', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, false);
    });
    
    // Sons
    vm.load.audio('attack', '/resourcesInGame/sons/actions/spearman/attack/Spikes-5_5.wav'); 
    vm.load.audio('hit', '/resourcesInGame/sons/actions/hit/Insect_Crush-5.wav');
    vm.load.audio('monsterDead', '/resourcesInGame/sons/monsters/dead/Evil_Whoosh-4.wav');
    vm.load.audio('usePotion', '/resourcesInGame/sons/actions/use/potion/Alchemy-9.wav');
    vm.load.audio('openPotion', '/resourcesInGame/sons/actions/use/potion/Bottle_Open-1_1.wav');
    vm.load.audio('walk', '/resourcesInGame/sons/actions/walk/ESM_Fantasy_Game_Footstep_Grass_Light_H.wav');
    vm.load.audio('roll', '/resourcesInGame/sons/actions/roll/ESM_Fantasy_Game_Footstep_Grass_Heavy_D.wav');
    vm.load.audio('portal', '/resourcesInGame/sons/actions/portal/ESM_Fantasy_Game_Magic_Airy_Sting_Accent_Medieval__Spell_Cast.wav');

    vm.load.spritesheet('spearmanIdle', '/resourcesInGame/sprites/main/spearman/spearman-idle.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('spearmanWalk', '/resourcesInGame/sprites/main/spearman/spearman-walk.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('spearmanJump', '/resourcesInGame/sprites/main/spearman/spearman-jump.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('spearmanDead', '/resourcesInGame/sprites/main/spearman/spearman-dead.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('spearmanAttack1', '/resourcesInGame/sprites/main/spearman/spearman-attack1.png', { frameWidth: 66, frameHeight: 48 });
    vm.load.spritesheet('spearmanAttack2', '/resourcesInGame/sprites/main/spearman/spearman-attack2.png', { frameWidth: 68, frameHeight: 48 });
    vm.load.spritesheet('spearmanAttack3', '/resourcesInGame/sprites/main/spearman/spearman-attack3.png', { frameWidth: 73, frameHeight: 48 });
    vm.load.spritesheet('spearmanRoll', '/resourcesInGame/sprites/main/spearman/spearman-roll.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('spearmanRun', '/resourcesInGame/sprites/main/spearman/spearman-run.png', { frameWidth: 50, frameHeight: 48 });
}