export default function swordman(vm) {
    vm.anims.create({
        key: "roll",
        frames: vm.anims.generateFrameNumbers('swordmanRoll', {start: 0, end: 5}),
        frameRate: 10,
        repeat: 0
    })
    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('swordmanJump', {start: 0, end: 1}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "up",
        frames: vm.anims.generateFrameNumbers('swordmanJump', {start: 2, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "down",
        frames: vm.anims.generateFrameNumbers('swordmanJump', {start: 3, end: 3}),
        frameRate: 8,
        repeat: 0
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('swordmanAttack1', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('swordmanAttack2', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('swordmanAttack3', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack4",
        frames: vm.anims.generateFrameNumbers('swordmanAttack4', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('swordmanIdle', {start: 0, end: 1}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "run",
        frames: vm.anims.generateFrameNumbers('swordmanRun', {start: 0, end: 5}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('swordmanWalk', {start: 0, end: 5}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('swordmanDead', {start: 0, end: 3}),
        frameRate: 5,
        repeat: 0
    })
}