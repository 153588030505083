import { mapGetters } from "vuex";
import axios from "axios";

import DescItemTooltipComponent from "@/shared/components/descItemTooltip/DescItemTooltip.vue";

import SaveCharService from '@/core/services/saveCharService';

export default {
    data() {
        return {
            enableButton: true,
            sellForms: {
                priceUnitSell: 1,
                amountSell: 1
            }
        }
    },
    props: ['marketItem'],
    components: {
        DescItemTooltipComponent
    },
    computed: {
        ...mapGetters(['translateWords'])
    },
    methods: {
        async insertNewMarketItem(item) {
            var newItem = {
                groupEquip: item.groupEquip,
                type: item.type,
                rarity: item.rarity,
                category: item.category,
                weight: item.weight,
                seller: item.seller,
                name: item.name,
                urlImg: item.urlImg,
                price: item.price,
                status: item.status
            }
            var index = this.$store.state.personModule.mainPerson.Inventory.indexOf(item);
            if(item._id) {
                delete item._id;
            }
            newItem.qtd = this.sellForms.amountSell;
            newItem.price = parseFloat(this.sellForms.priceUnitSell);
            newItem.location = this.$store.state.personModule.mainPerson.currentLocation.place;
            newItem.player = this.$store.state.personModule.mainPerson.nickname;
            newItem.identifier = this.$store.state.personModule.mainPerson._id;
            if(this.$store.state.personModule.mainPerson.Inventory[index].qtd < this.sellForms.amountSell || !this.$store.state.personModule.mainPerson.Inventory[index].qtd){
                alert.imgUrl = "actions/6.png"
                alert.message = "Você não possui itens suficientes!";
                this.$store.dispatch("showAlert", alert);
                return;
            }
            await axios.post(
                this.$store.state.generalModule.apiUrl + '/newMarketItem',
                newItem
            ).then(() => {
                console.log("Add");
            }).catch(error => {
                console.log(error.message);
                return;
            });
            let newItem2 = {
                item: item,
                quantidade: this.sellForms.amountSell
            };
            await this.$store.commit("getMarketItemsBack");
            this.$forceUpdate();
            this.$store.commit("deleteItemInventory", newItem2);
            SaveCharService.methods.putSaveChar(this.$store.state.personModule.mainPerson);
            this.$store.dispatch("showAlert", {type: "default", imgUrl: "actions/29.png", message: `${item.name} x${this.sellForms.amountSell} colocado a venda`});
        },
        verifyInput() {
            if(this.priceBuy > 99) {
                this.enableButton = false;
                return;
            }
            this.enableButton = true;
            return;
        }
    }
}