const mutations = {
    calculeHPMonsterCurrent: (state, method) => {
        if(method.sinal == "soma") state.current.status.hp += method.value
        else state.current.status.hp -= method.value
    },
    changeCurrentMonster: (state, newMonster) => {
        state.current = null;
        state.current = newMonster;
        state.current.status.hp = state.current.status.maxhp;
    },
    changeWave: (state, number) => {
        state.wave.current = number;
    },
    changeMonstersDead: (state, number) => {
        state.wave.monstersDead = number;
    },
    addMonstersDead: (state, number) => {
        state.wave.monstersDead += number;
    },
    changeScore: (state, number) => {
        state.wave.score = number;
    },
    addScore: (state, number) => {
        state.wave.score += number;
    },
    changeShowCount: (state, boolean) => {
        state.wave.showCount = boolean;
    },
    countNewWave: (state) => {
        state.wave.count = 5;
        var interval = setInterval(() => {
            state.wave.count--;
        }, 1000);
        var timeOut = setTimeout(() => {
            state.wave.showCount = false;
            clearInterval(interval);
            clearTimeout(timeOut);
        }, 7000);
    }
};

export default mutations;