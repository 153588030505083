
import DescSkillComponent from "./components/descSkill/DescSkill.vue";

export default {
    components: {
        DescSkillComponent
    },
    computed: {
        worksPerson() {
            return this.$store.state.personModule.mainPerson.works.filter(i => Object.prototype.hasOwnProperty.call(i, "points"));
        },
        pointsSkill() {
            return this.$store.state.personModule.mainPerson.class.points;
        },
        skills() {
            return this.$store.state.skillModule.skills.filter(i => i.work === this.$store.state.skillModule.work);
        },
        workSkillSelected() {
            return this.$store.state.skillModule.work;
        },
        contentSkill() {
            return this.$store.state.personModule.mainPerson.skills.names;
        }
    },
    methods: {
        addSkill(skill) {
            const skillNamesNeed = skill.need;
            var validate = true;
            const workSearchPoints = this.$store.state.personModule.mainPerson.class;
            if(skillNamesNeed) {
                skillNamesNeed.forEach(need => {
                    if(this.$store.state.personModule.mainPerson.skills.names.indexOf(need) === -1) {
                        validate = false;
                    }
                })
            }
            if(validate) {
                if(workSearchPoints.points >= skill.cost) {
                    this.$store.commit("changeWorkPoints", {workType: skill.work, value: skill.cost});
                    this.$store.commit("addSkillPerson", skill);
                    alert(`Você adquiriu a skill ${skill.name} com sucesso!`);
                    return;
                }
                alert("Você não possui pontos suficientes para essa skill!");
                return;
            }
            alert("Você não possui as skills necessárias para liberar essa");
        },
        close() {
            this.$store.commit("changeSkillModal", false);
        },
        changeWorkSkill() {
            var work = document.getElementById("skillSelect").value;
            this.$store.commit("changeWork", work);
        }
    },
    created() {
        // this.$store.commit("changeWork", this.$store.state.personModule.mainPerson.works.find(i => Object.prototype.hasOwnProperty.call(i, "points")).type);
        this.$store.commit("changeWork", this.$store.state.personModule.mainPerson.class.type);
        this.$store.commit("getSkills");
    }
}