export default {
    setScale: {
        x: 1.75,
        y: 2.25
    },
    setSize: {
        x: 25,
        y: 42
    },
    offset: {
        y: 84
    }
}