import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";
import { methodsDungeon } from "@/shared/components-phaser/methods";

import keyboardsImport from "@/shared/components-phaser/keyboards" 
import playerImport from "@/shared/components-phaser/player" 
import { updatePlayer } from "@/shared/components-phaser/updateActions" 

import preloadAllResources from "@/shared/components-phaser/preload.js"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusSkill = storeAcess.skillModule;
const statusItems = storeAcess.itemsModule;

class SceneCamp extends Phaser.Scene {
    constructor(locationInfos, mainPerson){
        super("mjolnirScene");
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.player;
        this.key;
        this.mapSize = {
            x: 8640,
            y: 1600
        };
        this.layer = {};
        this.map;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/Village_1_Loop.wav');
        this.load.audio('music', '/resourcesInGame/sons/musics/village/Town_-_Royal_Court.ogg');

        // Resources all maps
        preloadAllResources(this);

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/village/set/SET1_Mainlev_build.png');
        this.load.image('water1', '/resourcesInGame/title-set/tiles/village/set/Enviroment.png');
        this.load.image('tavern1', '/resourcesInGame/title-set/tiles/village/set/Tavern.png');
        this.load.image('constructions1', '/resourcesInGame/title-set/tiles/village/set/VP2_Main.png');
        this.load.image('constructions2', '/resourcesInGame/title-set/tiles/village/set/VP3_Main.png');
        this.load.image('arvores1', '/resourcesInGame/title-set/tiles/village/set/SET1_decorative_obj.png');
        this.load.image('background1', '/resourcesInGame/title-set/tiles/village/set/SET1_Main_bckgrdlev_build.png');
        this.load.tilemapTiledJSON("mjolnirMap", "/resourcesInGame/maps/mjolnir/scene1-mjolnir.json");

        //Constructions
        this.load.image("swordmanMonument", "/resourcesInGame/title-set/monuments/swordman.png");
        this.load.image("archerMonument", "/resourcesInGame/title-set/monuments/archer.png");
        this.load.image("mageMonument", "/resourcesInGame/title-set/monuments/mage.png");
        this.load.image("spearmanMonument", "/resourcesInGame/title-set/monuments/spearman.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/village/Green_Montains_BGa.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/road.png");
        this.load.image("mountains2", "/resourcesInGame/title-set/parallax/mountains/village/Green_Montains_BGc.png");
        this.load.image("mountains", "/resourcesInGame/title-set/parallax/trees/village/Green_Montains_BGd.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/village/Green_Montains_BGe.png");

        //animatedConstructions

        //NPCs
        this.load.spritesheet('smith', '/resourcesInGame/sprites/npcs/blacksmith/Smith_hammer.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('spearmanGuard', '/resourcesInGame/sprites/npcs/guard/spearman/spearmanGuard-idle.png', { frameWidth: 50, frameHeight: 48 });
        this.load.spritesheet('archerGuard', '/resourcesInGame/sprites/npcs/guard/archer/archerGuard-idle.png', { frameWidth: 50, frameHeight: 48 });
        this.load.spritesheet('mage', '/resourcesInGame/sprites/npcs/mage/Wizard_read.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('alchemist', '/resourcesInGame/sprites/npcs/alchemist/Alchemist_fill.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('fisherman', '/resourcesInGame/sprites/npcs/fisherman/Fisherman_fish.png', { frameWidth: 48, frameHeight: 48 });
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            //NPC
            vm.anims.create({
                key: "smith",
                frames: vm.anims.generateFrameNumbers('smith', {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "spearmanGuard",
                frames: vm.anims.generateFrameNumbers('spearmanGuard', {start: 0, end: 1}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "archerGuard",
                frames: vm.anims.generateFrameNumbers('archerGuard', {start: 0, end: 1}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "mage",
                frames: vm.anims.generateFrameNumbers('mage', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "fisherman",
                frames: vm.anims.generateFrameNumbers('fisherman', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "alchemist",
                frames: vm.anims.generateFrameNumbers('alchemist', {start: 0, end: 5}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "horseSeller",
                frames: vm.anims.generateFrameNumbers('horseSeller', {start: 0, end: 3}),
                frameRate: 3,
                repeat: -1
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, 0, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(0, 0, 'clouds').setOrigin(0).setScrollFactor(.15);

            vm.add.image(0, -900, 'mountains2').setOrigin(0).setScrollFactor(.33).setScale(1.5);
            vm.add.image(1920, -900, 'mountains2').setOrigin(0).setScrollFactor(.33).setScale(1.5);

            vm.add.image(0, -950, 'mountains').setOrigin(0).setScrollFactor(.66).setScale(1.5);
            vm.add.image(1920, -950, 'mountains').setOrigin(0).setScrollFactor(.66).setScale(1.5);

            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;
        }
    
        function monuments(vm) {
            vm.physicObject.create(1400, 485, 'swordmanMonument').setScale(.4, .5).setCollideWorldBounds(true);
            vm.physicObject.create(1600, 485, 'mageMonument').setScale(.4, .5).setCollideWorldBounds(true);
            vm.physicObject.create(1000, 485, 'spearmanMonument').setScale(.4, .5).setCollideWorldBounds(true);
            vm.physicObject.create(1200, 485, 'archerMonument').setScale(.4, .5).setCollideWorldBounds(true);
            // Monuments;
            var swordmanMonument = vm.physicObject.getChildren()[0];
            var mageMonument = vm.physicObject.getChildren()[1];
            var spearmanMonument = vm.physicObject.getChildren()[2];
            var archerMonument = vm.physicObject.getChildren()[3];
            
            vm.physics.add.overlap(vm.player, swordmanMonument, function() {
                if(vm.interage != "swordmanMonument") vm.interage = "swordmanMonument";
            });
            vm.physics.add.overlap(vm.player, mageMonument, function() {
                if(vm.interage != "mageMonument") vm.interage = "mageMonument";
            });
            vm.physics.add.overlap(vm.player, spearmanMonument, function() {
                if(vm.interage != "spearmanMonument") vm.interage = "spearmanMonument";
            });
            vm.physics.add.overlap(vm.player, archerMonument, function() {
                if(vm.interage != "archerMonument") vm.interage = "archerMonument";
            });
        }

        function createScene(vm) {
            parallax(vm);

            vm.map = vm.make.tilemap({ key: "mjolnirMap"});
            var floor = vm.map.addTilesetImage("SET1_Mainlev_build", "floor1");
            var constructions = vm.map.addTilesetImage("VP2_Main", "constructions1");
            var constructions2 = vm.map.addTilesetImage("VP3_Main", "constructions2");
            var water = vm.map.addTilesetImage("Enviroment", "water1");
            var arvores = vm.map.addTilesetImage("SET1_decorative_obj", "arvores1");
            var objects1 = vm.map.addTilesetImage("Tavern", "tavern1");
            var background = vm.map.addTilesetImage("SET1_Main_bckgrdlev_build", "background1");
            vm.layer.background = vm.map.createLayer("background", background, 0, -670).setScale(1.5, 2);
            vm.layer.objects = vm.map.createLayer("objects", [objects1, constructions2], 0, -670).setScale(1.5, 2);
            vm.layer.arvores = vm.map.createLayer("arvores", [arvores, water], 0, -670).setScale(1.5, 2);
            vm.layer.constructions = vm.map.createLayer("constructions", [constructions, constructions2, objects1], 0, -670).setScale(1.5, 2);
            vm.layer.water = vm.map.createLayer("water", water, 0, -670).setScale(1.5, 2).setDepth(15).setAlpha(0.85);
            vm.layer.floor = vm.map.createLayer("floor", [floor, objects1], 0, -670).setScale(1.5, 2).setDepth(20);

            monuments(vm);

            vm.layer.floor.setCollisionBetween(0, 5000);
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "swordmanMonument":
                        statusSkill.mutations.changePathSkill(statusSkill.state, "swordman");
                        statusSkill.mutations.changeClassSkill(statusSkill.state, "main");
                        statusSkill.mutations.changeSkillModal(statusSkill.state, true);
                        break;
                    case "archerMonument":
                        statusSkill.mutations.changePathSkill(statusSkill.state, "archer");
                        statusSkill.mutations.changeClassSkill(statusSkill.state, "main");
                        statusSkill.mutations.changeSkillModal(statusSkill.state, true);
                        break;
                    case "mageMonument":
                        statusSkill.mutations.changePathSkill(statusSkill.state, "mage");
                        statusSkill.mutations.changeClassSkill(statusSkill.state, "main");
                        statusSkill.mutations.changeSkillModal(statusSkill.state, true);
                        break;
                    case "spearmanMonument":
                        statusSkill.mutations.changePathSkill(statusSkill.state, "spearman");
                        statusSkill.mutations.changeClassSkill(statusSkill.state, "main");
                        statusSkill.mutations.changeSkillModal(statusSkill.state, true);
                        break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }

        function createNpc(vm, name, sprite, anime, scale, pos, flipX, haveShop, setSize) {
            var newNpc = vm.npcs.create(pos.x, pos.y, sprite);
            newNpc.setCollideWorldBounds(true).setScale(scale.x, scale.y).play(anime, true).setFlipX(flipX);
            if(setSize) newNpc.setSize(setSize.x, setSize.y);
            
            vm.physics.add.overlap(vm.player, newNpc, function() {
                if(haveShop) {
                    if(vm.interage != name) vm.interage = name;
                }
            });
        }

        var ambient = this.sound.add('ambient');
        var theme = this.sound.add('music');

        ambient.play({loop: true});
        theme.play({loop: true, volume: 0.1});

        this.mainAtk = this.add.group();
        this.physicObject = this.physics.add.group();
        this.physics.world.enable(this.mainAtk);

        this.npcs = this.physics.add.group();
        
        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        createAnimations(this);
        playerImport.createPlayer(this);
        createScene(this);

        this.methods.createKey(this, "enter");

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);

        this.physics.add.collider(this.physicObject, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize, 800);

        // createNpc(this, "blacksmith", "smith", "smith", {x: 2.5, y: 3}, {x: 1800, y: 600}, false, true);
        createNpc(this, "archerGuard", "archerGuard", "archerGuard", {x: 2.5, y: 3}, {x: 2200, y: 600}, true, false, {x: 35, y: 35});
        createNpc(this, "archerGuard", "archerGuard", "archerGuard", {x: 2.5, y: 3}, {x: 5000, y: 600}, false, false, {x: 35, y: 35});
        createNpc(this, "spearmanGuard", "spearmanGuard", "spearmanGuard", {x: 2.5, y: 3}, {x: 650, y: 600}, true, false, {x: 35, y: 35});
        // createNpc(this, "magicHouse", "mage", "mage", {x: 2.5, y: 3}, {x: 860, y: 600}, true, true);
        createNpc(this, "herbalist", "alchemist", "alchemist", {x: 2.1, y: 2.5}, {x: 6500, y: 600}, true, true);
        createNpc(this, "fisherman", "fisherman", "fisherman", {x: 2.5, y: 2.9}, {x: 7150, y: 600}, false, false);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize, 800);

        keyboardControls(this);
    }

    update ()
    {
        if (!this.player.body.embedded) {
            this.interage = "none";
        }
        updatePlayer(this);
    }
}

export default SceneCamp;