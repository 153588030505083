import axios from "axios";

import Store from "../store/store"

// Helpers
import _cookieHelper from '@/core/helpers/CookieHelper';

const storeAcess = Store._modules.root._rawModule.modules;
const generalModule = storeAcess.generalModule.state;
const personModule = storeAcess.personModule;

export default {
    methods: {
        putSaveChar() {
            var hash = _cookieHelper.getCookie("lgTkn");
            return new Promise((resolve, reject) => {
                axios.get(
                    generalModule.apiUrl + "/searchChar/" + hash
                ).then((res) => {
                    var marketBank = res.data[0].marketBank;
                    personModule.mutations.changeMarketBank(personModule.state, marketBank);
                    axios.post(generalModule.apiUrl + '/saveChar', personModule.state.mainPerson)
                    .then(res => {
                        try {
                            resolve(res.json());
                        } catch (err) {
                            reject(err);
                        }
                    });
                }).catch(error => {
                    console.log(error.message);
                });
            });
        },
        // async putSaveChar(char) {
        //     await axios.post(
        //         "https://localhost:3000/saveChar", char
        //     ).catch(error => {
        //         console.log(error.message);
        //     });
        // }
    }
}