export default {
    data() {
        return {
            questCurrent: this.$store.state.personModule.mainPerson.adventureGuild.quests[0],
            isValidate: false,
            letter: ""
        }
    },
    computed: {
        questsChar() {
            return this.$store.state.personModule.mainPerson.adventureGuild.quests;
        },
        reputation() {
            return this.$store.state.personModule.mainPerson.adventureGuild.level;
        },
        monsterDeadStatistic() {
            return this.$store.state.personModule.mainPerson.statistics.monsterDead;
        }
    },
    methods: {
        letterView() {
            switch(this.$store.state.personModule.mainPerson.adventureGuild.level.letter) {
                case 0:
                    return "D";
                case 1:
                    return "C";
                case 2:
                    return "B";
                case 3:
                    return "A";
                case 4:
                    return "S";
                case 5:
                    return "SS";
                case 6:
                    return "SSS";
                case 7:
                    return "SSS+";
            }
        },
        getItem(itemName) {
            return this.$store.state.itemsModule.items.find(i => i.name === itemName);
        },
        getMonster(monsterName) {
            return this.$store.state.monstersModule.types.find(i => i.name === monsterName);
        },
        getBoss(bossName) {
            return this.$store.state.bossesModule.bosses.find(i => i.identification === bossName);
        },
        getImage(typeQuest) {
            switch(typeQuest) {
                case 1:
                    return "bread.png"
                case 2:
                    return "horse.png"
                case 3:
                    return "lizard.png"
                case 4:
                    return "monster.png"
            }
        },
        filterQuests(value) {
            if(
                value.lvl.value <= this.$store.state.personModule.mainPerson.adventureGuild.level.letter &&
                !this.$store.state.personModule.mainPerson.adventureGuild.quests.find(i => i.id === value.id)
            ) {
                return true;
            }
            return false;
        },
        verifyNeed(quest) {
            var validate = true;
            quest.need.map((need) => {
                var itemOrMonster;
                switch(need.type) {
                    case 1:
                        if(validate) {
                            itemOrMonster = this.$store.state.personModule.mainPerson.Inventory.find(i => i.name === need.name);
                            if(!itemOrMonster) {
                                validate = false;
                                return;
                            }
                            if(itemOrMonster.qtd < need.amount) {
                                validate = false;
                                return;
                            }
                        }
                        break;
                    case 2:
                        if(validate) {
                            itemOrMonster = this.$store.state.personModule.mainPerson.statistics.monsterDead.find(i => i.name === need.name);
                            if(itemOrMonster) {
                                if(itemOrMonster.value - need.validate < need.amount) {
                                    validate = false;
                                    return;
                                }
                            }
                            if(!itemOrMonster) {
                                validate = false;
                                return;
                            }
                        }
                        break;
                }
            })
            return validate;
        },
        changeIsValidate() {
            this.isValidate = this.verifyNeed(this.questCurrent);
        },
        addQuest(quest) {
            if(quest.type === 3) {
                quest.need.map((need) => {
                    var monster = this.$store.state.personModule.mainPerson.statistics.monsterDead.find(i => i.name === need.name);
                    need.validate = monster.value;
                })
            }
            this.$store.commit("addQuest", quest);
            this.$forceUpdate();
        },
        selectQuest(quest) {
            this.questCurrent = quest;
            this.changeIsValidate();
            this.$forceUpdate();
            // console.log("this.$store.state.personModule.mainPerson.adventureGuild.quests", this.$store.state.personModule.mainPerson.adventureGuild.quests);
        },
        cancelQuest(quest) {
            this.$store.commit("removeQuest", quest);
            this.$forceUpdate();
        },
        confirmQuest(quest) {
            this.$store.dispatch("successfullQuest", quest);
            this.$forceUpdate();
        },
        close() {
            this.$store.commit("changeQuestPanel", false);
        }
    },
    mounted() {
        this.letter = this.letterView();
        this.changeIsValidate();
        this.$forceUpdate();
    }
}