import { mapGetters } from "vuex"

export default {
    data() {
        return {}
    },
    computed: {
        typeLocation() {
            return this.$store.state.mapModule.currentLocation.group;
        },
        costEnergy() {
            return ((this.$props.price / 15) - ((this.$props.price / 15) * (this.$store.state.personModule.mainPerson.travelTime === null ? 0 : this.$store.state.personModule.mainPerson.travelTime)) / 2);
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        changeLocation (newPlace){
            this.$store.commit("changeShowModalVoyage", false);
            if(newPlace){
                this.$store.dispatch("changeLocationNowAction", newPlace);
            }
        },
        costVoyage (newPlace) {
            if(this.$store.state.personModule.mainPerson.gold.value < this.$props.price){
                alert("Você não possui dinheiro suficiente!");
            } else {
                this.$store.commit("lessMoneyWallet", this.$props.price);
                this.$store.commit("changeShowModalVoyage", false);
                if(newPlace){
                    this.$store.dispatch("changeLocationNowActionMoney", newPlace);
                }
            }
        }
    },
    props: [
        "place",
        "price"
    ]
}