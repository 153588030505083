import axios from "axios";
import _cookieHelper from "../helpers/CookieHelper.js";

import Store from "../store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const generalModule = storeAcess.generalModule.state;

export default {
    methods: {
        getUser: (hash) => {
            storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, true);
            axios.get(
                generalModule.apiUrl + "/login/" + hash
            ).then((res) => {
                if(res.data.length == 0) {
                    storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, false);
                    return alert("Nenhum usuário encontrado!");
                } else {
                    _cookieHelper.setCookie("lgTkn", res.data[0].identifier, 1);
                    location.reload();
                }
                return res;
            }).catch(error => {
                console.log(error.message);
            });
        },
        getChar: (hash) => {
            storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, true);
            axios.get(
                generalModule.apiUrl + "/searchChar/" + hash
            ).then((res) => {
                if(res.data.length == 0) {
                    window.location.href = '/#/createperson';
                    storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, false);
                } else {
                    window.location.href = '/#/World';
                    storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, false);
                }
                return res.data[0];
            }).catch(error => {
                console.log(error.message);
            });
        },
        postNewUser(user) {
            storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, true);
            axios.post(
                generalModule.apiUrl + "/newUser", user
            ).then((res) => {
                window.location.href = '/';
                storeAcess.generalModule.mutations.changeIsEnabledLoading(generalModule, false);
                return res.data;
            }).catch(error => {
                console.log(error.message);
            });
        }
    }
}