import axios from "axios";

const mutations = {
    getSkills(state) {
        axios.get(
            "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getSkill"
        ).then((res) => {
            state.skills = res.data;
        }).catch(error => {
            console.log(error.message);
        });
    },
    setSkills(state, skills) {
        state.skills = skills;
    },
    changeCategory(state, category) {
        state.category = category;
    },
    changeWork(state, work) {
        state.work = work;
    },
    changeClassSkill(state, className) {
        state.class = className;
    },
    changePathSkill(state, path) {
        state.path = path;
    },
    changeSkillModal(state, boolean) {
        state.skillModal = boolean;
    },
    changeViewSkills(state, boolean) {
        state.masterSkill.viewsSkills = boolean;
    },
    changeMasterSkill(state, name) {
        state.masterSkill.nameMaster = name;
    }
}

export default mutations;