export default function spearman(vm) {
    vm.anims.create({
        key: "roll",
        frames: vm.anims.generateFrameNumbers('spearmanRoll', {start: 0, end: 5}),
        frameRate: 10,
        repeat: 0
    })
    vm.anims.create({
        key: "jump",
        frames: vm.anims.generateFrameNumbers('spearmanJump', {start: 0, end: 4}),
        frameRate: 15
    })
    vm.anims.create({
        key: "up",
        frames: vm.anims.generateFrameNumbers('spearmanJump', {start: 2, end: 2}),
        frameRate: 8,
        repeat: 0
    })
    vm.anims.create({
        key: "down",
        frames: vm.anims.generateFrameNumbers('spearmanJump', {start: 3, end: 3}),
        frameRate: 8,
        repeat: 0
    })

    vm.anims.create({
        key: "attack1",
        frames: vm.anims.generateFrameNumbers('spearmanAttack1', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack2",
        frames: vm.anims.generateFrameNumbers('spearmanAttack2', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "attack3",
        frames: vm.anims.generateFrameNumbers('spearmanAttack3', {start: 0, end: 5}),
        frameRate: 12
    })

    vm.anims.create({
        key: "turn",
        frames: vm.anims.generateFrameNumbers('spearmanIdle', {start: 0, end: 1}),
        frameRate: 5,
        repeat: -1
    });

    vm.anims.create({
        key: "walk",
        frames: vm.anims.generateFrameNumbers('spearmanWalk', {start: 0, end: 5}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "run",
        frames: vm.anims.generateFrameNumbers('spearmanRun', {start: 0, end: 5}),
        frameRate: 10,
        repeat: -1
    })

    vm.anims.create({
        key: "dead",
        frames: vm.anims.generateFrameNumbers('spearmanDead', {start: 0, end: 3}),
        frameRate: 5,
        repeat: 0
    })
}