import axios from "axios";

const actions = {
    getAndSetSkills({state, commit}) {
        commit("changeIsEnabledLoading", true);
        axios.get(
            "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getSkill"
        ).then((res) => {
            state.skills = res.data;
            commit("changeIsEnabledLoading", false);
        }).catch(error => {
            console.log(error.message);
        });
    }
}

export default actions;