const mutations = {
    changeEventNow(state, boolean) {
        state.eventNow = boolean;
    },
    attackBandits(state) {
        state.eventNow = true;
        state.randomEventCurrent = {
            type: 1,
            title: "Ataque de bandidos",
            text: "Você está sendo atacado por bandidos que te pararam no meio do caminho, um deles diz que se você não passar um dos seus itens ele vai te matar. O que você vai fazer sobre isso?",
            icon: {
                type: "sprite",
                url: "sprites/bandits/bandit/Idle.png"
            },
            options: [
                "talk",
                "attack",
                "run",
                "give"
            ],
            tree: [
                {
                    type: "talk",
                    status: "Carisma",
                    changes: [
                        {
                            numbers: 5,
                            minNeed: 10,
                            text: {
                                success: {
                                    text: "Você conseguiu enrolar graças a sua alta habilidade em enrolar as pessoas, eles te acharam um cara bacana e foram embora",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Sua história os assustou tanto que eles sairam se borrando de medo",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Parece que seu fracasso era inevitável, eles perceberam sua mentira, te bateram e roubaram um dos seus itens",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "Eles não cairam na sua, agora você está com um item a menos e muitas lágrimas nos olhos",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        },
                        {
                            numbers: 10,
                            minNeed: 5,
                            text: {
                                success: {
                                    text: "Meus parabéns, eles eram mais burros do que você imaginava e seu papo pegou eles de jeito",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Você é o rei da pilantragem, pegou eles no papo e está com sua saúde intacta e inventário também",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Você acabou deixando escapar uma palavra que desmentiu tudo que você tinha dito e os bandidos perceberam, por causa disso eles roubaram um item do seu inventário",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "Quem tem boca vai a Roma, mas você mal conseguiu ir até o seu destino sem tomar uma paulada, menos um item no seu inventário",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        },
                        {
                            numbers: 18,
                            minNeed: 3,
                            text: {
                                success: {
                                    text: "Você tem sorte que não fazem mais ladrões bons hoje em dia, esse ai eram fuleiros",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Seu papo sobre ser um homem perigoso e mal colou muito bem, eles sairam correndo daqui",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Que azar, esses até que eram inteligentes e agora você está com o inventário mais leve",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "Pelo menos agora você tá com o inventário mais livre para carregar outras coisas",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        },
                        {
                            numbers: 20,
                            minNeed: 1,
                            text: {
                                success: {
                                    text: "Você achou um trevo de quatro folhas e isso mudou sua sorte, os bandidos cairam no seu papo e foram andando de volta para o covil",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Sorte e papo é seu nome do meio, meus parabéns!",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Você é o pior orador de toda Parallelium, por isso eles resolveram te ajudar tomando um dos seus itens",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "É o que dá esquecer que carisma também é uma habilidade para se melhorar",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    type: "attack",
                    status: "Ataque",
                    changes: [
                        {
                            numbers: 5,
                            minNeed: 25,
                            text: {
                                success: {
                                    text: "Seu ataque foi tão poderoso que fez com que os bandidos saissem correndo",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Os bandidos contaram histórias sobre esse seu ataque esmagador",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Seu ataque foi fraco demais, e bandidos da floresta odeiam os fracos, você perdeu grande parte do seu HP e está quase morrendo",
                                    result: {
                                        type: "dead",
                                        number: 0
                                    }
                                },
                                failedAlternative: {
                                    text: "Você errou o ataque, e isso lhe custou a vida",
                                    result: {
                                        type: "dead",
                                        number: 0
                                    }
                                }
                            }
                        },
                        {
                            numbers: 10,
                            minNeed: 15,
                            text: {
                                success: {
                                    text: "Seu ataque feriu um dos bandidos gravemente, por causa disso os outros sairam correndo, deixando o colega para morrer",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Seu ataque foi tão rápido que impressionou ou bandidos, fazendo eles correram por suas vidas",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Seu ataque enfureceu o bando, você apanhou até quase morrer",
                                    result: {
                                        type: "hp",
                                        number: -80
                                    }
                                },
                                failedAlternative: {
                                    text: "Você até tentou atacar bravamente, mas caiu e bateu a cabeça, esquecendo parte da experiência que adquiriu",
                                    result: {
                                        type: "hp",
                                        number: -80
                                    }
                                }
                            }
                        },
                        {
                            numbers: 18,
                            minNeed: 8,
                            text: {
                                success: {
                                    text: "A sorte estava do seu lado, e seu ataque bem sucedido afastou os bandidos",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Você fez um ataque tão maluco, que os bandidos acharam que você estava possuido por GAW, o Deus da destruição. Ficaram com tanto medo que sairam correndo por suas vidas",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Você errou e apanhou bastante, mas pelo menos tá vivo",
                                    result: {
                                        type: "hp",
                                        number: -50
                                    }
                                },
                                failedAlternative: {
                                    text: "Você foi muito valente, mas não valeu de nada, tá quase caindo no chão, pedindo socorro",
                                    result: {
                                        type: "hp",
                                        number: -50
                                    }
                                }
                            }
                        },
                        {
                            numbers: 20,
                            minNeed: 2,
                            text: {
                                success: {
                                    text: "A deusa da sorte sorriu para você, um raio derrubou uma árvore em cima de alguns dos bandidos, e com medo dos deuses, eles sairam correndo",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                successAlternative: {
                                    text: "Um enxame de Wasps apareceram e atacaram os bandidos, a vida realmente sorriu para você",
                                    result: {
                                        type: "",
                                        number: 0
                                    }
                                },
                                failed: {
                                    text: "Você teve sorte, mas não tinha força, mesmo que os sussurros das montanhas tenham assustado alguns bandidos, um deles veio e te esfaqueou",
                                    result: {
                                        type: "hp",
                                        number: -20
                                    }
                                },
                                failedAlternative: {
                                    text: "Mesmo que a natureza tentou te ajudar, você ainda é um homem muito fraco e por isso foi atingido por uma das laminas dos bandidos, pelo menos não morreu",
                                    result: {
                                        type: "hp",
                                        number: -20
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    type: "run",
                    status: "Energia",
                    changes: [
                        {
                            numbers: 12,
                            minNeed: 400,
                            text: {
                                success: {
                                    text: "Você correu por kilometros até conseguir despistar os bandidos, no fim deu certo e você não os vê mais",
                                    result: {
                                        type: "Energia",
                                        number: -70
                                    }
                                },
                                successAlternative: {
                                    text: "Você disparou na frente e conseguiu fugir deles depois de se jogar em um rio e segurar sua respiração por um bom tempo",
                                    result: {
                                        type: "Energia",
                                        number: -70
                                    }
                                },
                                failed: {
                                    text: "Você não teve folego para fugir deles e então foi pego, perdeu um pouco de carga",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "Eles conseguiram te encurralar no meio do caminho, quem diria que bandidos fossem tão rápidos, você perdeu um dos seus itens",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        },
                        {
                            numbers: 18,
                            minNeed: 200,
                            text: {
                                success: {
                                    text: "Você correu como um maratonista e pulou o rio, ainda bem, pois bandidos não conseguem pular tão longe assim",
                                    result: {
                                        type: "Energia",
                                        number: -50
                                    }
                                },
                                successAlternative: {
                                    text: "Você correu e escalou uma árvore tão alta que os bandidos não puderam te encontrar, saiu ileso",
                                    result: {
                                        type: "Energia",
                                        number: -50
                                    }
                                },
                                failed: {
                                    text: "Você correu como louco, mas tropeçou e machucou a perna, além de perder um pouco de vida, também perdeu um pouco de itens",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "Você correu o máximo que pode, mas caiu e bateu a cabeça, esquecendo parte da experiência que adquiriu",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        },
                        {
                            numbers: 20,
                            minNeed: 100,
                            text: {
                                success: {
                                    text: "Que sorte, você correu e caiu em um buraco de Larva Gigante desocupado, você está protegido",
                                    result: {
                                        type: "Energia",
                                        number: -20
                                    }
                                },
                                successAlternative: {
                                    text: "Hermes olhando seu desespero o abençoou com velocidade suficiente para fugir dos bandidos, você conseguiu",
                                    result: {
                                        type: "Energia",
                                        number: -20
                                    }
                                },
                                failed: {
                                    text: "Você não aguentou correr quase nada, sua sorte é boa, mas suas pernas não ajudaram",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                },
                                failedAlternative: {
                                    text: "Você até tentou correr e quase conseguiu, mas acabou ficando com caimbra e caiu no chão no campo aberto, os bandidos viram e pegaram alguns dos seus itens",
                                    result: {
                                        type: "thief",
                                        number: 1
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    type: "give",
                    text: "Você foi obediente e deu um dos seus itens aos bandidos, foi o melhor a se fazer",
                    result: {
                        type: "thief",
                        number: 1
                    }
                }
            ]
        }
    },
    choiceTheGame(state) {
        state.eventNow = true;
        state.randomEventCurrent = {
            type: 1,
            title: "O jogo",
            text: "Você encontra um velho no meio do caminho, ele diz que conhece um jogo muito interessante e impossivel de ganhar, que nem os mais inteligentes magos ou poderosos guerreiros poderiam vence-lo, um jogo que apenas os espirituosos o aceitam, mas após aceitarem, suas vidas mudam para sempre. Você quer continuar a ouvir sobre ou desistirá do destino?",
            icon: {
                type: "static",
                url: "npcs/1/Icon5.png"
            },
            options: [
                "custom1",
                "custom2",
                "custom3"
            ],
            customOptions: [
                "Estou curioso, continue...",
                "Sai daqui velho, não quero saber",
                "Não, o jogo não"
            ],
            tree: [
                {
                    type: "custom1",
                    text: "Agora você está no jogo, você nunca o ganha jogo, toda vez que você lembrar do jogo, você perde o jogo, se ouvir alguém falando do jogo, você perde o jogo, e seu objetivo agora é trazer pessoas para o jogo, como eu fiz. Bem vindo ao jogo! Perdi o jogo",
                    result: {
                        type: "enterTheGame",
                        number: 1
                    }
                },
                {
                    type: "custom2",
                    text: "Jovem sábio, a curiosidade é algo perigoso, mas saiba de uma coisa, se você pesquisar sobre isso, você já perdeu!",
                    result: {
                        type: "",
                        number: 0
                    }
                },
                {
                    type: "custom3",
                    text: "PERDI O JOGO!",
                    result: {
                        type: "",
                        number: 0
                    }
                }
            ]
        }
    }
}

export default mutations;