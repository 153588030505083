export default {
    data() {
        return {
            toogle: true
        }
    },
    methods: {
        choiceLanguage(language) {
            localStorage.setItem('language', language);
            this.$store.commit("changeLanguage", language);
            this.toogle = false;
        }
    }
}