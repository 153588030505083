import {WorkArea2D, destroyGame} from "./components-game/cityAreaPhaser";
import {Mjolnir, MjolnirDestroyGame} from "@/shared/cidades/Mjolnir/cityAreaPhaser.js";
import {Eisendorf, EisendorfDestroyGame} from "@/shared/cidades/Eisendorf/cityAreaPhaser.js";

import blackSmithComponent from "./components/blacksmith/BlackSmith.vue";
import herbalistComponent from "./components/herbalist/Herbalist.vue";
import stableComponent from "./components/stable/Stable.vue";
import magicComponent from "./components/magicHouse/MagicHouse.vue";
import ComponentTavern from "./components/tavern/Tavern.vue";
import ComponentExplorer from "./components/explorer/Explorer.vue";

import ComponentMarketP2P from "../market/components/marketP2P/MarketP2P.vue";

import ComponentAdventureGuild from "./components/adventureGuild/AdventureGuild.vue";

import skillSelectComponent from "@/shared/components/skills/Skills.vue";

export default {
    data() {
        return {
        }
    },
    components: {
        blackSmithComponent,
        herbalistComponent,
        stableComponent,
        magicComponent,
        skillSelectComponent,
        ComponentTavern,
        ComponentExplorer,
        ComponentAdventureGuild,
        ComponentMarketP2P
    },
    computed: {
        currentCity() {
            return this.$store.state.mapModule.currentLocation.name;
        },
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        skillView() {
            return this.$store.state.skillModule.skillModal;
        },
        shop() {
            return this.$store.state.itemsModule.shop;
        }
    },
    methods: {},
    mounted() {
        switch(this.$store.state.mapModule.currentLocation.name) {
            case "Cormiles":
                WorkArea2D(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson); break;
            case "Mjölnir":
                Mjolnir(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson); break;
            case "Eisendorf":
                Eisendorf(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson); break;
        }
    },
    destroyed() {
        this.$store.commit("changeShop", "");
        this.$store.commit("changeActiveShop", false);
        this.$store.commit("changeDialogBox", false);
        switch(this.$store.state.mapModule.currentLocation.name) {
            case "Cormiles":
                destroyGame(); break;
            case "Mjölnir":
                MjolnirDestroyGame(); break;
            case "Eisendorf":
                EisendorfDestroyGame(); break;
        }
    }
}