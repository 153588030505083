
import Equip from "./components/equip/Equip.vue";
import Inventory from "./components/inventory/Inventory.vue";
import Attributes from "./components/attributes/Attributes.vue";
import Skills from "./components/skills/Skills.vue";
import StatusBlock from "../../shared/components/sidebar/components/StatusBlock.vue";

export default {
    name: "Home",
    components: {
        Equip,
        Inventory,
        Attributes,
        Skills,
        StatusBlock
    },
    data() {
        return {
            count: 0
        }
    },
    computed: {
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        InventorySpace() {
            return this.$store.state.personModule.mainPerson.inventorySize;
        },
        namePerson() {
            return this.$store.state.personModule.mainPerson;
        },
        equips () {
            return this.$store.state.personModule.mainPerson.Equips;
        },
        personInfos() {
            return this.$store.state.personModule.mainPerson.infos;
        },
        skillModal () {
            return this.$store.state.skillModule.skillModal;
        },
        mapCurrent() {
            return this.$store.state.mapModule.currentLocation;
        }
    },
    methods: {
        showSkills() {
            this.$store.commit("changeSkillModal", true);
        },
        actions(action) {
            switch(action) {
                case 'sitFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue / 5)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue / 5);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 20)});
                    this.$store.dispatch("levelHappiness", -5);
                    break;
                case 'restFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue / 2)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue / 2);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 10)});
                    this.$store.dispatch("levelHappiness", -8);
                    break;
                case 'sleepFloor':
                    this.$store.commit("calculeEnergyCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue)});
                    this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue);
                    this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue / 5)});
                    this.$store.dispatch("levelHappiness", -15);
                    break;
            }
        }
    },
    created() {
        this.$store.commit("getRecipes");
    }
}