import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        recipes: [],
        recipeNow: {},
        typeRecipe: 0,
        craftModal: false,
        itemCraft: {},
        materials: [],
        temporaryStatus: {},
        work: {},
        price: 0,
        weight: 0,
        resistance: 0,
        statusAdd: {},
        addItemsMaterials: [],
        rarity: {}
    },
    mutations,
    actions
}