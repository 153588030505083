import Phaser from "phaser";

import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods"
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateActions } from "@/shared/components-phaser/updateActions" 

import BattleService from "@/core/services/BattleService";

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene2 extends Phaser.Scene {
    constructor(){
        super("external2");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.player;
        this.mapSize = {
            x: 12960,
            y: 2400
        };
        this.trapHurt = true;
        this.layer = {};
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        // Ambient Sons
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/ruins/Cave-2_Loop.wav');
        this.load.audio('music', '/resourcesInGame/sons/musics/ruins/Dungeon_-_Catacomb_Crawler.ogg');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/ruins/tilemap/SET6_Main_bckgrdlev_build.png');
        this.load.image('traps1', '/resourcesInGame/title-set/tiles/ruins/tilemap/SET6_Mainlev_build.png');
        this.load.image('objects1', '/resourcesInGame/title-set/tiles/ruins/tilemap/SET6_decorative1.png');
        this.load.image('arvoresTile', '/resourcesInGame/title-set/tiles/ruins/tilemap/SET6_decorative2.png');
        this.load.tilemapTiledJSON("map2", "/resourcesInGame/maps/ruinasSkull/scene2-ruinasSkull.json");

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/ruins/SET6_background1.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/florest/grass.png");
        this.load.image("trees", "/resourcesInGame/title-set/parallax/trees/ruins/SET6_background4.png");
        this.load.image("trees2", "/resourcesInGame/title-set/parallax/trees/ruins/SET6_background3.png");
        this.load.image("trees3", "/resourcesInGame/title-set/parallax/trees/ruins/SET6_background2.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/clouds.png");

        //enemies
        this.load.spritesheet('skeletonIdle', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_origin/skeletonWarrior_idle.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonWalk', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_origin/skeletonWarrior_walk.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonAttack', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_origin/skeletonWarrior_attack.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonDeath', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_origin/skeletonWarrior_dead.png', {frameWidth: 50, frameHeight: 48});
        
        this.load.spritesheet('skeletonShieldIdle', '/resourcesInGame/sprites/ruins/skeleton/ske_shield/shield_origin/skeletonShield_idle.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonShieldWalk', '/resourcesInGame/sprites/ruins/skeleton/ske_shield/shield_origin/skeletonShield_walk.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonShieldAttack', '/resourcesInGame/sprites/ruins/skeleton/ske_shield/shield_origin/skeletonShield_attack.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonShieldDeath', '/resourcesInGame/sprites/ruins/skeleton/ske_shield/shield_origin/skeletonShield_dead.png', {frameWidth: 50, frameHeight: 48});
        
        this.load.spritesheet('goldSkeletonIdle', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_yellow/skeletonGoldWarrior_idle.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('goldSkeletonWalk', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_yellow/skeletonGoldWarrior_walk.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('goldSkeletonAttack', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_yellow/skeletonGoldWarrior_attack.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('goldSkeletonDeath', '/resourcesInGame/sprites/ruins/skeleton/ske_sword/sword_yellow/skeletonGoldWarrior_dead.png', {frameWidth: 50, frameHeight: 48});

        this.load.spritesheet('skeletonArcherIdle', '/resourcesInGame/sprites/ruins/skeleton/skeletonArcher/skeletonArcher_idle.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonArcherWalk', '/resourcesInGame/sprites/ruins/skeleton/skeletonArcher/skeletonArcher_walk.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonArcherAttack', '/resourcesInGame/sprites/ruins/skeleton/skeletonArcher/skeletonArcher_attack2.png', {frameWidth: 50, frameHeight: 48});
        this.load.spritesheet('skeletonArcherDeath', '/resourcesInGame/sprites/ruins/skeleton/skeletonArcher/skeletonArcher_dead.png', {frameWidth: 50, frameHeight: 48});
        
        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            // Enemy
            //Warrior Skeleton
            vm.anims.create({
                key: "skeletonIdle",
                frames: vm.anims.generateFrameNumbers("skeletonIdle", {start: 0, end: 2}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonWalk",
                frames: vm.anims.generateFrameNumbers("skeletonWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonAttack",
                frames: vm.anims.generateFrameNumbers("skeletonAttack", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonDeath",
                frames: vm.anims.generateFrameNumbers("skeletonDeath", {start: 0, end: 5}),
                frameRate: 8,
                repeat: 0
            })
            //Shield Skeleton
            vm.anims.create({
                key: "skeletonShieldIdle",
                frames: vm.anims.generateFrameNumbers("skeletonShieldIdle", {start: 0, end: 2}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonShieldWalk",
                frames: vm.anims.generateFrameNumbers("skeletonShieldWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonShieldAttack",
                frames: vm.anims.generateFrameNumbers("skeletonShieldAttack", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonShieldDeath",
                frames: vm.anims.generateFrameNumbers("skeletonShieldDeath", {start: 0, end: 5}),
                frameRate: 8,
                repeat: 0
            })
            //Gold Skeleton
            vm.anims.create({
                key: "goldSkeletonIdle",
                frames: vm.anims.generateFrameNumbers("goldSkeletonIdle", {start: 0, end: 2}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "goldSkeletonWalk",
                frames: vm.anims.generateFrameNumbers("goldSkeletonWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "goldSkeletonAttack",
                frames: vm.anims.generateFrameNumbers("goldSkeletonAttack", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "goldSkeletonDeath",
                frames: vm.anims.generateFrameNumbers("goldSkeletonDeath", {start: 0, end: 5}),
                frameRate: 8,
                repeat: 0
            })

            vm.anims.create({
                key: "skeletonArcherIdle",
                frames: vm.anims.generateFrameNumbers("skeletonArcherIdle", {start: 0, end: 2}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonArcherWalk",
                frames: vm.anims.generateFrameNumbers("skeletonArcherWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonArcherAttack",
                frames: vm.anims.generateFrameNumbers("skeletonArcherAttack", {start: 0, end: 5}),
                frameRate: 6,
                repeat: -1
            }),
            vm.anims.create({
                key: "skeletonArcherDeath",
                frames: vm.anims.generateFrameNumbers("skeletonArcherDeath", {start: 0, end: 5}),
                frameRate: 8,
                repeat: 0
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0).setScale(3.5);

            var clouds = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);

            var mountains = vm.add.image(0, 0, 'trees2').setOrigin(0).setScrollFactor(.33).setScale(3);
            var mountains2 = vm.add.image(1280, 0, 'trees2').setOrigin(0).setScrollFactor(.33).setScale(3);

            var bushes = vm.add.image(0, 120, 'trees3').setOrigin(0).setScrollFactor(.66).setScale(3);
            var bushes2 = vm.add.image(1280, 120, 'trees3').setOrigin(0).setScrollFactor(.66).setScale(3);
            var bushes3 = vm.add.image(2560, 120, 'trees3').setOrigin(0).setScrollFactor(.66).setScale(3);

            var mountainsBack = vm.add.image(0, 175, 'trees').setOrigin(0).setScrollFactor(.75).setScale(3);
            var mountainsBack2 = vm.add.image(1280, 175, 'trees').setOrigin(0).setScrollFactor(.75).setScale(3);

            var mountainsBackDown = vm.add.image(0, 300, 'trees').setOrigin(0).setScrollFactor(.75).setScale(3);
            var mountainsBackDown2 = vm.add.image(1280, 300, 'trees').setOrigin(0).setScrollFactor(.75).setScale(3);

            // vm.background.displayWidth = vm.mapSize;
            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            mountainsBackDown.displayHeight = vm.sys.game.config.height;
            mountainsBackDown2.displayHeight = vm.sys.game.config.height;

            bushes.displayHeight = vm.sys.game.config.height;
            bushes2.displayHeight = vm.sys.game.config.height;
            bushes3.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "nextScene":
                        vm.scene.restart(); vm.scene.start('internal1'); break;
                    case "tree":
                        vm.methods.workCommom(vm, vm.worked.item, vm.worked.time, vm.worked.message, vm.worked.tree); break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }
        
        function monsterSpawn(vm) {
            vm.enemyArray = [];
            vm.methods.createEnemy(vm, "skeletonShield", "skeletonShieldIdle", "skeletonShieldIdle", 8, 2.5, 3, {bodyX: 20, bodyY: 30}, {x: 14, y: 12}, 100);
            vm.methods.createEnemy(vm, "skeleton", "skeletonIdle", "skeletonIdle", 20, 2.5, 3, {bodyX: 20, bodyY: 30}, {x: 14, y: 12}, 200);
            vm.methods.createEnemy(vm, "skeletonArcher", "skeletonArcherIdle", "skeletonArcherIdle", 10, 2.5, 3, {bodyX: 20, bodyY: 30}, {x: 14, y: 12}, 200);
            vm.methods.createEnemy(vm, "goldSkeleton", "goldSkeletonIdle", "goldSkeletonIdle", 5, 2.5, 3, {bodyX: 20, bodyY: 30}, {x: 14, y: 12}, 2250);
        }
    
        function createScene(vm) {
            parallax(vm);

            var map = vm.make.tilemap({ key: "map2", tileWidth: 32, tileHeight: 32});
            var tileset = map.addTilesetImage("SET6_Main_bckgrdlev_build", "floor1");
            var arvores = map.addTilesetImage("SET6_decorative2", "arvoresTile");
            var objects1 = map.addTilesetImage("SET6_decorative1", "objects1");
            var traps = map.addTilesetImage("SET6_Mainlev_build", "traps1");
            vm.layer.background = map.createLayer("background", tileset, 0, 0).setScale(1.5);
            vm.layer.objects = map.createLayer("objects", objects1, 0, 0).setScale(1.5);
            vm.layer.arvores = map.createLayer("arvores", arvores, 0, 0).setScale(1.5);
            vm.layer.traps = map.createLayer("traps", traps, 0, 0);
            vm.layer.floor = map.createLayer("floor", tileset, 0, 0).setScale(1.5);

            vm.layer.traps.setScale(1.5);

            vm.layer.trapCollider = [];

            vm.layer.floor.setCollisionBetween(0, 1000);

            vm.layer.traps.forEachTile(function (tile){
                if(tile.tileset) vm.layer.trapCollider.push(tile);
            })
        }

        function createLimits(vm) {
            var initial; 
            vm.limits.add(vm.add.rectangle(50, 800, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];

            vm.physics.add.overlap(initial, vm.player, function() {
                vm.scene.start('external1');
            });
        }

        this.sounds.ambient = this.sound.add('ambient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.ambient.play({loop: true, volume: 0.8});
        this.sounds.theme.play({loop: true, volume: 0.5});

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.animals = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        playerImport.createPlayer(this, 400, 1000);
        createScene(this);
        createLimits(this);

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);
        this.physics.add.collider(this.animals, this.layer.floor);
        this.physics.add.collider(this.physicObject, this.layer.floor);
        this.physics.add.collider(this.limits, this.layer.floor);
        this.physics.add.collider(this.monsterAttack, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        // this.methods.createAnimal(this, "elk", "elkIdle", "elkIdle", {x: 2.5, y: 3}, {x: 350, y: 600}, true);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        
        monsterSpawn(this);
        
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        var vm = this;
        this.physics.world.overlapTiles(this.player, this.layer.trapCollider, function() {
            console.log("trap")
            if(vm.trapHurt) {
                vm.trapHurt = false;
                BattleService.methods.changeDurabilityArmor();
                vm.methods.hurtPlayer(25);
                var interval = setInterval(() => {
                    vm.trapHurt = true;
                    clearInterval(interval);
                }, 1000);
            }
        });
        updateActions(this);
    }
}