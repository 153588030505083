import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";
import { methodsDungeon } from "@/shared/components-phaser/methods";

import keyboardsImport from "@/shared/components-phaser/keyboards" 
import playerImport from "@/shared/components-phaser/player" 
import { updatePlayer } from "@/shared/components-phaser/updateActions" 

import preloadAllResources from "@/shared/components-phaser/preload.js"

import AnimatedTiles from 'phaser-animated-tiles/dist/AnimatedTiles.min.js'

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;

class SceneCamp extends Phaser.Scene {
    constructor(locationInfos, mainPerson){
        super("eisendorfScene");
        this.locationInfos = locationInfos;
        this.mainPerson = mainPerson;
        this.player;
        this.key;
        this.mapSize = {
            x: 8640,
            y: 1600
        };
        this.layer = {};
        this.map;
        this.door;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.scenePlugin('animatedTiles', AnimatedTiles, 'animatedTiles', 'animatedTiles');

        // Resources all maps
        preloadAllResources(this);

        this.load.audio('ambient', '/resourcesInGame/sons/ambient/Village_1_Loop.wav');
        this.load.audio('music', '/resourcesInGame/sons/musics/village/Town_-_Stone_Keep.ogg');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/village/set/SET1_Mainlev_build.png');
        this.load.image('ponte1', '/resourcesInGame/title-set/tiles/ponte/Bridge_tileset.png');
        this.load.image('water1', '/resourcesInGame/title-set/tiles/village/set/Enviroment.png');
        this.load.image('constructions1', '/resourcesInGame/title-set/tiles/village/set/VP2_Main.png');
        this.load.image('constructions2', '/resourcesInGame/title-set/tiles/village/set/VP3_Main.png');
        this.load.image('tavern', '/resourcesInGame/title-set/tiles/village/set/Tavern.png');
        this.load.image('objects1', '/resourcesInGame/title-set/objects/animated/animatedObjects.png');
        this.load.image('arvores1', '/resourcesInGame/title-set/objects/tree/pack1/trees.png');
        this.load.tilemapTiledJSON("eisendorfMap", "/resourcesInGame/maps/eisendorf/scene1-eisendorf.json");

        //Constructions
        this.load.image("swordmanMonument", "/resourcesInGame/title-set/monuments/swordman.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/village/bg.png");
        this.load.image("mountains2", "/resourcesInGame/title-set/parallax/trees/village/eisendorf/rocks_trees.png");
        this.load.image("mountains", "/resourcesInGame/title-set/parallax/trees/village/eisendorf/trees1.png");
        this.load.image("bushes", "/resourcesInGame/title-set/parallax/trees/village/eisendorf/bushes.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/village/Green_Montains_BGe.png");

        //animatedConstructions

        //NPCs
        this.load.spritesheet('smith', '/resourcesInGame/sprites/npcs/blacksmith/Blacksmith.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('spearmanGuard', '/resourcesInGame/sprites/npcs/guard/spearman/spearmanGuard-idle.png', { frameWidth: 50, frameHeight: 48 });
        this.load.spritesheet('archerGuard', '/resourcesInGame/sprites/npcs/guard/archer/archerGuard-idle.png', { frameWidth: 50, frameHeight: 48 });
        this.load.spritesheet('mage', '/resourcesInGame/sprites/npcs/mage/Wizard_read.png', { frameWidth: 48, frameHeight: 48 });
        this.load.spritesheet('explorer', '/resourcesInGame/sprites/npcs/tavern/NPC_Explorer.png', { frameWidth: 64, frameHeight: 64 });
        this.load.spritesheet('fisherman', '/resourcesInGame/sprites/npcs/fisherman/Fisherman_fish.png', { frameWidth: 48, frameHeight: 48 });
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            //NPC
            vm.anims.create({
                key: "smith",
                frames: vm.anims.generateFrameNumbers('smith', {start: 0, end: 10}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "spearmanGuard",
                frames: vm.anims.generateFrameNumbers('spearmanGuard', {start: 0, end: 1}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "archerGuard",
                frames: vm.anims.generateFrameNumbers('archerGuard', {start: 0, end: 1}),
                frameRate: 3,
                repeat: -1
            })
            vm.anims.create({
                key: "mage",
                frames: vm.anims.generateFrameNumbers('mage', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "explorer",
                frames: vm.anims.generateFrameNumbers('explorer', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
            vm.anims.create({
                key: "alchemist",
                frames: vm.anims.generateFrameNumbers('alchemist', {start: 0, end: 5}),
                frameRate: 3,
                repeat: -1
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, 0, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(0, 0, 'clouds').setOrigin(0).setScrollFactor(.15);

            vm.add.image(0, -100, 'mountains2').setOrigin(0).setScrollFactor(.33);
            vm.add.image(1920, -100, 'mountains2').setOrigin(0).setScrollFactor(.33);

            vm.add.image(0, 0, 'mountains').setOrigin(0).setScrollFactor(.66);
            vm.add.image(1920, 0, 'mountains').setOrigin(0).setScrollFactor(.66);
            vm.add.image(3840, 0, 'mountains').setOrigin(0).setScrollFactor(.66);
            vm.add.image(5760, 0, 'mountains').setOrigin(0).setScrollFactor(.66);

            vm.add.image(0, -100, 'bushes').setOrigin(0).setScrollFactor(.75);
            vm.add.image(1920, -100, 'bushes').setOrigin(0).setScrollFactor(.75);
            vm.add.image(3840, -100, 'bushes').setOrigin(0).setScrollFactor(.75);
            vm.add.image(5760, -100, 'bushes').setOrigin(0).setScrollFactor(.75);

            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;
        }

        function createScene(vm) {
            parallax(vm);

            vm.map = vm.make.tilemap({ key: "eisendorfMap", tileWidth: 16, tileHeight: 16});
            var floor = vm.map.addTilesetImage("SET1_Mainlev_build", "floor1");
            var constructions1 = vm.map.addTilesetImage("VP2_Main", "constructions1");
            var constructions2 = vm.map.addTilesetImage("VP3_Main", "constructions2");
            var ponte = vm.map.addTilesetImage("Bridge_tileset", "ponte1");
            var water = vm.map.addTilesetImage("Enviroment", "water1");
            var arvores = vm.map.addTilesetImage("trees", "arvores1");
            var objects1 = vm.map.addTilesetImage("animatedObjects", "objects1");
            var tavern = vm.map.addTilesetImage("Tavern", "tavern");
            vm.layer.background = vm.map.createLayer("background", [floor, ponte, objects1], 0, 200).setScale(2, 2.5);
            vm.layer.ponte = vm.map.createLayer("ponte", ponte, 0, 200).setScale(2, 2.5).setDepth(15);
            vm.layer.constructions = vm.map.createLayer("constructions", [constructions1, constructions2, objects1, tavern], 0, 200).setScale(2, 2.5);
            vm.layer.arvores = vm.map.createLayer("arvores", [arvores, water], 0, 200).setScale(2, 2.5);
            vm.layer.objects = vm.map.createLayer("objects", [objects1, constructions1, constructions2], 0, 200).setScale(2, 2.5);
            vm.layer.water = vm.map.createLayer("water", water, 0, 200).setScale(2, 2.5).setDepth(15).setAlpha(0.85);
            vm.layer.floor = vm.map.createLayer("floor", [floor, ponte], 0, 200).setScale(2, 2.5);

            vm.sys.animatedTiles.init(vm.map);

            vm.layer.floor.setCollisionBetween(0, 50000);
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "tavern":
                        vm.game.sound.stopAll();
                        vm.scene.start('tavernScene');
                        break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }

        function doors(vm) {
            var tavern;
            vm.door.add(vm.add.rectangle(4030, 865, 90, 190, 0xffffff));
            for(var i = 0; i < vm.door.getChildren().length; i++) {
                vm.door.getChildren()[i].alpha = 0;
                vm.door.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.door.getChildren()[i]);
                vm.door.getChildren()[i].body.moves = false;
                vm.door.getChildren()[i].body.setAllowGravity(false);
            }
            
            tavern = vm.door.getChildren()[0];

            vm.physics.add.overlap(vm.player, tavern, function() {
                if(vm.interage != "tavern") vm.interage = "tavern";
            });
        }

        function createNpc(vm, name, sprite, anime, scale, pos, flipX, haveShop, setSize) {
            var newNpc = vm.npcs.create(pos.x, pos.y, sprite);
            newNpc.setCollideWorldBounds(true).setScale(scale.x, scale.y).play(anime, true).setFlipX(flipX);
            if(setSize) newNpc.setSize(setSize.x, setSize.y);
            
            vm.physics.add.overlap(vm.player, newNpc, function() {
                if(haveShop) {
                    if(vm.interage != name) vm.interage = name;
                }
            });
        }

        var ambient = this.sound.add('ambient');
        var theme = this.sound.add('music');

        ambient.play({loop: true});
        theme.play({loop: true, volume: 0.1});

        this.mainAtk = this.add.group();
        this.physicObject = this.physics.add.group();
        this.physics.world.enable(this.mainAtk);
        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.door = this.physics.add.group();

        createAnimations(this);
        playerImport.createPlayer(this, 1000, 820);
        createScene(this);
        doors(this);

        this.methods.createKey(this, "enter");

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);

        this.physics.add.collider(this.physicObject, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        createNpc(this, "blacksmith", "smith", "smith", {x: 3, y: 3.5}, {x: 3350, y: 500}, false, true);
        createNpc(this, "archerGuard", "archerGuard", "archerGuard", {x: 2.5, y: 3}, {x: 2200, y: 600}, true, false, {x: 35, y: 35});
        createNpc(this, "archerGuard", "archerGuard", "archerGuard", {x: 2.5, y: 3}, {x: 5000, y: 600}, false, false, {x: 35, y: 35});
        createNpc(this, "spearmanGuard", "spearmanGuard", "spearmanGuard", {x: 2.5, y: 3}, {x: 950, y: 950}, true, false, {x: 35, y: 35});
        createNpc(this, "spearmanGuard", "spearmanGuard", "spearmanGuard", {x: 2.5, y: 3}, {x: 6500, y: 600}, true, false, {x: 35, y: 35});
        createNpc(this, "explorer", "explorer", "explorer", {x: 2, y: 2.5}, {x: 6200, y: 580}, true, true);
        // createNpc(this, "herbalist", "alchemist", "alchemist", {x: 2.1, y: 2.5}, {x: 6500, y: 600}, true, true);
        // createNpc(this, "fisherman", "fisherman", "fisherman", {x: 2.5, y: 2.9}, {x: 7150, y: 600}, false, false);

        this.cameras.main.startFollow(this.player).setBounds(0, -400, this.mapSize.x, this.mapSize.y);

        keyboardControls(this);
    }

    update ()
    {
        if (!this.player.body.embedded) {
            this.interage = "none";
        }
        updatePlayer(this);
    }
}

export default SceneCamp;