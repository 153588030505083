import SellItemsComponent from "@/pages/market/components/sellItems/SellItems.vue";
import BuyItemsComponent from "@/pages/market/components/buyItems/BuyItems.vue";

export default {
    data() {
        return {
            colapse: "sell",
            buyColapse: true
        }
    },
    components: {
        SellItemsComponent,
        BuyItemsComponent
    },
    computed: {
        buyItems() {
            return this.$store.state.itemsModule.items.filter(i => i.seller == "magic house" && i.rarity == "normal");
        },
        sellItems() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        }
    },
    methods: {
        toogleBuy(name) {
            this.colapse = name;
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        }
    }
}