const contract = {
    ArmorBoxContract: "0x503d0B81DD8fF73aCD0a7974B1D945C57d927C00",
    SwordBoxContract: "0x3A0Fe72D224F33eF0e49B67b31D3F16A4eB5a656",
    BlackSmithContract: "0x1DCeAb40103cDb6528EdF038Dcd73D4aDf4C1A85",
    AxeBoxContract: "0x3f9dDa59e7CB10D48b38788F9992215D2307dB0E",
    JewelerContract: "0x59A21988dC018e0bDE3477E7a40079408f54B41a",
    ArmorForgeContract: "0x041A6aA2D32ce63C8FdA5994409cCB03714Fd263",
    ShieldBoxContract: "0x6891aFF67B087629Ee7Fb260b1919ed8f2BCEc94",
    MagicHouseContract: "0xcBBDbF4167D25041a96FcE8890E0e2C0F5c6C600",
    PacksContract: "0x12983D3D3700a17C39A57b8d9C3314e47be05e65",
    SkinsContract: "0x35B4be305C08695d1B637958E997A1b0Bc6A69d6",
    PhotoSkinsContract: "0x86701D9e111b0623b93CbC9359ce45Dd8B52D941"
}

export default contract;