import { mapGetters } from "vuex";
import axios from "axios";

import SaveCharService from '@/core/services/saveCharService';

import DescItemTooltipComponent from "@/shared/components/descItemTooltip/DescItemTooltip.vue";

export default {
    data() {
        return {
            enableButton: true,
            buyForms: {
                priceBuy: 1
            }
        }
    },
    props: ['marketItem'],
    components: {
        DescItemTooltipComponent
    },
    computed: {
        charId() {
            return this.$store.state.personModule.mainPerson._id;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        async deleteFromMarket(item) {
            var newItem = item;
            axios.post(
                this.$store.state.generalModule.apiUrl + '/removeMarketItem',
                newItem
            ).then(() => {
                console.log("removeMarketItem");
            }).catch(error => {
                console.log(error.message);
                return;
            });
            delete newItem.location;
            delete newItem.player;
            delete newItem.identifier;
            var addItem = {
                item: newItem,
                quantidade: parseInt(newItem.qtd)
            }
            this.$store.commit("addItemInventory", addItem);
            SaveCharService.methods.putSaveChar(this.$store.state.personModule.mainPerson);
            await this.$store.commit("getMarketItemsBack");
            this.$forceUpdate();
        },
        async buyMarketItems(item) {
            var newItem = item;
            var alert = {
                type: "default",
                imgUrl: "",
                message: ""
            }
            this.$store.commit("changeIsEnabledLoading", true);
            if(item.qtd < this.buyForms.priceBuy) return;
            if(this.$store.state.personModule.mainPerson.gold.value < parseFloat(newItem.price) * this.buyForms.priceBuy) {
                alert.imgUrl = "actions/29.png"
                alert.message = "Você não possui dinheiro suficiente!";
                this.$store.dispatch("showAlert", alert);
                return;
            }
            if(newItem.qtd === this.buyForms.priceBuy) {
                axios.post(
                    this.$store.state.generalModule.apiUrl + '/removeMarketItem',
                    newItem
                ).then(() => {
                    console.log("removeMarketItem");
                }).catch(error => {
                    console.log(error.message);
                    return;
                });
            } else {
                newItem.qtd = parseInt(this.buyForms.priceBuy);
                axios.post(
                    this.$store.state.generalModule.apiUrl + '/updateAmountMarketItem',
                    newItem
                ).then((res) => {
                    console.log(res);
                }).catch(error => {
                    console.log(error.message);
                });
            }
            var transferMoney = {
                identifier: newItem.identifier,
                value: parseFloat(newItem.price) * this.buyForms.priceBuy,
            };
            delete newItem.location;
            delete newItem.player;
            delete newItem.identifier;
            axios.post(
                this.$store.state.generalModule.apiUrl + '/updateGoldBankMarket',
                transferMoney
            ).then((res) => {
                console.log(res);
            }).catch(error => {
                console.log(error.message);
            });
            var addItem = {
                item: newItem,
                quantidade: this.buyForms.priceBuy
            }
            alert.imgUrl = "actions/bag.png"
            alert.message = `Você comprou x${this.buyForms.priceBuy} ${newItem.name}`;
            this.$store.commit("lessMoneyWallet", newItem.price * this.buyForms.priceBuy);
            this.$store.commit("addItemInventory", addItem);
            this.$store.commit("changeIsEnabledLoading", false);
            this.$store.dispatch("showAlert", alert);
            await this.$store.commit("getMarketItemsBack");
            this.$forceUpdate();
        },
        verifyInput() {
            if(this.priceBuy > 99) {
                this.enableButton = false;
                return;
            }
            this.enableButton = true;
            return;
        }
    }
}