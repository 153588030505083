import playerClass from "@/core/models/inGame/player";
import BattleService from "@/core/services/BattleService";

const player = {
    createPlayer(vm,x,y) {
        //Player
        if(x && y) vm.player = new playerClass(vm, `${vm.mainPerson.sprite}Idle`, {x,y});
        else vm.player = new playerClass(vm, `${vm.mainPerson.sprite}Idle`, {x: 200,y: 400});
        vm.player.anims.play('turn', true);

        if(vm.monsterAttack) {
            vm.physics.add.overlap(vm.monsterAttack, vm.player, function(rectangle) {
                var atkMob = rectangle.damage;
                rectangle.destroy();
                vm.player.setTint(0xff0000);
                var interval = setInterval(() => {
                    vm.player.setTint(0xffffff);
                    var newDamage = BattleService.methods.attackMobCalcule(atkMob);
                    BattleService.methods.changeDurabilityArmor();
                    vm.methods.hurtPlayer(newDamage);
                    clearInterval(interval);
                }, 250);
            });
    
            vm.physics.add.collider(vm.monsterAttack, vm.player, function(rectangle) {
                var atkMob = rectangle.damage;
                rectangle.destroy();
                vm.player.setTint(0xff0000);
                var interval = setInterval(() => {
                    vm.player.setTint(0xffffff);
                    var newDamage = BattleService.methods.attackMobCalcule(atkMob);
                    BattleService.methods.changeDurabilityArmor();
                    vm.methods.hurtPlayer(newDamage);
                    clearInterval(interval);
                }, 250);
            });
        }
    },
    createPlayerMatter(vm, x, y) {
        vm.player = vm.matter.add.sprite(x, y, `${vm.mainPerson.sprite}Idle`, 1);
        vm.player.play("turn");
        vm.player.body.label = 'player';

        vm.player.setScale(2.5, 3);
        vm.player.depth = 10;
        vm.player.setBounce(0.2);

        // vm.player.setCollisionGroup(vm.groupsCollisions.world);

        vm.matter.world.on("collisionactive", (e) => {
            var interval;

            e.pairs.forEach(pair => {
                if( pair.bodyA.label == 'enemyAtk' && pair.bodyB.label =='player' ) {
                    vm.enemyAtk.forEach(atk => {atk.destroy()});
                    interval = setInterval(() => {
                        var newDamage = BattleService.methods.attackMobCalcule(pair.bodyA.damage);
                        BattleService.methods.changeDurabilityArmor();
                        vm.methods.hurtPlayer(newDamage);
                        clearInterval(interval);
                    }, 250);
                }
                if( pair.bodyA.label == 'player' && pair.bodyB.label =='enemyAtk' ) {
                    vm.enemyAtk.forEach(atk => {atk.destroy()});
                    interval = setInterval(() => {
                        var newDamage = BattleService.methods.attackMobCalcule(pair.bodyB.damage);
                        BattleService.methods.changeDurabilityArmor();
                        vm.methods.hurtPlayer(newDamage);
                        clearInterval(interval);
                    }, 250);
                }
            })
            
            // if( e.pairs.some (pair => pair.bodyA.label == 'enemyAtk' && pair.bodyB.label =='player' )) {
            //     var atkMob = rectangle.damage;
            //     vm.enemyAtk.forEach(atk => {atk.destroy()});
            //     var interval = setInterval(() => {
            //         var newDamage = BattleService.methods.attackMobCalcule(atkMob);
            //         BattleService.methods.changeDurabilityArmor();
            //         vm.methods.hurtPlayer(newDamage);
            //         clearInterval(interval);
            //     }, 250);
            // }
            
            // if( e.pairs.some (pair => pair.bodyA.label == 'player' && pair.bodyB.label =='enemyAtk' )) {
            //     vm.enemyAtk.forEach(atk => {atk.destroy()});
            //     interval = setTimeout(() => {
            //         vm.methods.getDamage(vm, newEnemy, name);
            //         clearTimeout(interval);
            //     }, 250);
            // }        
        });
    }
}

export default player;