import Phaser from "phaser";

import collisionAltmenerArcher from "@/shared/collisions/archer/altmenerArcher/collision";
import collisionNordicBarbarian from "@/shared/collisions/swordman/nordicBarbarian/collision";
import collisionBloodHit from "@/shared/collisions/swordman/bloodHit/collision";
import collisionMain from "@/shared/collisions/main/collision";

class player extends Phaser.GameObjects.Sprite {
    constructor(scene, sprite, position) {
        var x = position.x;
        var y = position.y;
        var newSprite = function() {
            var spriteReplace = sprite.replace("Idle", "");
            switch(spriteReplace) {
                case "altmenerArcher":
                    return collisionAltmenerArcher;
                case "nordicBarbarian":
                    return collisionNordicBarbarian;
                case "bloodHit":
                    return collisionBloodHit;
                default:
                    return collisionMain;
            }
        }

        super(scene, x, y, sprite);

        scene.physics.world.enable(this);
        scene.add.existing(this);

        // this.play(sprite);

        // this.setScale(2.5, 3);
        // this.body.setSize(20, 32, true);
        // this.body.offset.y = 10;

        this.setScale(newSprite().setScale.x, newSprite().setScale.y);
        this.body.setSize(newSprite().setSize.x, newSprite().setSize.y, true);
        this.body.offset.y = newSprite().offset.y;

        this.depth = 10;
        // this.body.setBounce(0.2);

        // this.status = {
        //     hp: status.
        // }

    }

    hurt() {

    }

    update() {

    }
}

export default player;