const normalDialog = {
    steps: [
        {
            number: 1,
            message: "Olá amigo, dia bonito para derrubar o capitalismo... Digo, essa árvore",
            options: [
                {
                    message: "??????? Ok...",
                    type: "default",
                    goStep: 2,
                    function: ""
                },
                {
                    message: "Você quer... Derrubar o capitalismo??",
                    type: "default",
                    goStep: 3,
                    function: ""
                },
                {
                    message: "Eu conheço você de algum lugar?",
                    type: "default",
                    goStep: 5,
                    function: ""
                },
                {
                    message: "MOTHERRUSSIA MOTHERFUCKYEA",
                    type: "default",
                    goStep: 4,
                    function: ""
                },
                {
                    message: "To caindo fora, tchau",
                    type: "default",
                    goStep: 9,
                    function: ""
                }
            ]
        },
        {
            number: 2,
            message: "É... então camarada, o que veio fazer aqui?",
            options: [
                {
                    message: "Preciso me tornar um lenhador, disseram que você poderia me ajudar com isso",
                    type: "default",
                    goStep: 6,
                    function: ""
                },
                {
                    message: "Camarada??? To ficando muito confuso... Tu não é o...",
                    type: "default",
                    goStep: 5
                }
            ]
        },
        {
            number: 3,
            message: "É... Claro que não... Você deve ter ouvido coisas... Eu amo o capitalismo... Olha o meu machado, é da Polidas...",
            options: [
                {
                    message: "Ah... Tudo bem então",
                    type: "default",
                    goStep: 6
                },
                {
                    message: "To caindo nesse papo não... Você por acaso não o...",
                    type: "default",
                    goStep: 5
                },
                {
                    message: "Isso tá ficando muito estranho, melhor eu sair daqui",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 4,
            message: "(Sorriso disfarçado) Desconheço essa Motherrussia que você gritou camarada, aqui só gritamos: MADEEEEEEEEEIIIIIIIRRRRRRRRAAAAAAAAA",
            options: [
                {
                    message: "Não estou entendendo, você não é o...",
                    type: "default",
                    goStep: 6,
                    function: ""
                }
            ]
        },
        {
            number: 5,
            message: "Não, não sou não, você deve estar louco, sou apenas um lenhador do proletário tentando ganhar a vida nesse mundo opres... MARAVILHOSO",
            options: [
                {
                    message: "??????... Bom, vamos continuar...",
                    type: "default",
                    goStep: 2,
                },
                {
                    message: "Depois de tudo isso, to fora",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 6,
            message: "Bom, primeiro você precisa da licença opresso... De lenhador, eu vendo ela, mas só porque eu amo o capitalismo",
            options: [
                {
                    message: "Ok, me mostre ai",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Ih, não curto muito esses negócios de capitalismo não, até",
                    type: "default",
                    goStep: 9
                }
            ]
        },
        {
            number: 9,
            message: "Então camarada, até mais, nos veremos logo",
            options: [
                {
                    message: "Tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        },
        {
            number: 10,
            message: "Acho melhor você ir embora, não to muito afim de continuar o assunto",
            options: [
                {
                    message: "Tudo bem então, tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        }
    ]
}

export default normalDialog;