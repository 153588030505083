export default {
    data() {
        return {
            key: 0
        }
    },
    props: ["skill"],
    methods: {
        addHotkey(item) {
            if(item.skillEnable) {
                item.hotkey = this.key;
                this.$store.commit("addHotkey", item);
                this.$forceUpdate();
            } else {
                item.hotkey = this.key;
                this.$store.commit("addHotkey", item);
                this.$store.commit("deleteItemInventory", {item: item, quantidade: item.qtd});
                this.$forceUpdate();
            }
        }
    }
}