import PathsComponent from "./paths/Paths.vue";

export default {
    computed: {
        skillPoints() {
            return this.$store.state.personModule.mainPerson.pointsAttr.skillPoints;
        },
        skillModule() {
            return this.$store.state.skillModule;
        }
    },
    methods: {
        closeView() {
            this.$store.commit("changeSkillModal", false);
        }
    },
    components: {
        PathsComponent
    },
    created() {
        if(this.$store.state.skillModule.skills.length < 1) this.$store.dispatch("getAndSetSkills");
    }
}