import axios from "axios";

import Store from "../store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const generalModule = storeAcess.generalModule.state;

export default {
    methods: {
        getNewPerson: () => {
            console.log("Acessou aqui");
        },
        postNewPerson: (char) => {
            axios.post(
                generalModule.apiUrl + "/newCharacter", JSON.parse(char)
            ).then((res) => {
                console.log("RES: ", res);
                location.reload();
                return res;
            }).catch(error => {
                console.log(error.message);
            });
        }
    }
}