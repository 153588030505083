const actions = {
    addTimeWork({commit, rootState}) {
        if(rootState.personModule.mainPerson.timer) {
            const timers = rootState.personModule.mainPerson.timer;
            if(timers.find(i => i.type === 'work')) {
                const timerWork = timers.find(i => i.type === 'work');
                let workTimes = {
                    initial: timerWork.initial,
                    end: timerWork.end
                };
                commit("addTimeInitialEnd", workTimes);
            }
            return;
        }
        let workTimes = {
            initial: rootState.generalModule.timeNow,
            end: rootState.generalModule.timeNow
        };
        commit("addTimeInitialEnd", workTimes);
    },
    calculeTimeWork({state, rootState}, seconds) {
        var initial = state.working.initial;
        var end = state.working.end;
        var date;
        let dateInitial, dateEnd;
        const timeUTCNow = rootState.generalModule.timeNow;
        if(Object.keys(initial).length < 1 && Object.keys(end).length < 1){
            date = String(`${timeUTCNow.year}-${timeUTCNow.month}-${timeUTCNow.day} ${timeUTCNow.hour}:${timeUTCNow.minute}:${timeUTCNow.second}`);
            dateInitial = new Date(date);
            dateEnd = new Date(date);
            dateEnd.setSeconds(dateEnd.getSeconds() + seconds);
            state.working.end = {
                day: dateEnd.getDay(),
                month: dateEnd.getMonth(),
                year: dateEnd.getFullYear(),
                hour: dateEnd.getHours(),
                minute: dateEnd.getMinutes(),
                second: dateEnd.getSeconds()
            }
            state.timeLeft = (dateEnd - dateInitial) / 1000;
            state.timeCurrent = (dateEnd - dateInitial) / 1000;
            return;
        }
        dateInitial = new Date(`${initial.year}-${initial.month}-${initial.day} ${initial.hour}:${initial.minute}:${initial.second}`);
        dateEnd = new Date(`${end.year}-${end.month}-${end.day} ${end.hour}:${end.minute}:${end.second}`);
        dateEnd.setSeconds(dateEnd.getSeconds() + seconds);
        state.working.end = {
            day: dateEnd.getDay(),
            month: dateEnd.getMonth(),
            year: dateEnd.getFullYear(),
            hour: dateEnd.getHours(),
            minute: dateEnd.getMinutes(),
            second: dateEnd.getSeconds()
        }
        state.timeLeft = (dateEnd - dateInitial) / 1000;
        state.timeCurrent = (dateEnd - dateInitial) / 1000;
    },
    initClock({state, rootState, commit}) {
        state.clock.hour = Math.floor(state.timeLeft/3600);
        state.clock.minute = Math.floor((state.timeLeft - state.clock.hour * 3600) / 60);
        state.clock.second = Math.ceil(state.timeLeft - (state.clock.hour * 3600 + state.clock.minute * 60));
        var secondClock = setInterval(() => {
            if(state.clock.hour > 0) {
                if(state.clock.minute === 60 || state.clock.minute === 0) {
                    state.clock.hour--;
                    state.clock.minute = 59;
                    state.clock.second = 59;
                    state.timeCurrent--;
                    return;
                }
            }
            if(state.clock.second === 0) {
                state.clock.minute--;
                state.clock.second = 59;
                state.timeCurrent--;
                return;
            }
            state.clock.second--;
            state.timeCurrent--;
        }, 1000)
        var timeOutClock = setTimeout(() => {
            clearInterval(secondClock);
            clearTimeout(timeOutClock);
            commit("restartTime");
            commit("changeActionText", "Fazendo nada");
            commit("changeOccupied", false);
        }, rootState.classWorkModule.timeLeft * 1000);
    },
    timeForCompletedActionWork({commit, dispatch}, time) {
        if(time > 30) {
            commit("refreshTime");
            dispatch("calculeTimeWork", time);
            dispatch("initClock");
            return;
        }
        dispatch("calculeTimeWork", time);
        dispatch("initClock");
    }
}

export default actions;