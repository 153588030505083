import { setTimeout } from "core-js";

export default {
    state: {
        currentLocation: {},
        isExplorer: false,
        modalVoyage: false,
        mapNow: "Odenlor",
        locationsInfos: [
            {
                mapName: "Odenlor",
                minAcceptLvl: 1,
                laws: {
                    pvp: true,
                },
                rights: {
                    hunt: {
                        licensed: true,
                        price: 0,
                        minLvl: 4,
                        nobility: 0,
                        reputation: 5
                    },
                    miner: {
                        licensed: true,
                        price: 500,
                        minLvl: 4,
                        nobility: 0,
                        reputation: 5
                    },
                    commerce: {
                        licensed: true,
                        price: 500,
                        minLvl: 2,
                        nobility: 0,
                        reputation: 10
                    },
                    woodcutting: {
                        licensed: true,
                        price: 0,
                        minLvl: 4,
                        nobility: 0,
                        reputation: 5
                    },
                    collector: {
                        licensed: true,
                        price: 0,
                        minLvl: 4,
                        nobility: 0,
                        reputation: 5
                    },
                    fisherman: {
                        licensed: true,
                        price: 0,
                        minLvl: 4,
                        nobility: 0,
                        reputation: 5
                    }
                },
                places: [
                    {
                        name: "Mjölnir",
                        group: "Village",
                        classname: 'village1',
                        background: 'villages/background_village1.jpg',
                        isEnabled: true,
                        security: {
                            current: 20,
                            max: 100
                        },
                        laws: {
                            minLvl: 1,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 220,
                            left: 210
                        },
                        jobs: [
                            "fishing"
                        ],
                        loots: [
                            {
                                work: null,
                                name: "Blueberrie",
                                porcent: 85,
                                qtd: [3, 6]
                            },
                            {
                                work: null,
                                name: "morango",
                                porcent: 55,
                                qtd: [5, 7]
                            },
                            {
                                work: "fishing",
                                name: "Pescado",
                                porcent: 75,
                                qtd: [1, 1]
                            },
                            {
                                work: "fishing",
                                name: "Peixe Luza",
                                porcent: 20,
                                qtd: [1, 1]
                            },
                            {
                                work: "fishing",
                                name: "Peixe Lebarino",
                                porcent: 5,
                                qtd: [1, 1]
                            },
                            {
                                work: "fishing",
                                name: "Peixe Obsidiana",
                                porcent: 0.1,
                                qtd: [1, 1]
                            }
                        ]
                    },
                    {
                        name: "Eisendorf",
                        group: "Village",
                        classname: 'village2',
                        background: 'florest/Forest1.png',
                        isEnabled: true,
                        security: {
                            current: 40,
                            max: 100
                        },
                        laws: {
                            minLvl: 1,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 290,
                            left: 250
                        },
                        jobs: [],
                        loots: [
                            {
                                work: null,
                                name: "Blueberrie",
                                porcent: 95,
                                qtd: [1, 1]
                            },
                            {
                                work: null,
                                name: "morango",
                                porcent: 60,
                                qtd: [1, 1]
                            }
                        ]
                    },
                    {
                        name: "Avalon",
                        group: "Cities",
                        background: 'mountains/mountains4.png',
                        isEnabled: false,
                        classname: 'city1',
                        cityLvl: 2,
                        security: {
                            current: 75,
                            max: 100
                        },
                        laws: {
                            minLvl: 15,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        temple: {
                            name: 'Templo de Yanon ',
                            imgSkill: 'raios.png',
                            skill: 'Fúria de Yanon',
                            skillDamage: 100,
                            mpUse: 180,
                            descSkill: '500 de dano ao inimigo',
                            mainGod: 'Yanon',
                            description: 'Nesse templo você encontrará a iluminação, o saber e a força, pois Yanon é nosso tempo e vida, o criador de nossa existência e de todos os Titãs hoje conhecidos como Germinianos.'
                        },
                        properties: {
                            roomTavern: {
                                name: "Quarto na Pousada Musas do Olimpo",
                                priceBuy: 1500,
                                priceRent: 50,
                                space: 15
                            }
                        },
                        coordinates: {
                            top: 490,
                            left: 495
                        },
                        jobs: []
                    },
                    {
                        name: "Azalarium",
                        group: "Cities",
                        background: 'mountains/mountains3.png',
                        isEnabled: false,
                        classname: 'city2',
                        security: {
                            current: 85,
                            max: 100
                        },
                        cityLvl: 3,
                        laws: {
                            minLvl: 5,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 550,
                            left: 130
                        },
                        jobs: []
                    },
                    {
                        name: "Cormiles",
                        group: "Cities",
                        background: 'mountains/mountains2.png',
                        isEnabled: true,
                        classname: 'city3',
                        security: {
                            current: 95,
                            max: 100
                        },
                        cityLvl: 1,
                        laws: {
                            minLvl: 4,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        temple: {
                            name: 'Filhos de Córmiles',
                            skill: {
                                imgSkill: 'martelodosdeuses.png',
                                name: 'Martelo dos deuses',
                                damage: 300,
                                mpUse: 150,
                                descSkill: '300 de dano ao inimigo'
                            },
                            mainGod: 'Rorth',
                            description: 'Como um bom filho de Córmiles, somos homens e mulheres de sangue guerreiro, vindos das terras mais distantes desse gigante universo, guiados por nosso maior guerreiro, Rorth, o Deus das Tempestades, criamos assentamentos em diversos planetas, mostrando aos povos conquistados ou conhecidos o poder de nossas táticas de batalha e armas avançadas de guerra.'
                        },
                        properties: {
                            roomTavern: {
                                name: "Quarto na Pousada Martelo dos Deuses",
                                priceBuy: 500,
                                priceRent: 10,
                                space: 10
                            }
                        },
                        coordinates: {
                            top: 300,
                            left: 310
                        },
                        jobs: []
                    },
                    {
                        name: "Pântano",
                        group: "Ruins",
                        classname: 'ruins2',
                        background: 'swamp/Background.png',
                        isEnabled: true,
                        security: {
                            current: 5,
                            max: 100
                        },
                        laws: {
                            minLvl: 5,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 280,
                            left: 415
                        },
                        monsters: [
                            "lizardWarrior",
                            "mandrake",
                            "grandLizard"
                        ],
                        treasures: {
                            gold: 200,
                            exp: 500
                        },
                        jobs: [
                            "collector"
                        ],
                        loots: [
                            {
                                work: "collector",
                                name: "pinha do pantano",
                                porcent: 40,
                                qtd: [1, 2]
                            }
                        ]
                    },
                    {
                        name: "Ruinas Skull",
                        group: "Ruins",
                        classname: 'ruins1',
                        background: 'ruins/Battleground1.png',
                        isEnabled: true,
                        security: {
                            current: 0,
                            max: 100
                        },
                        waves: [3, 8],
                        laws: {
                            minLvl: 3,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 380,
                            left: 270
                        },
                        monsters: [
                            "skeleton",
                            "skeletonShield",
                            "goldSkeleton"
                        ],
                        treasures: {
                            gold: 30,
                            exp: 100,
                            loot: [
                                {
                                    name: "Pergaminho dos Iniciantes no Fogo Eterno",
                                    porcent: 5,
                                    qtd: [1, 1]
                                }
                            ]
                        },
                        jobs: [],
                        loots: [
                            {
                                work: null,
                                name: "Blueberrie",
                                porcent: 80,
                                qtd: [8, 10]
                            },
                            {
                                work: null,
                                name: "morango",
                                porcent: 40,
                                qtd: [9, 15]
                            }
                        ]
                    },
                    {
                        name: "Minas Luthios",
                        group: "Mines",
                        background: 'mountains/mountains4.png',
                        isEnabled: true,
                        classname: 'cavern1',
                        security: {
                            current: 35,
                            max: 100
                        },
                        laws: {
                            minLvl: 2,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 180,
                            left: 390
                        },
                        jobs: [
                            "miner"
                        ],
                        monsters: [
                            "bear",
                            "spider"
                        ],
                        loots: [
                            {
                                work: "miner",
                                name: "ferro bruto",
                                porcent: 45,
                                qtd: [1, 2]
                            },
                            {
                                work: "miner",
                                name: "cobre bruto",
                                porcent: 60,
                                qtd: [1, 2]
                            },
                            {
                                work: "miner",
                                name: "estanho bruto",
                                porcent: 20,
                                qtd: [1, 2]
                            }
                        ]
                    },
                    {
                        name: "Minas Grethia",
                        group: "Mines",
                        classname: 'cavern2',
                        isEnabled: false,
                        security: {
                            current: 45,
                            max: 100
                        },
                        laws: {
                            minLvl: 10,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 52,
                            left: 80
                        },
                        jobs: [
                            "miner"
                        ],
                        loots: [
                            {
                                work: "miner",
                                name: "minério de ouro",
                                porcent: 3,
                                qtd: [1, 2]
                            },
                            {
                                work: "miner",
                                name: "topázio bruto",
                                porcent: 0.3,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "rubi bruto",
                                porcent: 0.1,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "esmeralda bruta",
                                porcent: 0.2,
                                qtd: [1, 1]
                            }
                        ]
                    },
                    {
                        name: "Daimana",
                        group: "Mines",
                        classname: 'cavern3',
                        isEnabled: false,
                        security: {
                            current: 30,
                            max: 100
                        },
                        laws: {
                            minLvl: 14,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 100,
                            left: 665
                        },
                        jobs: [
                            "miner"
                        ],
                        loots: [
                            {
                                work: "miner",
                                name: "topázio bruto",
                                porcent: 0.4,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "rubi bruto",
                                porcent: 0.15,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "esmeralda bruta",
                                porcent: 0.3,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "diamante",
                                porcent: 0.03,
                                qtd: [1, 1]
                            }
                        ]
                    },
                    {
                        name: "Caverna Del Andes",
                        group: "Mines",
                        classname: 'cavern4',
                        isEnabled: false,
                        security: {
                            current: 10,
                            max: 100
                        },
                        laws: {
                            minLvl: 8,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 360,
                            left: 500
                        },
                        jobs: [
                            "miner"
                        ],
                        loots: [
                            {
                                work: "miner",
                                name: "minério de prata",
                                porcent: 8,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "topázio bruto",
                                porcent: 0.2,
                                qtd: [1, 1]
                            },
                            {
                                work: "miner",
                                name: "esmeralda bruta",
                                porcent: 0.05,
                                qtd: [1, 1]
                            }
                        ]
                    },
                    {
                        name: "Holzfluss",
                        group: "Florest",
                        classname: 'florest1',
                        background: 'florest/Forest1.png',
                        isEnabled: true,
                        security: {
                            current: 20,
                            max: 100
                        },
                        waves: [3, 5],
                        laws: {
                            minLvl: 1,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 240,
                            left: 260
                        },
                        jobs: [
                            "woodcutting",
                            "collector",
                            "hunt"
                        ],
                        loots: [
                            {
                                work: null,
                                name: "Blueberrie",
                                porcent: 90,
                                qtd: [3, 8]
                            },
                            {
                                work: null,
                                name: "morango",
                                porcent: 60,
                                qtd: [4, 10]
                            },
                            {
                                work: "woodcutting",
                                name: "tronco",
                                porcent: 100,
                                qtd: [1, 2]
                            },
                            {
                                work: "collector",
                                name: "semente de feijão",
                                porcent: 40,
                                qtd: [1, 2]
                            },
                            {
                                work: "hunt",
                                name: "carne de cervo",
                                porcent: 50,
                                qtd: [1, 2]
                            },
                            {
                                work: "hunt",
                                name: "carne de javali",
                                porcent: 75,
                                qtd: [1, 2]
                            },
                            {
                                work: "hunt",
                                name: "couro cru",
                                porcent: 60,
                                qtd: [1, 2]
                            },
                            {
                                work: "hunt",
                                name: "chifre de cervo",
                                porcent: 25,
                                qtd: [1, 2]
                            },
                        ],
                        monsters: [
                            "slime",
                            "toad",
                            "boar",
                            "wolf",
                            "wasp"
                        ],
                        treasures: {
                            gold: 10,
                            exp: 30
                        }
                    },
                    {
                        name: "Acampamento Goblin",
                        group: "Camp",
                        classname: 'camp1',
                        background: 'florest/Battleground3.png',
                        isEnabled: true,
                        security: {
                            current: 0,
                            max: 100
                        },
                        waves: [7, 18],
                        laws: {
                            minLvl: 5,
                            commerce: true,
                            pvp: true,
                            toLive: true
                        },
                        coordinates: {
                            top: 345,
                            left: 365
                        },
                        monsters: [
                            "goblin",
                            "goblin ranged",
                            "troll"
                        ],
                        treasures: {
                            gold: 0,
                            exp: 1000
                        },
                        jobs: []
                    },
                    {
                        name: "Acampamento dos Bandidos",
                        group: "Camp",
                        classname: 'camp2',
                        background: 'florest/Forest3.png',
                        isEnabled: true,
                        security: {
                            current: 0,
                            max: 100
                        },
                        waves: 10,
                        laws: {
                            minLvl: 8,
                            commerce: false,
                            pvp: true,
                            toLive: true
                        },
                        monsters: [
                            "fanatic",
                            "moonluckFollower",
                            "berserkerMoonlucky"
                        ],
                        coordinates: {
                            top: 200,
                            left: 325
                        },
                        treasures: {
                            gold: 0,
                            exp: 25000
                        },
                        jobs: []
                    },
                    {
                        name: "Pirâmides",
                        group: "Especial",
                        classname: 'monument1',
                        background: 'mountains/mountains3.png',
                        isEnabled: true,
                        security: {
                            current: 5,
                            max: 100
                        },
                        waves: [10, 25],
                        laws: {
                            minLvl: 9,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 405,
                            left: 110
                        },
                        monsters: [
                            "mummy",
                            "priestMummy"
                        ],
                        floor: [
                            {
                                number: 1,
                                monsters: [0,1]
                            },
                            {
                                number: 3,
                                monsters: [0,1,2]
                            },
                            {
                                number: 7,
                                monsters: [1,2]
                            },
                            {
                                number: 15,
                                monsters: [2]
                            }
                        ],
                        treasures: {
                            gold: 1500,
                            exp: 20000
                        },
                        boss: "Anúbis, O primeiro filho da morte",
                        jobs: []
                    },
                    {
                        name: "Unknown",
                        group: "Ruins",
                        classname: 'ruins3',
                        background: 'swamp/Background.png',
                        isEnabled: false,
                        security: {
                            current: 5,
                            max: 100
                        },
                        laws: {
                            minLvl: 7,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        monsters: [],
                        coordinates: {
                            top: 390,
                            left: 440
                        },
                        jobs: [
                            "collector"
                        ],
                        loots: []
                    },
                    // Pontos secreto
                    {
                        name: "Ponto Secreto",
                        group: "SecretPoint",
                        classname: 'secretPoint2',
                        background: 'mountains/mountains3.png',
                        isEnabled: false,
                        security: {
                            current: 5,
                            max: 100
                        },
                        laws: {
                            minLvl: 1,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 265,
                            left: 360
                        },
                        jobs: []
                    },
                    {
                        name: "Ponto Secreto",
                        group: "SecretPoint",
                        classname: 'secretPoint3',
                        background: 'mountains/mountains3.png',
                        isEnabled: false,
                        security: {
                            current: 5,
                            max: 100
                        },
                        laws: {
                            minLvl: 10,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 440,
                            left: 310
                        },
                        jobs: []
                    },
                    {
                        name: "Ponto Secreto",
                        group: "SecretPoint",
                        classname: 'secretPoint4',
                        background: 'mountains/mountains3.png',
                        isEnabled: false,
                        security: {
                            current: 5,
                            max: 100
                        },
                        laws: {
                            minLvl: 10,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 480,
                            left: 170
                        },
                        jobs: []
                    },
                    {
                        name: "Ponto Secreto",
                        group: "SecretPoint",
                        classname: 'secretPoint5',
                        background: 'mountains/mountains3.png',
                        isEnabled: false,
                        security: {
                            current: 5,
                            max: 100
                        },
                        laws: {
                            minLvl: 10,
                            commerce: true,
                            pvp: false,
                            toLive: true
                        },
                        coordinates: {
                            top: 150,
                            left: 220
                        },
                        jobs: []
                    }
                ]
            }
        ]
    },
    mutations: {
        changeShowModalVoyage(state, boolean) {
            state.modalVoyage = boolean;
        },
        changeIsExplorer(state, boolean) {
            state.isExplorer = boolean;
        },
        changeCurrentLocation(state, locationName) {
            state.mapNow = locationName.mapNow;
            state.currentLocation = state.locationsInfos.find(i => i.mapName == state.mapNow).places.find(i => i.name === locationName.place);
        }
    },
    actions: {
        markerVoyage({state},place) {
            var indexLocationMap = state.locationsInfos.find(map => {return map.mapName === state.mapNow});
            var locationFind = indexLocationMap.places.find(obj => {return obj.name === place});
            var previous = state.currentLocation.coordinates;
            document.querySelector(".pointPerson").animate([
                {left: previous.left + "px", top: previous.top + "px"},
                {left: locationFind.coordinates.left + "px", top: locationFind.coordinates.top + "px"}
              ], { 
                duration: 60000
              });
        },
        changeLocationNowAction({state, commit, rootState, dispatch}, place){
            const indexLocationMap = state.locationsInfos.find(map => map.mapName === state.mapNow);
            const locationFind = indexLocationMap.places.find(obj => obj.name === place);
            if(locationFind.laws.minLvl > rootState.personModule.mainPerson.lvl) {
                alert("Level necessário: " + locationFind.laws.minLvl);
                return;
            } else {
                const distance = function() {
                    const previous = state.currentLocation.coordinates;
                    let sumTop = locationFind.coordinates.top - previous.top < 0 ? (locationFind.coordinates.top - previous.top) * -1 : locationFind.coordinates.top - previous.top;
                    let sumLeft = locationFind.coordinates.left - previous.left < 0 ? (locationFind.coordinates.left - previous.left) * -1 : locationFind.coordinates.left - previous.left;
                    return sumTop + sumLeft;
                }
                const costEnergy = distance() - ((distance()) * (rootState.personModule.mainPerson.travelTime === null ? 0 : rootState.personModule.mainPerson.travelTime));
                if(rootState.personModule.mainPerson.infos[0].li[2].value >= costEnergy / 2) {
                    dispatch("markerVoyage", place);
                    document.querySelector(".pointPerson").style.display = "block";
                    dispatch("possibleEvents");
                    var setTimeoutMap = setTimeout(() => {
                        rootState.personModule.mainPerson.infos[0].li[2].value -= costEnergy / 2;
                        commit("changeLocationNow", locationFind.name);
                        commit("changeCurrentLocation", {mapNow: state.mapNow, place: locationFind.name});
                        document.querySelector(".pointPerson").style.display = "none";
                        clearTimeout(setTimeoutMap);
                    }, 60000);
                } else {
                    alert("Você não tem energia suficiente para esse trajeto!");
                }
            }
        },
        changeLocationNowActionMoney({state, commit, rootState}, place){
            const indexLocationMap = state.locationsInfos.find(map => map.mapName === state.mapNow);
            const locationFind = indexLocationMap.places.find(obj => obj.name === place);
            if(locationFind.laws.minLvl > rootState.personModule.mainPerson.lvl) {
                alert("Level necessário: " + locationFind.laws.minLvl);
                return;
            } else {
                commit("changeLocationNow", locationFind.name);
                commit("changeCurrentLocation", {mapNow: state.mapNow, place: locationFind.name});
            }
        }
    }
}