import CraftComponent from "../../../shared/components/craft/Craft.vue";

export default {
    components: {
        CraftComponent
    },
    data() {
        return {
            url: '../../../assets/img/icons/building/',
            typeRoom: 0
        }
    },
    computed: {
        property() {
            return this.$store.state.personModule.mainPerson.propertiesAcquired.find(i => i.location == this.$store.state.mapModule.currentLocation.name && i.type == 2);
        },
        propertiesAcquired() {
            return this.$store.state.personModule.mainPerson.propertiesAcquired;
        },
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        craftModal() {
            return this.$store.state.craftModule.craftModal;
        }
    },
    methods: {
        rest() {
            const energyData = {
                sinal: "soma",
                value: this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue
            };
            this.$store.commit("calculeEnergyCurrent", energyData);
            this.$store.commit("changeMagicPoints", this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue);
            this.$store.commit("calculeHPCurrent", {sinal: "soma", value: (this.$store.state.personModule.mainPerson.infos[0].li[0].maxValue)});
        },
        viewAlchemist() {
            this.$store.commit("changeTypeCraft", 3);
            this.$store.commit("viewModalCraft", true);
        },
        viewRefine() {
            this.$store.commit("changeTypeCraft", 4);
            this.$store.commit("viewModalCraft", true);
        },
        viewWeapons() {
            this.$store.commit("changeTypeCraft", 1);
            this.$store.commit("viewModalCraft", true);
        },
        viewArmor() {
            this.$store.commit("changeTypeCraft", 5);
            this.$store.commit("viewModalCraft", true);
        },
        viewJeweler() {
            this.$store.commit("changeTypeCraft", 6);
            this.$store.commit("viewModalCraft", true);
        },
        viewToolMaker() {
            this.$store.commit("changeTypeCraft", 7);
            this.$store.commit("viewModalCraft", true);
        }
    }
}