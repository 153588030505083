import Phaser from "phaser";
import Scene1 from "./scene";
import Scene2 from "./scene2";

let game;

export const ruinasSkull = () => {
    var config = {
        type: Phaser.WEBGL,
        width: 1200,
        height: 800,
        canvas: document.querySelector("#mainCanva"),
        pixelArt: true,
        backgroundColor: '#000000',
        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 900 },
                debug: false
            }
        },
        scene: [Scene1, Scene2]
    };

    game = new Phaser.Game(config);

    game;
}

export function ruinasSkulldestroyGame() {
    game.destroy(true);
}