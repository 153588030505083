import { mapGetters } from "vuex"

export default {
    data() {
        return {
        }
    },
    computed: {
        dataNewPerson() {
            return this.$store.state.generalModule.createPersonInfos;
        },
        statusPerson() {
            return this.$store.state.personModule.mainPerson.infos;
        },
        infosPerson() {
            return this.$store.state.personModule.mainPerson;
        },
        ...mapGetters(['translateWords'])
    },
    mounted() {
    }
}