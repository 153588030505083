const normalDialog = {
    steps: [
        {
            number: 1,
            message: "Que dia lindo que está hoje, mas pode ficar melhor com esse garanhão aqui",
            options: [
                {
                    message: "Cavalo bonito esse, me interessei bastante",
                    type: "default",
                    goStep: 2
                },
                {
                    message: "Não sei, não conheço muito sobre cavalos",
                    type: "default",
                    goStep: 3
                },
                {
                    message: "Ué, você não deveria estar na India?",
                    type: "default",
                    goStep: 4
                },
                {
                    message: "Nada não, valeu",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 2,
            message: "Então venha fechar, tenho certeza que não vai se arrepender",
            options: [
                {
                    message: "Me mostre o que tem",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Vendo mais de perto, acho que não gostei não, obrigado",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 3,
            message: "Não tem problema, eu posso te apresentar os melhores cavalos da região e mostrar o que cada um deles tem de melhor",
            options: [
                {
                    message: "Bom, olhar não mata, vamos ver o que você tem",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Oh, você não é aquele cara que ia para a India e errou o caminho?",
                    type: "default",
                    goStep: 4
                },
                {
                    message: "Não estou pronto ainda, vou esperar",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 4,
            message: "É sério, de novo isso? Eu já disse que não errei o caminho, foi tudo planejado",
            options: [
                {
                    message: "Sei sei, conta outra",
                    type: "default",
                    goStep: 5
                }
            ]
        },
        {
            number: 5,
            message: "Você não conhece os mapas da fonte da juventude?",
            options: [
                {
                    message: "Conheço, mas ela não existe",
                    type: "default",
                    goStep: 6
                }
            ]
        },
        {
            number: 6,
            message: "Como você é tolo, ela existe sim, e eu estive lá, e graças a ela, nossa ordem vive forte e sádia, dominante pontos que você nunca conheceu, pois na verdade, eles não são 'REAIS'",
            options: [
                {
                    message: "Como assim?",
                    type: "default",
                    goStep: 7
                }
            ]
        },
        {
            number: 7,
            message: "Não posso contar muito mais, se quiser, vai ter de descobrir por si só",
            options: [
                {
                    message: "Como?",
                    type: "default",
                    goStep: 8
                }
            ]
        },
        {
            number: 8,
            message: "Quando o sistema de quests for finalizado, eu te conto",
            options: [
                {
                    message: "Merda, vou ter de esperar",
                    type: "default",
                    goStep: 9
                }
            ]
        },
        {
            number: 9,
            message: "Vamos recomeçar...",
            options: [
                {
                    message: "Ok",
                    type: "default",
                    goStep: 1
                }
            ]
        },
        {
            number: 10,
            message: "Bom, então nos vemos na próxima",
            options: [
                {
                    message: "Tudo bem, tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        }
    ]
}

export default normalDialog;