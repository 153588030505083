import { mapGetters } from "vuex"

export default {
    data() {
        return {
            step: 1
        }
    },
    // props: ['dialogs'],
    computed: {
        dialogs() {
            return this.$store.state.generalModule.dialog;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        changeStep(step) {
            if(step) {
                this.step = step;
                this.writerMachineEffect();
            }
        },
        writerMachineEffect() {
            var i = 0;
            var tag = document.querySelector(".ballon" + this.step);
            var html = document.querySelector(".ballon" + this.step).innerHTML;
            var speed = 50;
        
            function typeWriter() {
                if (i <= html.length) {
                    tag.innerHTML = html.slice(0 , i + 1);
                    i++;
                    setTimeout(typeWriter, speed);
                }
            }
            typeWriter();
        },
        handle_function_call(function_name) {
            if(function_name) {
                this[function_name]()
            }
        },
        close() {
            this.$store.commit("changeDialogBox", false);
            this.$store.commit("changeShop", "");
        },
        openShop() {
            this.$store.commit("changeDialogBox", false);
            this.$store.commit("changeOpenWindow", true);
        },
        registerInGuild() {
            if(this.$store.state.personModule.mainPerson.adventureGuild.level.letter === null) {
                this.$store.commit("enterAdventureGuild");
            }
        }
    },
    mounted() {
        var i = 0;
        var tag = document.querySelector(".ballon" + this.step);
        var html = document.querySelector(".ballon" + this.step).innerHTML;
        var speed = 50;
    
        function typeWriter() {
            if (i <= html.length) {
                tag.innerHTML = html.slice(0 , i + 1);
                i++;
                setTimeout(typeWriter, speed);
            }
        }
        typeWriter();
    }
}