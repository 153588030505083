import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;

export default function archer(vm) {
    vm.load.on('progress', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, true);
    });

    vm.load.on('complete', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, false);
    });

    // Sons
    vm.load.audio('attack', '/resourcesInGame/sons/actions/arrow/Arrow5.wav'); 
    vm.load.audio('hit', '/resourcesInGame/sons/actions/hit/Insect_Crush-5.wav');
    vm.load.audio('monsterDead', '/resourcesInGame/sons/monsters/dead/Evil_Whoosh-4.wav');
    vm.load.audio('usePotion', '/resourcesInGame/sons/actions/use/potion/Alchemy-9.wav');
    vm.load.audio('openPotion', '/resourcesInGame/sons/actions/use/potion/Bottle_Open-1_1.wav');
    vm.load.audio('walk', '/resourcesInGame/sons/actions/walk/ESM_Fantasy_Game_Footstep_Grass_Light_H.wav');
    vm.load.audio('roll', '/resourcesInGame/sons/actions/roll/ESM_Fantasy_Game_Footstep_Grass_Heavy_D.wav');
    vm.load.audio('portal', '/resourcesInGame/sons/actions/portal/ESM_Fantasy_Game_Magic_Airy_Sting_Accent_Medieval__Spell_Cast.wav');

    vm.load.image('arrow', '/resourcesInGame/sprites/projetil/arrow_1.png');
    vm.load.image('fireArrow', '/resourcesInGame/sprites/projetil/fire-arrow.png');
    vm.load.spritesheet('archerIdle', '/resourcesInGame/sprites/main/archer/archer-idle.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerWalk', '/resourcesInGame/sprites/main/archer/archer-walk.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerJump', '/resourcesInGame/sprites/main/archer/archer-jump.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerDead', '/resourcesInGame/sprites/main/archer/archer-dead.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerAttack1', '/resourcesInGame/sprites/main/archer/archer-attack1.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerAttack2', '/resourcesInGame/sprites/main/archer/archer-attack2.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerAttack3', '/resourcesInGame/sprites/main/archer/archer-attack3.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerRoll', '/resourcesInGame/sprites/main/archer/archer-roll.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('archerRun', '/resourcesInGame/sprites/main/archer/archer-run.png', { frameWidth: 50, frameHeight: 48 });
}