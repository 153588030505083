import photosMock from "./mocks/imgPersonsMock.js"

export default {
    data() {
        return {
            race: 0,
            info: {},
            photosRaces: photosMock
        }
    },
    computed: {
        raceSelect() {
            return this.$store.state.personModule.mainPerson.race;
        }
    },
    methods: {
        raceOption() {
            switch(parseInt(this.race)) {
                case 1:
                    this.info = {
                        id: 11,
                        message: "Uma vez humano, sempre humano (+1 Carisma, +20 Estamina, +20 Magia, +20 HP)",
                        status: {
                            maxEnergy: 20,
                            maxMp: 20,
                            maxHp: 20,
                            Carisma: 1
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 2:
                    this.info = {
                        id: 11,
                        message: "O povo mais antigo do universo, diria que a maioria das especies inteligentes do universo vieram deles (+25 Magia, +2 Força, +2 Critico, +15 HP e +15 Estamina)",
                        status: {
                            maxMp: 25,
                            maxEnergy: 15,
                            maxHp: 15,
                            Forca: 2,
                            Critico: 2
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 3:
                    this.info = {
                        id: 11,
                        message: "Um dos povos mais poderosos do Universo, a maior defesa da Triade Nisiriana (+4 Força, +1 Defesa, +35 HP e +25 Estamina)",
                        status: {
                            Forca: 4,
                            Defesa: 1,
                            maxHp: 35,
                            maxEnergy: 25
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 4:
                    this.info = {
                        id: 11,
                        message: "Venha em busca da dominação total do universo começando com as raças inferiores (+2 Negociação, +60 Magia e +5 Poder Mágico)",
                        status: {
                            maxMp: 60,
                            Negociacao: 2
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 5:
                    this.info = {
                        id: 11,
                        message: "A adaptação e inteligencia são as maiores fontes de poder que uma Triade poderia ter, o povo do computador de Orion (+3 Defesa, +2 Carisma, +25 Estamina, +1 Negociação e -5% de TimeTravel)",
                        status: {
                            Defesa: 3,
                            Carisma: 2,
                            maxEnergy: 25,
                            Negociacao: 1,
                            travelTime: 0.05,
                            PoderMagico: 5
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 6:
                    this.info = {
                        id: 11,
                        message: "Uma das raças mais antigas a explorar a magia e suas utilizações (+90 Magia e +25 Poder Mágico)",
                        status: {
                            maxMp: 90,
                            PoderMagico: 25
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 7:
                    this.info = {
                        id: 11,
                        message: "Ligeiros, os Ignis são perfeitos como assassinos e mercenários (+5 Critico, +20 Estamina e +1 Força)",
                        status: {
                            Critico: 6,
                            maxEnergy: 25,
                            Forca: 1
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
                case 8:
                    this.info = {
                        id: 11,
                        message: "Uriun Supremo é um explorador nato, usado em colonização e terraformação de planetas, ele geralmente é o ser lógico das operações (+30 Magia, +20 Estamina, +1 Força, +1 Defesa, +10 HP, +1 Negociação e -5% TimeTravel)",
                        status: {
                            maxHp: 10,
                            maxMp: 10,
                            maxEnergy: 20,
                            Forca: 1,
                            Defesa: 1,
                            Negociação: 1,
                            travelTime: 0.05
                        }
                    }
                    this.$store.dispatch("changeCreatePerson", this.info);
                    this.$store.commit("addInfos", this.info);
                    break;
            }
        },
        selectPhoto(race) {
            this.$store.commit("changeRace", race);
        }
    }
}