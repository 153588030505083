import {goblinCampMap, goblinDestroyGame} from "@/shared/camps/goblin/campAreaPhaser";
import {banditCampMap, banditDestroyGame} from "@/shared/camps/bandit/campAreaPhaser";

export default {
    data() {
        return {}
    },
    computed: {
        currentCity() {
            return this.$store.state.mapModule.currentLocation;
        },
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        shop() {
            return this.$store.state.itemsModule.shop;
        },
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        waveInfos() {
            return this.$store.state.monstersModule.wave;
        },
        hotkeys() {
            return this.$store.state.personModule.mainPerson.hotkeys;
        }
    },
    methods: {},
    mounted() {
        switch(this.$store.state.mapModule.currentLocation.name) {
            case "Acampamento Goblin":
                goblinCampMap(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
            case "Acampamento dos Bandidos":
                banditCampMap(this.$store.state.mapModule.currentLocation, this.$store.state.personModule.mainPerson, this.$store.state.monstersModule.types); break;
        }
    },
    destroyed() {
        this.$store.commit("changeShop", "");
        this.$store.commit("changeActiveShop", false);
        this.$store.commit("changeDialogBox", false);
        switch(this.$store.state.mapModule.currentLocation.name) {
            case "Acampamento Goblin":
                goblinDestroyGame(); break;
            case "Acampamento dos Bandidos":
                banditDestroyGame(); break;
        }
    }
}