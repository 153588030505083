import Phaser from "phaser";

import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods"
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateActions } from "@/shared/components-phaser/updateActions" 

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene1 extends Phaser.Scene {
    constructor(){
        super("external1");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.player;
        this.mapSize = {
            x: 3264,
            y: 1800
        };
        this.layer = {};
        this.map;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }

    preload ()
    {
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/swamp/SFX_-_insects_night_LOOP.ogg');
        this.load.audio('music', '/resourcesInGame/sons/musics/swamp/Battle_-_Dancing_In_Flames.ogg');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/desert/unknown/SET2_Mainlev_build.png');
        this.load.image('floor2', '/resourcesInGame/title-set/tiles/desert/unknown/MainLevBuild.png');
        this.load.image('objects1', '/resourcesInGame/title-set/tiles/desert/unknown/Decoratives.png');
        this.load.image('objects2', '/resourcesInGame/title-set/tiles/desert/unknown/SET2_decorative_obj.png');
        this.load.tilemapTiledJSON("mapUnknown1", "/resourcesInGame/maps/ruins/unknown/scene1-unknown.json");

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/ruins/1.png");
        this.load.image("mountains", "/resourcesInGame/title-set/parallax/mountains/ruins/Background41.png");
        this.load.image("mountains2", "/resourcesInGame/title-set/parallax/mountains/ruins/Background51.png");
        this.load.image("mountainsFront", "/resourcesInGame/title-set/parallax/mountains/ruins/Background31.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/ruins/3.png");
        this.load.image("clouds2", "/resourcesInGame/title-set/parallax/cloud/ruins/4.png");

        //enemies        
        this.load.spritesheet('lizardWarriorIdle', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-idle.png', {frameWidth: 68, frameHeight: 35});
        this.load.spritesheet('lizardWarriorWalk', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-walk.png', {frameWidth: 68, frameHeight: 35});
        this.load.spritesheet('lizardWarriorAttack', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-attack.png', {frameWidth: 68, frameHeight: 35});
        this.load.spritesheet('lizardWarriorDeath', '/resourcesInGame/sprites/swamp/lizardWarrior/lizardWarrior-dead.png', {frameWidth: 68, frameHeight: 35});
        
        this.load.spritesheet('mandrakeIdle', '/resourcesInGame/sprites/swamp/mandrake/mandrake-idle.png', {frameWidth: 57, frameHeight: 38});
        this.load.spritesheet('mandrakeWalk', '/resourcesInGame/sprites/swamp/mandrake/mandrake-walk.png', {frameWidth: 57, frameHeight: 38});
        this.load.spritesheet('mandrakeAttack', '/resourcesInGame/sprites/swamp/mandrake/mandrake-attack.png', {frameWidth: 57, frameHeight: 38});
        this.load.spritesheet('mandrakeDeath', '/resourcesInGame/sprites/swamp/mandrake/mandrake-dead.png', {frameWidth: 57, frameHeight: 38});
        
        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            // Enemy
            //lizard warrior
            vm.anims.create({
                key: "lizardWarriorIdle",
                frames: vm.anims.generateFrameNumbers("lizardWarriorIdle", {start: 0, end: 3}),
                frameRate: 7,
                repeat: -1
            }),
            vm.anims.create({
                key: "lizardWarriorWalk",
                frames: vm.anims.generateFrameNumbers("lizardWarriorWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "lizardWarriorAttack",
                frames: vm.anims.generateFrameNumbers("lizardWarriorAttack", {start: 0, end: 4}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "lizardWarriorDeath",
                frames: vm.anims.generateFrameNumbers("lizardWarriorDeath", {start: 0, end: 6}),
                frameRate: 10,
                repeat: 0
            })
            //mandrake
            vm.anims.create({
                key: "mandrakeIdle",
                frames: vm.anims.generateFrameNumbers("mandrakeIdle", {start: 0, end: 3}),
                frameRate: 7,
                repeat: -1
            }),
            vm.anims.create({
                key: "mandrakeWalk",
                frames: vm.anims.generateFrameNumbers("mandrakeWalk", {start: 0, end: 5}),
                frameRate: 8,
                repeat: -1
            }),
            vm.anims.create({
                key: "mandrakeAttack",
                frames: vm.anims.generateFrameNumbers("mandrakeAttack", {start: 0, end: 6}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "mandrakeDeath",
                frames: vm.anims.generateFrameNumbers("mandrakeDeath", {start: 0, end: 9}),
                frameRate: 10,
                repeat: 0
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.1);
            var clouds2 = vm.add.image(0, -250, 'clouds2').setOrigin(0).setScrollFactor(.1);
            
            // var trees = vm.add.image(0, -10, 'trees4').setOrigin(0).setScrollFactor(.15);
            // var trees2 = vm.add.image(0, -10, 'trees4').setOrigin(0).setScrollFactor(.15);

            var mountainsFront = vm.add.image(0, 500, 'mountainsFront').setOrigin(0).setScrollFactor(.75);
            var mountainsFront2 = vm.add.image(1920, 500, 'mountainsFront').setOrigin(0).setScrollFactor(.75);
            var mountainsFront3 = vm.add.image(3840, 500, 'mountainsFront').setOrigin(0).setScrollFactor(.75);

            var mountainsBack = vm.add.image(0, 600, 'mountains').setOrigin(0).setScrollFactor(.75);
            var mountainsBack2 = vm.add.image(1920, 600, 'mountains').setOrigin(0).setScrollFactor(.75);

            var mountains = vm.add.image(0, 500, 'mountains2').setOrigin(0).setScrollFactor(.66);
            var mountains2 = vm.add.image(1920, 500, 'mountains2').setOrigin(0).setScrollFactor(.66);

            // vm.background.displayWidth = vm.mapSize;
            clouds.displayHeight = vm.sys.game.config.height * 1.5;
            clouds2.displayHeight = vm.sys.game.config.height * 1.5;
            clouds.displayWidth = vm.sys.game.config.width * 1.5;
            clouds2.displayWidth = vm.sys.game.config.width * 1.5;
            
            // trees.displayHeight = vm.sys.game.config.height;
            // trees2.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            mountainsFront.displayHeight = vm.sys.game.config.height;
            mountainsFront2.displayHeight = vm.sys.game.config.height;
            mountainsFront3.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;
            vm.background.displayWidth = vm.sys.game.config.width;
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "nextScene":
                        vm.scene.restart(); vm.scene.start('internal1'); break;
                    case "tree":
                        vm.methods.workCommom(vm, vm.worked.item, vm.worked.time, vm.worked.message, vm.worked.tree); break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }
        
        // function monsterSpawn(vm) {
        //     vm.enemyArray = [];
        //     vm.methods.createEnemy(vm, "lizardWarrior", "lizardWarriorIdle", "lizardWarriorIdle", 15, 2.5, 3, {bodyX: 23, bodyY: 30}, {x: 34, y: 4}, 100);
        //     vm.methods.createEnemy(vm, "mandrake", "mandrakeIdle", "mandrakeIdle", 30, 2.5, 3, {bodyX: 20, bodyY: 32}, {x: 26, y: 5}, 100);
        // }
    
        function createScene(vm) {
            parallax(vm);

            vm.map = vm.make.tilemap({ key: "mapUnknown1", tileWidth: 16, tileHeight: 16});
            var floor1 = vm.map.addTilesetImage("SET2_Mainlev_build", "floor1");
            var floor2 = vm.map.addTilesetImage("MainLevBuild", "floor2");
            var objects1 = vm.map.addTilesetImage("Decoratives", "objects1");
            var objects2 = vm.map.addTilesetImage("SET2_decorative_obj", "objects2");
            vm.layer.background = vm.map.createLayer("background", [floor1, floor2], 0, 0).setScale(1.5);
            vm.layer.objects = vm.map.createLayer("objects", objects1, 0, 0).setScale(1.5);
            vm.layer.trees = vm.map.createLayer("trees", objects2, 0, 0).setScale(1.5);
            vm.layer.floor = vm.map.createLayer("floor", [floor1, floor2], 0, 0).setScale(1.5);
            vm.layer.rocks = vm.map.createLayer("rocks", [floor1, objects2], 0, 0).setScale(1.5);
            vm.layer.frontground = vm.map.createLayer("frontground", [floor2, objects1], 0, 0).setScale(1.5).setDepth(15);

            vm.layer.floor.setCollisionBetween(0, 50000);
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 380, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(8590, 450, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(initial, vm.player);

            vm.physics.add.overlap(end, vm.player, function() {
                vm.game.sound.stopAll();
                vm.scene.start('external2');
            });
        }

        this.sounds.ambient = this.sound.add('ambient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.ambient.play({loop: true, volume: 0.8});
        this.sounds.theme.play({loop: true, volume: 0.8});

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.animals = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        playerImport.createPlayer(this, 150, 0);
        createScene(this);
        createLimits(this);

        this.physics.add.collider(this.npcs, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);
        this.physics.add.collider(this.animals, this.layer.floor);
        this.physics.add.collider(this.physicObject, this.layer.floor);
        this.physics.add.collider(this.limits, this.layer.floor);
        this.physics.add.collider(this.monsterAttack, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        
        // monsterSpawn(this);
        
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        updateActions(this);
    }
}