import BuyItemsComponent from "@/pages/market/components/buyItems/BuyItems.vue";
import SellItemsComponent from "@/pages/market/components/sellItems/SellItems.vue";
import normalDialog from "./dialogs/normalDialog";

export default {
    data() {
        return {
            colapse: "buy",
            buyColapse: true
        }
    },
    computed: {
        buyItems() {
            return this.$store.state.itemsModule.items.filter(i => i.seller == "stable" && i.rarity == "normal");
        },
        sellItems() {
            return this.$store.state.personModule.mainPerson.Inventory.filter(i => i.seller == "stable");
        },
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        }
    },
    components: {
        BuyItemsComponent,
        SellItemsComponent
    },
    methods: {
        toogleBuy() {
            this.buyColapse = !this.buyColapse;
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        }
    },
    mounted() {
        console.log(1)
        this.$store.commit("changeDialogBox", true);
        this.$store.commit("changeNameDialog", "Tristão Colombo, O Vendedor de cavalos");
        this.$store.commit("addDialog", normalDialog);
    }
}