import MainSwordmanComponent from "./swordman/main/Main.vue";
import MainArcherComponent from "./archer/main/Main.vue";
import MainMageComponent from "./mage/main/Main.vue";
import MainSpearmanComponent from "./spearman/main/Main.vue";

export default {
    components: {
        MainSwordmanComponent,
        MainArcherComponent,
        MainMageComponent,
        MainSpearmanComponent
    },
    computed: {
        skillModule() {
            return this.$store.state.skillModule;
        }
    }
}