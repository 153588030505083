import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods" 
import npcImport from "@/shared/components-phaser/createNpc" 
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateActions } from "@/shared/components-phaser/updateActions" 

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene1 extends Phaser.Scene {
    constructor(){
        super("external1");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.platforms;
        this.mapSize = {
            x: 5800,
            y: 800
        };
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
        this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        this.load.audio('florestAmbient', '/resourcesInGame/sons/ambient/Forest_1_Loop.ogg');
        this.load.audio('music', '/resourcesInGame/sons/musics/Field_-_Adventurers_Road.ogg');

        // Tiles
        this.load.image("bloco1", "/resourcesInGame/title-set/tiles/florest/Tile_02.png");

        //cave background
        this.load.image("backgroundBlockCave1", "/resourcesInGame/title-set/tiles/mine/Tile_12.png");
        this.load.image("backgroundBlockCave2", "/resourcesInGame/title-set/tiles/mine/tile5.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/florest/bg.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/ground/florest/grass.png");
        this.load.image("trees", "/resourcesInGame/title-set/parallax/trees/florest/trees2.png");
        this.load.image("trees2", "/resourcesInGame/title-set/parallax/trees/florest/trees2.png");
        this.load.image("trees4", "/resourcesInGame/title-set/parallax/trees/florest/trees3.png");
        this.load.image("trees3", "/resourcesInGame/title-set/parallax/trees/florest/bushes.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/clouds.png");

        //Constructions
        this.load.image("house", "/resourcesInGame/title-set/constructions/home/1.png");
        this.load.image("mine", "/resourcesInGame/title-set/objects/mine/1.png");

        //Objects
        this.load.image("lamp", "/resourcesInGame/title-set/objects/lamp/lamp2.png");
        this.load.image("box", "/resourcesInGame/title-set/objects/boxes/5.png");
        this.load.image("box2", "/resourcesInGame/title-set/objects/boxes/6.png");
        this.load.image("well", "/resourcesInGame/title-set/objects/others/well.png");

        //Trees
        this.load.image("tree1", "/resourcesInGame/title-set/objects/tree/pack1/96x128Tree_5.png");
        this.load.image("tree2", "/resourcesInGame/title-set/objects/tree/pack1/160x160Trees_3.png");
        this.load.image("tree3", "/resourcesInGame/title-set/objects/tree/pack1/160x160Trees_4.png");
        this.load.image("tree4", "/resourcesInGame/title-set/objects/tree/pack1/96x128Tree_8.png");
        this.load.image("tree5", "/resourcesInGame/title-set/objects/tree/pack1/96x128Tree_4.png");
        this.load.image("tree6", "/resourcesInGame/title-set/objects/tree/pack1/96x128Tree_6.png");
        
        //enemies
        this.load.spritesheet('slimeIdle', '/resourcesInGame/sprites/florest/slime/Slime_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('slimeWalk', '/resourcesInGame/sprites/florest/slime/Slime_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('slimeAttack', '/resourcesInGame/sprites/florest/slime/Slime_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('slimeDeath', '/resourcesInGame/sprites/florest/slime/Slime_death.png', {frameWidth: 48, frameHeight: 48});
        
        this.load.spritesheet('toadIdle', '/resourcesInGame/sprites/florest/toad/Toad_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('toadWalk', '/resourcesInGame/sprites/florest/toad/Toad_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('toadAttack', '/resourcesInGame/sprites/florest/toad/Toad_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('toadDeath', '/resourcesInGame/sprites/florest/toad/Toad_death.png', {frameWidth: 48, frameHeight: 48});
        
        this.load.spritesheet('boarIdle', '/resourcesInGame/sprites/animals/boar/Boar_idle.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('boarWalk', '/resourcesInGame/sprites/animals/boar/Boar_walk.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('boarAttack', '/resourcesInGame/sprites/animals/boar/Boar_attack.png', {frameWidth: 48, frameHeight: 48});
        this.load.spritesheet('boarDeath', '/resourcesInGame/sprites/animals/boar/Boar_death.png', {frameWidth: 48, frameHeight: 48});
        
        //NPCs
        this.load.spritesheet('hunter', '/resourcesInGame/sprites/npcs/hunter/GraveRobber_idle.png', { frameWidth: 48, frameHeight: 48 });
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            // Enemy
            //slime
            vm.anims.create({
                key: "slimeIdle",
                frames: vm.anims.generateFrameNumbers("slimeIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "slimeWalk",
                frames: vm.anims.generateFrameNumbers("slimeWalk", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "slimeAttack",
                frames: vm.anims.generateFrameNumbers("slimeAttack", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "slimeDeath",
                frames: vm.anims.generateFrameNumbers("slimeDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })
            //toad
            vm.anims.create({
                key: "toadIdle",
                frames: vm.anims.generateFrameNumbers("toadIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "toadWalk",
                frames: vm.anims.generateFrameNumbers("toadWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "toadAttack",
                frames: vm.anims.generateFrameNumbers("toadAttack", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "toadDeath",
                frames: vm.anims.generateFrameNumbers("toadDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })
            //boar
            vm.anims.create({
                key: "boarIdle",
                frames: vm.anims.generateFrameNumbers("boarIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "boarWalk",
                frames: vm.anims.generateFrameNumbers("boarWalk", {start: 0, end: 5}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "boarAttack",
                frames: vm.anims.generateFrameNumbers("boarAttack", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1,
                repeatDelay: 800
            }),
            vm.anims.create({
                key: "boarDeath",
                frames: vm.anims.generateFrameNumbers("boarDeath", {start: 0, end: 3}),
                frameRate: 5,
                repeat: 0
            })

            //NPC
            vm.anims.create({
                key: "hunter",
                frames: vm.anims.generateFrameNumbers('hunter', {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            })
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            
            var trees = vm.add.image(0, -10, 'trees4').setOrigin(0).setScrollFactor(.15);
            var trees2 = vm.add.image(0, -10, 'trees4').setOrigin(0).setScrollFactor(.15);

            var mountainsBack = vm.add.image(0, -60, 'trees').setOrigin(0).setScrollFactor(.33);
            var mountainsBack2 = vm.add.image(1920, -60, 'trees').setOrigin(0).setScrollFactor(.33);

            var mountains = vm.add.image(0, -120, 'trees2').setOrigin(0).setScrollFactor(.66);
            var mountains2 = vm.add.image(1920, -120, 'trees2').setOrigin(0).setScrollFactor(.66);

            var bushes = vm.add.image(0, -120, 'trees3').setOrigin(0).setScrollFactor(.75);
            var bushes2 = vm.add.image(1920, -120, 'trees3').setOrigin(0).setScrollFactor(.75);
            var bushes3 = vm.add.image(3840, -120, 'trees3').setOrigin(0).setScrollFactor(.75);

            // vm.background.displayWidth = vm.mapSize;
            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;
            
            trees.displayHeight = vm.sys.game.config.height;
            trees2.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            bushes.displayHeight = vm.sys.game.config.height;
            bushes2.displayHeight = vm.sys.game.config.height;
            bushes3.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;

            var ground = vm.add.image(0, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground2 = vm.add.image(1920, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground3 = vm.add.image(3840, 0, 'ground').setOrigin(0).setScrollFactor(.8);

            ground.displayHeight = vm.sys.game.config.height;
            ground2.displayHeight = vm.sys.game.config.height;
            ground3.displayHeight = vm.sys.game.config.height;
        }

        function objects(vm) {
            //Lamp
            vm.add.image(400, 660, 'lamp').setScale(2.3, 2.6);
            vm.add.image(890, 660, 'lamp').setScale(2.3, 2.6);
            vm.add.image(1200, 660, 'lamp').setScale(2.3, 2.6);

            vm.add.image(1000, 660, 'well').setScale(2, 2.3);

            //Boxes
            vm.add.image(450, 682, 'box').setScale(2, 2.3);
            vm.add.image(500, 682, 'box').setScale(2, 2.3);
            vm.add.image(470, 623, 'box').setScale(2, 2.3);
        }

        function trees(vm) {
            var i;
            for(i = 0; i < 9; i++) {
                vm.methods.newTree(vm, "oak", {x: Phaser.Math.Between(0, vm.mapSize.x), y: 505}, "tree1");
                vm.methods.newTree(vm, "oak", {x: Phaser.Math.Between(0, vm.mapSize.x), y: 460}, "tree2");
                vm.methods.newTree(vm, "oak", {x: Phaser.Math.Between(0, vm.mapSize.x), y: 460}, "tree3");
                vm.methods.newTree(vm, "oak", {x: Phaser.Math.Between(0, vm.mapSize.x), y: 510}, "tree4");
                vm.methods.newTree(vm, "oak", {x: Phaser.Math.Between(0, vm.mapSize.x), y: 510}, "tree5");
                vm.methods.newTree(vm, "oak", {x: Phaser.Math.Between(0, vm.mapSize.x), y: 510}, "tree6");
            }
        }

        function scenary(vm) {
            trees(vm);
            vm.add.image(700, 517, 'house').setScale(3.3, 3.6);
            objects(vm);
        }
    
        function createScene(vm) {
            parallax(vm);
            scenary(vm);

            vm.platforms = vm.physics.add.staticGroup();
    
            for(var i = 0; i < 100; i++) {
                //Solo
                vm.platforms.create(64 * i, 748, 'bloco1').setScale(2, 2).refreshBody();
            }
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "nextScene":
                        vm.scene.restart(); vm.scene.start('internal1'); break;
                    case "tree":
                        vm.methods.workCommom(vm, vm.worked.item, vm.worked.time, vm.worked.message, vm.worked.tree); break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }
        
        function monsterSpawn(vm) {
            vm.enemyArray = [];
            vm.methods.createEnemy(vm, "slime", "slimeIdle", "slimeIdle", 12, 2, 2.5, {bodyX: 23, bodyY: 25}, {x: 25, y: 23}, 650);
            vm.methods.createEnemy(vm, "toad", "toadIdle", "toadIdle", 4, 2, 2.5, {bodyX: 35, bodyY: 25}, {x: 14, y: 23}, 650);
            vm.methods.createEnemy(vm, "boar", "boarIdle", "boarIdle", 1, 2, 2.5, {bodyX: 40, bodyY: 30}, {x: 5, y: 18}, 650);
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 380, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(5500, 380, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(initial, vm.player);

            vm.physics.add.overlap(end, vm.player, function() {
                vm.game.sound.stopAll();
                vm.scene.start('external2');
            });
        }

        this.sounds.florest = this.sound.add('florestAmbient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.florest.play({loop: true, volume: 0.8});
        this.sounds.theme.play({loop: true, volume: 0.1});

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.npcs = this.physics.add.group();
        this.animals = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        playerImport.createPlayer(this);
        createScene(this);
        createLimits(this);

        this.physics.add.collider(this.npcs, this.platforms);
        this.physics.add.collider(this.player, this.platforms);
        this.physics.add.collider(this.mainAtk, this.platforms);
        this.physics.add.collider(this.mainAtkMoreDamage, this.platforms);
        this.physics.add.collider(this.animals, this.platforms);
        this.physics.add.collider(this.physicObject, this.platforms);
        this.physics.add.collider(this.limits, this.platforms);
        this.physics.add.collider(this.monsterAttack, this.platforms);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        npcImport.createNpc(this, "hunter", "hunter", "hunter", {x: 2.5, y: 3}, {x: 350, y: 600}, true, true);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        
        monsterSpawn(this);
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        updateActions(this);
    }
}