import AppSelection from "../../components/selection/Selection.vue";
import { mapGetters } from "vuex";

export default {
    data () {
        return {
            personPath: {
                title: "Qual caminho deseja seguir?",
                options: [
                    {
                        id: 15,
                        choice: "Path of Swordman",
                        message: "Foco em energia e ataque (+5 Energia)",
                        status: {
                            maxEnergy: 10
                        }
                    },
                    {
                        id: 15,
                        choice: "Path of Mage",
                        message: "Foco em MP e Poder Mágico (+5 Magic)",
                        status: {
                            maxMp: 5
                        }
                    },
                    {
                        id: 15,
                        choice: "Path of Spearman",
                        message: "Foco em defesa e vida (+1 Defesa)",
                        status: {
                            Defesa: 1
                        }
                    },
                    {
                        id: 15,
                        choice: "Path of Archer",
                        message: "Foco em critico e dano (+2 Critico)",
                        status: {
                            Critico: 1
                        }
                    }
                ]
            },
            formCreatePerson: {
                nickname: {
                    error: ''
                },
                familyName: {
                    error: ''
                }
            }
        }
    },
    components: {
        AppSelection
    },
    computed: {
        nickname: {
            get() {
                return this.$store.state.personModule.mainPerson.nickname;
            },
            set(name) {
                this.$store.commit("setNickname", name)
            }
        },
        familyName: {
            get() {
                return this.$store.state.personModule.mainPerson.familyName;
            },
            set(name) {
                this.$store.commit("seFamilyName", name)
            }
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        checkForm (input) {
            if (!this.$store.state.personModule.mainPerson[input]) {
                this.formCreatePerson[input].error = `${input} ${this.$store.getters.translateWords('é obrigatório')}.`;
                this.$store.commit("changeErrorForm", 1);
                return;
            }
            this.formCreatePerson[input].error = null;
            this.$store.commit("changeErrorForm", 0);
        },
        verifyAll () {
            var inputs = Object.keys(this.formCreatePerson);
            inputs.forEach(input => {
                if(this.$store.state.generalModule.errorForm != 1) this.checkForm(input);
            })
        }
    }
}