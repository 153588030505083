import BuyItemsComponent from "@/pages/market/components/buyItems/BuyItems.vue";

import normalDialog from "./dialogs/normalDialog";

export default {
    data() {
        return {
            buyItems: this.$store.state.itemsModule.items.filter(i => i.seller == "hunter"),
        }
    },
    computed: {
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        }
    },
    components: {
        BuyItemsComponent
    },
    methods: {
        toogleBuy(name) {
            this.colapse = name;
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        }
    },
    mounted() {
        this.$store.commit("changeDialogBox", true);
        this.$store.commit("changeNameDialog", "Evandra Brownie, A Caçadora");
        this.$store.commit("addDialog", normalDialog);
    }
}