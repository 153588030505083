// import axios from "axios";
import DescItemTooltipComponent from "@/shared/components/descItemTooltip/DescItemTooltip.vue";

import { connectWallet, loadContractNetworkCurrent, NFTsURI } from "@/shared/nftInteract/interact.js";

import mockItems from "./mocks/items";
import mockNFT from "@/shared/nftInteract/imagesNFT.js";

import contractsMock from "@/shared/nftInteract/mocks/contracts";
import abisMock from "@/shared/nftInteract/mocks/abis";

// import normalDialog from "../blacksmith/dialogs/normalDialog";

export default {
    data() {
        return {
            nameContract: "",
            loading: false
        }
    },
    components: {
        DescItemTooltipComponent
    },
    computed: {
        isConnected() {
            return this.$store.state.contractModule.isConnected;
        },
        walletAddress() {
            return this.$store.state.contractModule.address;
        },
        itemsMinted() {
            return this.$store.state.contractModule.nfts.minted;
        },
        typeContract() {
            return this.$store.state.contractModule.typeContract;
        },
        windowShop() {
            return this.$store.state.itemsModule.shop.window;
        },
        nftActives() {
            return this.$store.state.personModule.mainPerson.nfts;
        }
    },
    methods: {
        async initWalletAccess() {
            if(window.ethereum) {
              try {
                const accounts = await window.ethereum.request({
                  method: "eth_accounts"
                });
                if(accounts.length) {
                    this.$store.commit("changeIsConnected", true);
                    this.$store.commit("changeWalletAddress", accounts[0]);
                } else {
                  throw Error;
                }
              } catch(err) {
                this.$store.commit("changeIsConnected", false);
              }
            } else {
              alert("Metamask não encontrada, por favor, instale em seu navegador")
            }
        },
        async changeConnectWallet() {
            const walletResponse = await connectWallet();
            this.$store.commit("changeIsConnected", walletResponse.connectedStatus);
            if(this.$store.state.contractModule.isConnected) {
                this.$store.commit("changeWalletAddress", walletResponse.address);
            }
            Location.reload();
        },
        async changeContract() {
            var address = contractsMock[this.nameContract];
            var abi = abisMock[this.nameContract];
            this.$store.commit("changeNameContract", this.nameContract);
            this.$store.commit("changeAddressContract", address);
            this.$store.commit("changeABI", abi);
            await this.refreshSearch();
        },
        itemPathChoice(itemName) {
            var path = this.$store.state.personModule.mainPerson.personPath.replace("Path of ", "").toLowerCase();
            if(itemName.indexOf("espada") > -1) {
                switch(path) {
                    case "mage":
                        return itemName.replace("espada", "fireStaff");
                    case "archer":
                        return itemName.replace("espada", "bow");
                    case "spearman":
                        return itemName.replace("espada", "lance");
                    default:
                        return itemName;
                }
            }
            if(itemName.indexOf("axe") > -1) {
                switch(path) {
                    case "mage":
                        return itemName.replace("axe", "energyStaff");
                    case "archer":
                        return itemName.replace("axe", "heavyBow");
                    case "spearman":
                        return itemName.replace("axe", "halberd");
                    default:
                        return itemName;
                }
            }
            if(itemName.indexOf("shield") > -1) {
                switch(path) {
                    case "mage":
                        return itemName.replace("shield", "energyOrb");
                    case "archer":
                        return itemName.replace("shield", "arrow");
                    default:
                        return itemName;
                }
            }
            return itemName;
        },
        async getNFTs() {
            this.loading = true;
            var contractModule = this.$store.state.contractModule;
            this.$store.commit("emptyNFTMintedList");
            let NFTsPLM = await NFTsURI(contractModule.address);
            if(NFTsPLM !== null) {
                let itemMock = mockItems;
                var itemName;
                var array = [];
                var newURI;
                this.$store.commit("setNFTsURI", NFTsPLM);
                if(contractModule.nameContract === "ArmorBoxContract" ||
                contractModule.nameContract === "SwordBoxContract" ||
                contractModule.nameContract === "AxeBoxContract" ||
                contractModule.nameContract === "ShieldBoxContract" ) {
                    this.$store.commit("changeTypeContract", 1);
                    if(contractModule.nfts.uri.length > 0) {
                        contractModule.nfts.uri.map((uri) => {
                            var objectItem = {};
                            itemName = uri.item.replace(/^.*\//g, '').replace(".json", '');
                            itemName = this.itemPathChoice(itemName);
                            objectItem.item = itemMock[itemName];
                            objectItem.URIToken = contractModule.nameContract + uri.URIToken;
                            array.push(objectItem);
                        })
                        this.$store.commit("addNFTMintedList", array);
                        console.log(this.$store.state.contractModule.nfts.minted);
                    }
                } else if (contractModule.nameContract === "SkinsContract") {
                    this.$store.commit("changeTypeContract", 4);
                    if(contractModule.nfts.uri.length > 0) {
                        contractModule.nfts.uri.map((uri) => {
                            if(uri.item !== "https://gateway.pinata.cloud/ipfs/QmawnKD94GGDQJV39F8ksBzJzCvExiGAxonNjy2qq6ACLv/magePack.json") {
                                newURI = uri.item.replace("https://gateway.pinata.cloud/ipfs/", "");
                                var findObject = {};
                                findObject.item = mockNFT.find(i => i.ipfs === newURI);
                                findObject.URIToken = contractModule.nameContract + uri.URIToken;
                                array.push(findObject);
                            } 
                        })
                        this.$store.commit("addNFTMintedList", array);
                    }
                } else if (contractModule.nameContract === "PhotoSkinsContract") {
                    this.$store.commit("changeTypeContract", 3);
                    if(contractModule.nfts.uri.length > 0) {
                        contractModule.nfts.uri.map((uri) => {
                            newURI = uri.item.replace("https://gateway.pinata.cloud/ipfs/", "");
                            var findObject = {};
                            findObject.item = mockNFT.find(i => i.ipfs === newURI);
                            findObject.URIToken = contractModule.nameContract + uri.URIToken;
                            array.push(findObject);
                        })
                        this.$store.commit("addNFTMintedList", array);
                    }
                } else if (contractModule.nameContract === "PacksContract") {
                    this.$store.commit("changeTypeContract", 2);
                    if(contractModule.nfts.uri.length > 0) {
                        contractModule.nfts.uri.map((uri) => {
                            newURI = uri.item.replace("https://gateway.pinata.cloud/ipfs/", "");
                            var findPack = {};
                            findPack.item = mockNFT.find(i => i.ipfs === newURI);
                            findPack.URIToken = contractModule.nameContract + uri.URIToken;
                            array.push(findPack);
                        })
                        this.$store.commit("addNFTMintedList", array);
                    }
                } else {
                    this.$store.commit("changeTypeContract", 1);
                    var arrayForge = [];
                    contractModule.nfts.uri.map(async (uri) => {
                        var indexOfStr = uri.item.indexOf("ipfs://");
                        if(indexOfStr > -1) newURI = uri.item.replace("ipfs://gateway.pinata.cloud", "https://amaranth-voluntary-elephant-469.mypinata.cloud");
                        else newURI = uri.item.replace("gateway.pinata.cloud", "amaranth-voluntary-elephant-469.mypinata.cloud");
                        var data = await fetch(newURI)
                        var json = await data.json()
                        var validJson = Object.keys(json);
                        var parseJson = JSON.parse(validJson);
                        var newItemForge = {};

                        parseJson.urlImg = mockNFT.find(i => i.ipfs === parseJson.image).urlInternal;

                        newItemForge.item = parseJson;
                        newItemForge.URIToken = contractModule.nameContract + uri.URIToken;
                        arrayForge.push(newItemForge);
                    })
                    this.$store.commit("addNFTMintedList", arrayForge);
                }
                this.loading = false;
            }
        },
        async refreshSearch() {
            await loadContractNetworkCurrent(this.$store.state.contractModule.contractABI, this.$store.state.contractModule.addressContract);
            await this.getNFTs();
        },
        changeAvatar(avatar) {
            this.$store.commit("changeAvatar", avatar);
            alert("NFT carregado com sucesso!");
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        },
        addItemChar(item) {
            this.$store.commit("addItemNFTInventory", item.item);
            this.$store.commit("addNFTArray", item.URIToken);
            alert("NFT importado com sucesso!");
        },
        changeSkin(skin) {
            this.$store.commit("changeSkin", skin.item.sprite);
            alert("NFT importado com sucesso!");
        },
        importPack(pack) {
            this.$store.dispatch("importPack", pack);
            alert("NFT importado com sucesso!");
        }
    },
    async mounted() {
        await loadContractNetworkCurrent(this.$store.state.contractModule.contractABI, this.$store.state.contractModule.addressContract);
        await this.initWalletAccess();
        // this.$store.commit("changeDialogBox", true);
        // this.$store.commit("changeNameDialog", "Alfredo, O Taverneiro");
        // this.$store.commit("addDialog", normalDialog);
    }
}