//Components
import LogComponent from "../logs/Log.vue";
import InventoryComponent from "../../home/components/inventory/Inventory.vue";

//Services
import BattleService from "../../../core/services/BattleService.js"
import LootService from ".././../../core/services/LootService";
import SaveCharService from "../../../core/services/saveCharService";

export default {
    data() {
        return {
            messages: [],
            wave: {
                current: 1,
                max: 0
            },
            currentMonsterNow: {},
            deadMonster: false,
            deadMain: false,
            finishExploration: false,
            boss: false,
            bossName: false,
            potionQtd: 0,
            autoBattleEnable: false,
            spellActive: false,
            spellAnimation: '',
            damageMonster: 0,
            damageBoolean: false,
            floor: {
                current: 1,
                indexArrayMonster: 0
            }
        }
    },
    computed: {
        hpMonster() {
            return this.$store.state.monstersModule.current.status.hp;
        },
        maxHpMonster() {
            return this.$store.state.monstersModule.current.status.maxhp;
        },
        hpMain() {
            return this.$store.state.personModule.mainPerson.infos[0].li[0];
        },
        mpMain() {
            return this.$store.state.personModule.mainPerson.infos[0].li[1];
        },
        energyMain() {
            return this.$store.state.personModule.mainPerson.infos[0].li[2];
        },
        currentMonster() {
            return this.$store.state.monstersModule.current;
        },
        nameMonster() {
            return this.$store.state.monstersModule.current.img;
        },
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        spells() {
            return this.$store.state.personModule.mainPerson.spells;
        },
        person() {
            return this.$store.state.personModule.mainPerson;
        },
        mapCurrent() {
            return this.$store.state.mapModule.currentLocation;
        }
    },
    components: {
        LogComponent,
        InventoryComponent
    },
    methods: {
        floorDetected() {
            this.floor.current++;
            const mapFloor = this.$store.state.mapModule.currentLocation.floor.find(i => i.number === this.floor.current);
            if(mapFloor) {
                this.floor.indexArrayMonster++;
            }
        },
        changeMonster() {
            let vm = this;
            const floorCurrent = vm.$store.state.mapModule.currentLocation.floor[this.floor.indexArrayMonster];
            const nowMonster = floorCurrent.monsters;
            let randomNumber;
            if(nowMonster.length > 1) {
                randomNumber = BattleService.methods.getRandomNumber(nowMonster[0], nowMonster[nowMonster.length - 1] + 1);
            } else {
                randomNumber = 0;
            }
            const monsterName = vm.$store.state.mapModule.currentLocation.monsters[randomNumber];
            vm.currentMonsterNow = vm.$store.state.monstersModule.types.find(i => i.name === monsterName);
            vm.$store.commit("changeCurrentMonster", vm.currentMonsterNow);
        },
        nextWave() {
            let vm = this;
            const hpMainNow = this.$store.state.personModule.mainPerson.infos[0].li[0].value;
            const monsterHP = this.$store.state.monstersModule.current;
            var newMessage;
            if(monsterHP.status.hp <= 0) {
                newMessage = {
                    id: this.messages.length,
                    name: "O " + monsterHP.name + " foi derrotado!",
                    type: "dead",
                    value: -1
                }
                this.messages.push(newMessage);
                this.deadMonster = true;
            }
            if(this.deadMonster) {
                let lootObj = BattleService.methods.getXPandGoldMonster();
                this.$store.commit("changeXPandGold", lootObj);
                for(var i = 0; i < vm.currentMonsterNow.loots.items.length; i++) {
                    LootService.methods.lootGeneral(vm.currentMonsterNow.loots.items[i]);
                }
                this.changeMonster();
                this.deadMonster = false;
                this.wave.current += 1;
                this.$store.dispatch("levelHappiness", 2);
                if(this.wave.current > this.wave.max){
                    if(this.boss){
                        this.bossName = true;
                        for(let i = 0; i < vm.$store.state.bossesModule.bosses.length; i++) {
                            if(vm.$store.state.bossesModule.bosses[i].name == vm.$store.state.mapModule.currentLocation.boss) {
                                vm.$store.commit("changeCurrentMonster", vm.$store.state.bossesModule.bosses[i]);
                                vm.boss = false;
                                return
                            }
                        }
                    } else {
                        const newMessage = {
                            id: this.messages.length,
                            name: "Parabéns! Você concluiu a exploração",
                            type: "healing",
                            value: -1
                        }
                        this.messages.push(newMessage);
                        this.getTreasure();
                        this.saveCharCurrent();
                        if(vm.$store.state.mapModule.currentLocation.treasures.loot) {
                            for(var a = 0; a < vm.$store.state.mapModule.currentLocation.treasures.loot.length; a++) {
                                LootService.methods.lootGeneral(vm.$store.state.mapModule.currentLocation.treasures.loot[a]);
                            }
                        }
                        this.$store.dispatch("levelHappiness", 5);
                        this.$store.commit("changeIsExplorer", false);
                    }
                }
            }
            else if(this.deadMain || hpMainNow <= 0) {
                const newMessage = {
                    id: this.messages.length,
                    name: "Você morreu!",
                    type: "dead",
                    value: -1
                }
                this.messages.push(newMessage);
                this.$store.dispatch("deadPlayer");
            }
        },
        changeDurabilityArmor() {
            if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "armor").name !== "")
                this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "armor"), value: 1});
            if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "boots").name !== "")
                this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "boots"), value: 1});
            if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "gauntlet").name !== "")
                this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "gauntlet"), value: 1});
            if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "helmet").name !== "")
                this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "helmet"), value: 1});
        },
        attackMob() {
            const hpMainNow = this.$store.state.personModule.mainPerson.infos[0].li[0].value;
            if(hpMainNow > 0) {
                const randomNumberDamage = BattleService.methods.getRandomNumber(0.6, 1.2, 100);
                const damage = BattleService.methods.attackMob(randomNumberDamage);
                let newMessage, damageData;
                this.changeDurabilityArmor();
                damageData = {
                    sinal: "subtrair",
                    value: damage
                }
                newMessage = {
                    id: this.messages.length,
                    name: "Você sofreu um ataque de ",
                    type: "atkMonster",
                    value: damage
                }
                this.$store.commit("calculeHPCurrent", damageData);
                this.messages.push(newMessage);
                if(hpMainNow <= 0){
                    this.deadMain = true;
                    this.nextWave();
                }
            }
        },
        attackMain() {
            let newMessage, damageData;
            const energy = this.$store.state.personModule.mainPerson.infos[0].li[2].value;
            if(energy <= 4) {
                newMessage = {
                    id: this.messages.length,
                    name: "Você está cansado, pule um turno",
                    type: "run",
                    value: -1
                }
                this.messages.push(newMessage);
            } else {
                const randomNumber = BattleService.methods.getRandomNumber(0.2, 100, 100);
                const randomNumberDamage = BattleService.methods.getRandomNumber(0.6, 1, 100);
                let criticalDamage = (this.$store.state.personModule.mainPerson.infos[1].li[3].value) * 0.2;
                const damage = BattleService.methods.attackMain(randomNumberDamage, randomNumber, criticalDamage);
                const monsterHP = this.$store.state.monstersModule.current.status.hp;
                const monsterName = this.$store.state.monstersModule.current.name;
                let primaryWeaponBattle = this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "first-weapon");
                if(criticalDamage < randomNumber) {
                    damageData = {
                        sinal: "subtrair",
                        value: damage
                    }
                    newMessage = {
                        id: this.messages.length,
                        name: "Você inflingiu um ataque de",
                        type: "atkMeNormal",
                        value: damage
                    }
                    this.damageMonster = damage;
                    this.damageBoolean = true;
                    var timeOut = setTimeout(() => {
                        this.damageBoolean = false;
                        clearTimeout(timeOut);
                    }, 400);
                } else {
                    damageData = {
                        sinal: "subtrair",
                        value: damage
                    }
                    newMessage = {
                        id: this.messages.length,
                        name: "Você inflingiu um ataque critico de",
                        type: "atkMeCritical",
                        value: damage
                    }
                    this.damageMonster = damage;
                    this.damageBoolean = true;
                    timeOut = setTimeout(() => {
                        this.damageBoolean = false;
                        clearTimeout(timeOut);
                    }, 400);
                }
                if(primaryWeaponBattle.type == "equipMagic") {
                    alert("Sua arma principal é mágica");
                    return
                }
                const energyData = {
                    sinal: "subtrair",
                    value: 5
                };
                this.$store.commit("calculeEnergyCurrent", energyData);
                if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "first-weapon").name !== "")
                    this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "first-weapon"), value: 1});
                if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "second-weapon")) {
                    if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "second-weapon").name !== "") {
                        this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "second-weapon"), value: 1})
                    }
                }
                else if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "shield")) {
                    if(this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "shield").name !== "") {
                        this.$store.dispatch("changeDurabilityItem", {item: this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "shield"), value: 1});
                    }
                }
                this.$store.commit("calculeHPMonsterCurrent", damageData);
                this.messages.push(newMessage);
                if(monsterHP <= 0) {
                    newMessage = {
                        id: this.messages.length,
                        name: "O " + monsterName + " foi derrotado!",
                        type: "dead",
                        value: -1
                    }
                    this.messages.push(newMessage);
                    this.deadMonster = true;
                }
                this.nextWave();
            }
        },
        jumpWave() {
            const newMessage2 = {
                id: this.messages.length,
                name: "Você descansou um momento antes de dar uma boa porrada",
                type: "jump",
                value: -1
            };
            const energyData = {
                sinal: "soma",
                value: 20
            };
            this.messages.push(newMessage2);
            this.$store.commit("calculeEnergyCurrent", energyData);
            this.$store.commit("changeMagicPoints", 20);
            this.nextWave();
        },
        usePotion() {
            let vm = this;
            var potionUse = vm.$store.state.personModule.mainPerson.Inventory.find(i => i.name.indexOf("life potion") > -1);
            if(!potionUse) {
                let newMessage = {
                    id: this.messages.length,
                    name: "Você não possui poções para tomar!",
                    type: "run",
                    value: -1
                }
                this.messages.push(newMessage);
                return;
            }
            if(potionUse.qtd <= 0){
                let newMessage = {
                    id: this.messages.length,
                    name: "Você não possui poções para tomar!",
                    type: "run",
                    value: -1
                }
                this.messages.push(newMessage);
                return;
            }
            potionUse.qtd -= 1;               
            this.potionQtd = potionUse.qtd;
            const heal = {
                sinal: "soma",
                value: potionUse.status.hp
            }
            let newMessage = {
                id: this.messages.length,
                name: "Você recuperou",
                type: "healing",
                value: (potionUse.status.hp + " de vida").toString()
            }
            this.messages.push(newMessage);
            this.$store.commit("calculeHPCurrent", heal);
            if(potionUse.qtd <= 0) {
                this.$store.commit("deleteItemInventory", potionUse._id);
            }
        },
        run() {
            let newMessage = {
                id: this.messages.length,
                name: "Sai correndo né cagão... HEHEHEHE",
                type: "run",
                value: -1
            }
            this.messages.push(newMessage);
            const energyData = {
                sinal: "sub",
                value: this.$store.state.personModule.mainPerson.infos[0].li[2].value * 0.9
            };
            this.saveCharCurrent();
            this.$store.commit("calculeEnergyCurrent", energyData);
            this.$store.commit("changeIsExplorer", false);
            setTimeout(function(){
                window.location.href = "";
            }, 2000);
        },
        getTreasure() {
            const vm = this;
            const mapModule = vm.$store.state.mapModule;
            let lootObj = {
                exp: mapModule.currentLocation.treasures.exp,
                gold: mapModule.currentLocation.treasures.gold
            }
            this.$store.commit("changeXPandGold", lootObj);
            vm.finishExploration = true;
        },
        saveCharCurrent() {
            SaveCharService.methods.putSaveChar(this.$store.state.personModule.mainPerson);
            this.$store.commit("addAlertMessage", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
            this.$store.commit("toogleSaveCharTime", false);
            let intervalCloseSaveAlert = setInterval(() => {
                this.$store.commit("deleteAlert", {type: "default", imgUrl: "actions/clock.png", message: "Jogo salvo com sucesso!"});
                clearInterval(intervalCloseSaveAlert);
            }, 5000);
        },
        refreshDungeon() {
            this.generateDungeonWaves();
            this.wave.current = 1;
            this.floorDetected();
            this.$store.commit("changeIsExplorer", true);
            this.finishExploration = false;
            this.messages = [];
        },
        refreshFloor() {
            this.generateDungeonWaves();
            this.wave.current = 1;
            this.$store.commit("changeIsExplorer", true);
            this.finishExploration = false;
            this.messages = [];
        },
        generateDungeonWaves() {
            this.changeMonster();
            const vm = this;
            const personModule = vm.$store.state.personModule;
            const mapModule = vm.$store.state.mapModule;
            this.$store.commit("changeIsExplorer", true);
            vm.wave.max = Math.floor(Math.random() * (mapModule.currentLocation.waves[1] - mapModule.currentLocation.waves[0] + 1)) + mapModule.currentLocation.waves[0];
            vm.wave.max = vm.wave.max * vm.floor.current;
            if(mapModule.currentLocation.boss) {
                vm.boss = true;
            }
            for(let i = 0; i < personModule.mainPerson.Inventory.length; i++) {
                if(vm.$store.state.personModule.mainPerson.Inventory[i].name.indexOf("life potion") > -1) {                
                    vm.potionQtd += vm.$store.state.personModule.mainPerson.Inventory[i].qtd;
                }
            }
        },
        addAlert(type, imgUrl, message) {
            this.$store.commit("addAlertMessage", {type, imgUrl, message});
            let intervalCloseSaveAlert = setInterval(() => {
                this.$store.commit("deleteAlert", {type, imgUrl, message});
                clearInterval(intervalCloseSaveAlert);
            }, 5000);
        },
        attackCalcAgility() {
            var attackFunction, typeAtk;
            var primaryWeaponBattleMagic = this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "first-weapon");
            const personAgility = this.$store.state.personModule.mainPerson.infos[1].li[4].value;
            const monsterAgility = this.$store.state.monstersModule.current.status.agility;
            if(primaryWeaponBattleMagic.type === "equip" || primaryWeaponBattleMagic.name === "") {
                typeAtk = "phisical";
                attackFunction = this.attackMain;
            } else {
                typeAtk = "magic";
                attackFunction = this.magicAtk;
            }
            if(typeAtk == "phisical" && this.$store.state.personModule.mainPerson.infos[0].li[2].value < 5) {
                this.addAlert("default", "actions/1025.png", "Você não possui energia suficiente");
                return;
            }
            if(typeAtk == "magic" && this.$store.state.personModule.mainPerson.infos[0].li[2].value < 5) {
                this.addAlert("default", "actions/1025.png", "Você não possui magia suficiente");
                return;
            }
            if(personAgility > monsterAgility * 2) {
                attackFunction();
                attackFunction();
                this.attackMob();
                return;
            }
            else if(personAgility > monsterAgility) {
                attackFunction();
                this.attackMob();
                return;
            }
            else if(personAgility * 2 < monsterAgility) {
                this.attackMob();
                this.attackMob();
                attackFunction();
                return;
            } else {
                this.attackMob();
                attackFunction();
            }
        },
        autoBattle() {
            const vm = this;
            var interval;
            const personModule = vm.$store.state.personModule;
            this.autoBattleEnable = !this.autoBattleEnable;
            interval = setInterval(() => {
                if(this.wave.current > this.wave.max && !this.boss) {
                    this.autoBattleEnable = false;
                    clearInterval(interval);
                    return;
                }
                if (this.autoBattleEnable) {
                    if(personModule.mainPerson.infos[0].li[0].value > (personModule.mainPerson.infos[0].li[0].maxValue / 100 * 40)) {
                        if (personModule.mainPerson.infos[0].li[2].value < (personModule.mainPerson.infos[0].li[2].maxValue / 100 * 30)) {
                            this.jumpWave();
                        } else {
                            this.attackCalcAgility();
                        }
                    } else {
                        var potion = vm.$store.state.personModule.mainPerson.Inventory.find(i => i.name.indexOf("life potion") > -1);
                        var Blueberrie = vm.$store.state.personModule.mainPerson.Inventory.find(i => i.name.indexOf("Blueberrie") > -1);
                        if(potion && potion.qtd > 0) {
                            this.usePotion();
                            return;
                        }
                        if(Blueberrie && Blueberrie.qtd > 0) {
                            for(var i = 0;i < 5;i++) {
                                this.$store.commit("useItem", Blueberrie);
                                if(Blueberrie.qtd === 0) {
                                    break;
                                }
                            }
                            return;
                        }
                        alert("Você não possui itens de cura, o Auto Ataque foi cancelado");
                        this.autoBattleEnable = false;
                    }
                    return;
                }
                clearInterval(interval);
            }, 1000);
        },
        magicAtk() {
            let newMessage, damageData;
            var primaryWeaponBattleMagic = this.$store.state.personModule.mainPerson.Equips.find(i => i.groupEquip == "first-weapon");
            const mana = this.$store.state.personModule.mainPerson.infos[0].li[1].value;
            if(mana <= 4) {
                newMessage = {
                    id: this.messages.length,
                    name: "Você está sem magia, pule um turno",
                    type: "run",
                    value: -1
                }
                this.messages.push(newMessage);
            } else if(primaryWeaponBattleMagic.type == "equip") {
                alert("Sua arma principal é física");
            } else {
                const damage = BattleService.methods.attackMagicMain();
                const randomNumber = BattleService.methods.getRandomNumber(0.2, 100, 100);
                const randomNumberDamage = BattleService.methods.getRandomNumber(0.6, 1.2, 100);
                const monsterHP = this.$store.state.monstersModule.current.status.hp;
                const monsterName = this.$store.state.monstersModule.current.name;
                let criticalDamage = (this.$store.state.personModule.mainPerson.infos[1].li[3].value) * 0.2;
                if(criticalDamage < randomNumber) {
                    damageData = {
                        sinal: "subtrair",
                        value: damage * randomNumberDamage
                    }
                    newMessage = {
                        id: this.messages.length,
                        name: "Você inflingiu um ataque de",
                        type: "atkMeNormal",
                        value: damage * randomNumberDamage
                    }
                } else {
                    damageData = {
                        sinal: "subtrair",
                        value: damage * 4
                    }
                    newMessage = {
                        id: this.messages.length,
                        name: "Você inflingiu um ataque critico de",
                        type: "atkMeCritical",
                        value: damage * 4
                    }
                }
                this.$store.commit("changeMagicPoints", -5);
                this.$store.commit("calculeHPMonsterCurrent", damageData);
                this.messages.push(newMessage);
                if(monsterHP <= 0) {
                    newMessage = {
                        id: this.messages.length,
                        name: "O " + monsterName + " foi derrotado!",
                        type: "dead",
                        value: -1
                    }
                    this.messages.push(newMessage);
                    this.deadMonster = true;
                }
                this.nextWave();
            }
        },
        attackSkill(skill) {
            var newMessage, damageData;
            var changeSpell = (boolean) => {this.toogleSpellActive(boolean)};
            if(this.$store.state.personModule.mainPerson.infos[0].li[1].maxValue > this.$store.state.personModule.mainPerson.infos[0].li[2].maxValue) {
                if(this.$store.state.personModule.mainPerson.infos[0].li[1].value < skill.mpUse) {
                    alert("Você não tem Mana/Magia suficiente");
                } else {
                    newMessage = {
                        id: this.messages.length,
                        name: "Você inflingiu um ataque de",
                        type: "atkMeNormal",
                        value: skill.damage
                    }
                    this.messages.push(newMessage);
                    damageData = {
                        sinal: "subtrair",
                        value: skill.damage
                    }
                    this.$store.commit("calculeHPMonsterCurrent", damageData);
                    this.$store.commit("changeMagicPoints", skill.mpUse * -1);
                    this.spellAnimation = skill.name.replace(/\s/g, '').toLowerCase();
                    changeSpell(true);
                    var interval = setInterval(() => {
                        changeSpell(false);
                        clearInterval(interval);
                        this.nextWave();
                    }, 3000);
                }
            } else {
                if(this.$store.state.personModule.mainPerson.infos[0].li[2].value < skill.mpUse) {
                    alert("Você não tem Energia suficiente");
                } else {
                    newMessage = {
                        id: this.messages.length,
                        name: "Você inflingiu um ataque de",
                        type: "atkMeNormal",
                        value: skill.damage
                    }
                    this.messages.push(newMessage);
                    damageData = {
                        sinal: "subtrair",
                        value: skill.damage
                    }
                    this.$store.commit("calculeHPMonsterCurrent", damageData);
                    const energyData = {
                        sinal: "subtrair",
                        value: skill.mpUse
                    };
                    this.$store.commit("calculeEnergyCurrent", energyData);
                    this.spellAnimation = skill.name.replace(/\s/g, '').toLowerCase();
                    changeSpell(true);
                    var interval2 = setInterval(() => {
                        changeSpell(false);
                        clearInterval(interval2);
                        this.nextWave();
                    }, 3000);
                }
            }
        },
        attackSpellMagic(spell) {
            if(this.$store.state.personModule.mainPerson.infos[0].li[1].value < spell.mpCost) {
                alert("Você não tem Mana/Magia suficiente");
            } else {
                var newMessage = {
                    id: this.messages.length,
                    name: "Você inflingiu um ataque de",
                    type: "atkMeNormal",
                    value: spell.damage + (spell.damage + this.$store.state.personModule.mainPerson.magicPaths.find(i => i.id == spell.path).boost)
                }
                this.messages.push(newMessage);
                var damageData = {
                    sinal: "subtrair",
                    value: spell.damage + (spell.damage + this.$store.state.personModule.mainPerson.magicPaths.find(i => i.id == spell.path).boost)
                }
                this.$store.commit("calculeHPMonsterCurrent", damageData);
                this.$store.commit("changeMagicPoints", (spell.mpCost + spell.mpCost * (this.$store.state.personModule.mainPerson.magicPaths.find(i => i.id == spell.path).boost * 0.5)) * -1);
                this.spellAnimation = spell.spellName;
                var changeSpell = (boolean) => {this.toogleSpellActive(boolean)};
                changeSpell(true);
                var interval = setInterval(() => {
                    changeSpell(false);
                    clearInterval(interval);
                    this.nextWave();
                }, 3000);
            }
        },
        toogleSpellActive(boolean) {
            this.spellActive = boolean;
            if(!boolean) {
                this.spellAnimation = "";
            }
        }
    },
    mounted(){
        this.generateDungeonWaves();
    }
}