import Phaser from "phaser";

import Store from "@/core/store/store"
import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods"
import keyboardsImport from "@/shared/components-phaser/keyboards"
import { updatePlayer } from "@/shared/components-phaser/updateActions" 

import preloadAllResources from "@/shared/components-phaser/preload.js"

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusItems = storeAcess.itemsModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene1 extends Phaser.Scene {
    constructor(){
        super("external1");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.platforms;
        this.mapSize = {
            x: 1500,
            y: 800
        };
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.sounds = {};
        this.player;
        this.limits;
        this.key;
        this.background;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.enemy = {};
        this.enemyArray = [];
        this.methods = methodsDungeon;
    }
    
    preload ()
    {
        // Tiles
        this.load.image("bloco1", "/resourcesInGame/title-set/tiles/florest/Tile_02.png");
        this.load.image("blocoMine1", "/resourcesInGame/title-set/tiles/mine/Tile_02.png");
        this.load.image("blocoMine2", "/resourcesInGame/title-set/tiles/mine/Tile_22.png");
        this.load.image("blocoMine3", "/resourcesInGame/title-set/tiles/mine/Tile_21.png");
        this.load.image("blocoMine4", "/resourcesInGame/title-set/tiles/mine/Tile_11.png");
        this.load.image("blocoMine5", "/resourcesInGame/title-set/tiles/mine/Tile_23.png");

        // Resources all maps
        preloadAllResources(this);

        //cave background
        this.load.image("backgroundBlockCave1", "/resourcesInGame/title-set/tiles/mine/Tile_12.png");
        this.load.image("backgroundBlockCave2", "/resourcesInGame/title-set/tiles/mine/tile5.png");

        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);

        //scenary
        this.load.image("sky", "/resourcesInGame/title-set/parallax/sky/mine/bg.png");
        this.load.image("ground", "/resourcesInGame/title-set/parallax/rocks/mine/rocks&water.png");
        this.load.image("mountains2", "/resourcesInGame/title-set/parallax/mountains/mine/mountains&trees.png");
        this.load.image("mountains", "/resourcesInGame/title-set/parallax/rocks/mine/rocks&water2.png");
        this.load.image("clouds", "/resourcesInGame/title-set/parallax/cloud/clouds.png");

        //Constructions
        this.load.image("minerHouse", "/resourcesInGame/title-set/constructions/home/miner/House.png");
        this.load.image("mine", "/resourcesInGame/title-set/objects/mine/1.png");

        //Objects
        this.load.image("lamp", "/resourcesInGame/title-set/objects/lamp/lamp2.png");
        this.load.image("box", "/resourcesInGame/title-set/objects/boxes/5.png");
        this.load.image("box2", "/resourcesInGame/title-set/objects/boxes/6.png");
        this.load.image("well", "/resourcesInGame/title-set/objects/others/well.png");

        //Trees
        this.load.image("tree1", "/resourcesInGame/title-set/objects/tree/tree2.png");
        this.load.image("tree2", "/resourcesInGame/title-set/objects/tree/tree3.png");
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);
        }

        function parallax(vm) {
            vm.background = vm.add.image(0, 0, 'sky').setOrigin(0).setScrollFactor(0);

            var clouds = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);
            var clouds2 = vm.add.image(0, -250, 'clouds').setOrigin(0).setScrollFactor(.15);

            var mountainsBack = vm.add.image(0, -60, 'mountains2').setOrigin(0).setScrollFactor(.33);
            var mountainsBack2 = vm.add.image(1920, -60, 'mountains2').setOrigin(0).setScrollFactor(.33);

            var mountains = vm.add.image(0, -120, 'mountains').setOrigin(0).setScrollFactor(.66);
            var mountains2 = vm.add.image(1920, -120, 'mountains').setOrigin(0).setScrollFactor(.66);

            clouds.displayHeight = vm.sys.game.config.height;
            clouds2.displayHeight = vm.sys.game.config.height;

            mountains.displayHeight = vm.sys.game.config.height;
            mountains2.displayHeight = vm.sys.game.config.height;

            mountainsBack.displayHeight = vm.sys.game.config.height;
            mountainsBack2.displayHeight = vm.sys.game.config.height;

            vm.background.displayHeight = vm.sys.game.config.height;

            var ground = vm.add.image(0, 0, 'ground').setOrigin(0).setScrollFactor(.8);
            var ground2 = vm.add.image(1920, 0, 'ground').setOrigin(0).setScrollFactor(.8);

            ground.displayHeight = vm.sys.game.config.height;
            ground2.displayHeight = vm.sys.game.config.height;
        }

        function objects(vm) {
            //Lamp
            vm.add.image(400, 660, 'lamp').setScale(2.3, 2.6);
            vm.add.image(750, 660, 'lamp').setScale(2.3, 2.6);

            vm.add.image(200, 660, 'well').setScale(2, 2.3);

            //Boxes
            vm.add.image(450, 682, 'box').setScale(2, 2.3);
            vm.add.image(500, 682, 'box').setScale(2, 2.3);
            vm.add.image(470, 623, 'box').setScale(2, 2.3);
        }

        function trees(vm) {
            vm.add.image(150, 560, 'tree1').setScale(2.5, 2.8);
        }

        function scenary(vm) {
            vm.add.image(700, 610, 'minerHouse').setScale(3, 3.3);
            var mine = vm.physicObject.create(1300, 555, 'mine').setScale(3, 3.3).setCollideWorldBounds(true);
            // mine;
            vm.physics.add.overlap(vm.player, mine, function() {
                if(vm.interage != "mine") vm.interage = "mine";
            });
            trees(vm);
            objects(vm);
        }
    
        function createScene(vm) {
            parallax(vm);
            scenary(vm);

            vm.platforms = vm.physics.add.staticGroup();
    
            for(var i = 0; i < 71; i++) {
                //Solo
                vm.platforms.create(64 * i, 748, 'bloco1').setScale(2, 2).refreshBody();
            }
        }

        function createLimits(vm) {
            var initial, end; 
            vm.limits.add(vm.add.rectangle(50, 600, 80, 700, 0xffffff));
            vm.limits.add(vm.add.rectangle(vm.mapSize.x - 50, 600, 80, 700, 0xffffff));
            for(var i = 0; i < vm.limits.getChildren().length; i++) {
                vm.limits.getChildren()[i].alpha = 0;
                vm.limits.getChildren()[i].collideWorldBounds = true;
                vm.physics.add.existing(vm.limits.getChildren()[i]);
                vm.limits.getChildren()[i].body.moves = false;
                vm.limits.getChildren()[i].body.setAllowGravity(false);
            }
            
            initial = vm.limits.getChildren()[0];
            end = vm.limits.getChildren()[1];

            vm.physics.add.collider(initial, vm.player);
            vm.physics.add.collider(end, vm.player);
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);

            keyboard.enter.on('down', function () {
                console.log(vm.interage);
                switch(vm.interage) {
                    case "mine":
                        vm.scene.restart(); vm.scene.start('internal1'); break;
                }
                statusItems.mutations.changeShop(statusItems.state, vm.interage);
                statusItems.mutations.changeActiveShop(statusItems.state, true);
            });
        }

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.physicObject = this.physics.add.group();
        this.limits = this.physics.add.group();

        createAnimations(this);
        playerImport.createPlayer(this);
        createScene(this);
        createLimits(this);

        this.methods.createKey(this, "enter");

        this.physics.add.collider(this.player, this.platforms);
        this.physics.add.collider(this.mainAtk, this.platforms);
        this.physics.add.collider(this.mainAtkMoreDamage, this.platforms);
        this.physics.add.collider(this.physicObject, this.platforms);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        keyboardControls(this);
    }

    update () {
        updatePlayer(this);
    }
}