import ComponentItems from "./items/Items.vue";
import ComponentSkills from "./skills/Skills.vue";

export default {
    computed: {
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        },
        spells() {
            return this.$store.state.personModule.mainPerson.spells;
        }
    },
    components: {
        ComponentItems,
        ComponentSkills
    }
}