import Phaser from "phaser";
import Scene1 from "./scene";
import Scene2 from "./scene2";
import Scene3 from "./scene3";

let game;

export const minasLuthios = () => {
    var config = {
        type: Phaser.WEBGL,
        width: 1200,
        height: 800,
        canvas: document.querySelector("#mainCanva"),
        pixelArt: true,
        backgroundColor: '#000000',
        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 900 },
                debug: false
            }
        },
        scene: [Scene1, Scene2, Scene3]
    };

    game = new Phaser.Game(config);

    game;
}

export function minasLuthiosdestroyGame() {
    game.destroy(true);
}