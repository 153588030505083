import Phaser from "phaser";

import Store from "@/core/store/store"

import spritesMain from "@/shared/sprites/main/spritesMainBase";
import allLoadResources from "@/shared/components-phaser/allLoadResources";
import animationMain from "@/shared/anims/main/animations";
import mainAnimationsAllMaps from "@/shared/anims/mainAnimationsAllMaps";

//Functions
import playerImport from "@/shared/components-phaser/player" 
import { methodsDungeon } from "@/shared/components-phaser/methods" 
import keyboardsImport from "@/shared/components-phaser/keyboards" 
import { updateBoss } from "@/shared/components-phaser/updateActions" 

import AnimatedTiles from 'phaser-animated-tiles/dist/AnimatedTiles.min.js'

//Store
const storeAcess = Store._modules.root._rawModule.modules;
const statusPerson = storeAcess.personModule;
const statusMap = storeAcess.mapModule;
const statusMonster = storeAcess.monstersModule;

export default class Scene1 extends Phaser.Scene {
    constructor(){
        super("bossRoom");
        this.locationInfos = statusMap.state.currentLocation;
        this.mainPerson = statusPerson.state.mainPerson;
        this.monsters = statusMonster.state.types;
        this.player;
        this.mapSize = {
            x: 1728,
            y: 1200
        };
        this.waterHurt = true;
        this.layer = {};
        this.map;
        this.alert = {
            type: "default",
            imgUrl: "",
            message: ""
        };
        this.traps;
        this.sounds = {};
        this.player;
        this.startWave;
        this.npcs;
        this.physicObject;
        this.rocksMiner;
        this.interage;
        this.worked;
        this.lightPlayer;
        this.damageText;
        this.monsterAttack;
this.mainAtkMoreDamage;
        this.animals = {};
        this.limits;
        this.enemy = {};
        this.enemyArray = [];
        this.animalArray = [];
        this.boss;
        this.randomAttack = 0;
        this.methods = methodsDungeon;
    }
    
    preload ()
    {        
        this.load.scenePlugin('animatedTiles', AnimatedTiles, 'animatedTiles', 'animatedTiles');

        // Ambient Sons
        this.load.audio('ambient', '/resourcesInGame/sons/ambient/ruins/Cave-2_Loop.wav');
        this.load.audio('music', '/resourcesInGame/sons/musics/bosses/Battle_-_No_Way_Out.ogg');

        // Ataques sons
        this.load.audio('rockSky', '/resourcesInGame/sons/actions/bosses/anubis/ESM_Fantasy_Game_Magic_Earth_Instant_Cast_Spell_A_Accent_Medieval_.wav');
        this.load.audio('anubisBall', '/resourcesInGame/sons/actions/bosses/anubis/ESM_Fantasy_Game_Arcane_Missile_2_Dry_Accent_Medieval_Magic_Spell_Cast.wav');
        this.load.audio('shout', '/resourcesInGame/sons/actions/bosses/anubis/ESM_Fantasy_Game_Creature_Growl_Monster_Voice_Vocal_Hiss_Bass_Rumble_Gurgle.wav');

        // Tilemap
        this.load.image('floor1', '/resourcesInGame/title-set/tiles/piramids/ThePyramidsTilesV3.png');
        this.load.image('objects1', '/resourcesInGame/title-set/tiles/piramids/ThePyramidsObjectsV3.png');
        this.load.tilemapTiledJSON("mapPyramidsBoss", "/resourcesInGame/maps/pyramids/sceneBoss-pyramids.json");

        //enemies
        this.load.spritesheet('anubisIdle', '/resourcesInGame/sprites/bosses/anubis/Anubis_idle.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisWalk', '/resourcesInGame/sprites/bosses/anubis/Anubis_walk.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisAttack1', '/resourcesInGame/sprites/bosses/anubis/Anubis_attack1.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisAttack2', '/resourcesInGame/sprites/bosses/anubis/Anubis_attack2.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisAttack3', '/resourcesInGame/sprites/bosses/anubis/Anubis_attack3.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisAttack4', '/resourcesInGame/sprites/bosses/anubis/Anubis_attack4.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisDeath', '/resourcesInGame/sprites/bosses/anubis/Anubis_death.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisSneer', '/resourcesInGame/sprites/bosses/anubis/Anubis_sneer.png', {frameWidth: 72, frameHeight: 72});

        // Magics
        this.load.spritesheet('sunStrike', '/resourcesInGame/effects/sunStrike/Sun-strike.png', {frameWidth: 72, frameHeight: 72});
        this.load.spritesheet('anubisBall', '/resourcesInGame/effects/anubisBall/Ball.png', {frameWidth: 6, frameHeight: 6});

        // Traps
        this.load.image('rock', '/resourcesInGame/sprites/traps/rock.png');
        
        //Player
        spritesMain(this, statusPerson.state.mainPerson.sprite);

        allLoadResources(this);
    }

    create ()
    {
        var keyboard = {};
        function createAnimations (vm) {
            animationMain(vm, statusPerson.state.mainPerson.sprite);

            mainAnimationsAllMaps(vm);

            // Enemy
            //slime
            vm.anims.create({
                key: "anubisIdle",
                frames: vm.anims.generateFrameNumbers("anubisIdle", {start: 0, end: 3}),
                frameRate: 5,
                repeat: -1
            }),
            vm.anims.create({
                key: "anubisWalk",
                frames: vm.anims.generateFrameNumbers("anubisWalk", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            }),
            vm.anims.create({
                key: "anubisAttack1",
                frames: vm.anims.generateFrameNumbers("anubisAttack1", {start: 0, end: 5}),
                frameRate: 10
            }),
            vm.anims.create({
                key: "anubisAttack2",
                frames: vm.anims.generateFrameNumbers("anubisAttack2", {start: 0, end: 5}),
                frameRate: 10
            }),
            vm.anims.create({
                key: "anubisAttack3",
                frames: vm.anims.generateFrameNumbers("anubisAttack3", {start: 0, end: 5}),
                frameRate: 10
            }),
            vm.anims.create({
                key: "anubisAttack4",
                frames: vm.anims.generateFrameNumbers("anubisAttack4", {start: 0, end: 3}),
                frameRate: 10
            }),
            vm.anims.create({
                key: "anubisDeath",
                frames: vm.anims.generateFrameNumbers("anubisDeath", {start: 0, end: 5}),
                frameRate: 8,
                repeat: 0
            }),
            vm.anims.create({
                key: "anubisSneer",
                frames: vm.anims.generateFrameNumbers("anubisSneer", {start: 0, end: 5}),
                frameRate: 10,
                repeat: -1
            })
        }
    
        function keyboardControls(vm) {
            keyboardsImport(vm);

            keyboard.enter = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);
        }
        
        function monsterSpawn(vm) {
            vm.enemyArray = [];
            // vm.methods.createEnemy(vm, "skeleton", "skeletonIdle", "skeletonIdle", 20, 2.5, 3, {bodyX: 20, bodyY: 30}, {x: 14, y: 12}, 200);
        }
    
        function createScene(vm) {
            vm.map = vm.make.tilemap({ key: "mapPyramidsBoss"});
            var objects1 = vm.map.addTilesetImage("ThePyramidsObjectsV3", "objects1");
            var tileset = vm.map.addTilesetImage("ThePyramidsTilesV3", "floor1");
            vm.layer.background = vm.map.createLayer("background", tileset).setScale(3);
            vm.layer.objects = vm.map.createLayer("objects", [objects1, tileset]).setScale(3);
            vm.layer.floor = vm.map.createLayer("floor", tileset).setScale(3);
            vm.layer.platforms = vm.map.createLayer("platforms", tileset).setScale(3);

            vm.layer.floor.setCollisionBetween(0, 500);
            vm.layer.platforms.setCollisionBetween(0, 500);

            vm.sys.animatedTiles.init(vm.map);
        }
        this.sounds.rockSky = this.sound.add('rockSky');
        this.sounds.anubisBall = this.sound.add('anubisBall');
        this.sounds.shout = this.sound.add('shout');

        this.sounds.ambient = this.sound.add('ambient');
        this.sounds.theme = this.sound.add('music');

        this.sounds.ambient.play({loop: true, volume: 0.8});
        this.sounds.theme.play({loop: true, volume: 0.5});

        statusMap.mutations.changeIsExplorer(statusMap.state, false);
        
        this.mainAtk = this.add.group();
        this.physics.world.enable(this.mainAtk);

        this.mainAtkMoreDamage = this.add.group();
        this.physics.world.enable(this.mainAtkMoreDamage);

        this.animals = this.physics.add.group();
        this.physicObject = this.physics.add.group();
        this.traps = this.physics.add.group();
        this.boss = this.physics.add.group();
        this.limits = this.physics.add.group();

        this.monsterAttack = this.add.group();
        this.damageText = this.add.group();
        this.physics.world.enable(this.monsterAttack);

        createAnimations(this);
        playerImport.createPlayer(this, 150, 160);
        createScene(this);

        this.physics.add.collider(this.player, this.layer.floor);
        this.physics.add.collider(this.boss, this.layer.floor);
        this.physics.add.collider(this.player, this.layer.platforms);
        this.physics.add.collider(this.mainAtk, this.layer.floor);
        this.physics.add.collider(this.mainAtkMoreDamage, this.layer.floor);
        this.physics.add.collider(this.animals, this.layer.floor);
        this.physics.add.collider(this.monsterAttack, this.layer.floor);

        this.physics.world.setBounds(0, 0, this.mapSize.x, this.mapSize.y);

        this.cameras.main.startFollow(this.player).setBounds(0, 0, this.mapSize.x, this.mapSize.y);
        // this.cameras.main.zoom = 0.25;
        
        monsterSpawn(this);
        this.methods.spawnBoss(this, "anubis", "anubisIdle", 900, 875, {x: 4.5, y: 5});
        
        keyboardControls(this);
        this.methods.forAttack(this);
    }

    update ()
    {
        updateBoss(this, "anubis");
    }
}