import { mapGetters } from "vuex";
import axios from "axios";

import SaveCharService from '@/core/services/saveCharService';

// Components
import ComponentItemSell from "./ItemSell/ItemSell.vue";
import ComponentItemBuy from "./ItemBuy/ItemBuy.vue";

export default {
    // minOrMax - false/Min e true/Max
    data() {
        return {
            loading: false,
            buyOrSell: "buy",
            enableButton: true,
            minOrMax: false, 
            formsFilter: {
                nameItem: ""
            },
            withdraw: 0
        }
    },
    components: {
        ComponentItemSell,
        ComponentItemBuy
    },
    computed: {
        marketItems() {
            return this.$store.state.itemsModule.marketItems.filter(this.filterItems).sort((a, b) => {
                if(!this.minOrMax) {
                    if(a.price > b.price) return 1;
                    if(a.price < b.price) return -1;
                    return 0;
                }
                if(a.price < b.price) return 1;
                if(a.price > b.price) return -1;
                return 0;
            });
        },
        marketSellItems() {
            return this.$store.state.personModule.mainPerson.Inventory.filter(this.filterItems);
        },
        balance() {
            return this.$store.state.personModule.mainPerson.marketBank;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        changeMinOrMax(boolean) {
            this.minOrMax = boolean;
        },
        async refresh() {
            this.loading = true;
            await this.$store.commit("getMarketItemsBack");
            this.$forceUpdate();
            this.loading = false;
        },
        withdrawnFlorins() {
            this.loading = true;
            var value = parseInt(this.withdraw);
            if(value > this.$store.state.personModule.mainPerson.marketBank) return;
            var withdrawFlorinsObject = {
                identifier: this.$store.state.personModule.mainPerson._id,
                value: value
            }
            axios.post(
                this.$store.state.generalModule.apiUrl + '/withdrawMarketBank',
                withdrawFlorinsObject
            ).then((res) => {
                console.log(res);
            }).catch(error => {
                console.log(error.message);
            });
            var timeOut = setTimeout(() => {
                this.refreshBalance();
                this.$store.dispatch("showAlert", {type: "default", imgUrl: "actions/bag.png", message: `Florins retirados com sucesso`});
                this.$store.commit("changeMoney", value);
                this.loading = false;
                clearTimeout(timeOut);
            }, 10000);
        },
        refreshBalance() {
            axios.get(
                this.$store.state.generalModule.apiUrl + "/searchChar/" + this.$store.state.personModule.mainPerson.identifier
            ).then((res) => {
                var marketBank = res.data[0].marketBank;
                this.$store.commit("changeMarketBank", marketBank);
                SaveCharService.methods.putSaveChar(this.$store.state.personModule.mainPerson);
            }).catch(error => {
                console.log(error.message);
            });
        },
        refreshList() {
            this.$forceUpdate();
        },
        changeBuyOrSell(choice) {
            this.buyOrSell = choice;
        },
        filterItems(value) {
            if(value.name.indexOf(this.formsFilter.nameItem.toLowerCase(), 0) > -1) return true;
            return false;
        },
        verifyInput() {
            if(this.priceBuy > 99) {
                this.enableButton = false;
                return;
            }
            this.enableButton = true;
            return;
        },
        close() {
            this.$store.commit("changeShop", "");
            this.$store.commit("changeActiveShop", false);
        }
    },
    async created() {
        this.loading = true;
        await this.$store.commit("getMarketItemsBack");
        this.loading = false;
    }
}