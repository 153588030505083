import { mapGetters } from "vuex"

export default {
    data() {
        return {
        }
    },
    computed: {
        items() {
            return this.$store.state.itemsModule.items;
        },
        monstersTypes() {
            return this.$store.state.monstersModule.types;
        },
        bossesTypes() {
            return this.$store.state.bossesModule.bosses;
        },
        ...mapGetters(['translateWords'])
    },
    props: [
        "place"
    ]
}