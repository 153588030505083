import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;

export default function swordman(vm) {
    vm.load.on('progress', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, true);
    });

    vm.load.on('complete', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, false);
    });
    
    // Sons
    vm.load.audio('attack', '/resourcesInGame/sons/actions/sword/Sword_Woosh-5.wav'); 
    vm.load.audio('hit', '/resourcesInGame/sons/actions/hit/ESM_Fantasy_Game_Weapon_Impact_Weapon_Medieval.wav');
    vm.load.audio('monsterDead', '/resourcesInGame/sons/monsters/dead/ESM_Fantasy_Game_Weapon_Impact_Blood_Weapon_Medieval.wav');
    vm.load.audio('usePotion', '/resourcesInGame/sons/actions/use/potion/Alchemy-9.wav');
    vm.load.audio('openPotion', '/resourcesInGame/sons/actions/use/potion/Bottle_Open-1_1.wav');
    vm.load.audio('walk', '/resourcesInGame/sons/actions/walk/ESM_Fantasy_Game_Footstep_Grass_Light_H.wav');
    vm.load.audio('roll', '/resourcesInGame/sons/actions/roll/ESM_Fantasy_Game_Footstep_Grass_Heavy_D.wav');
    vm.load.audio('portal', '/resourcesInGame/sons/actions/portal/ESM_Fantasy_Game_Magic_Airy_Sting_Accent_Medieval__Spell_Cast.wav');

    // Sprites
    vm.load.spritesheet('swordmanIdle', '/resourcesInGame/sprites/main/swordman/swordman-idle.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanWalk', '/resourcesInGame/sprites/main/swordman/swordman-walk.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanJump', '/resourcesInGame/sprites/main/swordman/swordman-jump.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanDead', '/resourcesInGame/sprites/main/swordman/swordman-dead.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanRoll', '/resourcesInGame/sprites/main/swordman/swordman-roll.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanRun', '/resourcesInGame/sprites/main/swordman/swordman-run.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanAttack1', '/resourcesInGame/sprites/main/swordman/swordman-attack1.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanAttack2', '/resourcesInGame/sprites/main/swordman/swordman-attack2.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanAttack3', '/resourcesInGame/sprites/main/swordman/swordman-attack3.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('swordmanAttack4', '/resourcesInGame/sprites/main/swordman/swordman-attack4.png', { frameWidth: 50, frameHeight: 48 });
}