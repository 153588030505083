const normalDialog = {
    steps: [
        {
            number: 1,
            message: "Como aqui está quente, ou será que estou trabalhando demais?",
            options: [
                {
                    message: "Realmente, estou com muito calor também",
                    type: "default",
                    goStep: 2,
                    function: ""
                },
                {
                    message: "Na verdade está bem frio",
                    type: "default",
                    goStep: 3,
                    function: ""
                },
                {
                    message: "Você parece bem cansado, está tudo bem?",
                    type: "default",
                    goStep: 5,
                    function: ""
                },
                {
                    message: "Não estou com muita vontade de conversar não",
                    type: "default",
                    goStep: 9,
                    function: ""
                }
            ]
        },
        {
            number: 2,
            message: "Ufa, achei que estava ficando doente, com febre, esse lugar é assustador demais, só trabalho aqui porque essa mina ainda tem recursos interessantes e não tem muitas pessoas que venham aqui concorrer comigo",
            options: [
                {
                    message: "Então aqui estou eu, seu primeiro competidor",
                    type: "default",
                    goStep: 6,
                    function: ""
                },
                {
                    message: "Realmente, é um lugar bem assustador, escuro e estranho",
                    type: "default",
                    goStep: 5
                }
            ]
        },
        {
            number: 3,
            message: "Boa sorte então amigo, qualquer inexperiente pode facilmente morrer nesse lugar, ainda mais com as aranhas e ursos que moram nos andares mais abaixo dessa mina",
            options: [
                {
                    message: "Você...Disse...Aranhas???",
                    type: "default",
                    goStep: 4
                },
                {
                    message: "Mas porque teria um urso em um lugar com esse? Não faz sentido",
                    type: "default",
                    goStep: 4
                },
                {
                    message: "E eu tenho cara que tem medo de 'bichinhos'",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 4,
            message: "Você é novato não é? Pelo visto não conhece nada desse mundo, certeza que chegou agora",
            options: [
                {
                    message: "Sim, ainda estou me adaptando ao lugar, não conheço nada desse mundo, e estou muito confuso com tudo ainda",
                    type: "default",
                    goStep: 6
                },
                {
                    message: "Como pode não achar esse lugar esquisito?",
                    type: "default",
                    goStep: 6
                }
            ]
        },
        {
            number: 5,
            message: "Bom amigo, se quer descobrir mais sobre esse mundo, então as cavernas são excelentes lugares para isso, e uma dica, não se apegue ao que é estranho para sua antiga realidade, pois ela não existe mais",
            options: [
                {
                    message: "Obrigado pela dica, vou me atentar a isso",
                    type: "default",
                    goStep: 2,
                },
                {
                    message: "Que papo estranho, acho que vou sair daqui",
                    type: "default",
                    goStep: 9
                }
            ]
        },
        {
            number: 6,
            message: "Mas então, o que te trouxe até aqui?",
            options: [
                {
                    message: "Gostaria de ver o que você tem para vender",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Só curiosidade mesmo",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 6,
            message: "Mas então, o que te trouxe até aqui?",
            options: [
                {
                    message: "Gostaria de ver o que você tem para vender",
                    type: "default",
                    function: "openShop"
                },
                {
                    message: "Só curiosidade mesmo",
                    type: "default",
                    goStep: 10
                }
            ]
        },
        {
            number: 9,
            message: "Sem problemas, preciso trabalhar mesmo, até outra hora",
            options: [
                {
                    message: "Tchau (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        },
        {
            number: 10,
            message: "Vai embora daqui, eu preciso trabalhar e não quero perder tempo com você",
            options: [
                {
                    message: "Eita, desculpe, estou indo (Sair)",
                    type: "default",
                    function: "close"
                }
            ]
        }
    ]
}

export default normalDialog;