export default function allLoadResources(vm) {
    vm.load.audio('smallExplosion', '/resourcesInGame/sons/actions/explosion/Small_Explosion-1.wav');
    vm.load.audio('blackHole', '/resourcesInGame/sons/actions/explosion/Low_Rumble-1.wav');

    vm.load.spritesheet('explosion', '/resourcesInGame/effects/explosion/Explosion.png', { frameWidth: 72, frameHeight: 72 });
    vm.load.spritesheet('blackHole', '/resourcesInGame/effects/explosion/Black-hole.png', { frameWidth: 72, frameHeight: 72 });
    vm.load.image('bronzeGrenade', '/resourcesInGame/itens/grenade/bronzeGrenade.png');
    vm.load.image('futureGrenade', '/resourcesInGame/itens/grenade/futureGrenade.png');
    vm.load.image('arrow', '/resourcesInGame/sprites/projetil/arrow_1.png');
    vm.load.image('fireArrow', '/resourcesInGame/sprites/projetil/fire-arrow.png');
}