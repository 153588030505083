const items = [
    {
        name: "life potion",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "Blueberrie",
                qtd: 10
            }
        ],
        price: 150
    },
    {
        name: "energy potion",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "morango",
                qtd: 10
            }
        ],
        price: 250
    },
    {
        name: "medium life potion",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "Blueberrie",
                qtd: 30
            }
        ],
        price: 400
    },
    {
        name: "medium energy potion",
        rarity: "normal",
        level: 1,
        ingredients: [
            {
                name: "morango",
                qtd: 30
            }
        ],
        price: 700
    }
]

export default items;