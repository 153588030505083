import axios from "axios";

const mutations = {
    getPropertiesBack(state) {
        axios.get(
            "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/getProperties"
        ).then((res) => {
            state.properties = res.data;
        }).catch(error => {
            console.log(error.message);
        });
    },
    getBuildingsBack(state) {
        axios.get(
            "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/buildings"
        ).then((res) => {
            state.buildings = res.data;
        }).catch(error => {
            console.log(error.message);
        });
    },
    updatePropertyQtd(state, property) {
        axios.post(
            "https://parallelium-backend-1bd1d263e7cc.herokuapp.com/properties", property
        ).then(() => {
            alert("Terreno comprado com sucesso!");
        }).catch(error => {
            console.log(error.message);
        });
    }
}

export default mutations;