import DescItemTooltipComponent from "@/shared/components/descItemTooltip/DescItemTooltip.vue";
import { mapGetters } from "vuex";

export default {
    data(){
        return {
            priceBuy: 1,
            enableButton: true
        }
    },
    props: [
        'items'
    ],
    components: {
        DescItemTooltipComponent
    },
    computed: {
        itemQtd() {
            var amount = 0;
            this.$store.state.personModule.mainPerson.Inventory.forEach(item => {
                if(item.name === this.$props.items.name) {
                    amount += item.qtd;
                }
            })
            return amount;
        },
        ...mapGetters(['translateWords'])
    },
    methods: {
        verifyInput() {
            if(this.priceBuy > 99) {
                this.enableButton = false;
                return;
            }
            this.enableButton = true;
            return;
        },
        buyItem(item) {
            var priceItem = item.price;
            var newItem = {item: item, quantidade: this.priceBuy};
            if(this.$store.state.personModule.mainPerson.gold.value < priceItem * newItem.quantidade){
                alert.imgUrl = "actions/29.png"
                alert.message = "Você não possui dinheiro suficiente!";
                this.$store.dispatch("showAlert", alert);
            } else if(this.$store.state.personModule.mainPerson.Inventory.length + 1 > this.$store.state.personModule.mainPerson.inventorySize) {
                alert.imgUrl = "actions/6.png"
                alert.message = "Seu inventário já está lotado!";
                this.$store.dispatch("showAlert", alert);
            } else {
                alert.imgUrl = "actions/bag.png"
                alert.message = `Você comprou x${newItem.quantidade} ${item.name}`;
                this.$store.commit("lessMoneyWallet", priceItem * newItem.quantidade);
                this.$store.commit("addItemInventory", newItem);
                this.$store.dispatch("showAlert", alert);
            }
            this.$forceUpdate();
        },
        buyAllItem(item) {
            var priceItem = item.price;
            var alert = {
                type: "default",
                imgUrl: "",
                message: ""
            }
            var newItem = {item: item, quantidade: Math.trunc(this.$store.state.personModule.mainPerson.gold.value / priceItem)};
            if(this.$store.state.personModule.mainPerson.gold.value < priceItem * newItem.quantidade){
                alert.imgUrl = "actions/29.png"
                alert.message = "Você não possui dinheiro suficiente!";
                this.$store.dispatch("showAlert", alert);
            } else if(this.$store.state.personModule.mainPerson.Inventory.length + 1 > this.$store.state.personModule.mainPerson.inventorySize) {
                alert.imgUrl = "actions/6.png"
                alert.message = "Seu inventário já está lotado!";
                this.$store.dispatch("showAlert", alert);
            } else {
                alert.imgUrl = "actions/bag.png"
                alert.message = `Você comprou x${newItem.quantidade} ${item.name}`;
                this.$store.commit("lessMoneyWallet", priceItem * newItem.quantidade);
                this.$store.commit("addItemInventory", newItem);
                this.$store.dispatch("showAlert", alert);
            }
            this.$forceUpdate();
        }
    }
}