export default {
    computed: {
        actionText() {
            return this.$store.state.classWorkModule.workAction;
        },
        workTime() {
            return this.$store.state.classWorkModule.working;
        },
        timeLeft() {
            return this.$store.state.classWorkModule.timeLeft;
        },
        timeCurrent() {
            return this.$store.state.classWorkModule.timeCurrent;
        },
        clock() {
            return this.$store.state.classWorkModule.clock;
        }
    },
    methods: {
    }
}