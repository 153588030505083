import Store from "@/core/store/store"

const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;

export default function mage(vm) {
    vm.load.on('progress', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, true);
    });

    vm.load.on('complete', function () {
        statusGeneral.mutations.changeIsEnabledLoading(statusGeneral.state, false);
    });
    
    // Sons
    vm.load.audio('attack', '/resourcesInGame/sons/actions/spells/Fire_Burst-9.wav'); 
    vm.load.audio('hit', '/resourcesInGame/sons/actions/hit/Insect_Crush-5.wav');
    vm.load.audio('monsterDead', '/resourcesInGame/sons/monsters/dead/Evil_Whoosh-4.wav');
    vm.load.audio('usePotion', '/resourcesInGame/sons/actions/use/potion/Alchemy-9.wav');
    vm.load.audio('openPotion', '/resourcesInGame/sons/actions/use/potion/Bottle_Open-1_1.wav');
    vm.load.audio('walk', '/resourcesInGame/sons/actions/walk/ESM_Fantasy_Game_Footstep_Grass_Light_H.wav');
    vm.load.audio('roll', '/resourcesInGame/sons/actions/roll/ESM_Fantasy_Game_Footstep_Grass_Heavy_D.wav');
    vm.load.audio('portal', '/resourcesInGame/sons/actions/portal/ESM_Fantasy_Game_Magic_Airy_Sting_Accent_Medieval__Spell_Cast.wav');

    vm.load.spritesheet('mageIdle', '/resourcesInGame/sprites/main/mage/mage-idle.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageWalk', '/resourcesInGame/sprites/main/mage/mage-walk.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageJump', '/resourcesInGame/sprites/main/mage/mage-jump.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageDead', '/resourcesInGame/sprites/main/mage/mage-dead.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageAttack1', '/resourcesInGame/sprites/main/mage/mage-attack1.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageAttack2', '/resourcesInGame/sprites/main/mage/mage-attack2.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageAttack3', '/resourcesInGame/sprites/main/mage/mage-attack3.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageRoll', '/resourcesInGame/sprites/main/mage/mage-roll.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('mageRun', '/resourcesInGame/sprites/main/mage/mage-run.png', { frameWidth: 50, frameHeight: 48 });
    vm.load.spritesheet('fireball', '/resourcesInGame/effects/fireball/Fireball.png', { frameWidth: 68, frameHeight: 8 });
}