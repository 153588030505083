import Phaser from "phaser";

import Store from "@/core/store/store"

import BattleService from "@/core/services/BattleService";

import projetils from "./projetils";


const storeAcess = Store._modules.root._rawModule.modules;
const statusGeneral = storeAcess.generalModule;
const statusPerson = storeAcess.personModule;

var skillsTime = [], enableAtk = true;

function destroyRect(vm) {
    for(var i = 0; i < vm.mainAtk.getChildren().length; i++) vm.mainAtk.getChildren()[i].destroy();            
}

function mainDamage(vm, x, y, w, h) {
    vm.mainAtk.add(vm.add.rectangle(x, y, w, h, 0xffffff));
    vm.mainAtk.getChildren().forEach(atk => {
        atk.alpha = 0;
        atk.collideWorldBounds = true;
        vm.physics.add.existing(atk);
        atk.body.moves = false;
    })
    var interval = setInterval(() => {
        destroyRect(vm)
        clearInterval(interval);
    }, 400);
}

function mainDamageAway(vm, x, y, speed, sprite, size, offsetX, anime) {
    var projetil = vm.mainAtk.create(x, y, sprite);
    vm.mainAtk.getChildren().forEach(atk => {
        atk.collideWorldBounds = true;
        if(anime) atk.play(anime, true);
        atk.setScale(2);
        vm.physics.add.existing(atk);
        atk.body.velocity.x = speed;
        if(speed < 0) atk.setFlip(true, false);
        if(!size) atk.body.setSize(20, 10, true);
        else atk.body.setSize(size.x, size.y, true)
        if(!offsetX) atk.body.offset.x = 0;
        else atk.body.offset.x = offsetX;
        atk.body.setAllowGravity(false);
    })
    var timeOut = setTimeout(() => {
        projetil.destroy();
        // destroyRect(vm);
        clearTimeout(timeOut);
    }, 750);
}

function grenade(vm, projetil) {
    var grenade;

    grenade = vm.mainAtkMoreDamage.create(projetil.x, projetil.y, projetil.sprite);
    grenade.collideWorldBounds = true;
    grenade.damage = projetil.damage;
    grenade.setScale(projetil.scale);
    vm.physics.add.existing(grenade);
    grenade.body.velocity.x = projetil.speedX;
    grenade.body.velocity.y = projetil.speedY;
    if(projetil.speedX < 0) grenade.setFlip(true, false);
    if(!projetil.size) grenade.body.setSize(20, 10, true);
    else grenade.body.setSize(projetil.size.x, projetil.size.y, true)
    if(!projetil.offsetX) grenade.body.offset.x = 0;
    else grenade.body.offset.x = projetil.offsetX;
    grenade.isDamage = false;
    // vm.mainAtkMoreDamage.getChildren().forEach(atk => {
    //     atk.collideWorldBounds = true;
    //     atk.setScale(scale);
    //     vm.physics.add.existing(atk);
    //     atk.body.velocity.x = speed;
    //     if(speed < 0) atk.setFlip(true, false);
    //     if(!size) atk.body.setSize(20, 10, true);
    //     else atk.body.setSize(size.x, size.y, true)
    //     if(!offsetX) atk.body.offset.x = 0;
    //     else atk.body.offset.x = offsetX;
    // })
    var timeOut = setTimeout(() => {
        grenade.body.setAllowGravity(false);
        if(projetil.anime) grenade.play(projetil.anime, true);
        grenade.isDamage = true;

        grenade.body.velocity.x = 0;
        grenade.body.velocity.y = 0;
        grenade.body.moves = false;
        grenade.setDepth(16);
        vm.sounds[projetil.som].play({volume: 1});
        grenade.setScale(projetil.scaleDamage);
        if(!projetil.explosionSize) grenade.body.setSize(20, 10, true);
        else grenade.body.setSize(projetil.explosionSize.x, projetil.explosionSize.y, true)

        // destroyRect(vm);
        clearTimeout(timeOut);
        var timeOut2 = setTimeout(() => {
            grenade.destroy();
            // destroyRect(vm);
            clearTimeout(timeOut2);
        }, projetil.timeDamage);
    }, projetil.timeGrenade);
}

function useGrenade(vm, grenadeType) {
    var projetil = projetils(grenadeType);
    var newProjetil = {
        vm: vm,
        x: vm.player.body.position.x + projetil.y,
        y: vm.player.body.position.y + projetil.y,
        speedX: projetil.speedX,
        speedY: projetil.speedY,
        sprite: projetil.sprite,
        size: projetil.size,
        offsetX: projetil.offsetX,
        anime: projetil.anime,
        timeGrenade: projetil.timeGrenade,
        timeDamage: projetil.timeDamage,
        explosionSize: projetil.explosionSize,
        scale: projetil.scale,
        scaleDamage: projetil.scaleDamage,
        damage: projetil.damage,
        som: projetil.som
    }
    if(vm.player.flipX) {
        newProjetil.speedX = newProjetil.speedX * -1;
    }
    grenade(vm, newProjetil);
}

function spells(vm, skill) {
    var energyData;
    if(!skillsTime.includes(skill.code)) {
        switch(skill.cost.type) {
            case "energy":
                energyData = {
                    sinal: "subtrair",
                    value: skill.cost.value
                };
                statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyData);
        }
        switch(skill.code) {
            case 1:
                vm.methods.isWalk = true;
                skillsTime.push(skill.code);
                vm.player.anims.play('attack2', true);
                vm.sounds.attack.play({volume: 1});
                if(!vm.player.flipX) {
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, 800, "arrow");
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 45, 800, "arrow");
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 15, 800, "arrow");
                } else {
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, -800, "arrow");
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 45, -800, "arrow");
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 15, -800, "arrow");
                }
                break;
            case 2:
                vm.methods.isWalk = true;
                skillsTime.push(skill.code);
                vm.player.anims.play('attack2', true);
                vm.sounds.attack.play({volume: 1});
                if(!vm.player.flipX) mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, 800, "fireArrow");
                else mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, -800, "fireArrow");
                break;
            case 3:
                vm.methods.isWalk = true;
                skillsTime.push(skill.code);
                vm.player.anims.play('attack2', true);
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 60, 800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, 800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 45, 800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 15, 800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 0, 800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 60, -800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, -800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 45, -800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 15, -800, "arrow");
                mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 0, -800, "arrow");
                break;
            case 4:
                skillsTime.push(skill.code);
                vm.player.anims.play('attack2', true);
                if(!vm.player.flipX) mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, 500, "fireball", {x: 45, y: 10}, 15, "fireball");
                else mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, -500, "fireball", {x: 45, y: 10}, 15, "fireball");
                break;
            case 5:
                skillsTime.push(skill.code);
                vm.player.anims.play('attack2', true);
                if(!vm.player.flipX) mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, 800, "fireArrow");
                else mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 30, -800, "fireArrow");
                break;
        }
        if(skill.damage) statusPerson.mutations.addTemporaryDamage(statusPerson.state, skill.damage);
        var timeoutAtk = setTimeout(() => {
            enableAtk = true;
            clearTimeout(timeoutAtk);
        }, (1000 - (statusPerson.state.mainPerson.infos[1].li[4].value * 2.5)));
        var timeOutSkill = setTimeout(() => {
            var index = skillsTime.indexOf(skill.code);
            skillsTime.splice(index, 1);
            clearTimeout(timeOutSkill);
        }, skill.cost.time);
    }
}

function attackTypes(vm) {
    var energyData, setTimeoutAtk, attackSpeed;
    var path = statusPerson.state.mainPerson.personPath.replace("Path of ", "").toLowerCase();
    switch(path) {
        case "swordman":
            attackSpeed = 500;
            break;
        case "spearman":
            attackSpeed = 750;
            break;
        default:
            attackSpeed = 1000;
            break;
    }
    if(enableAtk) {
        vm.methods.isWalk = true;
        vm.sounds.attack.play({volume: 1});
        if(statusPerson.state.mainPerson.sprite === "mage") {
            if(statusPerson.state.mainPerson.infos[0].li[1].value > 2) {
                vm.player.anims.play('attack3', true);
                if(!vm.player.flipX) {
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 35, 500, "fireball", {x: 45, y: 10}, 15, "fireball");
                } else {
                    mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 35, -500, "fireball", {x: 45, y: 10}, 15, "fireball");
                }
                enableAtk = false;
                vm.player.anims.playAfterDelay('turn', attackSpeed);
                setTimeoutAtk = setTimeout(() => {
                    enableAtk = true;
                    clearTimeout(setTimeoutAtk);
                }, (attackSpeed - (statusPerson.state.mainPerson.infos[1].li[4].value * 2.5)));
                statusPerson.mutations.changeMagicPoints(statusPerson.state, -2);
                BattleService.methods.changeDurabilityWeapon();
                return;
            }
            vm.alert.imgUrl = "actions/1025.png";
            vm.alert.message = "Você não possui mana suficiente";
            statusGeneral.actions.showAlert(Store, vm.alert);
        }
        else if(statusPerson.state.mainPerson.infos[0].li[2].value > 2) {
            energyData = {
                sinal: "subtrair",
                value: 2
            };
            statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyData);
            switch(path) {
                case "archer":
                    vm.player.anims.play('attack2', true);
                    if(!vm.player.flipX) {
                        mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 35, 750, "arrow");
                    } else {
                        mainDamageAway(vm, vm.player.body.position.x, vm.player.body.position.y + 35, -750, "arrow");
                    }
                    enableAtk = false;
                    break;
                case "spearman":
                    if(!vm.player.flipX) {
                        mainDamage(vm, vm.player.body.position.x + 63, vm.player.body.position.y + 50, 100, 85);
                    } else {
                        mainDamage(vm, vm.player.body.position.x - 10, vm.player.body.position.y + 50, 100, 85);
                    }
                    vm.player.anims.play('attack2', true);
                    enableAtk = false;
                    break;
                default:
                    if(!vm.player.flipX) {
                        mainDamage(vm, vm.player.body.position.x + 80, vm.player.body.position.y + 35, 50, 85);
                    } else {
                        mainDamage(vm, vm.player.body.position.x - 25, vm.player.body.position.y + 35, 50, 85);
                    }
                    vm.player.anims.play('attack1', true);
                    enableAtk = false;
                    break;
            }
            vm.player.anims.playAfterDelay('turn', attackSpeed);
            setTimeoutAtk = setTimeout(() => {
                enableAtk = true;
                clearTimeout(setTimeoutAtk);
            }, (attackSpeed - (statusPerson.state.mainPerson.infos[1].li[4].value * 2.5)));
            BattleService.methods.changeDurabilityWeapon();
            return;
        }
        vm.alert.imgUrl = "actions/1025.png";
        vm.alert.message = "Você não possui energia suficiente";
        statusGeneral.actions.showAlert(Store, vm.alert);
    }
}

function keyboardControls(vm) {
    vm.methods.isWalk = false;
    var keyboard = {}, roll = true, isRun = false, intervalRun;

    function setIntervalEnergy(value) {
        if(!isRun) {
            clearInterval(intervalRun);
            intervalRun = null;
        }
        if(vm.player.x > vm.mapSize.x - 500 && vm.player.x < 400 && statusPerson.state.mainPerson.infos[0].li[2].value < value) {
            vm.alert.imgUrl = "actions/1025.png";
            vm.alert.message = "Você não consegue mais correr";
            statusGeneral.actions.showAlert(Store, vm.alert);
            clearInterval(intervalRun);
            intervalRun = null;
            return;
        }
        intervalRun = setInterval(() => {
            if(vm.player.x < vm.mapSize.x - 500 && vm.player.x > 400 && statusPerson.state.mainPerson.infos[0].li[2].value >= value) {
                var energyData = {
                    sinal: "subtrair",
                    value: value
                };
                statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyData);
                return;
            }
            vm.alert.imgUrl = "actions/1025.png";
            vm.alert.message = "Você não consegue mais correr";
            statusGeneral.actions.showAlert(Store, vm.alert);
            clearInterval(intervalRun);
            intervalRun = null;
        }, 200);
    }

    // Sons actions
    vm.sounds.attack = vm.sound.add('attack');
    vm.sounds.hit = vm.sound.add('hit');
    vm.sounds.usePotion = vm.sound.add('usePotion');
    vm.sounds.openPotion = vm.sound.add('openPotion');
    vm.sounds.monsterDead = vm.sound.add('monsterDead');
    vm.sounds.walk = vm.sound.add('walk');
    vm.sounds.roll = vm.sound.add('roll');
    vm.sounds.portal = vm.sound.add('portal');
    vm.sounds.smallExplosion = vm.sound.add('smallExplosion');
    vm.sounds.blackHole = vm.sound.add('blackHole');

    keyboard.space = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
    keyboard.control = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.CTRL);
    keyboard.shift = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SHIFT);
    
    keyboard.a = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
    keyboard.d = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);

    // Hotkeys
    keyboard.one = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ONE);
    keyboard.two = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.TWO);
    keyboard.three = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.THREE);
    keyboard.four = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.FOUR);
    keyboard.five = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.FIVE);
    keyboard.six = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SIX);
    keyboard.seven = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SEVEN);
    keyboard.eight = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.EIGHT);
    keyboard.nine = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NINE);
    keyboard.zero = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ZERO);

    // Roll
    keyboard.q = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q);
    keyboard.e = vm.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E);

    function hotkeysActions (number) {
        if(statusPerson.state.mainPerson.hotkeys[number].type) {
            if(statusPerson.state.mainPerson.hotkeys[number].groupEquip === "grenade") {
                useGrenade(vm, statusPerson.state.mainPerson.hotkeys[number].name);
                statusPerson.mutations.useHotkey(statusPerson.state, number);
                return;
            }
            vm.sounds.openPotion.play({volume: 1});
            vm.sounds.usePotion.play({volume: 1});
            statusPerson.mutations.useHotkey(statusPerson.state, number);
            return;
        } else {
            spells(vm, statusPerson.state.mainPerson.hotkeys[number]);
        }
    }

    keyboard.zero.on('down', function () {
        hotkeysActions(0);
    });

    keyboard.one.on('down', function () {
        hotkeysActions(1);
    });

    keyboard.two.on('down', function () {
        hotkeysActions(2);
    });

    keyboard.three.on('down', function () {
        hotkeysActions(3);
    });

    keyboard.four.on('down', function () {
        hotkeysActions(4);
    });

    keyboard.five.on('down', function () {
        hotkeysActions(5);
    });

    keyboard.six.on('down', function () {
        hotkeysActions(6);
    });

    keyboard.seven.on('down', function () {
        hotkeysActions(7);
    });

    keyboard.eight.on('down', function () {
        hotkeysActions(8);
    });

    keyboard.nine.on('down', function () {
        hotkeysActions(9);
    });
    
    keyboard.q.on('down', function () {
        if(statusPerson.state.mainPerson.infos[0].li[2].value > 2 && roll) {
            const energyData = {sinal: "subtrair",value: 2};
            statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyData);
            if(vm.player.body.onFloor()) {
                vm.methods.isWalk = true;
            }
            vm.player.body.setVelocity(-300, 0);
            vm.player.anims.play('roll', true);
            vm.sounds.roll.play({volume: 1});
            roll = false;
            vm.player.flipX = true;
            var timeOut = setTimeout(() => {
                vm.player.anims.play('turn', true);
                vm.player.body.setVelocity(0);
                roll = true;
                clearTimeout(timeOut);
            }, 500);
            return;
        }
        else if(statusPerson.state.mainPerson.infos[0].li[2].value < 2) {
            vm.alert.imgUrl = "actions/1025.png";
            vm.alert.message = "Você não possui energia suficiente";
            statusGeneral.actions.showAlert(Store, vm.alert);
        }
    });
    
    keyboard.e.on('down', function () {
        if(statusPerson.state.mainPerson.infos[0].li[2].value > 2 && roll) {
            const energyData = {sinal: "subtrair",value: 2};
            statusPerson.mutations.calculeEnergyCurrent(statusPerson.state, energyData);
            if(vm.player.body.onFloor()) {
                vm.methods.isWalk = true;
            }
            vm.player.body.setVelocity(300, 0);
            vm.player.anims.play('roll', true);
            vm.sounds.roll.play({volume: 1});
            roll = false;
            vm.player.flipX = false;
            var timeOut = setTimeout(() => {
                vm.player.anims.play('turn', true);
                vm.player.body.setVelocity(0);
                roll = true;
                clearTimeout(timeOut);
            }, 500);
            return;
        }
        else if(statusPerson.state.mainPerson.infos[0].li[2].value < 2) {
            vm.alert.imgUrl = "actions/1025.png";
            vm.alert.message = "Você não possui energia suficiente";
            statusGeneral.actions.showAlert(Store, vm.alert);
        }
    });

    keyboard.space.on('down', function () {
        if(vm.player.body.onFloor()) {
            vm.player.body.velocity.y = -600;
        }
        if(vm.methods.isWalk) vm.player.anims.playAfterDelay('walk', 2000);
        else vm.player.anims.playAfterDelay('turn', 2000);
    });

    vm.input.on('pointerdown', function () {
        vm.methods.isWalk = true;
        attackTypes(vm);
    }, this);

    keyboard.control.on('down', function () {
        vm.methods.isWalk = true;
        attackTypes(vm);
    });

    keyboard.shift.on('down', function () {
        isRun = true;
    });

    keyboard.shift.on('up', function () {
        isRun = false;
    });

    keyboard.a.on('down', function () {
        if(isRun && statusPerson.state.mainPerson.infos[0].li[2].value >= 1) {
            setIntervalEnergy(1);
            vm.player.body.velocity.x = -300 - (statusPerson.state.mainPerson.infos[1].li[4].value * 0.75);
            vm.player.flipX = true;
            if(vm.player.body.onFloor()) {
                vm.methods.isWalk = true;
                vm.sounds.walk.play({volume: .5, loop: true, rate: 2.5});
                vm.player.anims.play('run', true);
            }
            return;
        }
        isRun = false;
        clearInterval(intervalRun);
        vm.player.body.velocity.x = -160 - (statusPerson.state.mainPerson.infos[1].li[4].value * 0.75);
        vm.player.flipX = true;
        if(vm.player.body.onFloor()) {
            vm.methods.isWalk = true;
            vm.sounds.walk.play({volume: .5, loop: true, rate: 2});
            vm.player.anims.play('walk', true);
        }
    });

    keyboard.a.on('up', function () {
        vm.player.body.velocity.x = 0;
        vm.methods.isWalk = false;
        clearInterval(intervalRun);
        if(vm.player.body.onFloor()) {
            vm.sounds.walk.stop();
            vm.player.anims.play('turn', true);
        }
    });

    keyboard.d.on('down', function () {
        if(isRun && statusPerson.state.mainPerson.infos[0].li[2].value >= 1) {
            setIntervalEnergy(1);
            vm.player.body.velocity.x = 300 + (statusPerson.state.mainPerson.infos[1].li[4].value * 0.75);
            vm.player.flipX = false;
            if(vm.player.body.onFloor()) {
                vm.methods.isWalk = true;
                vm.sounds.walk.play({volume: .5, loop: true, rate: 2});
                vm.player.anims.play('run', true);
            }
            return;
        }
        isRun = false;
        clearInterval(intervalRun);
        vm.player.body.velocity.x = 160 + (statusPerson.state.mainPerson.infos[1].li[4].value * 0.75);
        vm.player.flipX = false;
        if(vm.player.body.onFloor()) {
            vm.methods.isWalk = true;
            vm.sounds.walk.play({volume: .5, loop: true, rate: 2});
            vm.player.anims.play('walk', true);
        }
    });

    keyboard.d.on('up', function () {
        vm.player.body.velocity.x = 0;
        vm.methods.isWalk = false;
        clearInterval(intervalRun);
        if(vm.player.body.onFloor()) {
            vm.sounds.walk.stop();
            vm.player.anims.play('turn', true);
        }
    });
}

export default keyboardControls;