import Phaser from "phaser";
import SceneCamp from "./scene";

let game, newConfig;

export const banditCampMap = (locationInfos, mainPerson, monsters) => {
    const scene = new SceneCamp(locationInfos, mainPerson, monsters);
    var config = {
        type: Phaser.CANVAS,
        width: 1200,
        height: 800,
        canvas: document.querySelector("#mainCanva"),
        pixelArt: true,
        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 900 },
                debug: false
            }
        },
        scene: [scene]
    };

    newConfig = config;

    game = new Phaser.Game(newConfig);

    game;
}

export function banditDestroyGame() {
    game.destroy(true);
}