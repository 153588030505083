import mutations from "./mutations";
import actions from "./actions";
// import * as getters from "./getters";

import personModel from "../../../models/person";

export default {
    state: {
        mainPerson: personModel,
        lvlUpAnimation: false
    },
    mutations,
    actions
}