import Phaser from "phaser";
import SceneCamp from "./scene";
import Temple from "@/shared/cidades/Cormiles/temple/scene"

let game, newConfig;

export const WorkArea2D = (locationInfos, mainPerson) => {
    const scene = new SceneCamp(locationInfos, mainPerson);
    const temple = new Temple(locationInfos, mainPerson);
    var config = {
        type: Phaser.CANVAS,
        width: 1200,
        height: 800,
        canvas: document.querySelector("#mainCanva"),
        pixelArt: true,
        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 900 },
                debug: false
            }
        },
        scene: [scene, temple]
    };

    newConfig = config;

    game = new Phaser.Game(newConfig);

    game;
}

export function destroyGame() {
    game.destroy(true);
}