export const translate = [
    {
        name: "vida",
        ptBR: "vida",
        enUS: "health"
    },
    {
        name: "magia",
        ptBR: "magia",
        enUS: "magic"
    },
    {
        name: "energia",
        ptBR: "energia",
        enUS: "energy"
    },
    {
        name: "máximo",
        ptBR: "máximo",
        enUS: "health"
    },
    {
        name: "ataque",
        ptBR: "ataque",
        enUS: "attack"
    },
    {
        name: "força",
        ptBR: "força",
        enUS: "force"
    },
    {
        name: "ataque",
        ptBR: "ataque",
        enUS: "attack"
    },
    {
        name: "defesa",
        ptBR: "defesa",
        enUS: "defense"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "tempo de trajeto reduzido",
        ptBR: "Tempo de trajeto reduzido",
        enUS: "Reduce Time Travel"
    },
    {
        name: "poder mágico",
        ptBR: "Poder Mágico",
        enUS: "Magic Power"
    },
    {
        name: "nobreza",
        ptBR: "Nobreza",
        enUS: "Nobility"
    },
    {
        name: "regenera",
        ptBR: "Regenera",
        enUS: "Regenerate"
    },
    {
        name: "Feito por",
        ptBR: "Feito por",
        enUS: "Made by"
    },
    {
        name: "HP Máximo",
        ptBR: "HP Máximo",
        enUS: "Max Hp"
    },
    {
        name: "Magia Máxima",
        ptBR: "Magia Máxima",
        enUS: "Max Magic"
    },
    {
        name: "Energia Máxima",
        ptBR: "Energia Máxima",
        enUS: "Max Energy"
    },
    {
        name: "Sim",
        ptBR: "Sim",
        enUS: "yes"
    },
    {
        name: "Não",
        ptBR: "Não",
        enUS: "no"
    },
    {
        name: "Pagar para ir",
        ptBR: "Pagar para ir",
        enUS: "Pay to go"
    },
    {
        name: "Deseja viajar para",
        ptBR: "Deseja viajar para",
        enUS: "want to travel to"
    },
    {
        name: "Level minimo",
        ptBR: "Level minimo",
        enUS: "Min Level"
    },
    {
        name: "vila",
        ptBR: "vila",
        enUS: "Village"
    },
    {
        name: "cidade",
        ptBR: "cidade",
        enUS: "Cities"
    },
    {
        name: "ruinas",
        ptBR: "ruinas",
        enUS: "Ruins"
    },
    {
        name: "mina",
        ptBR: "mina",
        enUS: "Mines"
    },
    {
        name: "floresta",
        ptBR: "floresta",
        enUS: "Florest"
    },
    {
        name: "acampamento",
        ptBR: "acampamento",
        enUS: "camp"
    },
    {
        name: "Especial",
        ptBR: "Especial",
        enUS: "Especial"
    },
    {
        name: "Ponto Secreto",
        ptBR: "Local Secreto",
        enUS: "Secret Point"
    },
    {
        name: "SecretPoint",
        ptBR: "Ainda não construido",
        enUS: "Secret Point"
    },
    {
        name: "Pirâmides",
        ptBR: "Pirâmides",
        enUS: "piramyds"
    },
    {
        name: "Acampamento dos Bandidos",
        ptBR: "Acampamento dos Bandidos",
        enUS: "Bandit's Camp"
    },
    {
        name: "Acampamento Goblin",
        ptBR: "Acampamento Goblin",
        enUS: "Goblin Camp"
    },
    {
        name: "Holzfluss",
        ptBR: "Holzfluss",
        enUS: "Holzfluss"
    },
    {
        name: "Caverna Del Andes",
        ptBR: "Caverna Del Andes",
        enUS: "Del Andes"
    },
    {
        name: "Daimana",
        ptBR: "Daimana",
        enUS: "Daimana"
    },
    {
        name: "Minas Grethia",
        ptBR: "Minas Grethia",
        enUS: "Grethia Mines"
    },
    {
        name: "Minas Luthios",
        ptBR: "Minas Luthios",
        enUS: "Luthios Mines"
    },
    {
        name: "Ruinas Skull",
        ptBR: "Ruinas Skull",
        enUS: "Skull Ruins"
    },
    {
        name: "Pântano",
        ptBR: "Pântano",
        enUS: "swamp"
    },
    {
        name: "Cormiles",
        ptBR: "Cormiles",
        enUS: "Cormiles"
    },
    {
        name: "Azalarium",
        ptBR: "Azalarium",
        enUS: "Azalarium"
    },
    {
        name: "Avalon",
        ptBR: "Avalon",
        enUS: "Avalon"
    },
    {
        name: "Eisendorf",
        ptBR: "Eisendorf",
        enUS: "Eisendorf"
    },
    {
        name: "Mjölnir",
        ptBR: "Mjölnir",
        enUS: "Mjölnir"
    },
    {
        name: "Cidade sem nome",
        ptBR: "Cidade sem nome",
        enUS: "Unknown"
    },
    {
        name: "normal",
        ptBR: "normal",
        enUS: "normal"
    },
    {
        name: "melhorada",
        ptBR: "melhorada",
        enUS: "better"
    },
    {
        name: "perfeita",
        ptBR: "perfeita",
        enUS: "perfect"
    },
    {
        name: "rara",
        ptBR: "rara",
        enUS: "rare"
    },
    {
        name: "obraprima",
        ptBR: "obra-prima",
        enUS: "masterpiece"
    },
    {
        name: "lendario",
        ptBR: "lendário",
        enUS: "legendary"
    },
    {
        name: "leather helmet",
        ptBR: "Elmo de Couro",
        enUS: "leather helmet"
    },
    {
        name: "osso",
        ptBR: "osso",
        enUS: "bone"
    },
    {
        name: "Manopla de ferro",
        ptBR: "Manopla de ferro",
        enUS: "iron gaunlets"
    },
    {
        name: "Blueberrie",
        ptBR: "Blueberrie",
        enUS: "Blueberrie"
    },
    {
        name: "Botas do Mago Iniciante",
        ptBR: "Botas do Mago Iniciante",
        enUS: "Beginner Mage's Boots"
    },
    {
        name: "Receita Poção de Energia",
        ptBR: "Receita: Poção de Energia",
        enUS: "Recipe: Energy Potion"
    },
    {
        name: "large life potion",
        ptBR: "Poção de vida grande",
        enUS: "large life potion"
    },
    {
        name: "Master life potion",
        ptBR: "Poção de vida dos mestres",
        enUS: "Master life potion"
    },
    {
        name: "pó de múmia",
        ptBR: "pó de múmia",
        enUS: "mummy powder"
    },
    {
        name: "Licença para pescar",
        ptBR: "Licença para pescar",
        enUS: "License Fishing"
    },
    {
        name: "Crânio Nisiriano do Fogo Eterno (Secundária)",
        ptBR: "Crânio Nisiriano do Fogo Eterno",
        enUS: "Eternal Fire Nisirian Skull"
    },
    {
        name: "Large Mana potion",
        ptBR: "Grande poção de mana",
        enUS: "Large Mana potion"
    },
    {
        name: "large full potion",
        ptBR: "Grande Poção Completa",
        enUS: "large full potion"
    },
    {
        name: "Pergaminho dos Iniciantes no Fogo Eterno",
        ptBR: "Pergaminho dos Iniciantes no Fogo Eterno",
        enUS: "Eternal Fire Beginners Scroll"
    },
    {
        name: "jegue",
        ptBR: "jegue",
        enUS: "jegue"
    },
    {
        name: "tecido",
        ptBR: "tecido",
        enUS: "cloth"
    },
    {
        name: "life potion",
        ptBR: "Poção de vida",
        enUS: "life potion"
    },
    {
        name: "espada de ferro (Second)",
        ptBR: "espada de ferro (secundária)",
        enUS: "iron sword (second)"
    },
    {
        name: "ring of force",
        ptBR: "anel de força",
        enUS: "ring of force"
    },
    {
        name: "tabua",
        ptBR: "tabuá",
        enUS: "board"
    },
    {
        name: "Receita Bife de Javali",
        ptBR: "Receita: Bife de Javali",
        enUS: "recipe: Boar steak"
    },
    {
        name: "Peixe Lebarino",
        ptBR: "Peixe Lebarino",
        enUS: "Lebarino Fish"
    },
    {
        name: "carne de javali",
        ptBR: "carne de javali",
        enUS: "Boar steak"
    },
    {
        name: "Machado de Ferro (Secundária)",
        ptBR: "Machado de Ferro (Secundária)",
        enUS: "iron axe (second)"
    },
    {
        name: "Armadura de ferro",
        ptBR: "Armadura de ferro",
        enUS: "iron armor"
    },
    {
        name: "Receita Lingote de Ferro",
        ptBR: "Receita: Lingote de Ferro",
        enUS: "recipe: iron ingot"
    },
    {
        name: "Licença para Refinar",
        ptBR: "Licença para Refinar",
        enUS: "License to refine"
    },
    {
        name: "Receita Machado de Ferro (Secundario)",
        ptBR: "Receita: Machado de Ferro (Secundário)",
        enUS: "recipe: iron axe (second)"
    },
    {
        name: "arabic horse",
        ptBR: "Cavalo Árabe",
        enUS: "arabic horse"
    },
    {
        name: "spanish horse",
        ptBR: "Cavalo Espanhol",
        enUS: "spanish horse"
    },
    {
        name: "carriage",
        ptBR: "carruagem",
        enUS: "carriage"
    },
    {
        name: "tronco",
        ptBR: "tronco",
        enUS: "trunk"
    },
    {
        name: "espada de ferro (primária)",
        ptBR: "espada de ferro",
        enUS: "iron sword"
    },
    {
        name: "Machado de Ferro (Primária)",
        ptBR: "Machado de Ferro",
        enUS: "iron axe"
    },
    {
        name: "Cajado de fogo (Primária)",
        ptBR: "Cajado de fogo",
        enUS: "Fire Staff"
    },
    {
        name: "Receita Espada de Ferro (Primaria)",
        ptBR: "Receita: Espada de Ferro",
        enUS: "recipe: iron sword"
    },
    {
        name: "semente de feijão",
        ptBR: "semente de feijão",
        enUS: "bean seed"
    },
    {
        name: "Foice de ferro",
        ptBR: "Foice de ferro",
        enUS: "iron scythe"
    },
    {
        name: "Machado de ferro",
        ptBR: "Machado de ferro",
        enUS: "iron axe"
    },
    {
        name: "Medium Mana potion",
        ptBR: "Poção de mana média",
        enUS: "Medium Mana potion"
    },
    {
        name: "Licença para minerar",
        ptBR: "Licença para minerar",
        enUS: "License of miner"
    },
    {
        name: "Receita Madeira Tratada",
        ptBR: "Receita: Madeira Tratada",
        enUS: "Recipe: Treated Wood"
    },
    {
        name: "war horse",
        ptBR: "Cavalo de guerra",
        enUS: "war horse"
    },
    {
        name: "Elmo de ferro",
        ptBR: "Elmo de ferro",
        enUS: "iron helmet"
    },
    {
        name: "Pergaminho do fogo Infernal",
        ptBR: "Pergaminho do fogo Infernal",
        enUS: "Infernal Fire Scroll"
    },
    {
        name: "Mana potion",
        ptBR: "Poção de Mana",
        enUS: "Mana potion"
    },
    {
        name: "Chápeu do Mago Iniciante",
        ptBR: "Chápeu do Mago Iniciante",
        enUS: "Beginner's Hat"
    },
    {
        name: "Licença para Alquimista",
        ptBR: "Licença para Alquimista",
        enUS: "License for alchmist"
    },
    {
        name: "leather armor",
        ptBR: "armadura de couro",
        enUS: "leather armor"
    },
    {
        name: "gosma de slime",
        ptBR: "gosma de slime",
        enUS: "slime goo"
    },
    {
        name: "Licença para caçar animais",
        ptBR: "Licença para caçar animais",
        enUS: "license for hunt"
    },
    {
        name: "carne de cervo",
        ptBR: "carne de cervo",
        enUS: "deer meat"
    },
    {
        name: "pinha do pantano",
        ptBR: "pinha do pantâno",
        enUS: "swamp pine cone"
    },
    {
        name: "medium life potion",
        ptBR: "Poção de vida média",
        enUS: "medium life potion"
    },
    {
        name: "simple horse",
        ptBR: "cavalo",
        enUS: "simple horse"
    },
    {
        name: "energy potion",
        ptBR: "poção de energia",
        enUS: "energy potion"
    },
    {
        name: "morango",
        ptBR: "morango",
        enUS: "strawberry"
    },
    {
        name: "Botas de ferro",
        ptBR: "Botas de ferro",
        enUS: "iron boots"
    },
    {
        name: "big life potion",
        ptBR: "Grande poção de vida",
        enUS: "big life potion"
    },
    {
        name: "espada de bronze",
        ptBR: "espada de bronze",
        enUS: "bronze sword"
    },
    {
        name: "Peixe Luza",
        ptBR: "Peixe Luza",
        enUS: "luza fish"
    },
    {
        name: "simple bottle",
        ptBR: "garrafa de poção pequena",
        enUS: "simple bottle"
    },
    {
        name: "Licença para Cozinheiro",
        ptBR: "Licença para Cozinheiro",
        enUS: "license of cooking"
    },
    {
        name: "Receita Couro Tratado",
        ptBR: "Receita Couro Tratado",
        enUS: "recipe: Treated leather"
    },
    {
        name: "Licença para Ferreiro",
        ptBR: "Licença para Ferreiro",
        enUS: "license of smithing"
    },
    {
        name: "leather gauntlets",
        ptBR: "manopla de couro",
        enUS: "leather gauntlets"
    },
    {
        name: "leather boots",
        ptBR: "botas de couro",
        enUS: "leather boots"
    },
    {
        name: "carne podre",
        ptBR: "carne podre",
        enUS: "rotten meat"
    },
    {
        name: "pescado",
        ptBR: "pescado",
        enUS: "carp"
    },
    {
        name: "Peixe Obsidiana",
        ptBR: "Peixe Obsidiana",
        enUS: "obsidian fish"
    },
    {
        name: "Manopla do Mago Iniciante",
        ptBR: "Manopla do Mago Iniciante",
        enUS: "gaunlets of beginner mage"
    },
    {
        name: "prego",
        ptBR: "prego",
        enUS: "nail"
    },
    {
        name: "Receita Poção de vida",
        ptBR: "Receita: Poção de vida",
        enUS: "recipe: life potion"
    },
    {
        name: "Large energy potion",
        ptBR: "Grande poção de energia",
        enUS: "Large energy potion"
    },
    {
        name: "necklace of critical",
        ptBR: "colar do critico",
        enUS: "necklace of critical"
    },
    {
        name: "Armadura do Mago Iniciante",
        ptBR: "Roupa de mago iniciante",
        enUS: "beginner's mage robe"
    },
    {
        name: "ferro bruto",
        ptBR: "ferro bruto",
        enUS: "iron ore"
    },
    {
        name: "cobre bruto",
        ptBR: "cobre bruto",
        enUS: "cooper ore"
    },
    {
        name: "estanho bruto",
        ptBR: "estanho bruto",
        enUS: "tin ore"
    },
    {
        name: "Receita Escudo de Couro",
        ptBR: "Receita Escudo de Couro",
        enUS: "recipe: leather shield"
    },
    {
        name: "medium energy potion",
        ptBR: "Poção de energia média",
        enUS: "medium energy potion"
    },
    {
        name: "Indian Corsel Spirit",
        ptBR: "Corsel indomável",
        enUS: "Indian Corsel Spirit"
    },
    {
        name: "Licença para cortar madeira",
        ptBR: "Licença para cortar madeira",
        enUS: "license of woodcutting"
    },
    {
        name: "couro cru",
        ptBR: "couro cru",
        enUS: "raw leather"
    },
    {
        name: "Pergaminho Daemisse Corruption",
        ptBR: "Pergaminho corrupção Daemisse",
        enUS: "Daemisse Corruption"
    },
    {
        name: "full potion",
        ptBR: "poção completa",
        enUS: "full potion"
    },
    {
        name: "Receita Machado de Ferro (Primaria)",
        ptBR: "Receita: Machado de Ferro",
        enUS: "recipe: iron axe"
    },
    {
        name: "minério de ouro",
        ptBR: "minério de ouro",
        enUS: "gold ore"
    },
    {
        name: "pedra bruta",
        ptBR: "pedra bruta",
        enUS: "rock"
    },
    {
        name: "topázio bruto",
        ptBR: "topázio bruto",
        enUS: "rough topaz"
    },
    {
        name: "rubi bruto",
        ptBR: "rubi bruto",
        enUS: "rough ruby"
    },
    {
        name: "esmeralda bruta",
        ptBR: "esmeralda bruta",
        enUS: "rough esmerald"
    },
    {
        name: "diamante",
        ptBR: "diamante",
        enUS: "diamond"
    },
    {
        name: "minério de prata",
        ptBR: "minério de prata",
        enUS: "silver ore"
    },
    {
        name: "Receita armadura de ferro",
        ptBR: "Receita: armadura de ferro",
        enUS: "recipe: iron armor"
    },
    {
        name: "Receita manopla de ferro",
        ptBR: "Receita manopla de ferro",
        enUS: "recipe: iron gaunlets"
    },
    {
        name: "Receita elmo de ferro",
        ptBR: "Receita elmo de ferro",
        enUS: "recipe: iron helmet"
    },
    {
        name: "Receita botas de ferro",
        ptBR: "Receita botas de ferro",
        enUS: "recipe: iron boots"
    },
    {
        name: "Licença para Forjador de Armaduras",
        ptBR: "Licença para Forjador de Armaduras",
        enUS: "license for armor forge"
    },
    {
        name: "Receita Lingote de Prata",
        ptBR: "Receita: Lingote de Prata",
        enUS: "recipe: silver ore"
    },
    {
        name: "Receita Lingote de Ouro",
        ptBR: "Receita: Lingote de Ouro",
        enUS: "recipe: gold ore"
    },
    {
        name: "Licença para Joalheiro",
        ptBR: "Licença para Joalheiro",
        enUS: "license of jeweller"
    },
    {
        name: "Receita Rubi Lapidado",
        ptBR: "Receita: Rubi Lapidado",
        enUS: "recipe: Flawless ruby"
    },
    {
        name: "Receita Topázio Lapidado",
        ptBR: "Receita: Topázio Lapidado",
        enUS: "recipe: Flawless topaz"
    },
    {
        name: "Receita Esmeralda Lapidada",
        ptBR: "Receita: Esmeralda Lapidada",
        enUS: "recipe: Flawless esmerald"
    },
    {
        name: "Receita Anel de Prata com Rubi (Esquerdo)",
        ptBR: "Receita: Anel de Prata com Rubi (Esquerdo)",
        enUS: "recipe: silver ring with ruby (Left)"
    },
    {
        name: "Receita Anel de Ouro com Rubi (Direita)",
        ptBR: "Receita: Anel de Ouro com Rubi (Direita)",
        enUS: "recipe: gold ring with ruby (right)"
    },
    {
        name: "Receita Anel de Prata Simples",
        ptBR: "Receita: Anel de Prata",
        enUS: "recipe: silve ring"
    },
    {
        name: "Licença para Ferramenteiro",
        ptBR: "Licença para Ferramenteiro",
        enUS: "license of tool maker"
    },
    {
        name: "Receita Picareta de Ferro",
        ptBR: "Receita: Picareta de Ferro",
        enUS: "recipe: iron pickaxe"
    },
    {
        name: "lingote de bronze",
        ptBR: "lingote de bronze",
        enUS: "bronze ingot"
    },
    {
        name: "lingote de ferro",
        ptBR: "lingote de ferro",
        enUS: "iron ingot"
    },
    {
        name: "tronco reforcado",
        ptBR: "tronco reforcado",
        enUS: "reforced trunk"
    },
    {
        name: "iron arrow",
        ptBR: "flecha de ferro",
        enUS: "iron arrow"
    },
    {
        name: "simple bow",
        ptBR: "arco simples",
        enUS: "simple bow"
    },
    {
        name: "reinforced bow",
        ptBR: "arco reforçado",
        enUS: "reinforced bow"
    },
    {
        name: "recurve bow",
        ptBR: "arco recursivo",
        enUS: "recurve bow"
    },
    {
        name: "fragmento de alma",
        ptBR: "fragmento de alma",
        enUS: "soul fragment"
    },
    {
        name: "orb",
        ptBR: "orbe",
        enUS: "orb"
    },
    {
        name: "wodden shield",
        ptBR: "escudo de madeira",
        enUS: "wodden shield"
    },
    {
        name: "iron shield",
        ptBR: "escudo de ferro",
        enUS: "iron shield"
    },
    {
        name: "leather shield",
        ptBR: "escudo de couro",
        enUS: "leather shield"
    },
    {
        name: "rock lance",
        ptBR: "lança de pedra",
        enUS: "rock lance"
    },
    {
        name: "iron lance",
        ptBR: "lança de ferro",
        enUS: "iron lance"
    },
    {
        name: "steel lance",
        ptBR: "lança de aço",
        enUS: "steel lance"
    },
    {
        name: "Botas Imperiais",
        ptBR: "Botas Imperiais",
        enUS: "imperial boots"
    },
    {
        name: "Elmo Imperial",
        ptBR: "Elmo Imperial",
        enUS: "imperial helmet"
    },
    {
        name: "Armadura Imperial",
        ptBR: "Armadura Imperial",
        enUS: "imperial armor"
    },
    {
        name: "Manopla Imperial",
        ptBR: "Manopla Imperial",
        enUS: "imperial gaunlets"
    },
    {
        name: "Espada Imperial",
        ptBR: "Espada Imperial",
        enUS: "imperial sword"
    },
    {
        name: "aprendiz de ferreiro",
        ptBR: "aprendiz de ferreiro",
        enUS: "Blacksmith's apprentice"
    },
    {
        name: "Seu primeiro nível, agora você se tornou um aprendiz de Ferreiro",
        ptBR: "Seu primeiro nível, agora você se tornou um aprendiz de Ferreiro",
        enUS: "Your first level, now you've become a blacksmith's apprentice!"
    },
    {
        name: "primeira poção",
        ptBR: "primeira poção",
        enUS: "First potion"
    },
    {
        name: "Seu primeiro nível, agora você se tornou um aprendiz de Alquimista",
        ptBR: "Seu primeiro nível, agora você se tornou um aprendiz de Alquimista",
        enUS: "Your first level, now you've become a alchemist's apprentice!"
    },
    {
        name: "descascador de batatas",
        ptBR: "descascador de batatas",
        enUS: "Potato peeler"
    },
    {
        name: "Seu primeiro nível, agora você se tornou um aprendiz de cozinheiro",
        ptBR: "Seu primeiro nível, agora você se tornou um aprendiz de cozinheiro",
        enUS: "Your first level, now you've become a cooker's apprentice!"
    },
    {
        name: "Menor custo",
        ptBR: "Menor custo",
        enUS: "Lower cost"
    },
    {
        name: "Menos produtos serão usados para fazer a mesma arma",
        ptBR: "Menos produtos serão usados para fazer a mesma arma",
        enUS: "Fewer products will be used to make the same weapon"
    },
    {
        name: "Ferreiro Nobre",
        ptBR: "Ferreiro Nobre",
        enUS: "Noble Blacksmith"
    },
    {
        name: "Aumenta a possibilidade de um item de qualidade superior",
        ptBR: "Aumenta a possibilidade de um item de qualidade superior",
        enUS: "Increases the possibility of a higher quality item"
    },
    {
        name: "espremendo tudo",
        ptBR: "espremendo tudo",
        enUS: "Squeezing everything"
    },
    {
        name: "Quanto mais ingredientes, melhor fica a poção",
        ptBR: "Quanto mais ingredientes, melhor fica a poção",
        enUS: "The more ingredients, the better the potion"
    },
    {
        name: "ossos de esqueletos",
        ptBR: "ossos de esqueletos",
        enUS: "Skeleton bones"
    },
    {
        name: "Use ossos em armas para aumentar seu dano ou em escudos para aumentar a defesa",
        ptBR: "Use ossos em armas para aumentar seu dano ou em escudos para aumentar a defesa",
        enUS: "Use bones on weapons to increase your damage or on shields to increase defense"
    },
    {
        name: "pinha do pantano",
        ptBR: "pinha do pantano",
        enUS: "Swamp pine cone"
    },
    {
        name: "Use pinhas do pantano para adicionar ou melhorar a recuperação de mp",
        ptBR: "Use pinhas do pantano para adicionar ou melhorar a recuperação de mp",
        enUS: "Use swamp pine cones to add or improve MP recovery"
    },
    {
        name: "agora sei o que isso faz",
        ptBR: "agora sei o que isso faz",
        enUS: "Now i know what it does"
    },
    {
        name: "Agora você possui melhores formas de aproveitar os materiais",
        ptBR: "Agora você possui melhores formas de aproveitar os materiais",
        enUS: "Now you have better options to take use of the materials"
    },
    {
        name: "chifre de cervo",
        ptBR: "chifre de cervo",
        enUS: "Deer antler"
    },
    {
        name: "Use chifres de cervo para aumentar seu dano em armas ou em escudos para aumentar a defesa",
        ptBR: "Use chifres de cervo para aumentar seu dano em armas ou em escudos para aumentar a defesa",
        enUS: "Use deer antlers to increase your weapon damage or shields to increase defense"
    },
    {
        name: "compactando materiais",
        ptBR: "compactando materiais",
        enUS: "Compacting materials"
    },
    {
        name: "Agora você aprendeu a usar melhor o espaço e assim, mas materiais",
        ptBR: "Agora você aprendeu a usar melhor o espaço e assim, mas materiais",
        enUS: "Now you have learned to use space better and thus more materials"
    },
    {
        name: "aprendendo a refinar",
        ptBR: "aprendendo a refinar",
        enUS: "Learning to refine"
    },
    {
        name: "De um boost na sua aprendizagem como refinador",
        ptBR: "De um boost na sua aprendizagem como refinador",
        enUS: "Boost your learning as a refiner"
    },
    {
        name: "pó de mumia",
        ptBR: "pó de mumia",
        enUS: "Mummy powder"
    },
    {
        name: "Adicione pó de múmia nas suas jóias e as deixe mais 'mágicas'",
        ptBR: "Adicione pó de múmia nas suas jóias e as deixe mais 'mágicas'",
        enUS: "Add mummy powder to your jewelry and make it more 'magical'"
    },
    {
        name: "Triple fire",
        ptBR: "Tiro triplo",
        enUS: "Triple fire"
    },
    {
        name: "Atire três flechas ao invês de uma",
        ptBR: "Atire três flechas ao invês de uma",
        enUS: "Shoot three arrows instead of one"
    },
    {
        name: "Fire arrow",
        ptBR: "Tiro em chamas",
        enUS: "Fire arrow"
    },
    {
        name: "Atire uma flecha de fogo no inimigo",
        ptBR: "Atire uma flecha de fogo no inimigo",
        enUS: "Shoot a fire arrow at the enemy"
    },
    {
        name: "Triple ball",
        ptBR: "Bolas de fogo",
        enUS: "Triple ball"
    },
    {
        name: "Atire 3 bolas de fogo seguidas",
        ptBR: "Shoot 3 fireballs in a row",
        enUS: "Atire 3 bolas de fogo seguidas"
    },
    {
        name: "Discipulo da espada",
        ptBR: "Discipulo da espada",
        enUS: "Disciple of the sword"
    },
    {
        name: "Seja abençoado com a agilidade de um aprendiz",
        ptBR: "Seja abençoado com a agilidade de um aprendiz",
        enUS: "Be blessed with the agility of an apprentice"
    },
    {
        name: "Confinça nas mãos",
        ptBR: "confiança nas mãos",
        enUS: "Trust in hands"
    },
    {
        name: "Depois de matar mais alguns slimes você aprendeu a ter mais confiança em sua espada e agora se sente mais forte",
        ptBR: "Depois de matar mais alguns slimes você aprendeu a ter mais confiança em sua espada e agora se sente mais forte",
        enUS: "After killing a few more slimes you learned to have more confidence in your sword and now you feel stronger"
    },
    {
        name: "arqueiro novato",
        ptBR: "arqueiro novato",
        enUS: "Novice archer"
    },
    {
        name: "Mais energia e percepção, após uma longa jornada, você pode ser considerado um novato",
        ptBR: "Mais energia e percepção, após uma longa jornada, você pode ser considerado um novato",
        enUS: "More energy and perception, after a long journey, you can be considered a novice"
    },
    {
        name: "corra por sua vida",
        ptBR: "corra por sua vida",
        enUS: "Run for your life"
    },
    {
        name: "o melhor arqueiro não é aquele que mata mais rápido, mas sim aquele que corre mais rápido - Reis, Alan dos",
        ptBR: "o melhor arqueiro não é aquele que mata mais rápido, mas sim aquele que corre mais rápido - Reis, Alan dos",
        enUS: "The best archer is not the one who kills the fastest, but the one who runs the fastest - Reis, Alan dos"
    },
    {
        name: "aprendiz de feiticeiro",
        ptBR: "aprendiz de feiticeiro",
        enUS: "Sorcerer's apprentice"
    },
    {
        name: "Após muitas fireballs você está pronto para ser considerado um... Aprendiz",
        ptBR: "Após muitas fireballs você está pronto para ser considerado um... Aprendiz",
        enUS: "After many fireballs you are ready to be considered an... Apprentice"
    },
    {
        name: "controle de energia",
        ptBR: "controle de energia",
        enUS: "Energy control"
    },
    {
        name: "Agora você possui um melhor controle da sua energia, aumentando seu poder mágico e quanto de magia seu corpo aguenta",
        ptBR: "Agora você possui um melhor controle da sua energia, aumentando seu poder mágico e quanto de magia seu corpo aguenta",
        enUS: "You now have better control of your energy, increasing your magic power and how much magic your body can handle!"
    },
    {
        name: "guarda de si mesmo",
        ptBR: "guarda de si mesmo",
        enUS: "The guard of yourself"
    },
    {
        name: "Parabéns, agora você usa uma lança como um caçador, já é um começo",
        ptBR: "Parabéns, agora você usa uma lança como um caçador, já é um começo",
        enUS: "Congratulations, you now wield a spear like a hunter, that's a start!"
    },
    {
        name: "defender sempre, atacar talvez",
        ptBR: "defender sempre, atacar talvez",
        enUS: "Always defend and maybe attack"
    },
    {
        name: "Defesa é um crucial ponto, pois não adianta de nada dar um dano absurdo e morrer com 1 soco",
        ptBR: "Defesa é um crucial ponto, pois não adianta de nada dar um dano absurdo e morrer com 1 soco",
        enUS: "Defense is a crucial point, as there is no point in dealing absurd damage and dying with 1 punch"
    },
    {
        name: "Qual caminho deseja seguir?",
        ptBR: "Qual caminho deseja seguir?",
        enUS: "Which path do you want to follow?"
    },
    {
        name: "Foco em energia e ataque (+5 Energia)",
        ptBR: "Foco em energia e ataque (+5 Energia)",
        enUS: "Focus on Energy and Attack (+5 Energy)"
    },
    {
        name: "Foco em MP e Poder Mágico (+5 Magia)",
        ptBR: "Foco em MP e Poder Mágico (+5 Magia)",
        enUS: "Focus on MP and Magic power (+5 Magic)"
    },
    {
        name: "Foco em defesa e vida (+1 Defesa)",
        ptBR: "Foco em defesa e vida (+1 Defesa)",
        enUS: "Focus on Defense and Health (+1 Defense)"
    },
    {
        name: "Foco em critico e dano (+2 Critico)",
        ptBR: "Foco em critico e dano (+2 Critico)",
        enUS: "Focus on Crit and Damage (+2 Crit)"
    },
    {
        name: "Uma vez humano, sempre humano (+1 Carisma, +20 Estamina, +20 Magia, +20 HP)",
        ptBR: "Uma vez humano, sempre humano (+1 Carisma, +20 Estamina, +20 Magia, +20 HP)",
        enUS: "Once human, always human (+1 Charisma, +20 Stamina, +20 Magic, +20 HP)"
    },
    {
        name: "O povo mais antigo do universo, diria que a maioria das especies inteligentes do universo vieram deles (+25 Magia, +2 Força, +2 Critico, +15 HP e +15 Estamina)",
        ptBR: "O povo mais antigo do universo, diria que a maioria das especies inteligentes do universo vieram deles (+25 Magia, +2 Força, +2 Critico, +15 HP e +15 Estamina)",
        enUS: "The oldest people in the universe, I would say that most intelligent species in the universe descended from them (+25 Magic, +2 Strength, +2 Critical, +15 HP and +15 Stamina)"
    },
    {
        name: "Um dos povos mais poderosos do Universo, a maior defesa da Triade Nisiriana (+4 Força, +1 Defesa, +35 HP e +25 Estamina)",
        ptBR: "Um dos povos mais poderosos do Universo, a maior defesa da Triade Nisiriana (+4 Força, +1 Defesa, +35 HP e +25 Estamina)",
        enUS: "One of the most powerful people in the Universe, the highest defense of the Nisirian Triad (+4 Strength, +1 Defense, +35 HP and +25 Stamina)"
    },
    {
        name: "Venha em busca da dominação total do universo começando com as raças inferiores (+2 Negociação, +60 Magia e +5 Poder Mágico)",
        ptBR: "Venha em busca da dominação total do universo começando com as raças inferiores (+2 Negociação, +60 Magia e +5 Poder Mágico)",
        enUS: "In pursuit of total domination of the universe starting with the inferior races (+2 Negotiation, +60 Magic and +5 Spell Power)"
    },
    {
        name: "A adaptação e inteligencia são as maiores fontes de poder que uma Triade poderia ter, o povo do computador de Orion (+3 Defesa, +2 Carisma, +25 Estamina, +1 Negociação e -5% de TimeTravel)",
        ptBR: "A adaptação e inteligencia são as maiores fontes de poder que uma Triade poderia ter, o povo do computador de Orion (+3 Defesa, +2 Carisma, +25 Estamina, +1 Negociação e -5% de TimeTravel)",
        enUS: "Adaptation and intelligence are the greatest sources of power a Triad could have, Orion's computer people (+3 Defense, +2 Charisma, +25 Stamina, +1 Negotiation and -5% TimeTravel)"
    },
    {
        name: "Uma das raças mais antigas a explorar a magia e suas utilizações (+90 Magia e +25 Poder Mágico)",
        ptBR: "Uma das raças mais antigas a explorar a magia e suas utilizações (+90 Magia e +25 Poder Mágico)",
        enUS: "One of the oldest races to explore magic and its uses (+90 Magic and +25 Spell Power)"
    },
    {
        name: "Ligeiros, os Ignis são perfeitos como assassinos e mercenários (+5 Critico, +20 Estamina e +1 Força)",
        ptBR: "Ligeiros, os Ignis são perfeitos como assassinos e mercenários (+5 Critico, +20 Estamina e +1 Força)",
        enUS: "Agile, the Ignis are perfect as assassins and mercenaries (+5 Critical, +20 Stamina, and +1 Strength"
    },
    {
        name: "Uriun Supremo é um explorador nato, usado em colonização e terraformação de planetas, ele geralmente é o ser lógico das operações (+30 Magia, +20 Estamina, +1 Força, +1 Defesa, +10 HP, +1 Negociação e -5% TimeTravel)",
        ptBR: "Uriun Supremo é um explorador nato, usado em colonização e terraformação de planetas, ele geralmente é o ser lógico das operações (+30 Magia, +20 Estamina, +1 Força, +1 Defesa, +10 HP, +1 Negociação e -5% TimeTravel)",
        enUS: "Supreme Uriun is a born explorer, used in planet colonization and terraforming, he is usually the logical being of operations (+30 Magic, +20 Stamina, +1 Strength, +1 Defense, +10 HP, +1 Negotiation and -5 % TimeTravel)"
    },
    {
        name: "Qual sua aspiração?",
        ptBR: "Qual sua aspiração?",
        enUS: "What is your aspiration?"
    },
    {
        name: "Lutas",
        ptBR: "Lutas",
        enUS: "Fights"
    },
    {
        name: "Lutador desde criança (+1 defesa e +5 Estamina)",
        ptBR: "Lutador desde criança (+1 defesa e +5 Estamina)",
        enUS: "Fighter since childhood (+1 Defense and +5 Stamina)"
    },
    {
        name: "Ciências",
        ptBR: "Ciências",
        enUS: "Science"
    },
    {
        name: "Jovem cientista (+10 Magia)",
        ptBR: "Jovem cientista (+10 Magia)",
        enUS: "Young scientist (+10 Magic)"
    },
    {
        name: "Ninjas",
        ptBR: "Ninjas",
        enUS: "Ninja"
    },
    {
        name: "Sempre quis ser ninja (+1 Critico e +1 Agilidade)",
        ptBR: "Sempre quis ser ninja (+1 Critico e +1 Agilidade)",
        enUS: "Always wanted to be a ninja (+1 Critical and +1 Agility)"
    },
    {
        name: "Policiais",
        ptBR: "Policiais",
        enUS: "Police"
    },
    {
        name: "Quero defender os inocentes (+1 Força e +1 defesa)",
        ptBR: "Quero defender os inocentes (+1 Força e +1 defesa)",
        enUS: "You want to protect the innocent (+1 Strength and +1 Defense)"
    },
    {
        name: "Explorar",
        ptBR: "Explorar",
        enUS: "Explore"
    },
    {
        name: "Explorar e descobrir novas coisas (+5 Stamina e -5% Gasto de energia ao viajar)",
        ptBR: "Explorar e descobrir novas coisas (+5 Stamina e -5% Gasto de energia ao viajar)",
        enUS: "You like to explore and discover new things (+5 Stamina and -5% Energy spent when traveling)"
    },
    {
        name: "Trabalhar",
        ptBR: "Trabalhar",
        enUS: "Work"
    },
    {
        name: "Tive de trabalhar desde pequeno (+15 Estamina)",
        ptBR: "Tive de trabalhar desde pequeno (+15 Estamina)",
        enUS: "You had to work from a young age (+15 Stamina)"
    },
    {
        name: "Qual foi sua carreira?",
        ptBR: "Qual foi sua carreira?",
        enUS: "What was your career?"
    },
    {
        name: "Comerciante",
        ptBR: "Comerciante",
        enUS: "Merchant"
    },
    {
        name: "Fui um comerciante bem sucedido (+2 Negociação e +1 Carisma)",
        ptBR: "Fui um comerciante bem sucedido (+2 Negociação e +1 Carisma)",
        enUS: "You were a successful merchant (+2 Negotiation and +1 Charisma)"
    },
    {
        name: "Policial",
        ptBR: "Policial",
        enUS: "Police officer"
    },
    {
        name: "Meu sonho virou minha realidade (+1 defesa e +20 HP)",
        ptBR: "Meu sonho virou minha realidade (+1 defesa e +20 HP)",
        enUS: "My dream became my reality (+1 defense and +20 HP)"
    },
    {
        name: "Assassino",
        ptBR: "Assassino",
        enUS: "Assassin"
    },
    {
        name: "A vida como um todo vale aquilo que me pagam (+2 Critico, -1 Carisma e +2 Agilidade)",
        ptBR: "A vida como um todo vale aquilo que me pagam (+2 Critico, -1 Carisma e +2 Agilidade)",
        enUS: "Life as a whole is worth what they pay me (+2 Critical, -1 Charisma and +2 Agility)"
    },
    {
        name: "Médico",
        ptBR: "Médico",
        enUS: "Medic"
    },
    {
        name: "Salvar vidas é meu caminho (+25 HP)",
        ptBR: "Salvar vidas é meu caminho (+25 HP)",
        enUS: "Saving lives is my path (+25 HP)"
    },
    {
        name: "Quantos anos você tinha?",
        ptBR: "Quantos anos você tinha?",
        enUS: "How old were you?"
    },
    {
        name: "Muito jovem",
        ptBR: "Muito jovem",
        enUS: "Very young"
    },
    {
        name: "Jovem",
        ptBR: "Jovem",
        enUS: "Young"
    },
    {
        name: "Muito novo (+50 Estamina e -50 Magia)",
        ptBR: "Muito novo (+50 Estamina e -50 Magia)",
        enUS: "Very young (+50 Stamina and -50 Magic)"
    },
    {
        name: "Começando a vida (+35 Estamina e -30 Magia)",
        ptBR: "Começando a vida (+35 Estamina e -30 Magia)",
        enUS: "Just starting life (+35 Stamina and -30 Magic)"
    },
    {
        name: "Jovem Adulto",
        ptBR: "Jovem Adulto",
        enUS: "Young adult"
    },
    {
        name: "Adulto e responsável (+10 Estamina, +10 Magia e +1 Força)",
        ptBR: "Adulto e responsável (+10 Estamina, +10 Magia e +1 Força)",
        enUS: "Adult and responsible (+10 Stamina, +10 Magic and +1 Strength)"
    },
    {
        name: "Velho",
        ptBR: "Velho",
        enUS: "Old"
    },
    {
        name: "Foi sem se aposentar (-25 Estamina e +25 Magia e +10 Poder Mágico)",
        ptBR: "Foi sem se aposentar (-25 Estamina e +25 Magia e +10 Poder Mágico)",
        enUS: "Passed away without retiring (-25 Stamina and +25 Magic and +10 Spell Power)"
    },
    {
        name: "Muito Velho",
        ptBR: "Muito Velho",
        enUS: "Very old"
    },
    {
        name: "Velho e cansado (-50 Estamina, +50 Magia +20 Poder Mágico)",
        ptBR: "Velho e cansado (-50 Estamina, +50 Magia +20 Poder Mágico)",
        enUS: "Old and tired (-50 Stamina, +50 Magic +20 Spell Power)"
    },
    {
        name: "Como você veio para cá?",
        ptBR: "Como você veio para cá?",
        enUS: "How did you get here?"
    },
    {
        name: "Guerra",
        ptBR: "Guerra",
        enUS: "Warfare"
    },
    {
        name: "Com honra e glória (+3 força e +1 defesa)",
        ptBR: "Com honra e glória (+3 força e +1 defesa)",
        enUS: "With honor and glory (+3 strength and +1 defense)"
    },
    {
        name: "Fome",
        ptBR: "Fome",
        enUS: "Starvation"
    },
    {
        name: "Situação complicada, sem dinheiro ou comida (-20 Estamina, +3 Critico, -1 Força e +2 Agilidade)",
        ptBR: "Situação complicada, sem dinheiro ou comida (-20 Estamina, +3 Critico, -1 Força e +2 Agilidade)",
        enUS: "Tough situation, no money or food (-20 Stamina, +3 Critical, -1 Strength and +2 Agility)"
    },
    {
        name: "Traido",
        ptBR: "Traido",
        enUS: "Betrayed"
    },
    {
        name: "Traido por quem mais confiava (-2 carisma, +1 Critico, +2 defesa e +1 Agilidade)",
        ptBR: "Traido por quem mais confiava (-2 carisma, +1 Critico, +2 defesa e +1 Agilidade)",
        enUS: "Betrayed by those you trusted most (-2 Charisma, +1 Critical, +2 Defense, and +1 Agility)"
    },
    {
        name: "Acidente",
        ptBR: "Acidente",
        enUS: "Accident"
    },
    {
        name: "Um acidente bobo, mas no fim, mortal (-1 defesa, -25 HP, +3 Critico e +3 Agilidade)",
        ptBR: "Um acidente bobo, mas no fim, mortal (-1 defesa, -25 HP, +3 Critico e +3 Agilidade)",
        enUS: "A silly accident, but in the end, deadly (-1 defense, -25 HP, +3 Critical and +3 Agility)"
    },
    {
        name: "Velhice",
        ptBR: "Velhice",
        enUS: "Old age"
    },
    {
        name: "Quando a natureza chamou, eu decidi ir (-20 Estamina, +40 Magia)",
        ptBR: "Quando a natureza chamou, eu decidi ir (-20 Estamina, +40 Magia)",
        enUS: "When nature called, I decided to go (-20 Stamina, +40 Magic)"
    },
    {
        name: "Saqueado",
        ptBR: "Saqueado",
        enUS: "Looted"
    },
    {
        name: "Odeio saqueadores, os destruirei (-30 HP, +3 Defesa, +2 Critico e +2 Força)",
        ptBR: "Odeio saqueadores, os destruirei (-30 HP, +3 Defesa, +2 Critico e +2 Força)",
        enUS: "I hate marauders, I will destroy them all (-30 HP, +3 Defense, +2 Critical and +2 Strength)"
    },
    {
        name: "Fale logo o que quer, que te dou o que precisa",
        ptBR: "Fale logo o que quer, que te dou o que precisa",
        enUS: "Just say what you want. I have what you need."
    },
    {
        name: "Vim barganhar",
        ptBR: "Vim barganhar",
        enUS: "I came to bargain"
    },
    {
        name: "Preciso de equipamento novo, disseram que você é o cara para me ajudar nisso",
        ptBR: "Preciso de equipamento novo, disseram que você é o cara para me ajudar nisso",
        enUS: "I need new equipment, they said you are the right person who can help me with that"
    },
    {
        name: "Só queria puxar papo mesmo, disseram que você é uma pessoa legal",
        ptBR: "Só queria puxar papo mesmo, disseram que você é uma pessoa legal",
        enUS: "Just wanted to chat with you, they said you're a really nice person"
    },
    {
        name: "Nada não, valeu",
        ptBR: "Nada não, valeu",
        enUS: "Nevermind, thanks"
    },
    {
        name: "Então veio ao lugar errado, aqui não tem descontos, ou respeita meus preços, ou cai fora",
        ptBR: "Então veio ao lugar errado, aqui não tem descontos, ou respeita meus preços, ou cai fora",
        enUS: "Then you've come to the wrong place, there are no discounts here, either respect my prices, or get out"
    },
    {
        name: "Me desculpe, não quis ofende-lo, só quero comprar equipamento",
        ptBR: "Me desculpe, não quis ofende-lo, só quero comprar equipamento",
        enUS: "I'm sorry, I didn't mean to offend you, I just want to buy new equipment"
    },
    {
        name: "Ih, já to vendo que é papo chato, tchau",
        ptBR: "Ih, já to vendo que é papo chato, tchau",
        enUS: "Hmm, I can already see that this is a boring talk, bye"
    },
    {
        name: "Não estou com paciência para conversar, preciso trabalhar e colocar comida na mesa",
        ptBR: "Não estou com paciência para conversar, preciso trabalhar e colocar comida na mesa",
        enUS: "I don't have patience to talk. I need to work and put food on the table"
    },
    {
        name: "Desculpe, só queria puxar papo, quero comprar equipamento",
        ptBR: "Desculpe, só queria puxar papo, quero comprar equipamento",
        enUS: "I'm sorry, just wanted to have a chat. Actually I want to buy equipment"
    },
    {
        name: "Desculpe, vou indo embora",
        ptBR: "Desculpe, vou indo embora",
        enUS: "Sorry, I'm leaving"
    },
    {
        name: "Você é grosso em, vai se ferrar",
        ptBR: "Você é grosso em, vai se ferrar",
        enUS: "You're rude, huh? Screw you"
    },
    {
        name: "Você não aguenta 10 minutos de porrada comigo, vai encarar?",
        ptBR: "Você não aguenta 10 minutos de porrada comigo, vai encarar?",
        enUS: "You can't stand 10 minutes fighting me. Want to see for yourself?"
    },
    {
        name: "Que isso professor, to tranquilo",
        ptBR: "Que isso professor, to tranquilo",
        enUS: "Calm down, teacher. I'm fine"
    },
    {
        name: "Tem sorte que sou da paz",
        ptBR: "Tem sorte que sou da paz",
        enUS: "You're lucky that I'm chill today"
    },
    {
        name: "Vai comprar ou não?",
        ptBR: "Vai comprar ou não?",
        enUS: "Are you buying or not?"
    },
    {
        name: "Vou sim, mostre-me o que tem",
        ptBR: "Vou sim, mostre-me o que tem",
        enUS: "Yes, I will. Show me what you've got"
    },
    {
        name: "Depois de tudo isso, to fora",
        ptBR: "Depois de tudo isso, to fora",
        enUS: "After all that, I'm out"
    },
    {
        name: "Tudo bem, desculpe, ando estressado ultimamente",
        ptBR: "Tudo bem, desculpe, ando estressado ultimamente",
        enUS: "Okay, sorry, I've been stressed lately"
    },
    {
        name: "Sem problemas, podemos voltar aos negócios?",
        ptBR: "Sem problemas, podemos voltar aos negócios?",
        enUS: "No problem, can we get back to business?"
    },
    {
        name: "Ih, já to vendo que é papo chato, tchau",
        ptBR: "Ih, já to vendo que é papo chato, tchau",
        enUS: "Mmm, I can already see that this is a boring talk, bye"
    },
    {
        name: "Tudo bem então, obrigado e volte... Sempre?",
        ptBR: "Tudo bem então, obrigado e volte... Sempre?",
        enUS: "Alright then, thanks and come back... Again?"
    },
    {
        name: "Tchau (Sair)",
        ptBR: "Tchau (Sair)",
        enUS: "Bye (Leave)"
    },
    {
        name: "Saia daqui, por favor, tenho muito trabalho para fazer",
        ptBR: "Saia daqui, por favor, tenho muito trabalho para fazer",
        enUS: "Get out of here please, I have a lot of work to do"
    },
    {
        name: "Tudo bem, tchau (Sair)",
        ptBR: "Tudo bem, tchau (Sair)",
        enUS: "Alright, bye (Leave)"
    },
    {
        name: "Que dia lindo que está hoje, mas pode ficar melhor com esse garanhão aqui",
        ptBR: "Que dia lindo que está hoje, mas pode ficar melhor com esse garanhão aqui",
        enUS: "What a beautiful day it is today, but it could be better with this stallion here"
    },
    {
        name: "Cavalo bonito esse, me interessei bastante",
        ptBR: "Cavalo bonito esse, me interessei bastante",
        enUS: "Beautiful horse, I'm very interested"
    },
    {
        name: "Não sei, não conheço muito sobre cavalos",
        ptBR: "Não sei, não conheço muito sobre cavalos",
        enUS: "I can't say, I don't know much about horses"
    },
    {
        name: "Ué, você não deveria estar na India?",
        ptBR: "Ué, você não deveria estar na India?",
        enUS: "Hey, shouldn't you be in India?"
    },
    {
        name: "Nada não, valeu",
        ptBR: "Nada não, valeu",
        enUS: "Nevermind, thanks"
    },
    {
        name: "Então venha fechar, tenho certeza que não vai se arrepender",
        ptBR: "Então venha fechar, tenho certeza que não vai se arrepender",
        enUS: "So let's make a deal, I'm sure you won't regret it!"
    },
    {
        name: "Me mostre o que tem",
        ptBR: "Me mostre o que tem",
        enUS: "Show me what you got"
    },
    {
        name: "Vendo mais de perto, acho que não gostei não, obrigado",
        ptBR: "Vendo mais de perto, acho que não gostei não, obrigado",
        enUS: "Now that I'm looking closer, I don't think I like it..."
    },
    {
        name: "Não tem problema, eu posso te apresentar os melhores cavalos da região e mostrar o que cada um deles tem de melhor",
        ptBR: "Não tem problema, eu posso te apresentar os melhores cavalos da região e mostrar o que cada um deles tem de melhor",
        enUS: "No problem! I can introduce you to the best horses in the region and show you the best of each one of them"
    },
    {
        name: "Bom, olhar não mata, vamos ver o que você tem",
        ptBR: "Bom, olhar não mata, vamos ver o que você tem",
        enUS: "Well, taking a look doesn't kill so let's see what you've got!"
    },
    {
        name: "Oh, você não é aquele cara que ia para a India e errou o caminho?",
        ptBR: "Oh, você não é aquele cara que ia para a India e errou o caminho?",
        enUS: "Oh, aren't you that guy who was going to India and got the wrong way?"
    },
    {
        name: "Não estou pronto ainda, vou esperar",
        ptBR: "Não estou pronto ainda, vou esperar",
        enUS: "I'm not ready yet, I'll wait"
    },
    {
        name: "É sério, de novo isso? Eu já disse que não errei o caminho, foi tudo planejado",
        ptBR: "É sério, de novo isso? Eu já disse que não errei o caminho, foi tudo planejado",
        enUS: "Seriously, this again? I already said that I didn't go the wrong way, it was all planned"
    },
    {
        name: "Sei sei, conta outra",
        ptBR: "Sei sei, conta outra",
        enUS: "Come on, tell me another one"
    },
    {
        name: "Você não conhece os mapas da fonte da juventude?",
        ptBR: "Você não conhece os mapas da fonte da juventude?",
        enUS: "Don't you happen to know the maps of the fountain of youth?"
    },
    {
        name: "Conheço, mas ela não existe",
        ptBR: "Conheço, mas ela não existe",
        enUS: "I know, but it doesn't exist"
    },
    {
        name: "Como você é tolo, ela existe sim, e eu estive lá, e graças a ela, nossa ordem vive forte e sádia, dominante pontos que você nunca conheceu, pois na verdade, eles não são 'REAIS'",
        ptBR: "Como você é tolo, ela existe sim, e eu estive lá, e graças a ela, nossa ordem vive forte e sádia, dominante pontos que você nunca conheceu, pois na verdade, eles não são 'REAIS'",
        enUS: "How foolish you are, it does exist, and I was there, and thanks to it, our order lives strong and healthy, dominating points that you never knew, because in fact, they are not 'REAL'"
    },
    {
        name: "Como assim?",
        ptBR: "Como assim?",
        enUS: "What do you mean?"
    },
    {
        name: "Não posso contar muito mais, se quiser, vai ter de descobrir por si só",
        ptBR: "Não posso contar muito mais, se quiser, vai ter de descobrir por si só",
        enUS: "I can't tell you much more, if you want, you'll have to find out for yourself"
    },
    {
        name: "Como?",
        ptBR: "Como?",
        enUS: "How?"
    },
    {
        name: "Quando o sistema de quests for finalizado, eu te conto",
        ptBR: "Quando o sistema de quests for finalizado, eu te conto",
        enUS: "When the quest system is finalized, I'll let you know"
    },
    {
        name: "Merda, vou ter de esperar",
        ptBR: "Merda, vou ter de esperar",
        enUS: "Shit, I'll have to wait"
    },
    {
        name: "Vamos recomeçar...",
        ptBR: "Vamos recomeçar...",
        enUS: "Lets start all over again..."
    },
    {
        name: "Ok",
        ptBR: "Ok",
        enUS: "Ok"
    },
    {
        name: "Bom, então nos vemos na próxima",
        ptBR: "Bom, então nos vemos na próxima",
        enUS: "Well then, see you next time!"
    },
    {
        name: "Tudo bem, tchau (Sair)",
        ptBR: "Tudo bem, tchau (Sair)",
        enUS: "Alright, bye (Leave)"
    },
    {
        name: "Olá amigo, dia bonito para derrubar o capitalismo... Digo, essa árvore",
        ptBR: "Olá amigo, dia bonito para derrubar o capitalismo... Digo, essa árvore",
        enUS: "Hello Comrade, beautiful day to cut down capitalism... I mean, this tree"
    },
    {
        name: "??????? Ok...",
        ptBR: "??????? Ok...",
        enUS: "??????? Ok..."
    },
    {
        name: "Você quer... Derrubar o capitalismo??",
        ptBR: "Você quer... Derrubar o capitalismo??",
        enUS: "Do you want to... cut down capitalism??"
    },
    {
        name: "Eu conheço você de algum lugar?",
        ptBR: "Eu conheço você de algum lugar?",
        enUS: "Do I know you from somewhere?"
    },
    {
        name: "MOTHERRUSSIA MOTHERFUCKYEA",
        ptBR: "MOTHERRUSSIA MOTHERFUCKYEA",
        enUS: "MOTHERRUSSIA MOTHERFUCKYEA"
    },
    {
        name: "To caindo fora, tchau",
        ptBR: "To caindo fora, tchau",
        enUS: "I'm out, bye"
    },
    {
        name: "É... então meu camarada, o que veio fazer aqui?",
        ptBR: "É... então meu camarada, o que veio fazer aqui?",
        enUS: "Yeah... so comrade, what are you doing here?"
    },
    {
        name: "Preciso me tornar um lenhador, disseram que você poderia me ajudar com isso",
        ptBR: "Preciso me tornar um lenhador, disseram que você poderia me ajudar com isso",
        enUS: "I need to become a lumberjack, they said you could help me with that"
    },
    {
        name: "Camarada??? To ficando muito confuso... Tu não é o...",
        ptBR: "Camarada??? To ficando muito confuso... Tu não é o...",
        enUS: "Comrade??? I'm getting really confused... Aren't you the..."
    },
    {
        name: "É... Claro que não... Você deve ter ouvido coisas... Eu amo o capitalismo... Olha o meu machado, é da Polidas...",
        ptBR: "É... Claro que não... Você deve ter ouvido coisas... Eu amo o capitalismo... Olha o meu machado, é da Polidas...",
        enUS: "Yeah... Of course not... You must have heard things... I love capitalism... Look at my axe, it's from Polidas..."
    },
    {
        name: "Ah... Tudo bem então",
        ptBR: "Ah... Tudo bem então",
        enUS: "Oh... okay then"
    },
    {
        name: "To caindo nesse papo não... Você por acaso não o...",
        ptBR: "To caindo nesse papo não... Você por acaso não o...",
        enUS: "I'm not going to fall for this conversation... are you sure you aren't the...?"
    },
    {
        name: "Isso tá ficando muito estranho, melhor eu sair daqui",
        ptBR: "Isso tá ficando muito estranho, melhor eu sair daqui",
        enUS: "This is getting really weird, I better get out of here"
    },
    {
        name: "(Sorriso disfarçado) Desconheço essa Motherrussia que você gritou camarada, aqui só gritamos: MADEEEEEEEEEIIIIIIIRRRRRRRRAAAAAAAAA",
        ptBR: "(Sorriso disfarçado) Desconheço essa Motherrussia que você gritou camarada, aqui só gritamos: MADEEEEEEEEEIIIIIIIRRRRRRRRAAAAAAAAA",
        enUS: "(Disguised smile) I don't know this Mother Russia that you shout comrade, here we just shout: TIIMMMMMMMBBBBEEEEEEEEEEEEERRRRRRRRRRRRRRR"
    },
    {
        name: "Não estou entendendo, você não é o...",
        ptBR: "Não estou entendendo, você não é o...",
        enUS: "I don't understand, aren't you the...?"
    },
    {
        name: "Não, não sou não, você deve estar louco, sou apenas um lenhador do proletário tentando ganhar a vida nesse mundo opres... MARAVILHOSO",
        ptBR: "Não, não sou não, você deve estar louco, sou apenas um lenhador do proletário tentando ganhar a vida nesse mundo opres... MARAVILHOSO",
        enUS: "No, I'm not, you must be crazy, I'm just a proletarian lumberjack trying to make a living in this oppres.... WONDERFUL world"
    },
    {
        name: "??????... Bom, vamos continuar...",
        ptBR: "??????... Bom, vamos continuar...",
        enUS: "??????... Well, let's continue..."
    },
    {
        name: "Depois de tudo isso, to fora",
        ptBR: "Depois de tudo isso, to fora",
        enUS: "After all that, I'm out"
    },
    {
        name: "Bom, primeiro você precisa da licença opresso... De lenhador, eu vendo ela, mas só porque eu amo o capitalismo",
        ptBR: "Bom, primeiro você precisa da licença opresso... De lenhador, eu vendo ela, mas só porque eu amo o capitalismo",
        enUS: "Well, first you need the oppres... Lumberjack license, I sell it, but only because I love capitalism"
    },
    {
        name: "Ok, me mostre ai",
        ptBR: "Ok, me mostre ai",
        enUS: "Okay, show me"
    },
    {
        name: "Ih, não curto muito esses negócios de capitalismo não, até",
        ptBR: "Ih, não curto muito esses negócios de capitalismo não, até",
        enUS: "Mmm, I'm not really into capitalism, see you later"
    },
    {
        name: "Então camarada, até mais, nos veremos logo",
        ptBR: "Então camarada, até mais, nos veremos logo",
        enUS: "So comrade, see you later."
    },
    {
        name: "Tchau (Sair)",
        ptBR: "Tchau (Sair)",
        enUS: "Bye (Leave)"
    },
    {
        name: "Acho melhor você ir embora, não to muito afim de continuar o assunto",
        ptBR: "Acho melhor você ir embora, não to muito afim de continuar o assunto",
        enUS: "I think you'd better go, I don't feel like continuing the subject"
    },
    {
        name: "Tudo bem então, tchau (Sair)",
        ptBR: "Tudo bem então, tchau (Sair)",
        enUS: "Alright then, bye (Leave)"
    },
    {
        name: "Digite aqui",
        ptBR: "Digite aqui",
        enUS: "type here"
    },
    {
        name: "Nome da familia (Clã)",
        ptBR: "Nome da familia (Clã)",
        enUS: "family name (clan)"
    },
    {
        name: "Nickname (Nome do personagem)",
        ptBR: "Nickname (Nome do personagem)",
        enUS: "nickname (char name)"
    },
    {
        name: "é obrigatório",
        ptBR: "é obrigatório",
        enUS: "is required"
    },
    {
        name: "Carisma",
        ptBR: "Carisma",
        enUS: "charisma"
    },
    {
        name: "Negociação",
        ptBR: "Negociação",
        enUS: "Negotiation"
    },
    {
        name: "TimeTravel",
        ptBR: "Tempo de viagem",
        enUS: "Time Travel"
    },
    {
        name: "Comprar",
        ptBR: "Comprar",
        enUS: "buy"
    },
    {
        name: "Possui",
        ptBR: "Possui",
        enUS: "have"
    },
    {
        name: "Vender",
        ptBR: "Vender",
        enUS: "sell"
    },
    {
        name: "Vender tudo",
        ptBR: "Vender tudo",
        enUS: "sell all"
    },
    {
        name: "Quantidade",
        ptBR: "Quantidade",
        enUS: "amount"
    },
    {
        name: "Hotkeys ajudam muito no fogo da batalha, você pode colocar poções, comidas, spells e orações",
        ptBR: "Hotkeys ajudam muito no fogo da batalha, você pode colocar poções, comidas, spells e orações",
        enUS: "Hotkeys help a lot during battles, you can put potions, food, spells and prayers"
    },
    {
        name: "Arqueiros são os que possuem o ataque mais longo, mas o menor dano entre as classes, mago possui menor alcance, mas o dano é bem maior",
        ptBR: "Arqueiros são os que possuem o ataque mais longo, mas o menor dano entre as classes, mago possui menor alcance, mas o dano é bem maior",
        enUS: "Archers have the longest attack but the lowest damage among the classes, mage has a shorter range but the damage is much higher"
    },
    {
        name: "Agilidade aumenta sua velocidade de ataque e velocidade de corrida",
        ptBR: "Agilidade aumenta sua velocidade de ataque e velocidade de corrida",
        enUS: "Agility increases your attack speed and sprint speed"
    },
    {
        name: "A magia funciona como uma força que traz a vida, todos os seres vivos possuem certa quantidade dela e conforme você consegue domina-la, seu poder de domar o universo aumenta",
        ptBR: "A magia funciona como uma força que traz a vida, todos os seres vivos possuem certa quantidade dela e conforme você consegue domina-la, seu poder de domar o universo aumenta",
        enUS: "Magic works as a force that brings life, all living beings have a certain amount of it and as you manage to master it, your power to tame the universe increases"
    },
    {
        name: "Apenas caçadores podem tirar peles e carne de animais e monstros",
        ptBR: "Apenas caçadores podem tirar peles e carne de animais e monstros",
        enUS: "Only hunters can get skins and meat from animals and monsters"
    },
    {
        name: "Muitas sociedades controlam os submundos de Parallelium, muito cuidado ao conhece-las",
        ptBR: "Muitas sociedades controlam os submundos de Parallelium, muito cuidado ao conhece-las",
        enUS: "Many societies control the underworlds of Parallelium, be very careful when meeting them"
    },
    {
        name: "Não saia a noite, você pode não voltar",
        ptBR: "Não saia a noite, você pode não voltar",
        enUS: "Don't go out at night, you might not come back"
    },
    {
        name: "Cuidado com os random events, não arrisque em enganar quem está te atacando se seu carisma for de uma batata",
        ptBR: "Cuidado com os random events, não arrisque em enganar quem está te atacando se seu carisma for de uma batata",
        enUS: "Beware of random events, don't risk fooling who is attacking you if your charisma is like of a potato"
    },
    {
        name: "Cuidado! Cultistas de Moonluck foram avistados nesse lugar",
        ptBR: "Cuidado! Cultistas de Moonluck foram avistados nesse lugar",
        enUS: "Watch out! Moonluck cultists have been sighted in this place"
    },
    {
        name: "O mal teme a Corte, assim como a escuridão teme a luz",
        ptBR: "O mal teme a Corte, assim como a escuridão teme a luz",
        enUS: "Evil fears the Court as darkness fears the light"
    },
    {
        name: "Ataque de bandidos",
        ptBR: "Ataque de bandidos",
        enUS: "Bandit attack"
    },
    {
        name: "Você está sendo atacado por bandidos que te pararam no meio do caminho, um deles diz que se você não passar um dos seus itens ele vai te matar. O que você vai fazer sobre isso?",
        ptBR: "Você está sendo atacado por bandidos que te pararam no meio do caminho, um deles diz que se você não passar um dos seus itens ele vai te matar. O que você vai fazer sobre isso?",
        enUS: "You are being attacked by bandits who stopped you halfway, one of them says that if you don't hand over one of your items he will kill you. What are you going to do about it?"
    },
    {
        name: "Você conseguiu enrolar graças a sua alta habilidade em enrolar as pessoas, eles te acharam um cara bacana e foram embora",
        ptBR: "Você conseguiu enrolar graças a sua alta habilidade em enrolar as pessoas, eles te acharam um cara bacana e foram embora",
        enUS: "You managed to trick people thanks to your high skill in tricking people, they liked your vibe and left you alone"
    },
    {
        name: "Sua história os assustou tanto que eles sairam se borrando de medo",
        ptBR: "Sua história os assustou tanto que eles sairam se borrando de medo",
        enUS: "Your story scared them so much that they freaked out and left"
    },
    {
        name: "Parece que seu fracasso era inevitável, eles perceberam sua mentira, te bateram e roubaram um dos seus itens",
        ptBR: "Parece que seu fracasso era inevitável, eles perceberam sua mentira, te bateram e roubaram um dos seus itens",
        enUS: "It seems that your failure is inevitable, they saw through your lie, beat you and stole one of your items"
    },
    {
        name: "Eles não cairam na sua, agora você está com um item a menos e muitas lágrimas nos olhos",
        ptBR: "Eles não cairam na sua, agora você está com um item a menos e muitas lágrimas nos olhos",
        enUS: "They didn't fall for your conversation and now you're one item short and a lot of tears in your eyes"
    },
    {
        name: "Meus parabéns, eles eram mais burros do que você imaginava e seu papo pegou eles de jeito",
        ptBR: "Meus parabéns, eles eram mais burros do que você imaginava e seu papo pegou eles de jeito",
        enUS: "Congratulations, they were dumber than you thought and your talk convinced them"
    },
    {
        name: "Você é o rei da pilantragem, pegou eles no papo e está com sua saúde intacta e inventário também",
        ptBR: "Você é o rei da pilantragem, pegou eles no papo e está com sua saúde intacta e inventário também",
        enUS: "You are the king of crooks, you managed to convince them with the conversation and now you have your health intact and inventory too"
    },
    {
        name: "Você acabou deixando escapar uma palavra que desmentiu tudo que você tinha dito e os bandidos perceberam, por causa disso eles roubaram um item do seu inventário",
        ptBR: "Você acabou deixando escapar uma palavra que desmentiu tudo que você tinha dito e os bandidos perceberam, por causa disso eles roubaram um item do seu inventário",
        enUS: "You ended up letting out a word that contradicted everything you had said and the bandits noticed, because of that they stole an item from your inventory"
    },
    {
        name: "Quem tem boca vai a Roma, mas você mal conseguiu ir até o seu destino sem tomar uma paulada, menos um item no seu inventário",
        ptBR: "Quem tem boca vai a Roma, mas você mal conseguiu ir até o seu destino sem tomar uma paulada, menos um item no seu inventário",
        enUS: "Better to ask the way than to go astray, but you barely made it to your destination without taking a beating, one less item in your inventory"
    },
    {
        name: "Você tem sorte que não fazem mais ladrões bons hoje em dia, esse ai eram fuleiros",
        ptBR: "Você tem sorte que não fazem mais ladrões bons hoje em dia, esse ai eram fuleiros",
        enUS: "You're lucky there aren't more good thieves these days, those were stinkers"
    },
    {
        name: "Seu papo sobre ser um homem perigoso e mal colou muito bem, eles sairam correndo daqui",
        ptBR: "Seu papo sobre ser um homem perigoso e mal colou muito bem, eles sairam correndo daqui",
        enUS: "Your talk about being a dangerous and evil man went very well, they ran out of here"
    },
    {
        name: "Que azar, esses até que eram inteligentes e agora você está com o inventário mais leve",
        ptBR: "Que azar, esses até que eram inteligentes e agora você está com o inventário mais leve",
        enUS: "What bad luck, those were actually smart and now you have a lighter inventory"
    },
    {
        name: "Pelo menos agora você tá com o inventário mais livre para carregar outras coisas",
        ptBR: "Pelo menos agora você tá com o inventário mais livre para carregar outras coisas",
        enUS: "At least now you have more space in your inventory to carry other things"
    },
    {
        name: "Você achou um trevo de quatro folhas e isso mudou sua sorte, os bandidos cairam no seu papo e foram andando de volta para o covil",
        ptBR: "Você achou um trevo de quatro folhas e isso mudou sua sorte, os bandidos cairam no seu papo e foram andando de volta para o covil",
        enUS: "You found a four-leaf clover and that changed your luck, the bandits fell for your talk and walked back to their camp"
    },
    {
        name: "Sorte e papo é seu nome do meio, meus parabéns!",
        ptBR: "Sorte e papo é seu nome do meio, meus parabéns!",
        enUS: "Luck and chat is your middle name, congratulations!"
    },
    {
        name: "Você é o pior orador de toda Parallelium, por isso eles resolveram te ajudar tomando um dos seus itens",
        ptBR: "Você é o pior orador de toda Parallelium, por isso eles resolveram te ajudar tomando um dos seus itens",
        enUS: "You are the worst prayer in all of Parallelium, that's why they decided to help you by taking one of your items"
    },
    {
        name: "É o que dá esquecer que carisma também é uma habilidade para se melhorar",
        ptBR: "É o que dá esquecer que carisma também é uma habilidade para se melhorar",
        enUS: "This is what happens when you forget that charisma is also a skill to improve"
    },
    {
        name: "Seu ataque foi tão poderoso que fez com que os bandidos saissem correndo",
        ptBR: "Seu ataque foi tão poderoso que fez com que os bandidos saissem correndo",
        enUS: "Your attack was so powerful that it made the bandits run away"
    },
    {
        name: "Os bandidos contaram histórias sobre esse seu ataque esmagador",
        ptBR: "Os bandidos contaram histórias sobre esse seu ataque esmagador",
        enUS: "The bandits told stories of your crushing attack"
    },
    {
        name: "Seu ataque foi fraco demais, e bandidos da floresta odeiam os fracos, você perdeu grande parte do seu HP e está quase morrendo",
        ptBR: "Seu ataque foi fraco demais, e bandidos da floresta odeiam os fracos, você perdeu grande parte do seu HP e está quase morrendo",
        enUS: "Your attack was too weak, and forest bandits hate the weak, you've lost a lot of your HP and you're almost dying"
    },
    {
        name: "Você errou o ataque, e isso lhe custou a vida",
        ptBR: "Você errou o ataque, e isso lhe custou a vida",
        enUS: "You missed the attack, and it cost your life"
    },
    {
        name: "Seu ataque feriu um dos bandidos gravemente, por causa disso os outros sairam correndo, deixando o colega para morrer",
        ptBR: "Seu ataque feriu um dos bandidos gravemente, por causa disso os outros sairam correndo, deixando o colega para morrer",
        enUS: "Your attack seriously hurts one of the bandits, because of that the others ran away, leaving their colleague to die"
    },
    {
        name: "Seu ataque foi tão rápido que impressionou ou bandidos, fazendo eles correram por suas vidas",
        ptBR: "Seu ataque foi tão rápido que impressionou ou bandidos, fazendo eles correram por suas vidas",
        enUS: "Your attack was so fast that it stunned the bandits, making them run for their lives"
    },
    {
        name: "Seu ataque enfureceu o bando, você apanhou até quase morrer",
        ptBR: "Seu ataque enfureceu o bando, você apanhou até quase morrer",
        enUS: "Your attack enraged the group, you were nearly beaten to death"
    },
    {
        name: "Você até tentou atacar bravamente, mas caiu e bateu a cabeça, esquecendo parte da experiência que adquiriu",
        ptBR: "Você até tentou atacar bravamente, mas caiu e bateu a cabeça, esquecendo parte da experiência que adquiriu",
        enUS: "You even tried to attack bravely, but you fell and hit your head, forgetting a portion of the experience you gained"
    },
    {
        name: "A sorte estava do seu lado, e seu ataque bem sucedido afastou os bandidos",
        ptBR: "A sorte estava do seu lado, e seu ataque bem sucedido afastou os bandidos",
        enUS: "Luck was on his side, and your successful attack drove the bandits away"
    },
    {
        name: "Você fez um ataque tão maluco, que os bandidos acharam que você estava possuido por GAW, o Deus da destruição. Ficaram com tanto medo que sairam correndo por suas vidas",
        ptBR: "Você fez um ataque tão maluco, que os bandidos acharam que você estava possuido por GAW, o Deus da destruição. Ficaram com tanto medo que sairam correndo por suas vidas",
        enUS: "You did such a crazy attack that the bad guys thought you were possessed by GAW, the God of Destruction. They were so scared that they ran for their lives"
    },
    {
        name: "Você errou e apanhou bastante, mas pelo menos tá vivo",
        ptBR: "Você errou e apanhou bastante, mas pelo menos tá vivo",
        enUS: "You missed and got beaten a lot, but at least you're alive"
    },
    {
        name: "Você foi muito valente, mas não valeu de nada, tá quase caindo no chão, pedindo socorro",
        ptBR: "Você foi muito valente, mas não valeu de nada, tá quase caindo no chão, pedindo socorro",
        enUS: "You were very brave, but it's no use, you're almost falling to the ground, asking for help"
    },
    {
        name: "A deusa da sorte sorriu para você, um raio derrubou uma árvore em cima de alguns dos bandidos, e com medo dos deuses, eles sairam correndo",
        ptBR: "A deusa da sorte sorriu para você, um raio derrubou uma árvore em cima de alguns dos bandidos, e com medo dos deuses, eles sairam correndo",
        enUS: "The goddess of luck smiled at you, lightning knocked a tree down on top of some of the bandits, and scared of the gods, they ran away"
    },
    {
        name: "Um enxame de Wasps apareceram e atacaram os bandidos, a vida realmente sorriu para você",
        ptBR: "Um enxame de Wasps apareceram e atacaram os bandidos, a vida realmente sorriu para você",
        enUS: "A swarm of wasps appeared and attacked the bad guys, life really smiled on you"
    },
    {
        name: "Você teve sorte, mas não tinha força, mesmo que os sussurros das montanhas tenham assustado alguns bandidos, um deles veio e te esfaqueou",
        ptBR: "Você teve sorte, mas não tinha força, mesmo que os sussurros das montanhas tenham assustado alguns bandidos, um deles veio e te esfaqueou",
        enUS: "You were lucky, but you didn't have the strength, even though the whispers of the mountains scared some bandits, one of them came and stabbed you"
    },
    {
        name: "Mesmo que a natureza tentou te ajudar, você ainda é um homem muito fraco e por isso foi atingido por uma das laminas dos bandidos, pelo menos não morreu",
        ptBR: "Mesmo que a natureza tentou te ajudar, você ainda é um homem muito fraco e por isso foi atingido por uma das laminas dos bandidos, pelo menos não morreu",
        enUS: "Even nature trying to help you, you're still a very weak man and that's why you were hit by one of the bandits' blades, at least you didn't die"
    },
    {
        name: "Você correu por kilometros até conseguir despistar os bandidos, no fim deu certo e você não os vê mais",
        ptBR: "Você correu por kilometros até conseguir despistar os bandidos, no fim deu certo e você não os vê mais",
        enUS: "You ran for miles until you managed to lose the bad guys, in the end it worked and you don't see them anymore"
    },
    {
        name: "Você disparou na frente e conseguiu fugir deles depois de se jogar em um rio e segurar sua respiração por um bom tempo",
        ptBR: "Você disparou na frente e conseguiu fugir deles depois de se jogar em um rio e segurar sua respiração por um bom tempo",
        enUS: "You dashed ahead and managed to get away from them after diving into a river and holding your breath for quite some time"
    },
    {
        name: "Você não teve folego para fugir deles e então foi pego, perdeu um pouco de carga",
        ptBR: "Você não teve folego para fugir deles e então foi pego, perdeu um pouco de carga",
        enUS: "You didn't have the breath to run away from them and so you got caught, lost some of your inventory"
    },
    {
        name: "Eles conseguiram te encurralar no meio do caminho, quem diria que bandidos fossem tão rápidos, você perdeu um dos seus itens",
        ptBR: "Eles conseguiram te encurralar no meio do caminho, quem diria que bandidos fossem tão rápidos, você perdeu um dos seus itens",
        enUS: "They managed to corner you halfway, who would say bandits were so fast, you lost one of your items"
    },
    {
        name: "Você correu como um maratonista e pulou o rio, ainda bem, pois bandidos não conseguem pular tão longe assim",
        ptBR: "Você correu como um maratonista e pulou o rio, ainda bem, pois bandidos não conseguem pular tão longe assim",
        enUS: "You ran like a marathon runner and jumped the river, thank goodness bandits can't jump that far"
    },
    {
        name: "Você correu e escalou uma árvore tão alta que os bandidos não puderam te encontrar, saiu ileso",
        ptBR: "Você correu e escalou uma árvore tão alta que os bandidos não puderam te encontrar, saiu ileso",
        enUS: "You ran and climbed a tree so high that the bandits couldn't find you, you got away unscathed"
    },
    {
        name: "Você correu como louco, mas tropeçou e machucou a perna, além de perder um pouco de vida, também perdeu um pouco de itens",
        ptBR: "Você correu como louco, mas tropeçou e machucou a perna, além de perder um pouco de vida, também perdeu um pouco de itens",
        enUS: "You ran like crazy, but you tripped and injured your leg, not only did you lose some health but you also lost some items"
    },
    {
        name: "Você correu o máximo que pode, mas caiu e bateu a cabeça, esquecendo parte da experiência que adquiriu",
        ptBR: "Você correu o máximo que pode, mas caiu e bateu a cabeça, esquecendo parte da experiência que adquiriu",
        enUS: "You ran away as hard as you could, but you fell and hit your head, forgetting some of the experience you gained"
    },
    {
        name: "Que sorte, você correu e caiu em um buraco de Larva Gigante desocupado, você está protegido",
        ptBR: "Que sorte, você correu e caiu em um buraco de Larva Gigante desocupado, você está protegido",
        enUS: "Luckly you ran and fell into an unoccupied Giant Larva hole, you're protected"
    },
    {
        name: "Hermes olhando seu desespero o abençoou com velocidade suficiente para fugir dos bandidos, você conseguiu",
        ptBR: "Hermes olhando seu desespero o abençoou com velocidade suficiente para fugir dos bandidos, você conseguiu",
        enUS: "Hermes watching your despair blessed you with enough speed to run away from the bandits, you did it"
    },
    {
        name: "Você não aguentou correr quase nada, sua sorte é boa, mas suas pernas não ajudaram",
        ptBR: "Você não aguentou correr quase nada, sua sorte é boa, mas suas pernas não ajudaram",
        enUS: "You couldn't run far, your luck is good, but your legs didn't help"
    },
    {
        name: "Você até tentou correr e quase conseguiu, mas acabou ficando com caimbra e caiu no chão no campo aberto, os bandidos viram e pegaram alguns dos seus itens",
        ptBR: "Você até tentou correr e quase conseguiu, mas acabou ficando com caimbra e caiu no chão no campo aberto, os bandidos viram e pegaram alguns dos seus itens",
        enUS: "You even tried to run and almost made it, but ended up getting a cramp and fell to the ground in the open field, the bandits saw and took some of your items"
    },
    {
        name: "Você foi obediente e deu um dos seus itens aos bandidos, foi o melhor a se fazer",
        ptBR: "Você foi obediente e deu um dos seus itens aos bandidos, foi o melhor a se fazer",
        enUS: "You were obedient and gave one of your items to the bandits, it was the best thing to do"
    },
    {
        name: "Você encontra um velho no meio do caminho, ele diz que conhece um jogo muito interessante e impossivel de ganhar, que nem os mais inteligentes magos ou poderosos guerreiros poderiam vence-lo, um jogo que apenas os espirituosos o aceitam, mas após aceitarem, suas vidas mudam para sempre. Você quer continuar a ouvir sobre ou desistirá do destino?",
        ptBR: "Você encontra um velho no meio do caminho, ele diz que conhece um jogo muito interessante e impossivel de ganhar, que nem os mais inteligentes magos ou poderosos guerreiros poderiam vence-lo, um jogo que apenas os espirituosos o aceitam, mas após aceitarem, suas vidas mudam para sempre. Você quer continuar a ouvir sobre ou desistirá do destino?",
        enUS: "You meet an old man halfway through, he says that he knows a very interesting and impossible game to win, that not even the smartest mages or powerful warriors could beat it, a game that only the witty accept it, but once they accept it, their lives change forever. Do you want to continue to hear about it or will you give up on fate?"
    },
    {
        name: "O jogo",
        ptBR: "O jogo",
        enUS: "The game"
    },
    {
        name: "Estou curioso, continue...",
        ptBR: "Estou curioso, continue...",
        enUS: "I'm curious, keep going..."
    },
    {
        name: "Sai daqui velho, não quero saber",
        ptBR: "Sai daqui velho, não quero saber",
        enUS: "Get out of here old man, I don't want to know"
    },
    {
        name: "Não, o jogo não",
        ptBR: "Não, o jogo não",
        enUS: "No, not The game"
    },
    {
        name: "Agora você está no jogo, você nunca o ganha jogo, toda vez que você lembrar do jogo, você perde o jogo, se ouvir alguém falando do jogo, você perde o jogo, e seu objetivo agora é trazer pessoas para o jogo, como eu fiz. Bem vindo ao jogo! Perdi o jogo",
        ptBR: "Agora você está no jogo, você nunca o ganha jogo, toda vez que você lembrar do jogo, você perde o jogo, se ouvir alguém falando do jogo, você perde o jogo, e seu objetivo agora é trazer pessoas para o jogo, como eu fiz. Bem vindo ao jogo! Perdi o jogo",
        enUS: "Now you're in the game, you never win the game, every time you remember the game, you lose the game, if you hear someone talking about the game, you lose the game, and your goal now is to bring people into the game, like I did. Welcome to the game! I lost the game"
    },
    {
        name: "Jovem sábio, a curiosidade é algo perigoso, mas saiba de uma coisa, se você pesquisar sobre isso, você já perdeu!",
        ptBR: "Jovem sábio, a curiosidade é algo perigoso, mas saiba de uma coisa, se você pesquisar sobre isso, você já perdeu!",
        enUS: "Young sage, curiosity is a dangerous thing, but know one thing, if you research it, you've already lost it!"
    },
    {
        name: "PERDI O JOGO!",
        ptBR: "PERDI O JOGO!",
        enUS: "I LOST THE GAME!"
    },
    {
        name: "Hotkeys ajudam muito no fogo da batalha, você pode colocar poções, comidas, spells e orações",
        ptBR: "Hotkeys ajudam muito no fogo da batalha, você pode colocar poções, comidas, spells e orações",
        enUS: "Hotkeys help a lot during battles, you can put potions, food, spells and prayers"
    },
    {
        name: "Arqueiros são os que possuem o ataque mais longo, mas o menor dano entre as classes, mago possui menor alcance, mas o dano é bem maior",
        ptBR: "Arqueiros são os que possuem o ataque mais longo, mas o menor dano entre as classes, mago possui menor alcance, mas o dano é bem maior",
        enUS: "Archers have the longest attack but the lowest damage among the classes, mage has a shorter range but the damage is much higher"
    },
    {
        name: "Agilidade aumenta sua velocidade de ataque e velocidade de corrida",
        ptBR: "Agilidade aumenta sua velocidade de ataque e velocidade de corrida",
        enUS: "Agility increases your attack speed and sprint speed"
    },
    {
        name: "A magia funciona como uma força que traz a vida, todos os seres vivos possuem certa quantidade dela e conforme você consegue domina-la, seu poder de domar o universo aumenta",
        ptBR: "A magia funciona como uma força que traz a vida, todos os seres vivos possuem certa quantidade dela e conforme você consegue domina-la, seu poder de domar o universo aumenta",
        enUS: "Magic works as a force that brings life, all living beings have a certain amount of it and as you manage to master it, your power to tame the universe increases"
    },
    {
        name: "Apenas caçadores podem tirar peles e carne de animais e monstros",
        ptBR: "Apenas caçadores podem tirar peles e carne de animais e monstros",
        enUS: "Only hunters can get skins and meat from animals and monsters"
    },
    {
        name: "Muitas sociedades controlam os submundos de Parallelium, muito cuidado ao conhece-las",
        ptBR: "Muitas sociedades controlam os submundos de Parallelium, muito cuidado ao conhece-las",
        enUS: "Many societies control the underworlds of Parallelium, be very careful when meeting them"
    },
    {
        name: "Não saia a noite, você pode não voltar",
        ptBR: "Não saia a noite, você pode não voltar",
        enUS: "Don't go out at night, you might not come back"
    },
    {
        name: "Cuidado com os random events, não arrisque em enganar quem está te atacando se seu carisma for de uma batata",
        ptBR: "Cuidado com os random events, não arrisque em enganar quem está te atacando se seu carisma for de uma batata",
        enUS: "Beware of random events, don't risk fooling who is attacking you if your charisma is like of a potato"
    },
    {
        name: "Cuidado! Cultistas de Moonluck foram avistados nesse lugar",
        ptBR: "Cuidado! Cultistas de Moonluck foram avistados nesse lugar",
        enUS: "Watch out! Moonluck cultists have been sighted in this place"
    },
    {
        name: "O mal teme a Corte, assim como a escuridão teme a luz",
        ptBR: "O mal teme a Corte, assim como a escuridão teme a luz",
        enUS: "Evil fears the Court as darkness fears the light"
    },
    {
        name: "Pontos",
        ptBR: "Pontos",
        enUS: "points"
    },
    {
        name: "Ponto",
        ptBR: "Ponto",
        enUS: "point"
    },
    {
        name: "Você não possui NFTs para se registrar no jogo, vamos te redirecionar para nosso NFTStore",
        ptBR: "Você não possui NFTs para se registrar no jogo, vamos te redirecionar para nosso NFTStore",
        enUS: "You do not have NFTs to register in the game, we will redirect you to our NFTStore"
    },
    {
        name: "Metamask não encontrada, por favor, instale em seu navegador",
        ptBR: "Metamask não encontrada, por favor, instale em seu navegador",
        enUS: "Metamask not found, please install in your browser"
    },
    {
        name: "Digite aqui",
        ptBR: "Digite aqui",
        enUS: "Type here"
    },
    {
        name: "Senha",
        ptBR: "Senha",
        enUS: "password"
    },
    {
        name: "Nome de usuário",
        ptBR: "Nome de usuário",
        enUS: "Username"
    },
    {
        name: "Registrar-se",
        ptBR: "Registrar-se",
        enUS: "register"
    },
    {
        name: "Logar",
        ptBR: "Logar",
        enUS: "login"
    },
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // Parou aqui, agora é continuar
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    {
        name: "Concluir cadastro",
        ptBR: "Concluir cadastro",
        enUS: "register now"
    },
    {
        name: "clã",
        ptBR: "clã",
        enUS: "clan"
    },
    {
        name: "peso",
        ptBR: "peso",
        enUS: "weight"
    },
    {
        name: "bronze grenade",
        ptBR: "granada de bronze",
        enUS: "bronze grenade"
    },
    {
        name: "future grenade",
        ptBR: "Granada do Hawkins",
        enUS: "Black Hole Grenade"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    },
    {
        name: "critico",
        ptBR: "critico",
        enUS: "critical"
    }
]

export default translate;