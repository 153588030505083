const actions = {
    possibleEvents({commit}) {
        var randomNumber = () => {
            return Math.floor(Math.random() * (100 * 1000 - 0 * 1000) + 0 * 1000) / (1 * 1000);
        };
        var randomAttack = randomNumber();
        if(randomAttack < 3) {
            commit("attackBandits");
            commit("changeIsExplorer", true);
            return;
        }
        var randomTheGame = randomNumber();
        if(randomTheGame < 3) {
            commit("choiceTheGame");
            commit("changeIsExplorer", true);
            return;
        }
    }
}

export default actions;