import mutations from "./mutations";
import actions from "./actions";

export default {
    state: {
        eventNow: false,
        randomEventCurrent: {}
    },
    mutations,
    actions
}