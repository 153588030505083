import DescItemTooltipComponent from "@/shared/components/descItemTooltip/DescItemTooltip.vue";

export default {
    props: [
        'inventoryData'
    ],
    components: {
        DescItemTooltipComponent
    },
    computed: {
        Items() {
            return this.$store.state.personModule.mainPerson.Inventory;
        }
    },
    methods: {
        useItemInventory(item) {
            switch(item.groupEquip) {
                case "potion":
                    this.$store.dispatch("useItem", item);
                    this.$forceUpdate();
                    break;
                case "food":
                    this.$store.commit("useItem", item);
                    this.$forceUpdate();
                    break;
                case "work license":
                    this.$store.commit("useWorkScroll", item);
                    this.$forceUpdate();
                    break;
                case "path scroll":
                    this.$store.dispatch("usePathScroll", item);
                    this.$forceUpdate();
                    break;
                case "magic scroll":
                    this.$store.dispatch("useMagicScroll", item);
                    this.$forceUpdate();
                    break;
                case "recipe":
                    this.$store.dispatch("learnRecipe", item);
                    this.$forceUpdate();
                    break;
                default:
                    return;
            }
        },
        equipNewItem(item) {
            this.$store.dispatch("changeEquipItem", item);
            this.$forceUpdate();
        },
        equipNewTool(item) {
            this.$store.dispatch("changeTool", item);
            this.$forceUpdate();
        },
        equipNewMagicItem(item) {
            this.$store.dispatch("changeMagicItem", item);
            this.$forceUpdate();
        }
    },
    watch: {
        Items () {
            this.$forceUpdate();
        }
    }
}